import React, { Component } from "react";
import { Layout, Popover, message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NewLecture from "../../misc/newLecture/NewLecture";
import VotingActivityForm from "../../votingFolder/new";
import { logout, refreshAuthToken } from "../../../actions/authActions.js";
import { updateUserProfile, getUserProfile } from "../../../actions/users";

import { FormattedMessage } from "react-intl";
import messages from "../../../messages";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import languageData, { getLanguage } from "./languageData";

const { Content, Footer } = Layout;

const languageMenu = (changeLanguage) => (
  <ul style={{ margin: 0 }} className="gx-sub-popover">
    {languageData.map((language) => (
      <li
        style={{ padding: "9px 0 2px" }}
        className="gx-media gx-pointer"
        key={JSON.stringify(language)}
        onClick={(e) => changeLanguage(language.locale)}
      >
        <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
        <span className="gx-language-text">{language.name}</span>
      </li>
    ))}
  </ul>
);

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      lecture: false,
      votingPopup: false,
      workbookType: "workbook",
    };
  }

  componentDidMount = () => {
    const { isAuthenticated } = this.props.auth;
    if (!isAuthenticated) {
      this.context.router.push("/");
    }
  };

  toggleCollapse = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  toggleLecture = (property) => {
    this.setState({ lecture: !this.state.lecture, workbookType: property });
  };
  toggleVotingPopup = () => {
    this.setState({ votingPopup: !this.state.votingPopup });
  };

  logout = (e) => {
    e.preventDefault();
    this.props.logout();
    this.context.router.push("/");
  };

  changeLanguage = (language) => {
    console.log(language);
    const { updateUserProfile, refreshAuthToken } = this.props;
    updateUserProfile({ locale: language })
      .then((res) => {
        message.success("Your locale settings updated successfully.");
        // this.getUserProfile();
        refreshAuthToken();
      })
      .catch((err) => {
        let { data } = err.response;
        message.error("'Error while updating your language settings.'");
      });
  };

  render() {
    const { collapse, votingPopup } = this.state;
    const {
      isLicensed,
      licenseExpiry,
      locale,
      educator,
    } = this.props.auth.user;
    return (
      <>
        <Layout className="gx-app-layout">
          {/* <Layout.Header className="header">

        </Layout.Header> */}
          <Sidebar
            visible={collapse}
            toggleCollapse={this.toggleCollapse}
            currentUser={this.props.auth.user}
            logout={this.props.logout}
          />

          <Layout>
            {!isLicensed ? (
              <div
                style={{
                  width: "100%",
                  height: "20px",
                  background: "#294957",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "#fff", fontSize: 15 }}>
                  {Math.round(licenseExpiry) <= 0
                    ? "Your trial is expired."
                    : `Your trial will expire in ${Math.round(
                        licenseExpiry
                      )} days.`}{" "}
                  <a href="/profile">Click Here</a> to enter your TouchIT Serial
                  Number from your Interactive LED.(On the side of product)
                </span>
              </div>
            ) : null}
            <Topbar
              toggleCollapse={this.toggleCollapse}
              toggleLecture={this.toggleLecture}
              toggleVotingPopup={this.toggleVotingPopup}
              nsgContext={this.context}
              logout={this.props.logout}
              currentUser={this.props.auth.user}
            />
            <Content className={`gx-layout-content`}>
              <div className="gx-main-content-wrapper">
                {this.props.children}
              </div>
              <Footer style={{ display: "flex" }}>
                <span>
                  © Copyright 2008-{new Date().getFullYear()}. All Rights
                  Reserved, TouchIT Technologies Holdings, Inc
                </span>
                <div className="gx-module-box-header-right">
                  <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomLeft"
                    content={languageMenu(this.changeLanguage)}
                    trigger="click"
                  >
                    <span className="gx-pointer gx-flex-row gx-align-items-center">
                      <i
                        className={`flag flag-24 flag-${
                          getLanguage(locale)?.icon
                        }`}
                      />
                      <span className="gx-pl-2 gx-language-name">
                        {getLanguage(locale)?.name}
                      </span>
                      <i className="icon icon-chevron-down gx-pl-2" />
                    </span>
                  </Popover>
                </div>
              </Footer>
            </Content>
          </Layout>

          <NewLecture
            visible={this.state.lecture}
            toggleModal={this.toggleLecture}
            workbookType={this.state.workbookType}
            title={
              this.state.workbookType == "workbook" ? (
                <FormattedMessage {...messages.createNewWorkbook} />
              ) : (
                <FormattedMessage {...messages.startNewLecture} />
              )
            }
          />

          <VotingActivityForm
            visible={votingPopup}
            toggleModal={this.toggleVotingPopup}
            votingPopupType={"new"}
          />
        </Layout>
      </>
    );
  }
}

Auth.propTypes = {
  logout: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

Auth.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, {
  logout,
  refreshAuthToken,
  updateUserProfile,
  getUserProfile,
})(Auth);
