import React, { Component } from "react";
import {
  Modal,
  Typography,
  Form,
  Input,
  Button,
  Alert,
  message,
  Switch,
  Space,
  Radio,
} from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import actions from "../../../actions/votingActivityActions";
import map from "lodash/map";
const { Text } = Typography;
class NsgComponent extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      folders: [],
      errors: {},
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    const { workbook, votingPopupType } = this.props;
    if (votingPopupType == "edit") {
      this.setState({ name: workbook.name, folder: workbook.folder });
    }

    try {
      let {
        data: { data: nsgData },
      } = await actions.readFolder();
      this.setState({ folders: nsgData, isLoading: false });
    } catch (error) {
      this.setState({
        errors: error.response ? error.response.data.errors : error,
        isLoading: false,
      });
    }
  };

  onFinish = (values) => {
    actions
      .create(values)
      .then(({ data }) => {
        this.context.router.push({
          pathname: "/voting-editor",
          query: { id: data.activity._id },
        });
        this.props.toggleModal();
      })
      .catch((err) => {
        this.setState({
          errors: err.response ? err.response.data.errors : err,
          isLoading: false,
        });
      });
  };

  render() {
    const { errors, folders } = this.state;
    const { toggleModal, votingPopupType } = this.props;

    const buttonText =
      votingPopupType == "new"
        ? "Create Voting Activity"
        : "Edit Voting Activity";
    return (
      <Modal
        title="Create Voting Activity"
        visible={this.props.visible}
        okText={buttonText}
        okButtonProps={{
          form: "wbForm",
          key: "submit",
          htmlType: "submit",
          size: "large",
        }}
        onCancel={toggleModal}
        cancelButtonProps={{
          size: "large",
        }}
      >
        <Form
          name="wbForm"
          size="large"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          {errors.form && <Alert message={errors.form} type="error" />}

          <Form.Item
            name="name"
            {...(errors.name && {
              help: errors.name,
              validateStatus: "error",
            })}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Activity Name" />
          </Form.Item>

          <Form.Item
            style={{ paddingLeft: 15, paddingRight: 15 }}
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.createNew !== currentValues.createNew
            }
          >
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  name="folder"
                  {...(errors.folder && {
                    help: errors.folder,
                    validateStatus: "error",
                  })}
                  normalize={(value) => {
                    return `${(value || "").replace(/[^a-zA-Z0-9-_ ]/g, "")}`;
                  }}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  {getFieldValue("createNew") ? (
                    <Input placeholder="New folder name" />
                  ) : (
                    <Space direction="vertical">
                      <Text>Select Folder</Text>
                      <Radio.Group>
                        {map(folders, (val) => (
                          <Radio value={val}>{val}</Radio>
                        ))}
                      </Radio.Group>
                    </Space>
                  )}
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item name="createNew">
            <Switch
              onChange={() => {
                this.formRef.current.setFieldsValue({
                  folder: "",
                });
              }}
              checkedChildren="Uncheck to use existing folder"
              unCheckedChildren="Check to create new folder"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

NsgComponent.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  votingPopupType: PropTypes.string.isRequired,
  actions: PropTypes.object,
  workbook: PropTypes.object,
};

NsgComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { actions })(NsgComponent);
