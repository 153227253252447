import React, { Component } from "react";
import GooglePicker from "../common/GoogleDrive";
// import {connect } from 'react-redux';
import DataPreviewList from "./DataPreviewList";
import Promise from "bluebird";

import DataFormater from "./common/DataFormater";
import { categorizeGoogleMedia } from "../../utils/helper";
// import { IMAGE_TYPE } from '../../utils/types';
import PropTypes from "prop-types";

function getExtention(name) {
	console.log(name);
	return name
		.slice((Math.max(0, name.lastIndexOf(".")) || Infinity) + 1)
		.toUpperCase();
}

class Google extends Component {
	constructor(props) {
		super(props);
		this.state = {
			IMAGE: [],
			LAYOUT: [],
			SPREADSHEET: [],
			GOOGLE_SPREADSHEET: [],
			VIDEO: [],
			TEXT: [],
			GOOGLE_TEXT: [],
			DATA: [],
			GOOGLE_DATA: [],
			NOTEBOOK: [],
			dataFlag: true,
		};
		this.googleResponse = this.googleResponse.bind(this);
		this.delClick = this.delClick.bind(this);
		this.addAllToWorkbook = this.addAllToWorkbook.bind(this);
	}

	googleResponse(media) {
		if (!(media.action === "picked")) {
			return false;
		}
		if (media.docs.length > 0) {
			this.setState({ dataFlag: false });
		}
		let local = {
			IMAGE: [],
			LAYOUT: [],
			SPREADSHEET: [],
			GOOGLE_SPREADSHEET: [],
			VIDEO: [],
			TEXT: [],
			GOOGLE_TEXT: [],
			DATA: [],
			GOOGLE_DATA: [],
			NOTEBOOK: [],
		};
		const categorize = categorizeGoogleMedia(media.docs);
		for (const keys in categorize) {
			categorize[keys].forEach((i) => {
				local[keys].push(i);
			});
			this.state[keys].forEach((i) => {
				local[keys].push(i);
			});
		}
		let IMAGE = local.IMAGE,
			LAYOUT = local.LAYOUT,
			SPREADSHEET = local.SPREADSHEET,
			GOOGLE_SPREADSHEET = local.GOOGLE_SPREADSHEET,
			VIDEO = local.VIDEO,
			DATA = local.DATA,
			GOOGLE_DATA = local.GOOGLE_DATA,
			TEXT = local.TEXT,
			GOOGLE_TEXT = local.GOOGLE_TEXT,
			NOTEBOOK = local.NOTEBOOK;

		local = {
			IMAGE,
			LAYOUT,
			SPREADSHEET,
			GOOGLE_SPREADSHEET,
			VIDEO,
			TEXT,
			GOOGLE_TEXT,
			DATA,
			GOOGLE_DATA,
			NOTEBOOK,
		};
		const newLocal = {
			IMAGE: [],
			LAYOUT: [],
			SPREADSHEET: [],
			GOOGLE_SPREADSHEET: [],
			VIDEO: [],
			TEXT: [],
			GOOGLE_TEXT: [],
			DATA: [],
			GOOGLE_DATA: [],
			GOOGLE_DATA: [],
			NOTEBOOK: [],
		};
		const requestObject = [];
		const promiseArray = [];
		for (const keys in local) {
			local[keys].forEach((obj) => {
				const p = new Promise((resolve, reject) => {
					let file = obj,
						id = file.id,
						// request = window.gapi.client.drive.files.get({
						//   fileId: id
						// });

						request = gapi.client.request({
							path: `/drive/v2/files/${id}`,
							method: "GET",
						});

					request.execute((res) => {
						if (res.exportLinks) {
							res.downloadUrl = res.exportLinks["application/pdf"];
							file.type = "pdf";
						}
						file.source = "base64";
						newLocal[keys].push(file);
						console.log(res);

						if (file.downloadFileType !== "notebook") {
							file.thumbnail = res.thumbnailLink;
						}

						const xhr = new XMLHttpRequest();
						xhr.open("GET", res.downloadUrl);
						let token = window.gapi.auth.getToken();
						token = token.access_token;
						xhr.responseType = "blob";

						xhr.setRequestHeader("Authorization", `Bearer ${token}`);
						xhr.onload = function () {
							const blob = xhr.response;
							file.downloadLink = window.URL.createObjectURL(blob);

							const myXhr = new XMLHttpRequest();
							myXhr.responseType = "blob";

							myXhr.onload = function () {
								const recoveredBlob = myXhr.response;

								const reader = new FileReader();

								reader.onload = function () {
									const blobAsDataUrl = reader.result;
									file.downloadLink = blobAsDataUrl;
									file.downloadLinkType = "base64";

									// if (IMAGE_TYPE.indexOf(getExtention(file.name)) > -1) {
									//   file.thumbnail = blobAsDataUrl;
									// }
									resolve();
								};
								reader.readAsDataURL(recoveredBlob);
							};
							myXhr.open("GET", window.URL.createObjectURL(blob));
							myXhr.send();
							console.log(window.URL.createObjectURL(blob));
						};
						xhr.onerror = function () {
							console.log("error");
						};
						xhr.send();
					});
					console.log("after");
				});

				promiseArray.push(p);
			});
		}

		Promise.all(promiseArray).then(() => {
			console.log(newLocal);
			this.setState({
				IMAGE,
				LAYOUT,
				GOOGLE_SPREADSHEET,
				SPREADSHEET,
				VIDEO,
				GOOGLE_TEXT,
				TEXT,
				GOOGLE_DATA,
				DATA,
				NOTEBOOK,
			});

			console.log("all the files were created");
		});
	}

	downloadFileView(data, name, mimeType) {
		const a = document.createElement("a");
		a.style = "display: none";
		let json = data,
			blob = new Blob([json], { type: mimeType }),
			url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = name;
		a.click();
		window.URL.revokeObjectURL(url);
	}

	delClick(item) {
		const state = this.state;
		let isDeleted = false;
		for (const key in state) {
			if (!isDeleted) {
				state[key].forEach((i) => {
					const eq = JSON.stringify(item) == JSON.stringify(i);
					if (eq && !isDeleted) {
						isDeleted = true;
						state[key].splice(i, 1);
						console.log(state[key]);
						console.log(key);
						const data = state[key];
						if (key == "IMAGE") {
							this.setState({ IMAGE: data });
						} else if (key == "LAYOUT") {
							this.setState({ LAYOUT: data });
						} else if (key == "GOOGLE_SPREADSHEET") {
							this.setState({ GOOGLE_SPREADSHEET: data });
						} else if (key == "SPREADSHEET") {
							this.setState({ SPREADSHEET: data });
						} else if (key == "VIDEO") {
							this.setState({ VIDEO: data });
						} else if (key == "GOOGLE_TEXT") {
							this.setState({ GOOGLE_TEXT: data });
						} else if (key == "TEXT") {
							this.setState({ TEXT: data });
						} else if (key == "DATA") {
							this.setState({ DATA: data });
						} else if (key == "GOOGLE_DATA") {
							this.setState({ GOOGLE_DATA: data });
						} else if (key == "NOTEBOOK") {
							this.setState({ NOTEBOOK: data });
						}
					}
				});
			}
		}
	}

	addAllToWorkbook() {
		const state = this.state;
		for (const key in state) {
			if (typeof state[key] == "object") {
				state[key].forEach((item) => {
					this.props.addClick(item, "thumbnail");
				});
			}
		}
	}

	render() {
		return (
			<li>
				<GooglePicker
					clientId={
						"822121502733-b90091552l3kcd04v9e9v3pr2lvu5bkm.apps.googleusercontent.com"
					}
					developerKey={"AIzaSyDYWEMkhEinKD1hSdwRz31M4BYnK21M6oU"}
					scope={["https://www.googleapis.com/auth/drive"]}
					onSuccess={this.googleResponse}
					multiselect
					navHidden={false}
					authImmediate={false}
					viewId={"DOCS"}
				>
					<a href="javascript:;" className="opener btn-google-plus">
						<i className="fa fa-google" aria-hidden="true" /> G-Drive
          </a>
				</GooglePicker>
				<div
					className="slide"
					style={{
						display: "block",
						position: "absolute",
						top: "-9999px",
						left: "-9999px",
						width: 297,
					}}
				>
					<DataPreviewList
						IMAGE={this.state.IMAGE}
						LAYOUT={this.state.LAYOUT}
						SPREADSHEET={this.state.SPREADSHEET}
						GOOGLE_SPREADSHEET={this.state.GOOGLE_SPREADSHEET}
						TEXT={this.state.TEXT}
						GOOGLE_TEXT={this.state.GOOGLE_TEXT}
						VIDEO={this.state.VIDEO}
						DATA={this.state.DATA}
						GOOGLE_DATA={this.state.GOOGLE_DATA}
						NOTEBOOK={this.state.NOTEBOOK}
						dataFlag={this.state.dataFlag}
						mouseUp={this.props.mouseUp}
						addClick={this.props.addClick}
						delClick={this.delClick}
						driveType="gdrive"
					/>
				</div>
			</li>
		);
	}
}
Google.propTypes = {
	mouseUp: PropTypes.func.isRequired,
	addClick: PropTypes.func.isRequired,
};
export default Google;
