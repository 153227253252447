import React, { Component } from "react";
import loadScript from "load-script";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import messages from "../../messages";

const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/classroom/v1/rest",
];
const SCOPES =
  "https://www.googleapis.com/auth/classroom.coursework.me.readonly https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.coursework.students.readonly https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/classroom.profile.emails https://www.googleapis.com/auth/classroom.profile.photos";

class GoogleClassroom extends Component {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,

    scope: PropTypes.string,
    buttonText: PropTypes.string,
    style: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.scriptLoadingStarted = false;
  }

  componentDidMount = () => {
    if (this.isGoogleReady()) {
      this.onApiLoad();
    } else if (!this.scriptLoadingStarted) {
      this.scriptLoadingStarted = true;
      loadScript(GOOGLE_SDK_URL, this.onApiLoad);
    } else {
      console.info("google classroom loading ...");
    }
  };

  isGoogleReady = () => {
    return !!window.gapi;
  };

  isGoogleAuthReady = () => {
    return !!window.gapi.auth2;
  };

  onApiLoad = () => {
    window.gapi.load("client:auth2", this.initClient);
  };

  initClient = () => {
    window.gapi.client.init({
      discoveryDocs: DISCOVERY_DOCS,
      clientId: this.props.clientId,
      scope: SCOPES,
    });
  };

  handleAuthClick = () => {
    if (
      !this.isGoogleReady() ||
      !this.isGoogleAuthReady() ||
      !window.gapi.auth2.getToken ||
      !window.gapi.auth2.getAuthInstance().isSignedIn.get()
    ) {
      window.gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          this.listCourses();
        });
    } else {
      this.listCourses();
    }
  };

  listCourses = async () => {
    let classroomList = [];
    try {
      let classroomCoursesList = await window.gapi.client.classroom.courses.list(
        {
          pageSize: 100,
          teacherId: "me",
        }
      );
      const { courses } = classroomCoursesList.result;

      for (const item of courses) {
        if (item.courseState == "ACTIVE") {
          let classroom = {
            name: item.name,
            student: [],
          };

          let classroomCoursesStudents = await window.gapi.client.classroom.courses.students.list(
            { courseId: item.id }
          );
          const { students } = classroomCoursesStudents.result;

          if (students) {
            for (const std of students) {
              const { profile } = std;

              classroom.student.push({
                email: profile.emailAddress,
                name: profile.name.fullName,
              });
            }
          }
          classroomList.push(classroom);
        }
      }

      this.props.onSuccess(classroomList);
    } catch (e) {
      this.props.onFailure(e);
    }
  };

  render() {
    return (
      <div onClick={this.handleAuthClick}>
        {this.props.children ? (
          this.props.children
        ) : (
          <button>
            <FormattedMessage {...messages.googleClassroon} />
          </button>
        )}
      </div>
    );
  }
}

export default GoogleClassroom;
