import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import s from './Spotlight.module.css';



class Spotlight extends Component {
    static propTypes = {
        activeTools: PropTypes.array.isRequired
    };

    constructor(props) {
        super(props)

        this.state = {

        }
    }

    componentDidMount() {
        this.spotlightSize = 'transparent 160px, rgba(0, 0, 0, 0.85) 200px)';

        window.addEventListener('mousemove', this.handleMousemove, true);
        window.addEventListener('mousedown', this.handleMousedown, true);
        window.addEventListener('mouseup', this.handleMouseUp, true);

        window.addEventListener('touchstart', this.handleMousedown, false);
        window.addEventListener('touchmove', this.handleMousemove, false);
        window.addEventListener('touchend', this.handleMouseUp, false);

    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleMousemove, true);
        window.removeEventListener('mousedown', this.handleMousedown, true);
        window.removeEventListener('mouseup', this.handleMouseUp, true);

        window.removeEventListener('touchstart', this.handleMousedown, false);
        window.removeEventListener('touchmove', this.handleMousemove, false);
        window.removeEventListener('touchend', this.handleMouseUp, false);
    }

    handleMousemove = (e) => {
        this.updateSpotlight(e)
    }

    handleMousedown = (e) => {
        this.spotlightSize = 'transparent 130px, rgba(0, 0, 0, 0.95) 150px)';
        this.updateSpotlight(e);
    }
    handleMouseUp = (e) => {
        this.spotlightSize = 'transparent 160px, rgba(0, 0, 0, 0.85) 200px)';
        this.updateSpotlight(e);
    }

    updateSpotlight = (e) => {
        if (e.touches && e.touches.length) {
            e.pageX = e.touches[0].pageX
            e.pageY = e.touches[0].pageY
        }
        this.spotlight.style.backgroundImage = `radial-gradient(circle at ${e.pageX / window.innerWidth * 100}% ${e.pageY / window.innerHeight * 100}%, ${this.spotlightSize}`;
    }

    render() {
        return (
            <div className={s["spotlight"]} ref={v => this.spotlight = v}></div>
        );
    }
}

export default Spotlight;