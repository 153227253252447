import axios from 'axios';

export function saveCourse(courseData) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/course/saveCourse`, courseData);
}

export function fetchCourses(data) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/course/fetch`, data);
}

export function deleteCourse(courseID, userID) {
  return dispatch => axios.post(`/api/course/deleteCourse`, { courseID, userID });
}

export function updateCourse(data) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/course/updateCourse`, { data });
}

export function addNewList(data) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/course/addNewList`, data);
}

export function addNewEmail(data) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/course/addNewEmail`, data);
}
export function sendInvitation(data) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/course/inviteStudents`, data);
}
export function deleteEmail(data) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/course/deleteEmail`, data);
}
