import React, { Component } from "react";
import OneDrive from "../../common/OneDrive/OneDrive";
import { categorizeOneMedia } from "../../../utils/helper";
import PropTypes from "prop-types";
import s from "../WorkbookPage.module.css";
import cx from "classnames";

class OneDrivePage extends Component {
  static propTypes = {
    onProcessData: PropTypes.func.isRequired,
    activeDrive: PropTypes.string.isRequired,
    viewFiles: PropTypes.func.isRequired,
    One: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {};

  onSuccess = (files) => {
    const { value } = files;

    let { onProcessData } = this.props;
    let One = {
      IMAGE: [],
      LAYOUT: [],
      SPREADSHEET: [],
      VIDEO: [],
      TEXT: [],
      DATA: [],
      NOTEBOOK: [],
      count: 0,
    };

    const categorize = categorizeOneMedia(value);
    for (const keys in categorize) {
      One.count += categorize[keys].length;

      categorize[keys].forEach((i) => {
        One[keys].push(i);
      });
    }

    onProcessData("One", One);
  };

  render() {
    let { One, viewFiles, activeDrive } = this.props;
    let { count } = One;
    return (
      <li
        className={cx(s["at-onedrive"], {
          [s["at-showcatagory"]]: count,
          [s["at-active"]]: activeDrive == "One",
        })}
      >
        {/* <i onClick={viewFiles.bind(this, 'One')} className={cx('fa fa-folder-open-o', s['at-btnviewfiles'])}></i> */}
        <OneDrive
          clientId={"c6b44894-0169-492a-a51f-e59f91ad3849"}
          success={this.onSuccess}
          multiSelect={true}
          action={"download"}
          advanced={{
            // redirectUri: 'https://app.contribute.cloud/workbook',
            redirectUri: `${process.env.REACT_APP_API_HOST}/workbook`,
          }}
        >
          <a href={null}>
            <i className={s["at-divebtnholder"]}>
              <img src="images/cloud-icon.png" />
            </i>
            <span>One Drive</span>
          </a>
        </OneDrive>
      </li>
    );
  }
}

export default OneDrivePage;
