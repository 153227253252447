import { SET_GALLERY_FILES } from '../actions/types';

const initialState = {
  files: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_GALLERY_FILES:
      return {
        files: action.files,
      };
    default: return state;
  }
};
