// export const globalFolders = ['CC - 01 Kindergarten - Math', 'CC - Grade 1 - Math'];
export const globalFolders = [];
export const globalFoldersActivities = {
    // 'CC - 01 Kindergarten - Math': [
    //     {
    //         "folder": "01 Kindergarten - Math",
    //         "name": "Counting & Cardinality",
    //         "questions": [
    //             { "options": [], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide1.png', "answer": '' },
    //             { "options": ["A", "B", "C", "D"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide2.png', "answer": 'C' },
    //             { "options": ["A", "B", "C", "D"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide3.png', "answer": 'B' },
    //             { "options": ["A", "B", "C", "D"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide4.png', "answer": 'D' },
    //             { "options": ["A", "B", "C", "D"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide5.png', "answer": 'D' },
    //             { "options": [], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide6.png', "answer": '' },
    //             { "options": [], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide7.png', "answer": '' },
    //             { "options": ["A", "B", "C", "D"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide8.png', "answer": 'C' },
    //             { "options": ["A", "B", "C"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide9.png', "answer": 'C' },
    //             { "options": ["A", "B", "C"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide10.png', "answer": 'A' },
    //             { "options": ["A", "B"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide11.png', "answer": 'B' },
    //             { "options": ["A", "B", "C"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide12.png', "answer": 'C' },
    //             { "options": ["A", "B"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide13.png', "answer": 'A' },
    //             { "options": ["A", "B"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide14.png', "answer": 'A' },
    //             { "options": ["A", "B"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide15.png', "answer": 'B' },
    //             { "options": ["A", "B"], "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Counting & Cardinality/Slide16.png', "answer": 'A' },
    //         ],
    //     },
    //     {
    //         "folder": "01 Kindergarten - Math",
    //         "name": "Geometry",
    //         "questions": [
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide1.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide2.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide3.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide4.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide5.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide6.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide7.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide8.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide9.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide10.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide11.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide12.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide13.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide14.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide15.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Geometry/Slide16.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //         ],
    //     },
    //     {
    //         "folder": "01 Kindergarten - Math",
    //         "name": "Measurement & Data",
    //         "questions": [
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Measurement & Data/Slide1.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Measurement & Data/Slide2.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Measurement & Data/Slide3.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Measurement & Data/Slide4.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Measurement & Data/Slide5.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Measurement & Data/Slide6.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Measurement & Data/Slide7.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Measurement & Data/Slide8.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Measurement & Data/Slide9.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Measurement & Data/Slide10.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Measurement & Data/Slide11.png', "options": ["A", "B"], "answer": '' },
    //         ],
    //     },
    //     {
    //         "folder": "01 Kindergarten - Math",
    //         "name": "Number & operations in base ten",
    //         "questions": [
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide1.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide2.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide3.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide4.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide5.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide6.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide7.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide8.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide9.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide10.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide11.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide12.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide13.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide14.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Number & operations in base ten/Slide15.png', "options": [], "answer": '' },
    //         ],
    //     },
    //     {
    //         "folder": "01 Kindergarten - Math",
    //         "name": "Operations & Algebraic thinking",
    //         "questions": [
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide1.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide2.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide3.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide4.png', "options": ["A", "B", "C"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide5.png', "options": ["A", "B", "C"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide6.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide7.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide8.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide9.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide10.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide11.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide12.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide13.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide14.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/01 Kindergarten/Maths/Operations & Algebraic thinking/Slide15.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //         ],
    //     },

    // ],
    // 'CC - Grade 1 - Math': [
    //     {
    //         "folder": "Grade 1 - Math",
    //         "name": "Geometry",
    //         "questions": [
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide1.png", "options": [], "answer": '' },
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide2.png", "options": ["A", "B", "C"], "answer": "C" },
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide3.png", "options": ["A", "B", "C"], "answer": "A" },
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide4.png", "options": ["A", "B"], "answer": "B" },
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide5.png", "options": ["A", "B"], "answer": "B" },
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide6.png", "options": ["A", "B", "C", "D"], "answer": "B" },
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide7.png", "options": ["A", "B", "C", "D"], "answer": "C" },
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide8.png", "options": ["A", "B", "C", "D"], "answer": "A" },
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide9.png", "options": ["A", "B"], "answer": "B" },
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide10.png", "options": ["A", "B"], "answer": "B" },
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide11.png", "options": ["A", "B"], "answer": "B" },
    //             { "type": "image", "question": "voting-attachments/Grade 1/Maths/Geometry/Slide12.png", "options": ["A", "B"], "answer": "A" }
    //         ],
    //     },
    //     {
    //         "folder": "Grade 1 - Math",
    //         "name": "Measurement & Data",
    //         "questions": [
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide1.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide2.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide3.png', "options": ["A", "B", "C"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide4.png', "options": ["A", "B", "C"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide5.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide6.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide7.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide8.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide9.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide10.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide11.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide12.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide13.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide14.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Measurement & Data/Slide15.png', "options": [], "answer": '' },
    //         ],
    //     },
    //     {
    //         "folder": "Grade 1 - Math",
    //         "name": "Number & operations in base ten",
    //         "questions": [
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide1.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide2.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide3.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide4.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide5.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide6.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide7.png', "options": ["A", "B", "C"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide8.png', "options": ["A", "B", "C"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide9.png', "options": ["A", "B", "C"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide10.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide11.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide12.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide13.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Number & operations in base ten/Slide14.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //         ],
    //     },
    //     {
    //         "folder": "Grade 1 - Math",
    //         "name": "Operations & Algebraic thinking",
    //         "questions": [
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide1.png', "options": [], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide2.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide3.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide4.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide5.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide6.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide7.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide8.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide9.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide10.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide11.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide12.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide13.png', "options": ["A", "B", "C", "D"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide14.png', "options": ["A", "B", "C"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide15.png', "options": ["A", "B"], "answer": '' },
    //             { "type": "image", "question": 'voting-attachments/Grade 1/Maths/Operations & Algebraic thinking/Slide16.png', "options": ["A", "B", "C"], "answer": '' },
    //         ],
    //     },
    // ]
};