import React from "react";
import { Col, Row, Card, Space } from "antd";
import { getLanguage } from "../layout/auth/languageData";

const ProfileOverview = ({ userProfile }) => {
  return (
    <>
      <Card
        title={"Overview"}
        className={`gx-card-widget gx-card-tabs gx-card-profile`}
      >
        <div className="gx-mb-2">
          <Row>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <i className={`icon icon-user gx-fs-xlxl gx-text-orange`} />
                </div>

                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">First Name</h6>
                  <p className="gx-mb-0">{userProfile.first_name}</p>
                </div>
              </div>
            </Col>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <i className={`icon icon-user gx-fs-xlxl gx-text-orange`} />
                </div>

                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Last Name</h6>
                  <p className="gx-mb-0">{userProfile.last_name}</p>
                </div>
              </div>
            </Col>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <i className={`icon icon-wall gx-fs-xlxl gx-text-orange`} />
                </div>

                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Username</h6>
                  <p className="gx-mb-0">{userProfile.username}</p>
                </div>
              </div>
            </Col>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <i className={`icon icon-email gx-fs-xlxl gx-text-orange`} />
                </div>

                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Email</h6>
                  <p className="gx-mb-0 gx-link">{userProfile.email}</p>
                </div>
              </div>
            </Col>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <i
                    className={`icon icon-translation gx-fs-xlxl gx-text-orange`}
                  />
                </div>

                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Language</h6>
                  <p className="gx-mb-0">
                    {getLanguage(userProfile.locale)?.name}{" "}
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <i
                    className={`icon icon-data-display gx-fs-xlxl gx-text-orange`}
                  />
                </div>

                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Lecture Tools</h6>
                  <p className="gx-mb-0">
                    {userProfile.lectureTools ? "Enabled" : "Disabled"}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
      <Card title={null} className={`gx-card-widget gx-card-profile`}>
        <div className="ant-card-head">
          <span className="ant-card-head-title gx-mb-1">About Us</span>
        </div>
        <div className="gx-pt-md-3">
          <div className="gx-media gx-featured-item">
            <div className="gx-featured-thumb">
              <a href="https://touchittechnologies.com/" target="_blank">
                <img
                  className="gx-rounded-lg"
                  src="images/touchit-logo.png"
                  alt="TouchIT Technologies"
                />
              </a>
            </div>
            <div className="gx-media-body gx-featured-content">
              <div className="gx-featured-content-left">
                <h3 className="gx-mb-2">TouchIT Technologies</h3>
                <div className="ant-row-flex">
                  <Space direction="vertical">
                    <div className="gx-media gx-text-grey gx-mb-1">
                      <i
                        className={`icon icon-email gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
                      />
                      <span className="gx-media-body gx-ml-1">
                        sales@touchittechnologies.com
                      </span>
                    </div>

                    <div className="gx-media gx-text-grey gx-mb-1">
                      <i
                        className={`icon icon-phone gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
                      />
                      <span className="gx-media-body gx-ml-1">
                        248 764 1084
                      </span>
                    </div>
                  </Space>

                  <div>
                    <p></p>
                    <p>
                      Founded in 2008, based in Austin, TX, USA, TouchIT
                      Technologies designs and manufactures innovative
                      touch-based interactive products for use in both Education
                      and Corporate environments under the TouchIT Technologies
                      brand name as well as under various OEM brands throughout
                      the world.
                    </p>
                    <p>
                      TouchIT began life as a manufacturer of interactive
                      whiteboards. We developed what we believe was the world’s
                      first touch-based enamel steel interactive whiteboard, a
                      format which was emulated by many manufacturers around the
                      world. The TouchIT Board was sold in over 30 countries
                      world wide and over 20K units were sold before it went end
                      of life in 2015.
                    </p>
                    <p>
                      Today, TouchIT concentrates on manufacturing Interactive
                      Flat Panels. In 2019 we’ve launched our 7th generation of
                      the product which has become a firm favourite with
                      schools, colleges and business around the World – TouchIT
                      Rapide.
                    </p>
                    <p>
                      On the software side of things, TouchIT has its own App
                      Store (www.touchitappstore.com) a native App Store App for
                      Android, as well as being the designer and owner of the
                      Contribute family of annotation tools and content delivery
                      platform. Contribute Cloud is our cloud-based content
                      creation, delivery, collaboration and casting platform.
                      Contribute Signage is our digital signage software which
                      combines playback apps with a web control panel. Recent
                      additions to the portfolio are TouchIT Air for IOS and OSX
                      casting and Wireless Wizard.
                    </p>
                    <p>
                      <strong>Links</strong>
                    </p>
                    <p className="gx-link">
                      <a href="http://www.touchitappstore.com" target="_blank">
                        http://www.touchitappstore.com
                      </a>
                    </p>
                    <p className="gx-link">
                      <a href="http://contribute.cloud" target="_blank">
                        http://contribute.cloud
                      </a>
                    </p>
                    <p className="gx-link">
                      <a
                        href="http://www.contributesignage.com"
                        target="_blank"
                      >
                        http://www.contributesignage.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ProfileOverview;
