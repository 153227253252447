import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";

import s from "./unauth.module.css";
import { FormattedMessage } from "react-intl";
import messages from "../../../messages";

class UnAuth extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const { isAuthenticated, educator } = this.props.auth;
    if (isAuthenticated) {
      this.context.router.push("/folders");
    }
  }

  render() {
    return (
      <div className={s["background-image"]}>
        <div id="mainContainer" className={s["container-outer"]}>
          <div className={s["container-box"]}>
            <div className={s["container-inner"]}>
              <div className={cx("row", s["at-row"])}>
                <div className={cx("col", s["zero-padding"])}>
                  <div className={cx(s["left-aligned"])}>
                    <div className={cx(s["the-boxes"])}>
                      <div className={cx(s["logo-dimention"])}>
                        <img
                          className={s["logo-dimention"]}
                          src={"images/logo.png"}
                          alt="Contribute Cloud Logo"
                        />
                      </div>
                    </div>

                    <div className={cx(s["the-boxes"])}>
                      <div className={cx(s["login-heading"])}>
                        <span>
                          <FormattedMessage {...messages.loginWithSocial} />
                        </span>
                      </div>
                      <div className={s["at-socialholder"]}>
                        <a
                          className={cx(s["fb-btn"], s["login-btns"])}
                          href={`${process.env.REACT_APP_API_HOST}/api/auth/login-via-facebook`}
                        >
                          <i className="fa fa-facebook"></i>
                        </a>

                        <a
                          className={cx(s["twitter-btn"], s["login-btns"])}
                          href={`${process.env.REACT_APP_API_HOST}/api/auth/login-via-twitter`}
                        >
                          <i className="fa fa-twitter"></i>
                        </a>

                        <a
                          className={cx(s["google-btn"], s["login-btns"])}
                          href={`${process.env.REACT_APP_API_HOST}/api/auth/login-via-google`}
                        >
                          <i className="fa fa-google-plus"></i>
                        </a>
                        <a
                          className={cx(s["microsoft-btn"], s["login-btns"])}
                          href={`${process.env.REACT_APP_API_HOST}/api/auth/login-via-outlook`}
                        >
                          <i className="fa fa-windows"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={cx("col", s["zero-padding"])}>
                  <strong className={s.logo}>
                    <span>Touch it technology</span>
                  </strong>
                  <div className={s["at-fromsholer"]}>
                    {this.props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UnAuth.propTypes = {
  auth: PropTypes.object.isRequired,
};

UnAuth.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, null)(UnAuth);
