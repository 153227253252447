import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import map from "lodash/map";
import moment from "moment";
import uuid4 from "uuid/v4";
// import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import CustomScrollbars from "../../utils/CustomScrollbars";

import AlertBox from "../common/AlertBox/AlertBox";
import TextFieldGroup from "../common/TextFieldGroup/TextFieldGroup";
import Pagination from "../common/Pagination/Pagination";
import NewLecture from "../misc/newLecture/NewLecture";
import ModalPopup from "../common/ModalPopup/ModalPopup";
import PopupContainer2 from "../common/PopupContainer2/PopupContainer2";
import actions from "../../actions/votingActivityActions";
import { addFlashMessage } from "../../actions/flashMessages";
import s from "./index.module.css";
import { Link } from "react-router";
import FEditor from "./froalaEditor";
import ReactQuill from "react-quill";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import {
  Button,
  PageHeader,
  Row,
  Col,
  Space,
  Tooltip,
  Popconfirm,
  message,
  Input,
  Tag,
  Select,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const { Title } = Typography;

const DragHandle = SortableHandle(
  ({ count, item, removeQuestion, editQuestion }) => (
    <div dangerouslySetInnerHTML={{ __html: item.question }} />
  )
);
const SortableItem = SortableElement(
  ({ count, item, removeQuestion, editQuestion }) => (
    <li className={s["at-slide"]}>
      <figure className={s["at-slideimg"]}>
        <Popconfirm
          placement="bottomRight"
          title={
            "Are you sure to delete this voting question? It will not be recoverable"
          }
          onConfirm={removeQuestion.bind(this, count)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete voting question">
            <DeleteOutlined
              style={{
                position: "absolute",
                right: 2,
                top: 2,
                background: "#FFF",
                fontSize: 30,
                color: "#02a2de",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </Popconfirm>

        {/* <a
          onClick={removeQuestion.bind(this, count)}
          className={s["at-btncloseslide"]}
          href="javascript:void(0)"
        >
          <i className="fa fa-close" />
        </a> */}

        <DragHandle
          count={count}
          item={item}
          editQuestion={editQuestion}
          removeQuestion={removeQuestion}
        />
      </figure>

      <div className={s["at-btnviewname"]}>
        <span>
          {map(item.options, (option, key) => (
            <button
              type="button"
              className={cx(
                "badge mr-2",
                { "badge-info": option !== item.answer },
                { "badge-success": option === item.answer }
              )}
            >
              {option}
            </button>
          ))}
          &nbsp;
        </span>

        <div className={s["at-btnview"]}>
          <a href="javascript:void(0)">
            <i
              onClick={editQuestion.bind(this, count)}
              className="fa fa-pencil"
            />
          </a>
        </div>
      </div>
    </li>
  )
);

const SortableList = SortableContainer(
  ({ items, removeQuestion, editQuestion }) => (
    <div className={cx("mt-3 mb-3", s["activity-question-holder"])}>
      <ul>
        {items.map((item, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            count={index}
            item={item}
            removeQuestion={removeQuestion}
            editQuestion={editQuestion}
          />
        ))}
      </ul>
    </div>
  )
);

class NsgComponent extends Component {
  static propTypes = {
    addFlashMessage: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      inputVisible: false,
      inputValue: "",
      editInputIndex: -1,
      editInputValue: "",
      id: "",
      activity: {},
      activityFolder: "",
      text:
        "<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />",
      questionOptions: [],
      optionInputText: "",
      answer: "",
      errors: {},
      editIndex: "",
    };
  }

  modules = {
    toolbar: [
      // [{ 'header': [1, 2, false] }],
      // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      // [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      // ['link', 'image'],
      // ['clean']
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote"],

      // [{ 'header': [1, 2, false] }],               // custom button values
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      // [{ 'script': 'sub' }],      // superscript/subscript

      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      // [{ 'font': [] }],
      // [{ 'align': [] }],

      ["link", "image"],
      // ['clean']                                         // remove formatting button
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  componentDidMount = () => {
    const { location } = this.props;
    const id = location.query.id;
    if (id) {
      this.setState({ id }, () => {
        this.getActivityDetails();
      });
    }
  };

  getActivityDetails = async () => {
    const { addFlashMessage } = this.props;
    const { id } = this.state;

    try {
      let {
        data: { data: activity },
      } = await actions.readById(id);

      this.setState({ activity, activityFolder: activity.folder });
    } catch (error) {
      message.error("Oops, Please refresh your page and try again.");
    }
  };

  handleQuillChange = (value) => {
    this.setState({ text: value });
  };

  replaceAll = function (text, search, replacement) {
    return text.replace(new RegExp(search, "g"), replacement);
  };
  createActivityQuestion = () => {
    let {
      activity: { questions },
      editIndex,
      text,
      questionOptions,
      answer,
    } = this.state;

    let questionText = text;
    questionText = this.replaceAll(questionText, "&nbsp;", "");
    questionText = this.replaceAll(questionText, "<br>", "");
    questionText = this.replaceAll(questionText, "<br />", "");
    questionText = this.replaceAll(questionText, "<p></p>", "");
    questionText = this.replaceAll(questionText, "<p>", "");
    questionText = this.replaceAll(questionText, "</p>", "");

    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    tempDiv.querySelectorAll("*:empty").forEach((x) => {
      console.log("createActivityQuestion -> x", x);
      if (x.tagName !== "IMG") {
        x.remove();
      }
    });
    tempDiv.querySelectorAll("*:empty").forEach((x) => {
      if (x.tagName !== "IMG") {
        x.remove();
      }
    });
    tempDiv.querySelectorAll("*:empty").forEach((x) => {
      if (x.tagName !== "IMG") {
        x.remove();
      }
    });
    tempDiv.querySelectorAll("*:empty").forEach((x) => {
      if (x.tagName !== "IMG") {
        x.remove();
      }
    });
    tempDiv = tempDiv.outerHTML;
    if (questionOptions.length && !answer) {
      this.setState({
        errors: { message: "Select an answer for the options." },
      });
    } else if (questionText == "") {
      this.setState({
        errors: { message: "Activity Question detail is required." },
      });
    } else {
      if (editIndex !== "" && editIndex >= 0) {
        questions[editIndex] = {
          type: "html",
          question: tempDiv,
          options: questionOptions,
          answer,
        };
      } else {
        questions.push({
          type: "html",
          question: tempDiv,
          options: questionOptions,
          answer,
        });
      }

      this.setState(
        {
          activity: { questions },
          text:
            "<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />",
          editIndex: "",
          questionOptions: [],
          answer: "",
          errors: {},
        },
        () => {
          this.saveActivity();
        }
      );
    }
  };

  onOptionInputTextChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };
  addOption = (e) => {
    e.preventDefault();

    let { questionOptions, optionInputText } = this.state;
    if (!optionInputText) {
      return true;
    }

    questionOptions.push(optionInputText);

    this.setState({
      questionOptions: [...new Set(questionOptions)],
      optionInputText: "",
    });
  };

  removeQuestionOption = (option) => {
    let questionOptions = this.state.questionOptions;

    const index = questionOptions.indexOf(option);
    if (index > -1) {
      questionOptions.splice(index, 1);
      this.setState({ questionOptions }, () => {
        if (index == this.state.editIndex) {
          this.setState({ editIndex: "" });
        }
      });
    }
  };

  removeQuestion = (index) => {
    let {
      activity: { questions },
    } = this.state;

    questions.splice(index, 1);
    this.setState({ activity: { questions } }, () => {
      this.saveActivity();
    });
  };
  editQuestion = (index) => {
    let {
      activity: { questions },
    } = this.state;
    let { question, options, answer } = questions[index];
    this.setState({
      editIndex: index,
      text: question,
      questionOptions: options,
      answer,
    });
  };

  saveActivity = async () => {
    const { id, activity, activityFolder } = this.state;

    try {
      await actions.updateById(id, activity);
      message.success("Voting activity saved successfully.");
    } catch (error) {
      message.error("Oops, Error while processing your request.");
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let {
      activity: { questions },
    } = this.state;
    let nsgQuestions = arrayMove(questions, oldIndex, newIndex);
    this.setState({ activity: { questions: nsgQuestions } }, () => {
      this.saveActivity();
    });
  };

  handleClose = (removedTag) => {
    debugger;
    // const tags = this.state.tags.filter((tag) => tag !== removedTag);
    // console.log(tags);
    // this.setState({ tags });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue, questionOptions } = this.state;

    if (!inputValue) {
      return true;
    }

    questionOptions.push(inputValue);

    this.setState({
      questionOptions: [...new Set(questionOptions)],
      inputValue: "",
      inputVisible: false,
    });
  };

  handleEditInputChange = (e) => {
    this.setState({ editInputValue: e.target.value });
  };

  handleEditInputConfirm = () => {
    this.setState(({ questionOptions, editInputIndex, editInputValue }) => {
      const newTags = [...questionOptions];
      newTags[editInputIndex] = editInputValue;

      return {
        questionOptions: newTags,
        editInputIndex: -1,
        editInputValue: "",
      };
    });
  };

  saveInputRef = (input) => {
    this.input = input;
  };

  saveEditInputRef = (input) => {
    this.editInput = input;
  };

  render() {
    let {
      activity: { questions },
      optionInputText,
      questionOptions,
      answer,
      errors,
      inputVisible,
      inputValue,
      editInputIndex,
      editInputValue,
    } = this.state;

    return (
      <CustomScrollbars>
        <PageHeader
          className="gx-mb-4"
          ghost={false}
          onBack={() =>
            this.context.router.push(
              `/voting-list?folder=${this.state.activityFolder}`
            )
          }
          title={`${this.state.activity.name || ""}`}
          subTitle={` Activity have ${
            questions ? questions.length : 0
          } questions`}
        />

        <div style={{ border: 0 }} className="card mb-3">
          <div className="row no-gutters">
            <div className="bg-light col-md-2">
              <SortableList
                removeQuestion={this.removeQuestion}
                editQuestion={this.editQuestion}
                items={questions || []}
                onSortEnd={this.onSortEnd}
                useDragHandle={true}
                axis={"y"}
              />
            </div>
            <div className="col-md-9">
              <div className="card-body">
                {errors.message && (
                  <div className="alert alert-danger" role="alert">
                    {errors.message}
                  </div>
                )}

                <ReactQuill
                  theme="snow"
                  value={this.state.text}
                  onChange={this.handleQuillChange}
                  modules={this.modules}
                  formats={this.formats}
                />
                <div className="mt-3 mb-3">
                  <Title level={4}>Choose correct answer</Title>
                  <Select
                    labelInValue
                    style={{ width: "100%" }}
                    onChange={({ value }) => {
                      this.setState({ answer: value });
                    }}
                    value={{ value: answer }}
                    placeholder="Choose correct answer"
                  >
                    {map(questionOptions, (option, key) => (
                      <Option value={option}>{option}</Option>
                    ))}
                  </Select>
                </div>
                <>
                  {questionOptions.map((tag, index) => {
                    if (editInputIndex === index) {
                      return (
                        <Input
                          ref={this.saveEditInputRef}
                          key={tag}
                          size="large"
                          placeholder="Update question option"
                          style={{ maxWidth: 300 }}
                          value={editInputValue}
                          onChange={this.handleEditInputChange}
                          onBlur={this.handleEditInputConfirm}
                          onPressEnter={this.handleEditInputConfirm}
                        />
                      );
                    }

                    const isLongTag = tag.length > 20;

                    const tagElem = (
                      <Tag
                        style={{ fontSize: 15, padding: 5 }}
                        icon={
                          tag === answer ? (
                            <CheckCircleOutlined />
                          ) : (
                            <CloseCircleOutlined />
                          )
                        }
                        color={tag === answer ? "success" : "error"}
                        className="edit-tag"
                        key={tag}
                        closable
                        onClose={() => this.removeQuestionOption(tag)}
                      >
                        <span
                          onDoubleClick={(e) => {
                            if (index !== 0) {
                              this.setState(
                                { editInputIndex: index, editInputValue: tag },
                                () => {
                                  this.editInput.focus();
                                }
                              );
                              e.preventDefault();
                            }
                          }}
                        >
                          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </span>
                      </Tag>
                    );
                    return isLongTag ? (
                      <Tooltip title={tag} key={tag}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                  {inputVisible && (
                    <Input
                      ref={this.saveInputRef}
                      type="text"
                      size="large"
                      placeholder="Add new option"
                      style={{ maxWidth: 300 }}
                      value={inputValue}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputConfirm}
                      onPressEnter={this.handleInputConfirm}
                    />
                  )}
                  {!inputVisible && (
                    <Tag
                      style={{ fontSize: 15, padding: 5 }}
                      onClick={this.showInput}
                    >
                      <PlusOutlined /> Add New Option
                    </Tag>
                  )}
                </>

                {/* {map(questionOptions, (option, key) => (
                  <Button
                    key={key}
                    style={{
                      ...(option === answer
                        ? { backgroundColor: "green" }
                        : { backgroundColor: "red" }),
                      color: "#FFF",
                    }}
                  >
                    {option}
                    <span
                      onClick={this.removeQuestionOption.bind(this, option)}
                      className="gx-ml-4 badge badge-light"
                    >
                      <i className="fa fa-times" />
                    </span>
                  </Button>
                ))} */}

                <Button
                  type="primary"
                  onClick={this.createActivityQuestion}
                  className="btn at-themebtn mt-3 mb-3 float-right"
                >
                  Save Activity Question
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* <Button
          onClick={this.saveActivity}
          type="primary"
          className="btn at-themebtn btn-lg btn-block"
        >
          Save & Exit to Voting Dashboard
        </Button> */}
      </CustomScrollbars>
    );
  }
}

export default connect(null, { addFlashMessage })(NsgComponent);
