import React, { Fragment } from "react";
import s from "./LecturePage.module.css";
import {
  Row,
  Col,
  Card,
  Input,
  InputNumber,
  Switch,
  Slider,
  Space,
  Tabs,
  Divider,
  Select,
  Checkbox,
  Button,
  Radio,
  Popover,
} from "antd";
import {
  CloseOutlined,
  DownloadOutlined,
  SearchOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import CustomScrollbars from "../../utils/CustomScrollbars";
import Draggable from "react-draggable";
import cx from "classnames";
import {
  SketchPicker,
  CompactPicker,
  CirclePicker,
  AlphaPicker,
  BlockPicker,
  ChromePicker,
  GithubPicker,
  HuePicker,
} from "react-color";
import fonts from "../../data/font";
import _ from "lodash";
const { TabPane } = Tabs;
const { Option } = Select;

function callback(key) {
  console.log(key);
}

const fontOptions = _.map(fonts, (value, key) => (
  <Option value={value}>{key}</Option>
));

const Settings = ({
  onClose,
  lineColor,
  lineWidth,
  fillColor,
  fontSize,
  fontName,
  liveSearch,
  toolsPosition,
  studentSettings,
  toolbarSize,
  onChangeSettings,
  changeAndEmitSettings,
  zoomHandler,
}) => {
  return (
    <>
      <Draggable handle={`.nsg-settings`}>
        <div
          className={cx(s["at-imagesearchoholder"], {
            [s["at-openlivesearch"]]: true,
          })}
        >
          <Card
            headStyle={{
              color: "#FFFFFF",
              backgroundColor: "#02a2de",
            }}
            title={
              <Space
                style={{ cursor: "move" }}
                className="nsg-settings"
                align="start"
              >
                <i
                  className="icon icon-menu gx-pt-2"
                  style={{ fontSize: 20, color: "#FFFFFF" }}
                />
                <h2 style={{ color: "#FFFFFF" }}>Settings</h2>
              </Space>
            }
            extra={
              <CloseOutlined
                className="gx-pointer"
                onClick={onClose}
                style={{ fontSize: 20, color: "#FFFFFF" }}
              />
            }
            className="gx-card-widget"
          >
            <ul className={s["at-searchimages"]}>
              <CustomScrollbars>
                <Tabs defaultActiveKey="1" onChange={callback}>
                  <TabPane
                    className="gx-ml-1 gx-pr-1"
                    tab="Tools Settings"
                    key="1"
                  >
                    <Row>
                      <Col xs={24}>
                        <span className="gx-product-name gx-text-primary">
                          Annotation Thickness
                        </span>
                        <Slider
                          min={3}
                          max={50}
                          value={lineWidth}
                          onChange={(value) =>
                            onChangeSettings("lineWidth", value)
                          }
                        />
                      </Col>
                      <Divider />
                      <Col xs={24}>
                        <Row>
                          <Col span={12}>
                            <span className="gx-product-name gx-text-primary">
                              Text Font Size
                            </span>
                            <InputNumber
                              style={{ width: "100%" }}
                              defaultValue={fontSize}
                              onChange={(value) =>
                                onChangeSettings("fontSize", value)
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <span className="gx-product-name gx-text-primary">
                              Text Font Style
                            </span>
                            <Select
                              defaultValue={fontName}
                              style={{ width: "100%" }}
                              onChange={(value) =>
                                onChangeSettings("fontName", value)
                              }
                            >
                              {fontOptions}
                            </Select>
                          </Col>
                        </Row>
                      </Col>
                      <Divider />
                      <Col span={24}>
                        <span className="gx-product-name gx-text-primary">
                          <span>Annotation OR Text Color</span>
                          <Popover
                            content={
                              <CompactPicker
                                color={lineColor}
                                onChangeComplete={(color) =>
                                  onChangeSettings("lineColor", color.hex)
                                }
                              />
                            }
                            title={null}
                            trigger="click"
                          >
                            <span className="gx-float-right gx-pointer">
                              More Colors
                            </span>
                          </Popover>
                        </span>
                        <HuePicker
                          onChangeComplete={(color) =>
                            onChangeSettings("lineColor", color.hex)
                          }
                          color={lineColor}
                          width={"100%"}
                        />
                      </Col>
                      <Divider />
                      <Col span={24}>
                        <span className="gx-product-name gx-text-primary">
                          <span>Fill Color</span>
                          <Space className="gx-float-right">
                            <Popover
                              content={
                                <CompactPicker
                                  color={fillColor}
                                  onChangeComplete={(color) => {
                                    onChangeSettings("fillColor", color.hex);
                                  }}
                                />
                              }
                              title={null}
                              trigger="click"
                            >
                              <span className="gx-pointer">More Colors</span>
                            </Popover>
                            <span
                              onClick={() =>
                                onChangeSettings("fillColor", "transparent")
                              }
                              className="gx-pointer"
                            >
                              Transparent
                            </span>
                          </Space>
                        </span>
                        <HuePicker
                          color={fillColor}
                          onChangeComplete={(color) =>
                            onChangeSettings("fillColor", color.hex)
                          }
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="General Settings" key="2">
                    <Row>
                      <Col span={14}>
                        <Checkbox
                          className="gx-product-name gx-text-primary"
                          onChange={(ev) => {
                            changeAndEmitSettings(
                              "studentSettings",
                              ev.target.checked,
                              "toggleStudentSettings"
                            );
                          }}
                          defaultChecked={studentSettings}
                        >
                          Participant Settings
                        </Checkbox>
                      </Col>
                      <Col span={10}>
                        <Checkbox
                          className="gx-product-name gx-text-primary"
                          onChange={(ev) => {
                            changeAndEmitSettings(
                              "studentLiveImageSearch",
                              ev.target.checked,
                              "toggleStudentLiveImageSearch"
                            );
                          }}
                          defaultChecked={liveSearch}
                        >
                          Live Search
                        </Checkbox>
                      </Col>
                      <Divider />

                      {/*<Col span={24}>*/}
                      {/*  <span className="gx-product-name gx-text-primary">*/}
                      {/*    Editor Zoom*/}
                      {/*  </span>*/}
                      {/*  <Slider*/}
                      {/*    marks={{ 1: "" }}*/}
                      {/*    included={false}*/}
                      {/*    defaultValue={1}*/}
                      {/*    max={2.5}*/}
                      {/*    step={0.1}*/}
                      {/*    min={-0.5}*/}
                      {/*  />*/}
                      {/*</Col>*/}
                      {/*<Divider />*/}
                      <Col span={24}>
                        <span className="gx-product-name gx-text-primary gx-d-block">
                          Tools Position
                        </span>
                        <Radio.Group
                          onChange={(ev) => {
                            onChangeSettings("toolsPosition", ev.target.value);
                            localStorage.setItem(
                              "toolsPosition",
                              ev.target.value
                            );
                          }}
                          value={toolsPosition}
                        >
                          <Radio value={"top"}>Top bar</Radio>
                          <Radio value={"float"}>Floating bar</Radio>
                          <Radio value={"both"}>Both</Radio>
                        </Radio.Group>
                      </Col>

                      {(toolsPosition === "float" ||
                        toolsPosition === "both") && (
                        <Col span={24}>
                          <Divider />
                          <span className="gx-product-name gx-text-primary gx-d-block">
                            Floating bar size
                          </span>
                          <Radio.Group
                            onChange={(ev) => {
                              onChangeSettings("toolbarSize", ev.target.value);
                            }}
                            value={toolbarSize}
                          >
                            <Radio value={"small"}>Small</Radio>
                            <Radio value={"md"}>Medium</Radio>
                            <Radio value={"lg"}>Large</Radio>
                          </Radio.Group>
                        </Col>
                      )}

                      <Divider />
                      <Col span={24}>
                        <span className="gx-product-name gx-text-primary gx-d-block">
                          Zoom Handler
                        </span>
                        <Button.Group size="large">
                          <Button
                            onClick={() => zoomHandler("zoomIn")}
                            icon={<ZoomInOutlined />}
                          />
                          <Button
                            onClick={() => zoomHandler("zoomReset")}
                            icon={<SearchOutlined />}
                          />
                          <Button
                            onClick={() => zoomHandler("zoomOut")}
                            icon={<ZoomOutOutlined />}
                          />
                        </Button.Group>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </CustomScrollbars>
            </ul>
          </Card>
        </div>
      </Draggable>
    </>
  );
};
export default Settings;
