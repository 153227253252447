import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import s from './PopupContainer2.module.css';

class PopupContainer2 extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        isOpen: false,
    };

    render() {
        const { isOpen } = this.props;

        return (
            <div className={cx(s["at-popupcontainer"], { [s['at-openpopup']]: isOpen })}>
                {this.props.children}
            </div>
        );


    }
}

export default PopupContainer2;