import axios from 'axios';

export const getPreview = (file, type, payload) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('type', type);
	formData.append('payload', payload);
	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	};
	return axios.post('/api/utils/getPreview', formData, config);
};
