import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable'
import cx from 'classnames';

import s from './ToolHolder.module.css';
import parent from '../../LecturePage.module.css';



class ToolHolder extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        classname: PropTypes.string,
        holderclassname: PropTypes.string,
        tool: PropTypes.string.isRequired,

        closeTool: PropTypes.func.isRequired,
        showResizer: PropTypes.bool

    };
    static defaultProps = {
        holderclassname: '',
        name: 'Untitled Tool',
        showResizer: true

    };
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.teacherStreamResizHandler.addEventListener('mousedown', this.initTeacherStreamResizeDrag, false);
        this.teacherStreamResizHandler.addEventListener('touchstart', this.initTeacherStreamResizeDrag, false);
    }

    initTeacherStreamResizeDrag = (e) => {
        var ev = /touch/.test(e.type) ? e.targetTouches[0] : e;
        this.startX = ev.clientX;
        this.startY = ev.clientY;
        this.startWidth = parseInt(document.defaultView.getComputedStyle(this.teacherStreamContainer).width, 10);
        this.startHeight = parseInt(document.defaultView.getComputedStyle(this.teacherStreamContainer).height, 10);
        document.documentElement.addEventListener('mousemove', this.doDrag, false);
        document.documentElement.addEventListener('touchmove', this.doDrag, false);
        document.documentElement.addEventListener('mouseup', this.stopDrag, false);
        document.documentElement.addEventListener('touchend', this.stopDrag, false);
    }
    doDrag = (e) => {
        var ev = /touch/.test(e.type) ? e.targetTouches[0] : e;

        let width = this.startWidth + ev.clientX - this.startX
        let height = this.startHeight + ev.clientY - this.startY
        this.teacherStreamContainer.style.width = (width) + 'px';

        // if (height < 400) return true;
        this.teacherStreamContainer.style.height = (height) + 'px';
    }

    stopDrag = (e) => {
        document.documentElement.removeEventListener('mousemove', this.doDrag, false);
        document.documentElement.removeEventListener('touchmove', this.doDrag, false);
        document.documentElement.removeEventListener('mouseup', this.stopDrag, false);
        document.documentElement.removeEventListener('touchend', this.stopDrag, false);
    }


    render() {
        const { name, classname, closeTool, tool, showResizer, holderclassname } = this.props

        return (<Draggable handle={`.${s["at-watchhead"]}`}>
            <div ref={tsc => this.teacherStreamContainer = tsc} className={cx(s["at-holder"], s[holderclassname])}>
                <div className={s["at-watchhead"]}>
                    <h2>{name}</h2>
                    <a onClick={closeTool.bind(this, tool)} className={s["at-btnclosetool"]}><i className="fa fa-times"></i></a>

                </div>

                <div className={cx("at-stopwatchcontent", s[classname])}>
                    {this.props.children}


                    <ul className={cx(parent["at-callactions"])}>
                        <li ref={tsrh => this.teacherStreamResizHandler = tsrh}>
                            {showResizer ?
                                <a className={parent['nsg-resizeHandler']}>
                                    <i className="fa fa-expand"></i>
                                </a>
                                : null}
                        </li>
                    </ul>


                </div>

            </div>
        </Draggable>);
    }
}

export default ToolHolder;