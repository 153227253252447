import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Spin,
  Card,
  Button,
  Space,
  Row,
  Col,
  Tooltip,
  InputNumber,
  Popconfirm,
  Form,
  message,
  Typography,
  Divider,
  Modal,
} from "antd";
import {
  EditOutlined,
  CloseOutlined,
  SaveOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import GoogleClassroom from "./GoogleClassroom";
import {
  getUserFilesDetails,
  getUserClassroomList,
  isUserExistInClassroom,
  removeUserFromClassroom,
  removeClassroom,
  saveGoogleClassrooms,
  createNewClassroom,
  updateClassroomDetails,
  addUserToClassroom,
} from "../../actions/student";

import { FormattedMessage } from "react-intl";
import messages from "../../messages";

const { Text, Link } = Typography;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `This field is required`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const expandedRowRender = (record) => {
  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 100,
      align: "center",
      render: (row) => (
        <Space>
          {/* <span className="gx-text-primary gx-pointer" onClick={() => row.editStudent(record)}>
                    <i className="icon icon-edit gx-fs-sm gx-mr-2" />Edit
                </span> */}
          {/* <Divider type="vertical" /> */}
          <Popconfirm
            placement="left"
            title="Are you sure?"
            okText="Yes"
            onConfirm={() => row.removeStudent(record.key, row.key)}
          >
            <span className="gx-text-primary gx-pointer">
              <i className="icon icon-trash gx-fs-sm gx-mr-2" />
              Delete
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const data = _.map(record.students, (rec, key) => {
    return {
      key: rec._id,
      name: rec.name,
      email: rec.email,
      openStudentModal: record.openStudentModal,
      removeStudent: record.removeStudent,
    };
  });

  return (
    <Card
      className={`gx-card-widget`}
      title={
        <h2 className="h4 gx-text-capitalize gx-ml-3 gx-mb-0">{record.name}</h2>
      }
      extra={
        <p
          onClick={() => record.openStudentModal(record.key)}
          className="gx-text-primary gx-mr-4 gx-mb-0 gx-pointer gx-d-none gx-d-sm-block"
        >
          <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle mr-1" />
          Add Student
        </p>
      }
    >
      <Table
        className="gx-table-no-bordered"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
      />
    </Card>
  );
};

export default () => {
  const [form] = Form.useForm();
  const [studentForm] = Form.useForm();
  const [studentModalVisible, setStudentModalVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [classrooms, setClassrooms] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  useEffect(() => {
    let mounted = true;
    fetchClassroom();
    return () => (mounted = false);
  }, []);

  const fetchClassroom = () => {
    setLoading(true);
    getUserClassroomList().then((result) => {
      setClassrooms(result.data.classrooms);
      setLoading(false);
    });
  };
  const removeStudent = (classroomId, studentId) => {
    removeUserFromClassroom(classroomId, studentId)
      .then((res) => {
        message.success(res.data.message);
        fetchClassroom();
      })
      .catch((error) => {
        message.error("OOps, Something happen wrong. Please try again.");
      });
  };
  const addStudent = (values) => {
    addUserToClassroom(values.classroomId, values)
      .then((res) => {
        message.success(res.data.message);
        fetchClassroom();
        setStudentModalVisible(false);
      })
      .catch((error) => {
        message.error("OOps, Something happen wrong. Please try again.");
      });
  };

  const handleGoogleClassroomSuccess = (data) => {
    saveGoogleClassrooms({ classrooms: data })
      .then((res) => {
        message.success(res.data.message);
        fetchClassroom();
      })
      .catch((error) => {
        message.error("OOps, Something happen wrong. Please try again.");
      });
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const columns = [
    {
      title: "Classroom name",
      dataIndex: "name",
      editable: true,
    },
    {
      title: "Total Students",
      dataIndex: "noOfStudent",
      width: "10%",
      editable: false,
    },
    {
      title: "Actions",
      dataIndex: "operation",
      key: "x",
      fixed: "right",
      width: 200,
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Space>
              <span
                className="gx-text-primary gx-pointer"
                onClick={() => save(record.key)}
              >
                <SaveOutlined className="icon gx-fs-sm gx-mr-2" />
                Save
              </span>
              <Divider type="vertical" />
              <span className="gx-text-primary gx-pointer" onClick={cancel}>
                <CloseOutlined className="icon gx-fs-sm gx-mr-2" />
                Cancel
              </span>
            </Space>
          </>
        ) : (
          <Space>
            <span
              className="gx-text-primary gx-pointer"
              onClick={() => edit(record)}
            >
              <i className="icon icon-edit gx-fs-sm gx-mr-2" />
              Edit
            </span>
            <Divider type="vertical" />
            <Popconfirm
              placement="left"
              title="Are you sure?"
              okText="Yes"
              onConfirm={() => remove(record.key)}
            >
              <span className="gx-text-primary gx-pointer">
                <i className="icon icon-trash gx-fs-sm gx-mr-2" />
                Delete
              </span>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const cdata = _.map(classrooms, (classroom, key) => {
    return {
      key: classroom._id,
      name: classroom.name,
      noOfStudent: classroom.student.length,
      students: classroom.student,
      removeStudent: removeStudent,
      openStudentModal: (classroomId) => {
        studentForm.setFieldsValue({
          classroomId,
          name: "",
          email: "",
        });

        setStudentModalVisible(true);
      },
    };
  });

  const cancel = () => {
    setEditingKey("");
  };

  const remove = (classroomId) => {
    removeClassroom(classroomId)
      .then((res) => {
        message.success("Classroom deleted successfully.");
        fetchClassroom();
      })
      .catch((error) => {
        message.error("OOps, Something happen wrong. Please try again.");
      });
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      setLoading(true);
      const newData = [...classrooms];
      const index = newData.findIndex((item) => key === item._id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        updateClassroomDetails(key, row)
          .then(() => {
            setClassrooms(newData);
            setEditingKey("");
            setLoading(false);
            message.success("Classroom name updated successfully.");
          })
          .catch((e) => {
            message.error("OOps, Something happen wrong. Please try again.");
          });
      } else {
        newData.push(row);
        createNewClassroom(row)
          .then((res) => {
            message.success("Classroom name created successfully.");
            setLoading(false);
            setPopupVisible(false);
            setEditingKey("");
            fetchClassroom();
            form.setFieldsValue({
              name: "",
            });
          })
          .catch((e) => {
            message.error("OOps, Something happen wrong. Please try again.");
          });
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card
        className={`gx-card-widget`}
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0">
            <FormattedMessage {...messages.participantLists} />
          </h2>
        }
        extra={
          <Space>
            <GoogleClassroom
              key={0}
              clientId={
                "822121502733-b90091552l3kcd04v9e9v3pr2lvu5bkm.apps.googleusercontent.com"
              }
              onSuccess={handleGoogleClassroomSuccess}
              onFailure={(error) => {
                message.error(
                  "OOps, Something happen wrong. Please try again."
                );
              }}
            >
              <p className="gx-text-primary gx-mb-0 gx-pointer">
                <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle mr-1" />
                Import From Google Classroom
              </p>
            </GoogleClassroom>

            <p
              onClick={() => setPopupVisible(true)}
              className="gx-text-primary gx-mb-0 gx-pointer"
            >
              <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle mr-1" />
              Add New List
            </p>
          </Space>
        }
      >
        <Form form={form} component={false}>
          <Table
            className="gx-table-no-bordered"
            bordered
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={cdata}
            expandable={{ expandedRowRender }}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
          <Modal
            title="Add New List"
            visible={popupVisible}
            onOk={save}
            onCancel={() => setPopupVisible(false)}
            okText="Save"
          >
            <Form.Item
              name={"name"}
              rules={[
                {
                  required: true,
                  message: `This field is required`,
                },
              ]}
            >
              <Input placeholder="Classroom Name" />
            </Form.Item>
          </Modal>
        </Form>

        <Modal
          title="Add Student"
          visible={studentModalVisible}
          okText="Save"
          okButtonProps={{
            form: "sf",
            key: "submit",
            htmlType: "submit",
            size: "large",
          }}
          onCancel={() => setStudentModalVisible(false)}
          cancelButtonProps={{
            size: "large",
          }}
        >
          <Form name="sf" size="large" form={studentForm} onFinish={addStudent}>
            <Form.Item
              style={{ display: "none" }}
              name={"classroomId"}
              rules={[
                {
                  required: true,
                  message: `This field is required`,
                },
              ]}
            >
              <Input placeholder="Classroom Id" />
            </Form.Item>

            <Form.Item
              name={"name"}
              rules={[
                {
                  required: true,
                  message: `This field is required`,
                },
              ]}
            >
              <Input placeholder="Participant Name" />
            </Form.Item>

            <Form.Item
              name={"email"}
              rules={[
                {
                  required: true,
                  message: `This field is required`,
                },
                {
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Participant Email" />
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </Spin>
  );
};
