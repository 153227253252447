import React, { Component, Fragment } from "react";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import GoogleDrive from "@uppy/google-drive";
import OneDrive from "@uppy/onedrive";
import uppyUrl from "@uppy/url";
import Dropbox from "@uppy/dropbox";
import { DashboardModal, Dashboard } from "@uppy/react";
import PropTypes from "prop-types";
import { Button, Tooltip, Modal } from "antd";
import { FolderViewOutlined } from "@ant-design/icons";
import MyFiles from "../../components/gallery"; //"./components/profile/Storage";

const restrictions = {
  allowedFileTypes: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/octet-stream",
    "application/pdf",
    "image/*",
    "video/*",
  ],
};

class UppyPage extends Component {
  static propTypes = {
    onProcessData: PropTypes.func.isRequired,
    uppyVisible: PropTypes.bool.isRequired,
    toggleUppyVisible: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { driveType: "", showGallery: false };

    this.uppyDrive = new Uppy({
      id: "uppyDrive",
      autoProceed: true,
      debug: true,
      restrictions,
      locale: {
        strings: {
          closeModal: "Close Window",
          addMoreFiles: "Add More Slides",
        },
      },
    })
      .use(Tus, { endpoint: "https://tus.contribute.cloud/files/" })
      .use(GoogleDrive, { companionUrl: "https://uppy.contribute.cloud" })
      .use(Dropbox, { companionUrl: "https://uppy.contribute.cloud" })
      .use(uppyUrl, { companionUrl: "https://uppy.contribute.cloud" })
      .use(OneDrive, { companionUrl: "https://uppy.contribute.cloud" });

    this.uppyDrive.on("complete", this.uppyOnComplete);
  }

  uppyOnComplete = async (result) => {
    if (result.successful.length) {
      for (const file of result.successful) {
        console.log("DrivePage -> uppyOnComplete -> file", file);
        const { addFromUppy } = this.props;
        addFromUppy(file);
      }
    }
  };

  componentDidMount = () => {
    this.props.onRef(this);
  };

  render() {
    const {
      uppyVisible,
      toggleUppyVisible,
      addFromMyFilesWorkbook,
    } = this.props;

    return (
      <>
        <Dashboard
          uppy={this.uppyDrive}
          showProgressDetails={true}
          disableThumbnailGenerator={false}
          showLinkToFileUploadResult={false}
          proudlyDisplayPoweredByUppy={false}
          closeModalOnClickOutside
          open={uppyVisible}
          onRequestClose={() => toggleUppyVisible()}
          plugins={["GoogleDrive", "Dropbox", "OneDrive"]}
          width={"calc(100vw - 230px)"}
          height={"calc(100vh - 200px)"}
        />
        <Tooltip placement="left" title={"Add from My Files"}>
          <Button
            onClick={() => this.setState({ showGallery: true })}
            type="primary"
            shape="circle"
            size="large"
            style={{
              position: "absolute",
              right: 50,
              bottom: 50,
              zIndex: 5,
              width: 50,
              height: 50,
            }}
            icon={
              <FolderViewOutlined style={{ fontSize: 30, paddingTop: 10 }} />
            }
          />
        </Tooltip>

        <Modal
          title="My Files"
          width={"100%"}
          style={{ height: "100%" }}
          visible={this.state.showGallery}
          footer={null}
          onCancel={() => this.setState({ showGallery: false })}
          className="gx-my-files-modal"
        >
          <MyFiles
            onSelect={(item) => {
              addFromMyFilesWorkbook(item);
              this.setState({ showGallery: false });
            }}
          />
        </Modal>
      </>
    );
  }
}

export default UppyPage;
