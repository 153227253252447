import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Pagination.module.css';

class Pagination extends Component {
    static propTypes = {
        total: PropTypes.number.isRequired,
        current: PropTypes.string.isRequired,
        perPage: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        total: 0,
        current: 0,
        perPage: 20,
        onClick: () => { }
    }


    render() {
        const { total, current, perPage, onClick } = this.props;
        let paginations = []
        let pageCount = parseInt(total / perPage);
        if (total % perPage > 0) { pageCount++; }

        for (let index = 0; index < pageCount; index++) {
            paginations.push(<li key={index} onClick={onClick.bind(this, index)}><a href="javascript:void(0);">{index + 1}</a></li>)
        }

        return (
            <ul className={s['at-paginations']}>
                <li onClick={(current - 1) >= 0 ? onClick.bind(this, (current - 1)) : null}><a href="javascript:void(0);"><i className="fa fa-angle-left"></i></a></li>
                {paginations}
                <li onClick={(current + 1) < pageCount ? onClick.bind(this, (current + 1)) : null}><a href="javascript:void(0);"><i className="fa fa-angle-right"></i></a></li>
            </ul>
        );
    }
}

export default Pagination;



