import ReactDOM from "react-dom";
import GoldenLayout from "golden-layout";

import {
    GoldenWordPuzzle, GoldenCompleteTheSequence, GoldenSolveMath, GoldenMathBoy,
    GoldenSnakeAndLadders, GoldenPiano, GoldenAddition,
    GoldenCountCars,
    GoldenCountFingers,
    GoldenDivision,
    GoldenEvenOdd,
    GoldenEvenOddPop,
    GoldenEvenOddShip,
    GoldenFoodCount,
    GoldenFreeWords,
    GoldenGolfSport,
    GoldenLetsReach1000,
    GoldenLetsReach1000ASAP,
    GoldenLetsReachZero,
    GoldenLetsReachZeroASAP,
    GoldenMagicWords,
    GoldenMathsTrueorFalse,
    GoldenMissingNumber,
    GoldenMultiplication,
    GoldenNumbersMemory,
    GoldenPickTheLock,
    GoldenPiggyBank,
    GoldenPool8ball,
    GoldenQuizMath,
    GoldenSubtraction,
    GoldenTextMaths,
    GoldenTextMathsNegative,
    GoldenWaffleWord,
    GoldenWordMaker,
    GoldenWordUp,

    Golden_Language_rearrangeLetters2,
    Golden_Language_wordFinder,
    Golden_Language_quiz2,
    Golden_Math_genius2,
    Golden_Math_guessHowMany,
    Golden_Math_WhatIsInside,
    Golden_Geography_unitedStatesMapGame50States,
    Golden_PreK_coloringBook,
    Golden_PreK_memoryGame

} from "./tools";



let myLayout = null;

const goldenLayoutSetup = function (root) {
    var config = {
        settings: {
            showCloseIcon: false,
            showMaximiseIcon: false,
            showPopoutIcon: false,
        },
        content: []
    };

    myLayout = new GoldenLayout(config, root);

    myLayout.registerComponent('.', function (container, state) {
        container.getElement().html(`<img style="width: 100%; height: 100%;" class="img-fluid" src="/images/multi-games-background.png" />`);
    });

    myLayout.registerComponent("Language_rearrangeLetters2", function (container, state) {
        registerComponentWithCallback(container, state, Golden_Language_rearrangeLetters2);
    });

    myLayout.registerComponent("Language_wordFinder", function (container, state) {
        registerComponentWithCallback(container, state, Golden_Language_wordFinder);
    });

    myLayout.registerComponent("Language_quiz2", function (container, state) {
        registerComponentWithCallback(container, state, Golden_Language_quiz2);
    });

    myLayout.registerComponent("Math_genius2", function (container, state) {
        registerComponentWithCallback(container, state, Golden_Math_genius2);
    });

    myLayout.registerComponent("Math_guessHowMany", function (container, state) {
        registerComponentWithCallback(container, state, Golden_Math_guessHowMany);
    });

    myLayout.registerComponent("Math_WhatIsInside", function (container, state) {
        registerComponentWithCallback(container, state, Golden_Math_WhatIsInside);
    });

    myLayout.registerComponent("Geography_unitedStatesMapGame50States", function (container, state) {
        registerComponentWithCallback(container, state, Golden_Geography_unitedStatesMapGame50States);
    });

    myLayout.registerComponent("PreK_coloringBook", function (container, state) {
        registerComponentWithCallback(container, state, Golden_PreK_coloringBook);
    });

    myLayout.registerComponent("PreK_memoryGame", function (container, state) {
        registerComponentWithCallback(container, state, Golden_PreK_memoryGame);
    });


    myLayout.registerComponent("word-puzzle", function (container, state) {
        registerComponentWithCallback(container, state, GoldenWordPuzzle);
    });

    myLayout.registerComponent("complete-the-sequence", function (container, state) {
        registerComponentWithCallback(container, state, GoldenCompleteTheSequence);
    });

    myLayout.registerComponent("solve-math", function (container, state) {
        registerComponentWithCallback(container, state, GoldenSolveMath);
    });

    myLayout.registerComponent("math-boy", function (container, state) {
        registerComponentWithCallback(container, state, GoldenMathBoy);
    });

    myLayout.registerComponent("snake-and-ladders", function (container, state) {
        registerComponentWithCallback(container, state, GoldenSnakeAndLadders);
    });

    myLayout.registerComponent("virtual-piano", function (container, state) {
        registerComponentWithCallback(container, state, GoldenPiano);
    });

    myLayout.registerComponent("GoldenAddition", function (container, state) {
        registerComponentWithCallback(container, state, GoldenAddition);
    });

    myLayout.registerComponent("GoldenCountCars", function (container, state) {
        registerComponentWithCallback(container, state, GoldenCountCars);
    });

    myLayout.registerComponent("GoldenCountFingers", function (container, state) {
        registerComponentWithCallback(container, state, GoldenCountFingers);
    });

    myLayout.registerComponent("GoldenDivision", function (container, state) {
        registerComponentWithCallback(container, state, GoldenDivision);
    });

    myLayout.registerComponent("GoldenEvenOdd", function (container, state) {
        registerComponentWithCallback(container, state, GoldenEvenOdd);
    });

    myLayout.registerComponent("GoldenEvenOddPop", function (container, state) {
        registerComponentWithCallback(container, state, GoldenEvenOddPop);
    });

    myLayout.registerComponent("GoldenEvenOddShip", function (container, state) {
        registerComponentWithCallback(container, state, GoldenEvenOddShip);
    });

    myLayout.registerComponent("GoldenFoodCount", function (container, state) {
        registerComponentWithCallback(container, state, GoldenFoodCount);
    });

    myLayout.registerComponent("GoldenFreeWords", function (container, state) {
        registerComponentWithCallback(container, state, GoldenFreeWords);
    });

    myLayout.registerComponent("GoldenGolfSport", function (container, state) {
        registerComponentWithCallback(container, state, GoldenGolfSport);
    });

    myLayout.registerComponent("GoldenLetsReach1000", function (container, state) {
        registerComponentWithCallback(container, state, GoldenLetsReach1000);
    });

    myLayout.registerComponent("GoldenLetsReach1000ASAP", function (container, state) {
        registerComponentWithCallback(container, state, GoldenLetsReach1000ASAP);
    });

    myLayout.registerComponent("GoldenLetsReachZero", function (container, state) {
        registerComponentWithCallback(container, state, GoldenLetsReachZero);
    });

    myLayout.registerComponent("GoldenLetsReachZeroASAP", function (container, state) {
        registerComponentWithCallback(container, state, GoldenLetsReachZeroASAP);
    });

    myLayout.registerComponent("GoldenMagicWords", function (container, state) {
        registerComponentWithCallback(container, state, GoldenMagicWords);
    });

    myLayout.registerComponent("GoldenMathsTrueorFalse", function (container, state) {
        registerComponentWithCallback(container, state, GoldenMathsTrueorFalse);
    });

    myLayout.registerComponent("GoldenMissingNumber", function (container, state) {
        registerComponentWithCallback(container, state, GoldenMissingNumber);
    });

    myLayout.registerComponent("GoldenMultiplication", function (container, state) {
        registerComponentWithCallback(container, state, GoldenMultiplication);
    });

    myLayout.registerComponent("GoldenNumbersMemory", function (container, state) {
        registerComponentWithCallback(container, state, GoldenNumbersMemory);
    });

    myLayout.registerComponent("GoldenPickTheLock", function (container, state) {
        registerComponentWithCallback(container, state, GoldenPickTheLock);
    });

    myLayout.registerComponent("GoldenPiggyBank", function (container, state) {
        registerComponentWithCallback(container, state, GoldenPiggyBank);
    });

    myLayout.registerComponent("GoldenPool8ball", function (container, state) {
        registerComponentWithCallback(container, state, GoldenPool8ball);
    });

    myLayout.registerComponent("GoldenQuizMath", function (container, state) {
        registerComponentWithCallback(container, state, GoldenQuizMath);
    });

    myLayout.registerComponent("GoldenSubtraction", function (container, state) {
        registerComponentWithCallback(container, state, GoldenSubtraction);
    });

    myLayout.registerComponent("GoldenTextMaths", function (container, state) {
        registerComponentWithCallback(container, state, GoldenTextMaths);
    });

    myLayout.registerComponent("GoldenTextMathsNegative", function (container, state) {
        registerComponentWithCallback(container, state, GoldenTextMathsNegative);
    });

    myLayout.registerComponent("GoldenWaffleWord", function (container, state) {
        registerComponentWithCallback(container, state, GoldenWaffleWord);
    });

    myLayout.registerComponent("GoldenWordMaker", function (container, state) {
        registerComponentWithCallback(container, state, GoldenWordMaker);
    });

    myLayout.registerComponent("GoldenWordUp", function (container, state) {
        registerComponentWithCallback(container, state, GoldenWordUp);
    });

    myLayout.on("beforeItemDestroyed", function (event) {
        if (event.isComponent) {
            var state = event.config.componentState;
            if (state["location"]) {
                var react_component = document.getElementById(state["location"]);
                if (react_component) {
                    ReactDOM.unmountComponentAtNode(react_component);
                }
            }
        }
    });

    myLayout.init();
};

const nextId = (function () {
    var next_id = 0;
    return function () {
        next_id += 1;
        return next_id;
    }
})();

const registerComponentWithCallback = function (container, state, callback) {
    var its_id = nextId();
    var location = "component_" + its_id;
    var new_state = Object.assign({}, state);
    container.getElement().html("<div id='" + location + "' style='width:100%; height: 100%'></div>");
    new_state["location"] = location;
    container.setState(new_state);
    setTimeout(function () { callback(location) }, 1);
};


export { goldenLayoutSetup, myLayout };
