import React, { Component } from 'react';
import DataPreviewList from './DataPreviewList';
import DropZone from 'react-dropzone';
import PropTypes from 'prop-types';
import { categorizeLocalMedia } from '../../utils/helper';

class Local extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IMAGE: [],
            LAYOUT: [],
            SPREADSHEET: [],
            VIDEO: [],
            TEXT: [],
            DATA: [],
            NOTEBOOK: [],
            dataFlag: true,
        };
        this.localResponse = this.localResponse.bind(this);
        this.delClick = this.delClick.bind(this);
        this.addAllToWorkbook = this.addAllToWorkbook.bind(this);
    }
    _onBackgroundImageDrop(accepted/*, rejected*/) {

    }
    localResponse(accepted, rejected) {
        const local = {
            IMAGE: [],
            LAYOUT: [],
            SPREADSHEET: [],
            VIDEO: [],
            TEXT: [],
            DATA: [],
            NOTEBOOK: []
        };

        if (accepted && accepted.length > 0) {
            categorizeLocalMedia(accepted).then((categorize, rej) => {
                for (const keys in categorize) {
                    categorize[keys].forEach((i) => {
                        local[keys].push(i);
                    });
                    this.state[keys].forEach((i) => {
                        local[keys].push(i);
                    });
                }
                let IMAGE = local.IMAGE,
                    LAYOUT = local.LAYOUT,
                    SPREADSHEET = local.SPREADSHEET,
                    VIDEO = local.VIDEO,
                    DATA = local.DATA,
                    TEXT = local.TEXT,
                    NOTEBOOK = local.NOTEBOOK;

                this.setState({ IMAGE, LAYOUT, SPREADSHEET, VIDEO, TEXT, DATA, NOTEBOOK });

                this.setState({ dataFlag: false });
            });
        }
    }

    delClick(item) {
        console.log(item);
        const state = this.state;
        let isDeleted = false;
        for (const key in state) {
            if (!isDeleted) {
                state[key].forEach((i) => {
                    const eq = JSON.stringify(item) == JSON.stringify(i);
                    if (eq && !isDeleted) {
                        isDeleted = true;
                        state[key].splice(i, 1);
                        console.log(state[key]);
                        console.log(key);
                        const data = state[key];
                        if (key == 'IMAGE') {
                            this.setState({ IMAGE: data });
                        } else if (key == 'LAYOUT') {
                            this.setState({ LAYOUT: data });
                        } else if (key == 'SPREADSHEET') {
                            this.setState({ SPREADSHEET: data });
                        } else if (key == 'VIDEO') {
                            this.setState({ VIDEO: data });
                        } else if (key == 'TEXT') {
                            this.setState({ TEXT: data });
                        } else if (key == 'DATA') {
                            this.setState({ DATA: data });
                        } else if (key == 'NOTEBOOK') {
                            this.setState({ NOTEBOOK: data });
                        }
                    }
                });
            }
        }
    }

    addAllToWorkbook() {
        const state = this.state;
        for (const key in state) {
            if (typeof (state[key]) == 'object') {
                state[key].forEach((item) => {
                    this.props.addClick(item, 'thumbnail')
                });
            }

        }
    }

    render() {
        return (
            <li>
                <div onClick={() => { this.dropzoneRef.open() }}>
                    <a className="opener btn-oneDrive"><i className="fa fa-upload" />My Computer</a>
                </div>
                <div className="slide">
                    <DataPreviewList
                        IMAGE={this.state.IMAGE}
                        LAYOUT={this.state.LAYOUT}
                        SPREADSHEET={this.state.SPREADSHEET}
                        TEXT={this.state.TEXT}
                        VIDEO={this.state.VIDEO}
                        DATA={this.state.DATA}
                        NOTEBOOK={this.state.NOTEBOOK}
                        dataFlag={this.state.dataFlag}
                        mouseUp={this.props.mouseUp}
                        addClick={this.props.addClick}
                        delClick={this.delClick}
                        driveType="google"
                    />
                </div>
                <DropZone accept=".pdf,.jpg,.jpeg,.png,.xlsx,.xlx,.doc,.docx,.ppt,.pptx,.mp4,.notebook" style={{ display: "none" }} ref={c => (this.dropzoneRef = c)} onDrop={this.localResponse}>
                    <p>Drop files here.</p>
                </DropZone>
            </li>
        );
    }
}

Local.propTypes = {
    mouseUp: PropTypes.func.isRequired,
    addClick: PropTypes.func.isRequired,
};
export default Local;
