import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import Validator from "validator";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import TextFieldGroup from "../common/TextFieldGroup";
import s from "./LecturePage.module.css";
import { tempUpload } from "../../actions/galleryActions";
import { ValidURL } from "../../utils/helper";

import { Widget, addResponseMessage } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import "./GroupChat.css";
import { Button, Badge } from "antd";
import { WechatOutlined } from "@ant-design/icons";

class GroupChat extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    socketEmitHandler: PropTypes.func.isRequired,
    groupChat: PropTypes.array.isRequired,
    tempUpload: PropTypes.func.isRequired,
    newMessageNotification: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isClosed: true,
      message: "",
      isUploading: false,
      errors: {},
      groupChat: [],
      students: [],
      newMessageNotification: false,
      notificationCount: 0,
      selectStudents: false,
    };
  }
  componentDidMount() {
    let { socket, auth } = this.props;
    const { user } = auth;
    this.username = `${user.email}(Teacher)`;

    socket.on("new message", (data) => {
      const { groupChat } = this.state;
      const { message } = data;
      let newMessage = {
        username: message.username,
        message: message.message,
      };
      groupChat.push(newMessage);
      this.setState({ groupChat }, () => {
        if (message.username !== this.username) {
          this.setState({
            newMessageNotification: true,
            notificationCount: this.state.notificationCount + 1,
          });
        }
      });
      const scrollHeight = this._chatarea.scrollHeight;
      const height = this._chatarea.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this._chatarea.scrollTop = this._chatarea.scrollHeight;
      this._chatarea.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    });
  }

  socketEmitHandler = (type, data) => {
    let { socket } = this.props;
    let { selectStudents, students } = this.state;
    switch (type) {
      case "sendMessage":
        let { sessionId } = this.props;
        data.sessionId = sessionId;
        if (selectStudents) {
          data.students = students;
          socket.emit("private message", data);
        } else {
          socket.emit("new message", data);
        }
        break;
      default:
        console.error("invalid socket emit, case", type, data);
    }
  };

  toggleChat = () => {
    this.setState({
      isClosed: !this.state.isClosed,
      newMessageNotification: false,
      notificationCount: 0,
    });
  };

  toggleStudentSelectOption = () => {
    this.setState({ selectStudents: !this.state.selectStudents, students: [] });
  };

  isValid() {
    const { message } = this.state;
    const errors = {};

    if (Validator.isNull(message)) {
      errors.message = "Cann't send empty message.";
    }

    const isValid = isEmpty(errors);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      const { message } = this.state;

      this.setState({ errors: {}, message: "" }, () => {
        this.socketEmitHandler("sendMessage", {
          message,
          username: this.username,
        });
      });
    }
  };

  onChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      [name]: value,
      newMessageNotification: false,
      notificationCount: 0,
    });
  };

  onFocus = (e) => {
    this.setState({ newMessageNotification: false, notificationCount: 0 });
  };
  uploadFile = (e) => {
    const { tempUpload } = this.props;
    const { files } = e.target;
    if (files.length) {
      this.setState({ isUploading: true });

      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`file${i}`, files[i]);
      }

      tempUpload(formData)
        .then((res) => {
          let uploadedFiles = res.data.files;
          const { auth } = this.props;
          const { user } = auth;

          for (let i = 0; i < uploadedFiles.length; i++) {
            this.socketEmitHandler("sendMessage", {
              message: `${process.env.REACT_APP_BASE_URL}/tmp-attachments/${uploadedFiles[i].filename}`,
              username: `${user.email}(Host)`,
            });
          }

          this.setState({ isUploading: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  onSelectCheckbox = (val) => {
    const students = this.state.students;

    const index = students.indexOf(val);
    if (index > -1) {
      students.splice(index, 1);
      this.setState({ students });
    } else {
      students.push(val);
      this.setState({ students: [...new Set(students)] });
    }
  };

  render() {
    const {
      errors,
      message,
      isClosed,
      isUploading,
      groupChat,
      newMessageNotification,
      students,
      selectStudents,
    } = this.state;
    const { studentList } = this.props;
    const groupChatMessages = map(groupChat, (value, key) => (
      <div key={key} className={cx(s["at-clientmessage"])}>
        <div className={cx(s["at-messagebox"])}>
          <figure className={cx(s["at-userimg"])}>
            <img src="images/user-placeholder.jpg" alt="image description" />
          </figure>
          <div className={cx(s["at-chatcontent"])}>
            {ValidURL(value.message) ? (
              <p>
                <a href={value.message} target="_blank">
                  Click to view attachment
                </a>{" "}
              </p>
            ) : (
              <p>{value.message}</p>
            )}
            {/* <p>{value.message}</p> */}
          </div>
        </div>
        <div className={cx(s["at-messagestatus"])}>
          <span className={cx(s["at-emailadd"])}>{value.username}</span>
        </div>
      </div>
    ));

    let users = map(studentList, (val, key) => (
      <span key={key} className={s["at-checkbox"]}>
        <input
          onChange={this.onSelectCheckbox.bind(this, val)}
          checked={!!(students.indexOf(val) > -1)}
          type="checkbox"
          name="join-chat"
          id={`${key + "+" + val}`}
        />
        <label htmlFor={`${key + "+" + val}`}> {val} &nbsp;</label>
      </span>
    ));

    return (
      <Draggable bounds="parent" handle={`.${s["at-chatclientimg"]}`}>
        <div className={s["at-chatpopup"]}>
          <div
            className={cx(s["at-chatbox"], { [s["at-closechat"]]: isClosed })}
          >
            <div className={cx(s["at-clientnameandstatus"])}>
              <span>
                Contribute Cloud Group Chat{" "}
                {isUploading ? " - Uploading file..." : null} &nbsp;
                <a
                  onClick={this.toggleStudentSelectOption}
                  id="at-btnclosechat"
                  className={s["at-btnclosechat"]}
                  href="javascript:void(0);"
                >
                  <i className="fa fa-users"></i>
                </a>
              </span>

              <img
                onClick={this.toggleChat}
                className={cx(s["at-chatgrabimg"])}
                src="images/chat-drag.png"
              />
              <a
                id="at-btnclosechat"
                className={s["at-btnclosechat"]}
                href="javascript:void(0);"
              >
                <i className="icon-cross"></i>
              </a>
            </div>
            <div
              ref={(c) => (this._chatarea = c)}
              className={cx(s["at-chathistory"])}
            >
              <div className={cx(s["at-scrollbar"])}>
                {selectStudents ? (
                  <div className={s["at-notification"]}>{users}</div>
                ) : null}
                <div className={s["at-notification"]}></div>

                {groupChatMessages}
              </div>
            </div>
            <div className={cx(s["at-formchat"])}>
              <form onSubmit={this.onSubmit}>
                <fieldset>
                  {/* <i className="fa fa-paperclip"></i> */}

                  <label htmlFor="file-input">
                    <i className="fa fa-paperclip fa-lg" aria-hidden="true"></i>
                  </label>
                  <input
                    type="file"
                    className={s["hide"]}
                    name="file-input"
                    id="file-input"
                    onChange={this.uploadFile}
                  />

                  <TextFieldGroup
                    error={errors.message}
                    label="Type here &amp; press enter"
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    value={message}
                    field="message"
                  />
                  <button className="fa fa-paper-plane-o"></button>
                </fieldset>
              </form>
            </div>
          </div>

          <Badge count={this.state.notificationCount} offset={[-20, 0]}>
            <Button
              size={"large"}
              // type="primary"
              shape="circle"
              onClick={this.toggleChat}
              style={{
                width: 60,
                height: 60,
                ...(newMessageNotification
                  ? { backgroundColor: "#008000" }
                  : { backgroundColor: "rgb(2, 162, 222)" }),
              }}
              icon={
                <WechatOutlined
                  style={{
                    fontSize: 40,
                    // ...(newMessageNotification && {
                    color: "#FFF",
                    // }),
                  }}
                />
              }
            />
          </Badge>
        </div>
      </Draggable>
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, { tempUpload })(GroupChat);

// class GroupChat extends Component {
//   static propTypes = {
//     auth: PropTypes.object.isRequired,
//     socketEmitHandler: PropTypes.func.isRequired,
//     groupChat: PropTypes.array.isRequired,
//     tempUpload: PropTypes.func.isRequired,
//     newMessageNotification: PropTypes.bool.isRequired,
//   };
//
//   constructor(props) {
//     super(props);
//     this.state = {
//       isClosed: true,
//       message: "",
//       isUploading: false,
//       errors: {},
//       groupChat: [],
//       students: [],
//       newMessageNotification: false,
//       selectStudents: false,
//     };
//   }
//
//   componentDidMount() {
//     let { socket, auth } = this.props;
//     const { user } = auth;
//     this.username = `${user.username || user.email}(Teacher)`;
//
//     socket.on("new message", (data) => {
//       const { message } = data;
//       addResponseMessage(`${message.username} - ${message.message}`);
//     });
//   }
//
//   socketEmitHandler = (type, data) => {
//     let { socket } = this.props;
//     let { selectStudents, students } = this.state;
//     switch (type) {
//       case "sendMessage":
//         let { sessionId } = this.props;
//         data.sessionId = sessionId;
//         if (selectStudents) {
//           data.students = students;
//           socket.emit("private message", data);
//         } else {
//           socket.emit("new message", data);
//         }
//         break;
//       default:
//         console.error("invalid socket emit, case", type, data);
//     }
//   };
//
//   toggleStudentSelectOption = () => {
//     this.setState({ selectStudents: !this.state.selectStudents, students: [] });
//   };
//
//   uploadFile = (e) => {
//     const { tempUpload } = this.props;
//     const { files } = e.target;
//     if (files.length) {
//       this.setState({ isUploading: true });
//
//       let formData = new FormData();
//       for (let i = 0; i < files.length; i++) {
//         formData.append(`file${i}`, files[i]);
//       }
//
//       tempUpload(formData)
//         .then((res) => {
//           let uploadedFiles = res.data.files;
//           const { auth } = this.props;
//           const { user } = auth;
//
//           for (let i = 0; i < uploadedFiles.length; i++) {
//             this.socketEmitHandler("sendMessage", {
//               message: `${process.env.REACT_APP_API_HOST}/tmp-attachments/${uploadedFiles[i].filename}`,
//               username: `${user.email}(Host)`,
//             });
//           }
//
//           this.setState({ isUploading: false });
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   };
//   onSelectCheckbox = (val) => {
//     const students = this.state.students;
//
//     const index = students.indexOf(val);
//     if (index > -1) {
//       students.splice(index, 1);
//       this.setState({ students });
//     } else {
//       students.push(val);
//       this.setState({ students: [...new Set(students)] });
//     }
//   };
//
//   handleNewUserMessage = (newMessage) => {
//     this.socketEmitHandler("sendMessage", {
//       message: `${newMessage}`,
//       username: this.username,
//     });
//   };
//
//   render() {
//     return (
//       <>
//         {/*<div className="rcw-widget-container">gjkhkhkhjhgj</div>*/}
//         <Widget
//           handleNewUserMessage={this.handleNewUserMessage}
//           profileAvatar={"images/buddy.png"}
//           title="Contribute Cloud Chat"
//           subtitle={null}
//         />
//       </>
//     );
//   }
// }
//
// function mapStateToProps(state) {
//   return { auth: state.auth };
// }
//
// export default connect(mapStateToProps, { tempUpload })(GroupChat);
