// import React from "react";

// const GetRoom = () => (
//   <iframe
//     width="100%"
//     height="100%"
//     style={{ border: 0 }}
//     allow="camera; microphone"
//     src={`${process.env.REACT_APP_WIDGET_HOST}/#${Math.random()
//       .toString(36)
//       .substring(2)}`}
//   />
// );

// export default GetRoom;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
class GetRoom extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <iframe
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allow="camera; microphone"
          src={`${process.env.REACT_APP_WIDGET_HOST}/#${Math.random()
            .toString(36)
            .substring(2)}`}
        />
        <Button
          onClick={() => (window.location.href = "/")}
          style={{ position: "absolute", top: 5, right: 30 }}
          danger
        >
          Leave Session
        </Button>
      </>
    );
  }
}

export default connect(null, {})(GetRoom);
