import React, { Component } from "react";
import { Modal, Typography, Form, Input, Button, Alert, message } from "antd";
import TextFieldGroup from "../common/TextFieldGroup";
import validateInput from "../../validations/resetPassword";
import { connect } from "react-redux";
import { resetPassword } from "../../actions/authActions";
import { addFlashMessage } from "../../actions/flashMessages";
import PropTypes from "prop-types";
import cx from "classnames";
import { FormattedMessage } from "react-intl";
import messages from "../../messages";
import s from "./Login.module.css";

const { Paragraph, Title } = Typography;
class ResetPassReqForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isLoading: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }

  onFinish(values) {
    this.setState({ errors: {}, isLoading: true });
    this.props.resetPassword(values).then(
      () => {
        this.setState({ errors: {}, isLoading: false });
        message.success("Email for password reset has been sent successfully.");
        this.props.toggleForgetPassModal();
      },
      (err) =>
        this.setState({ errors: err.response.data.errors, isLoading: false })
    );
  }

  render() {
    const { errors, isLoading } = this.state;
    const { toggleForgetPassModal, visible } = this.props;
    const { intl } = this.context;
    return (
      <Modal
        title="Forgotten your password?"
        visible={visible}
        okText={<FormattedMessage {...messages.sendResetLink} />}
        okButtonProps={{
          form: "resetForm",
          key: "submit",
          htmlType: "submit",
          size: "large",
        }}
        onCancel={toggleForgetPassModal}
        cancelText={<FormattedMessage {...messages.cancel} />}
        cancelButtonProps={{
          size: "large",
        }}
      >
        <Form name="resetForm" size="large" onFinish={this.onFinish}>
          <Paragraph>
            <FormattedMessage {...messages.forgottenPopupMessage} />
          </Paragraph>

          {errors.form && <Alert message={errors.form} type="error" />}

          <Form.Item
            name="identifier"
            {...(errors.identifier && {
              help: errors.identifier,
              validateStatus: "error",
            })}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input
              placeholder={intl.formatMessage({ ...messages.usernameOrEmail })}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

ResetPassReqForm.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  toggleForgetPassModal: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
};
ResetPassReqForm.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default connect(null, { resetPassword, addFlashMessage })(
  ResetPassReqForm
);
