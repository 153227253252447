import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Modal,
  Breadcrumb,
  Tag,
  Badge,
  Divider,
  Dropdown,
  Menu,
  Card,
  Empty,
  Form,
  Input,
  message,
  Space,
  Popconfirm,
} from "antd";
import {
  FolderAddOutlined,
  UploadOutlined,
  RightOutlined,
  HomeOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
// import { useAccess, Access } from "umi";

import map from "lodash/map";
import { request } from "../../utils/request";
import CustomScrollbars from "../../utils/CustomScrollbars";
import IconFont from "./iconFont";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import Tus from "@uppy/tus";
import GoogleDrive from "@uppy/google-drive";
import OneDrive from "@uppy/onedrive";
import uppyUrl from "@uppy/url";
import Dropbox from "@uppy/dropbox";

import Webcam from "@uppy/webcam";
// import Url from '@uppy/url';
import ScreenCapture from "@uppy/screen-capture";

import "@uppy/core/dist/style.css";
import "@uppy/file-input/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import "@uppy/url/dist/style.css";
import "@uppy/screen-capture/dist/style.css";
import { getIconFromMIME, getImageFromMIME } from "./mimeTypes";
import { isMobile } from "react-device-detect";

const restrictions = {
  allowedFileTypes: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/pdf",
    "image/*",
    "video/*",
  ],
};

export default ({ onSelect }) => {
  const [form] = Form.useForm();
  const [fullPath, setFullPath] = useState("");
  const [FnF, setFnF] = useState({});
  const [folderModalVisibility, setFolderModalVisibility] = useState(false);
  const [uppyModalVisibility, setUppyModalVisibility] = useState(false);
  const [previewModalVisibility, setPreviewModalVisibility] = useState(false);
  const [previewItem, setPreviewItem] = useState({});

  //   const access = useAccess();
  const fetchFnF = async () => {
    const data = await request(`/api/cloud-drive/get-folders-and-files`, {
      method: "post",
      body: JSON.stringify({ fullPath }),
    });

    setFnF(data.result);
  };
  const uppy = Uppy({
    debug: true,
    autoProceed: true,
    restrictions: {
      // maxFileSize: 1000000,
      // maxNumberOfFiles: 1,
      // minNumberOfFiles: 1,
      // allowedFileTypes: ['image/*'],
    },
  })
    .use(Tus, { endpoint: "https://tus.contribute.cloud/files/" })
    .use(ScreenCapture, { endpoint: "https://tus.contribute.cloud/files/" })
    .use(Webcam, { endpoint: "https://tus.contribute.cloud/files/" })
    // Cloud Drives
    .use(GoogleDrive, { companionUrl: "https://uppy.contribute.cloud" })
    .use(Dropbox, { companionUrl: "https://uppy.contribute.cloud" })
    // .use(uppyUrl, { companionUrl: "https://uppy.contribute.cloud" })
    .use(OneDrive, { companionUrl: "https://uppy.contribute.cloud" });

  uppy.on("complete", async (result) => {
    for (const item of result.successful) {
      let parentId = "";
      if (fullPath) {
        const pathArray = fullPath.split("/");
        parentId = pathArray[pathArray.length - 1];
      }

      await request("/api/cloud-drive/create-file", {
        method: "post",
        body: JSON.stringify({
          filename: item.name,
          mimetype: item.type,
          size: item.size,
          folder: parentId,
          fileUrl: item.uploadURL,
          fileExtension: item.extension,
        }),
      });

      fetchFnF();
    }
  });

  useEffect(() => {
    fetchFnF();
  }, [fullPath]);

  React.useEffect(() => {
    return () => uppy.close();
  }, []);

  const onCreateFolder = async (values) => {
    try {
      let parentId = "";
      if (fullPath) {
        const pathArray = fullPath.split("/");
        parentId = pathArray[pathArray.length - 1];
      }

      await request("/api/cloud-drive/create-folder", {
        method: "post",
        body: JSON.stringify({ ...values, parentId }),
      });

      form.resetFields();
      setFolderModalVisibility(false);

      fetchFnF();
      message.success("Folder created successful");
    } catch (error) {
      message.error("Fail to create folder, please try again");
    }
  };

  const onDeleteFile = async (values, type) => {
    try {
      await request(`/api/cloud-drive/delete-${type}`, {
        method: "delete",
        body: JSON.stringify(values),
      });

      fetchFnF();
      message.success("File deleted successful");
    } catch (error) {
      message.error("Fail to delete, please try again");
    }
  };

  const Menu2 = ({ item }) => {
    return (
      <Menu>
        <Menu.Item key="1">
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              // onDelete(item);
            }}
            okText="Yes"
            cancelText="No"
          >
            Delete
          </Popconfirm>
        </Menu.Item>
        {/* <Menu.Item key="2">Rename</Menu.Item> */}
      </Menu>
    );
  };

  const Menu3 = ({ item, type }) => {
    return (
      <Menu>
        <Menu.Item key="1">
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              onDeleteFile(item, type);
            }}
            okText="Yes"
            cancelText="No"
          >
            Delete
          </Popconfirm>
        </Menu.Item>
        {/* <Menu.Item key="2">Rename</Menu.Item> */}
      </Menu>
    );
  };

  return (
    <>
      {!onSelect && (
        <Space
          style={{ position: "absolute", bottom: 70 }}
          direction="horizontal"
        >
          <Typography.Text className="dd-d-block" type="danger">
            *Double click to open folder
          </Typography.Text>
          <Typography.Text className="dd-d-block" type="danger">
            *Click on file for preview
          </Typography.Text>
          <Typography.Text className="dd-d-block" type="danger">
            *Right Click for more actions
          </Typography.Text>
        </Space>
      )}
      {/* <div
        style={{ position: "absolute", bottom: 0, right: 0, zIndex: 2 }}
        className="dd-d-flex dd-justify-content-between dd-align-items-center"
      >
        <div>
          <Typography.Text className="dd-d-block" type="danger">
            *Double click to open folder
          </Typography.Text>
          <Typography.Text className="dd-d-block" type="danger">
            *Click on file for preview
          </Typography.Text>
          <Typography.Text className="dd-d-block" type="danger">
            *Right Click for more actions
          </Typography.Text>
        </div>

          <div>
            <Button
              onClick={() => setFolderModalVisibility(true)}
              icon={<FolderAddOutlined />}
              type="primary"
              size="large"
              value="large"
            >
              Create Folder
            </Button>
            <Button
              onClick={() => setUppyModalVisibility(true)}
              icon={<UploadOutlined />}
              type="primary"
              size="large"
              value="large"
            >
              Upload File
            </Button>
          </div>

      </div> */}
      <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-mt-3">
        <Breadcrumb
          separator={
            <Tag color="red">
              <RightOutlined />
            </Tag>
          }
        >
          <Breadcrumb.Item
            className="gx-pointer"
            onClick={() => {
              setFullPath("");
            }}
          >
            <HomeOutlined /> Home
          </Breadcrumb.Item>
          {/* @ts-ignore */}
          {map(FnF.breadcrumbs, (breadcrumb, index) => (
            <Breadcrumb.Item
              className="gx-pointer"
              onClick={() => {
                setFullPath(breadcrumb.id);
              }}
            >
              {`${breadcrumb.name}`}
              {/* @ts-ignore */}
              {FnF.breadcrumbs.length - 1 == index ? (
                <Badge
                  className="gx-ml-2"
                  /* @ts-ignore */
                  count={FnF.files.length ? FnF.files.length : 0}
                />
              ) : null}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>

        <div className="p-0 m-0">
          {!onSelect && (
            <>
              <Button
                onClick={() => setFolderModalVisibility(true)}
                icon={<FolderAddOutlined />}
                type="primary"
                size="large"
                value="large"
              >
                Create Folder
              </Button>
              <Button
                onClick={() => setUppyModalVisibility(true)}
                icon={<UploadOutlined />}
                type="primary"
                size="large"
                value="large"
              >
                Upload File
              </Button>
            </>
          )}
        </div>
      </div>

      <CustomScrollbars style={{ height: "calc(100vh - 300px)" }}>
        {/* @ts-ignore */}
        {(FnF.folders || []).length || (FnF.files || []).length ? (
          <>
            <Divider orientation="left">Folders</Divider>

            <div
              style={{
                gridGap: "1rem",
                gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
              }}
              className="gx-card-list"
            >
              {/* @ts-ignore */}
              {(FnF.folders || []).map((item, index) => (
                <Dropdown
                  overlay={<Menu3 item={item} type="folder" />}
                  trigger={["contextMenu"]}
                >
                  <Card
                    onDoubleClick={() => setFullPath(item.fullPath)}
                    style={{
                      padding: 0,
                      borderRadius: 5,
                      textAlign: "center",
                      boxShadow: "0 1px 5px rgba(50,50,50,.1)",
                      border: "1px solid #35688d47",
                    }}
                    className="gx-card-item file"
                    title={item.name}
                    bordered={false}
                    bodyStyle={{ padding: 0, maxHeight: 150 }}
                  >
                    <IconFont type="dd-folder1" style={{ fontSize: 150 }} />
                  </Card>
                </Dropdown>
              ))}
            </div>

            <Divider orientation="left">Files</Divider>

            <div
              style={{
                gridGap: "1rem",
                gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
              }}
              className="gx-card-list"
            >
              {/* @ts-ignore */}
              {(FnF.files || []).map((item, index) => (
                <Dropdown
                  overlay={<Menu3 item={item} type="file" />}
                  trigger={["contextMenu"]}
                >
                  <Card
                    onClick={() => {
                      if (onSelect) {
                        onSelect(item);
                      }
                    }}
                    onDoubleClick={() => {
                      if (onSelect) {
                        onSelect(item);
                      } else {
                        setPreviewItem(item);
                        setPreviewModalVisibility(true);
                      }
                    }}
                    style={{
                      padding: 0,
                      borderRadius: 5,
                      textAlign: "center",
                      boxShadow: "0 1px 5px rgba(50,50,50,.1)",
                      border: "1px solid #35688d47",
                    }}
                    bordered={false}
                    className="gx-card-item gx-pointer"
                    title={item.filename}
                    bodyStyle={{ padding: 0, maxHeight: 150 }}
                  >
                    {item.mimetype.includes("image") ? (
                      <img src={item.path} alt={item.name} />
                    ) : (
                      <>
                        <IconFont
                          type={getImageFromMIME(item.mimetype)}
                          style={{
                            fontSize: 130,
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                        />
                      </>
                    )}
                  </Card>
                </Dropdown>
              ))}
            </div>
          </>
        ) : (
          <Empty />
        )}
      </CustomScrollbars>

      <Modal
        title={"Upload or Import Files"}
        width={750}
        bodyStyle={{ height: 800 }}
        visible={uppyModalVisibility}
        onCancel={() => setUppyModalVisibility(false)}
        footer={null}
      >
        <Dashboard
          height={750}
          hideUploadButton={true}
          proudlyDisplayPoweredByUppy={false}
          hideProgressAfterFinish={true}
          uppy={uppy}
          plugins={[
            "Webcam",
            ...(!isMobile ? ["ScreenCapture"] : []),
            "GoogleDrive",
          ]}
        />
      </Modal>

      <Modal
        title={"Preview"}
        width={750}
        bodyStyle={{ height: 800 }}
        visible={previewModalVisibility}
        onCancel={() => {
          setPreviewItem({});
          setPreviewModalVisibility(false);
        }}
        footer={null}
      >
        <iframe
          style={{ width: "100%", height: "100%", border: 0 }}
          src={`https://docs.google.com/gview?url=https://portal.maxifjaved.com${previewItem.path}&embedded=true`}
        ></iframe>
      </Modal>

      <Modal
        title="Create Folder"
        visible={folderModalVisibility}
        onCancel={() => setFolderModalVisibility(false)}
        okText="Create Folder"
        okButtonProps={{
          form: "createFolder",
          htmlType: "submit",
        }}
      >
        <Form
          form={form}
          id="createFolder"
          style={{ width: "100%" }}
          onFinish={onCreateFolder}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Folder name" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
