import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Spin,
  Card,
  Button,
  Space,
  Row,
  Col,
  Tooltip,
  InputNumber,
  Popconfirm,
  Form,
  message,
  Typography,
  Divider,
  Modal,
  DatePicker,
} from "antd";
import {
  CloseOutlined,
  CopyOutlined,
  LockOutlined,
  UserDeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import axios from "axios";
import _ from "lodash";

const copyToClipboard = (clipboardText) => {
  let textArea = document.createElement("textarea");

  textArea.style.position = "fixed";
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = "2em";
  textArea.style.height = "2em";
  textArea.style.padding = 0;
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";
  textArea.style.background = "transparent";
  textArea.value = clipboardText;
  document.body.appendChild(textArea);
  textArea.select();
  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Copying sessionId command was " + msg);
  } catch (err) {
    console.log("Oops, unable to copy");
  }
  document.body.removeChild(textArea);
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `This field is required`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const expandedRowRender = (record) => {
  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 100,
      align: "center",
      render: (row) => (
        <Space>
          {/* <span className="gx-text-primary gx-pointer" onClick={() => row.editStudent(record)}>
                    <i className="icon icon-edit gx-fs-sm gx-mr-2" />Edit
                </span> */}
          {/* <Divider type="vertical" /> */}
          <Popconfirm
            placement="left"
            title="Are you sure?"
            okText="Yes"
            onConfirm={() => row.removeStudent(record.key, row.key)}
          >
            <span className="gx-text-primary gx-pointer">
              <i className="icon icon-trash gx-fs-sm gx-mr-2" />
              Delete
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const data = _.map(record.students, (rec, key) => {
    return {
      key: rec._id,
      name: rec.name,
      email: rec.email,
      openStudentModal: record.openStudentModal,
      removeStudent: record.removeStudent,
    };
  });

  return (
    <Card
      className={`gx-card-widget`}
      title={
        <h2 className="h4 gx-text-capitalize gx-ml-3 gx-mb-0">{record.name}</h2>
      }
      extra={
        <p
          onClick={() => record.openStudentModal(record.key)}
          className="gx-text-primary gx-mr-4 gx-mb-0 gx-pointer gx-d-none gx-d-sm-block"
        >
          <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle mr-1" />
          Add Student
        </p>
      }
    >
      <Table
        className="gx-table-no-bordered"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
      />
    </Card>
  );
};

export const AdminArea = () => {
  const [form] = Form.useForm();
  const [editUserForm] = Form.useForm();
  const [studentModalVisible, setStudentModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editedUser, setEditedUser] = useState({ userId: "" });
  const [editedUserExpiry, setEditedUserExpiry] = useState("");

  const [loading, setLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [usersData, setUsersData] = useState({
    data: [],
    total: 0,
    limit: 20,
    currentPage: 1,
  });
  const [editingKey, setEditingKey] = useState("");

  useEffect(() => {
    fetchUsersList({
      limit: usersData.limit,
      skip: usersData.limit * (usersData.currentPage - 1),
    });
  }, []);

  const fetchUsersList = async ({
    limit = usersData.limit,
    skip = usersData.limit * (usersData.currentPage - 1),
    qs = "",
  }) => {
    try {
      setLoading(true);
      const result = await axios(
        `/api/v3/adminArea/users?limit=${limit || 10}&skip=${skip || 0}&qs=${
          qs || ""
        }`
      );
      setUsersData({ ...usersData, ...result?.data });
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Error while processing your request"
      );
    }
    setLoading(false);
  };

  const loginToUserAccount = async (userId: string, loginType: string) => {
    try {
      setLoading(true);
      const result = await axios.post(`/api/v3/adminArea/login`, { userId });
      if (loginType === "login") {
        localStorage.setItem("jwtToken", result?.data?.token);
        window.location.reload();
      } else if (loginType === "copyLoginUrl") {
        copyToClipboard(
          `${
            process.env.REACT_APP_FRONTEND_URL
          }?type=social&success=true&token=${JSON.stringify({
            token: result?.data?.token,
          })}`
        );
        message.success("Login URL copied to clipboard");
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Error while processing your request"
      );
    }
    setLoading(false);
  };
  const deleteUserById = async (userId: string) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v3/adminArea/user/${userId}`);

      fetchUsersList({
        limit: usersData.limit,
        skip: usersData.limit * (usersData.currentPage - 1),
      });
      message.success("Permanently deleted user.");
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Error while processing your request"
      );
    }
  };

  const saveEditedUser = async (values: any) => {
    try {
      await axios.post(`/api/v3/adminArea/update-user/${editedUser?.userId}`, {
        ...values,
        license_expiry: editedUserExpiry,
      });
      message.success("User updated successfully.");
      setEditModalVisible(false);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Error while processing your request"
      );
    }
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "name",
      editable: false,
      className: "gx-pointer",
    },
    {
      title: "Username",
      dataIndex: "username",
      editable: false,
      className: "gx-pointer",
    },
    {
      title: "Email",
      dataIndex: "email",
      editable: false,
      className: "gx-pointer",
    },
    {
      title: "License",
      dataIndex: "license",
      editable: false,
      className: "gx-pointer",
    },
    {
      title: "Actions",
      dataIndex: "operation",
      key: "x",
      fixed: "right",
      width: 400,
      align: "center",
      render: (_, record) => {
        return (
          <Space>
            <span
              onClick={() => loginToUserAccount(record.userId, "login")}
              className="gx-text-primary gx-pointer"
            >
              <LockOutlined className="icon gx-fs-sm gx-mr-2" />
              Login
            </span>
            <Divider type="vertical" />
            <span
              onClick={() => loginToUserAccount(record.userId, "copyLoginUrl")}
              className="gx-text-primary gx-pointer"
            >
              <CopyOutlined className="icon gx-fs-sm gx-mr-2" />
              Copy Login Url
            </span>
            <Divider type="vertical" />
            <span
              onClick={() => {
                editUserForm.resetFields();
                console.log(
                  "🚀 ~ file: index.tsx ~ line 327 ~ AdminArea ~ record",
                  record
                );

                setEditedUser(record);
                setEditModalVisible(true);
              }}
              className="gx-text-primary gx-pointer"
            >
              <EditOutlined className="icon gx-fs-sm gx-mr-2" />
              Edit
            </span>
            <Divider type="vertical" />

            <Popconfirm
              placement="left"
              title={
                <Space direction="vertical">
                  <span>Are you sure?</span>
                  <span>
                    This will delete all the workbook and all the data related
                    to this user.
                  </span>
                  <span>This action cannot be undone.</span>
                </Space>
              }
              okText="Delete This User"
              onConfirm={() => deleteUserById(record.userId)}
            >
              <span className="gx-text-primary gx-pointer">
                <i className="icon icon-trash gx-fs-sm gx-mr-2" />
                Delete User Permanently
              </span>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const cdata = _.map(usersData?.data, (item, key) => {
    return {
      key: item._id || item.id,
      userId: item._id || item.id,
      name: `${item.first_name || ""} ${item.last_name || ""}`,
      username: `${item.username}`,
      email: item.email,
      license: item.license,
      ...item,
    };
  });

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {};

  return (
    <Spin spinning={loading}>
      <Card
        className={`gx-card-widget`}
        title={<h2 className="h4 gx-text-capitalize gx-mb-0">Users List</h2>}
        extra={
          <Space>
            {/* <p
              onClick={() => setPopupVisible(true)}
              className="gx-text-primary gx-mb-0 gx-pointer"
            >
              <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle mr-1" />
              Add New User
            </p> */}
            <Input.Search
              style={{
                width: 400,
              }}
              placeholder="Search User By Username, Email, License"
              onSearch={(value) => {
                fetchUsersList({ limit: usersData.limit, skip: 0, qs: value });
              }}
              enterButton
            />
          </Space>
        }
      >
        <Form form={form} component={false}>
          <Table
            className="gx-table-no-bordered"
            bordered
            size="small"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={cdata}
            // expandable={{ expandedRowRender }}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              onShowSizeChange: (current, size) => {
                setUsersData({
                  ...usersData,
                  currentPage: current,
                  limit: size,
                });
                fetchUsersList({ limit: size, skip: size * (current - 1) });
              },
              onChange: (page, pageSize) => {
                setUsersData({
                  ...usersData,
                  currentPage: page,
                  limit: pageSize,
                });
                fetchUsersList({
                  limit: pageSize,
                  skip: pageSize * (page - 1),
                });
              },
              total: usersData?.total,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} users. `,
            }}
          />
        </Form>

        <Modal
          title="Edit User"
          visible={editModalVisible}
          okText="Save"
          okButtonProps={{
            form: "euf",
            htmlType: "submit",
            size: "large",
          }}
          onCancel={() => {
            editUserForm.resetFields();
            setEditModalVisible(false);
            setEditedUser({});
          }}
          cancelButtonProps={{
            size: "large",
          }}
        >
          <Form
            name="euf"
            size="large"
            form={editUserForm}
            onFinish={saveEditedUser}
            initialValues={editedUser}
          >
            <Form.Item label="First Name" name={"first_name"}>
              <Input disabled placeholder="First Name" />
            </Form.Item>
            <Form.Item label="Last Name" name={"last_name"}>
              <Input disabled placeholder="Last Name" />
            </Form.Item>

            <Form.Item label="Email" name={"email"}>
              <Input disabled placeholder="Email" />
            </Form.Item>
            <Form.Item label="License" name={"license"}>
              <Input placeholder="License" />
            </Form.Item>

            <Form.Item label="New Password" name={"new_password"}>
              <Input placeholder="New Password" />
            </Form.Item>

            <DatePicker
              onChange={(selectedDate) => {
                setEditedUserExpiry(selectedDate.format("YYYY-MM-DD"));
              }}
              format="YYYY-MM-DD"
              placeholder="License Expiry Date"
            />
          </Form>
        </Modal>
      </Card>
    </Spin>
  );
};
