// Storage.js
//
import React, { Component } from 'react';
import ProfileForm from './ProfileForm';
import NavigationBar from '../NavigationBar';

import { Link } from 'react-router';

import { connect } from 'react-redux';
import { userProfileRequest, getUserDetails } from '../../actions/profileActions';
import { addFlashMessage } from '../../actions/flashMessages.js';
import PropTypes from 'prop-types';

class Storage extends Component {
  constructor(props) {
    super(props);
    window.document.title = 'Contibute | Storage';
  }
  render() {
    return (<div style={{ backgroundColor: '#f5f8fa' }}>
      <NavigationBar />

      <div className="container" >
        <div className="row">
          <div className="col-lg-10 col-lg-offset-1 m-t-25">
            <div className="col-lg-4">
              <div className="module">
                <ul>
                  <li className="active"><Link to="/profile" className="list-link js-nav">Account
                    <i className="fa fa-angle-right" />
                  </Link></li>
                  <li><Link to="/students" className="list-link js-nav">Participant List
                    <i className="fa fa-angle-right" />
                  </Link></li>
                  <li><Link to="/storage" className="list-link js-nav">Storage
                    <i className="fa fa-angle-right" />
                  </Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="white-box">
                <div className="setting-header white-bg">
                  <h2 className="content-main-heading">List Name Here</h2>
                </div>
                <div className="setting-content white-bg">
                  <div>
                    <span>Dropbox Files</span>
                    <small className="pull-right">1500/3,000</small>
                  </div>
                  <div className="progress progress-small">
                    <div style={{ width: '50%' }} className="progress-bar" />
                  </div>
                  <table className="table stats">
                    <tbody>
                      <tr>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">15</button>
                          Pictures
                        </td>
                        <td> <button type="button" className="btn btn-info-theme m-r-sm">40</button>
                          PDFs
                        </td>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">15</button>
                          Exceel Sheet
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">20</button>
                          Word Document
                        </td>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">40</button>
                          Videos
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <span>Google Drive Files</span>
                    <small className="pull-right">2300/3000</small>
                  </div>
                  <div className="progress progress-small">
                    <div style={{ width: '73%' }} className="progress-bar progress-bar-warning" />
                  </div>
                  <table className="table stats">
                    <tbody>
                      <tr>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">15</button>
                          Pictures
                        </td>
                        <td> <button type="button" className="btn btn-info-theme m-r-sm">40</button>
                          PDFs
                        </td>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">15</button>
                          Exceel Sheet
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">20</button>
                          Word Document
                        </td>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">40</button>
                          Videos
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* One Drive */}
                  <div>
                    <span>One Drive</span>
                    <small className="pull-right">2800/3000</small>
                  </div>
                  <div className="progress progress-small">
                    <div style={{ width: '95%' }} className="progress-bar progress-bar-danger" />
                  </div>
                  <table className="table stats">
                    <tbody>
                      <tr>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">15</button>
                          Pictures
                        </td>
                        <td> <button type="button" className="btn btn-info-theme m-r-sm">40</button>
                          PDFs
                        </td>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">15</button>
                          Exceel Sheet
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">20</button>
                          Word Document
                        </td>
                        <td>
                          <button type="button" className="btn btn-info-theme m-r-sm">40</button>
                          Videos
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* /one drive */}
                  <div className="clearfix" />
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>

    </div>);
  }
}

Storage.propTypes = {
  userProfileRequest: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
};

export default connect(null, { userProfileRequest, addFlashMessage, getUserDetails })(Storage);
