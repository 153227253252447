import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Col,
  Button,
  Input,
  Popconfirm,
  message,
  Space,
  Tooltip,
  PageHeader,
  Card,
  Typography,
} from "antd";
import {
  EditOutlined,
  DownloadOutlined,
  UsergroupAddOutlined,
  CalendarOutlined,
  DeleteOutlined,
  FontColorsOutlined,
  FolderOpenOutlined,
  PlaySquareOutlined,
} from "@ant-design/icons";

import PropTypes from "prop-types";
import map from "lodash/map";
import messages from "../../messages";
import Pagination from "../common/Pagination";
import InvitationPage from "./InvitationPage";
import NewLecture from "../misc/newLecture/NewLecture";
import NewLecture2 from "../misc/newLecture/NewLecture";

import {
  getUserWorkbookList,
  deleteUserWorkbook,
  exportUserWorkbook,
  startLectureAndInviteStudent,
} from "../../actions/workbook";
import { Link } from "react-router";
import { FormattedMessage } from "react-intl";
import intlMessages from "../../messages";
import CustomScrollbars from "../../utils/CustomScrollbars";

const { Search } = Input;

class DashboardPage extends Component {
  static propTypes = {
    getUserWorkbookList: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      workbooks: [],
      toggleWorkbookModal: false,
      toggleInvitationModal: false,
      wb: {},
      totalWorkbooks: 0,
      currentPage: 0,
      search: "",
      errors: {},
      isLoading: false,
      folder: "",
      workbookType: "workbook",
      lecture: false,
    };
  }

  componentDidMount = () => {
    const { location } = this.props;
    const folder = location.query.folder;
    if (folder) {
      this.setState({ folder }, () => {
        this.getUserWorkbookList();
      });
    } else {
      this.getUserWorkbookList();
    }
  };
  refreshData = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getUserWorkbookList();
    });
  };
  getUserWorkbookList = () => {
    const { getUserWorkbookList } = this.props;
    const { currentPage, search, folder } = this.state;

    getUserWorkbookList(currentPage, search, folder)
      .then((res) => {
        this.setState({
          workbooks: res.data.workbooks,
          totalWorkbooks: res.data.total,
        });
      })
      .catch((error) => {
        console.error(error);
        message.error(
          "Oops, Error while processing your request, Please try again."
        );
      });
  };

  toggleWorkbookModal = (wb) => {
    this.setState({
      toggleWorkbookModal: !this.state.toggleWorkbookModal,
      wb: wb || {},
    });

    this.getUserWorkbookList();
  };

  toggleInvitationModal = (wb) => {
    this.setState({
      toggleInvitationModal: !this.state.toggleInvitationModal,
      wb: wb || {},
    });
  };

  exportUserWorkbook = (workbookId) => {
    const { exportUserWorkbook } = this.props;
    message.success(
      "Your workbook export request is in progress, workbook download will start soon."
    );

    exportUserWorkbook(workbookId)
      .then((res) => {
        message.success("Your workbook exported successfully.");

        window.open(
          `${process.env.REACT_APP_API_HOST}/api/workbook/download/${workbookId}`
        );
      })
      .catch((error) => {
        console.error(error);
        message.error(
          "Oops, Error while processing your request, Please try again."
        );
      });
  };

  startLectureAndInviteStudent = (id) => {
    const { startLectureAndInviteStudent } = this.props;

    startLectureAndInviteStudent(id, { students: [] })
      .then((res) => {
        this.context.router.push({
          pathname: "/lecture",
          query: { id: id, sessionId: res.data.sessionId },
        });
      })
      .catch((error) => {
        console.error(error);
        message.error(
          "Oops, Error while processing your request, Please try again."
        );

        this.setState({
          errors: error.response ? error.response.data.errors : error,
        });
      });
  };

  onSearch = (search) => {
    this.setState({ search }, () => {
      this.getUserWorkbookList("search");
    });
  };

  onDeleteConfirm = (workbook) => {
    const { deleteUserWorkbook } = this.props;

    deleteUserWorkbook(workbook._id)
      .then((res) => {
        message.success(res.data.message);
        this.getUserWorkbookList();
      })
      .catch((error) => {
        console.error(error);
        message.error("Error while deleting your workbook, Please try again.");
      });
  };

  toggleLecture = (property) => {
    this.setState({ lecture: !this.state.lecture, workbookType: property });
  };

  render() {
    const {
      workbooks,
      toggleWorkbookModal,
      totalWorkbooks,
      currentPage,
      toggleInvitationModal,
      wb,
      folder,
    } = this.state;

    return (
      <CustomScrollbars>
        <PageHeader
          className="gx-mb-4"
          ghost={false}
          onBack={() => this.context.router.push("/folders")}
          title={`${folder}`}
          subTitle={
            <FormattedMessage
              {...intlMessages.dashboardInfoText}
              values={{
                count: totalWorkbooks,
              }}
            />
          }
          extra={[
            <Button
              onClick={() => this.toggleLecture("workbook")}
              type="primary"
            >
              Create New Workbook
            </Button>,
            <Search
              style={{ width: 300 }}
              placeholder="Search workbook"
              allowClear
              onSearch={this.onSearch}
              size="large"
            />,
          ]}
        />
        {/* <Row> */}
        <div className="gx-card-list">
          {map(workbooks, (workbook, key) => {
            return (
              <div className="gx-card-item gx-product-item">
                <div className="gx-card">
                  {/* <div className="gx-card-actions">
                    <Tooltip title="Edit workbook Details">
                      <FontColorsOutlined
                        onClick={this.toggleWorkbookModal.bind(this, workbook)}
                      />
                    </Tooltip>

                    <Tooltip title="Download workbook PDf">
                      <DownloadOutlined
                        onClick={this.exportUserWorkbook.bind(
                          this,
                          workbook._id
                        )}
                      />
                    </Tooltip>

                    <Tooltip title="Invite users">
                      <UsergroupAddOutlined
                        onClick={this.toggleInvitationModal.bind(
                          this,
                          workbook
                        )}
                      />
                    </Tooltip>

                    <Tooltip title="Open in workbook editor">
                      <Link to={`/workbook?id=${workbook._id}`}>
                        <EditOutlined />
                      </Link>
                    </Tooltip>

                    <Popconfirm
                      placement="bottomRight"
                      title={"Are you sure to delete this workbook?"}
                      onConfirm={() => this.onDeleteConfirm(workbook)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip title="Delete workbook">
                        <DeleteOutlined />
                      </Tooltip>
                    </Popconfirm>

                    <Tooltip title="Start session">
                      <PlaySquareOutlined
                        onClick={this.startLectureAndInviteStudent.bind(
                          this,
                          workbook._id
                        )}
                      />
                    </Tooltip>
                  </div> */}
                  <div className="gx-card-body">
                    <figure
                      style={{
                        backgroundImage: `url(${
                          workbook.slide.length > 0 &&
                          workbook.slide[0].thumbnail
                            ? workbook.slide[0].thumbnail
                            : "images/build-lecture.png"
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  </div>
                  <div className="gx-card-foot">
                    <Card.Meta
                      title={workbook.name}
                      description={
                        <Space direction="vertical">
                          <Typography.Paragraph
                            type="secondary"
                            ellipsis={{
                              rows: 4,
                              expandable: false,
                              tooltip: workbook.description,
                            }}
                          >
                            {workbook.description || <>&nbsp;</>}
                          </Typography.Paragraph>
                          <div>
                            <Button
                              icon={<FontColorsOutlined className="gx-fs-xl" />}
                              shape="circle"
                              onClick={this.toggleWorkbookModal.bind(
                                this,
                                workbook
                              )}
                              type="primary"
                            />
                            <Button
                              icon={<DownloadOutlined className="gx-fs-xl" />}
                              shape="circle"
                              onClick={this.exportUserWorkbook.bind(
                                this,
                                workbook._id
                              )}
                              type="primary"
                            />
                            <Button
                              icon={
                                <UsergroupAddOutlined className="gx-fs-xl" />
                              }
                              shape="circle"
                              onClick={this.toggleInvitationModal.bind(
                                this,
                                workbook
                              )}
                              type="primary"
                            />
                            <Button
                              icon={<EditOutlined className="gx-fs-xl" />}
                              shape="circle"
                              onClick={() =>
                                this.context.router.push(
                                  `/workbook?id=${workbook._id}`
                                )
                              }
                              type="primary"
                            />
                            <Button
                              icon={<PlaySquareOutlined className="gx-fs-xl" />}
                              shape="circle"
                              onClick={this.startLectureAndInviteStudent.bind(
                                this,
                                workbook._id
                              )}
                              type="primary"
                            />
                            <Popconfirm
                              placement="bottomRight"
                              title={"Are you sure to delete this workbook?"}
                              onConfirm={() => this.onDeleteConfirm(workbook)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                icon={<DeleteOutlined className="gx-fs-xl" />}
                                shape="circle"
                                type="primary"
                              />
                            </Popconfirm>
                          </div>
                        </Space>
                      }
                    />
                  </div>

                  {/* <div
                    style={{ textAlign: "center" }}
                    className="gx-product-item gx-product-vertical"
                  >

                    <div className="gx-product-image">
                      <img
                        style={{ height: 200, width: 200 }}
                        src={
                          workbook.slide.length > 0 && workbook.slide[0].thumbnail
                            ? workbook.slide[0].thumbnail
                            : "images/build-lecture.png"
                        }
                      />
                    </div>
                    <div className="gx-product-body">
                      <div className="gx-product-name">{workbook.name}</div>
                    </div>
                    <Button
                      onClick={this.startLectureAndInviteStudent.bind(
                        this,
                        workbook._id
                      )}
                      type="primary"
                      className="gx-mt-sm-4 gx-fs-sm gx-btn-block gx-mb-0 gx-text-uppercase gx-border-radius-top-left-0 gx-border-radius-top-right-0"
                      size="large"
                      block
                    >
                      start session
                  </Button>
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
        {/* </Row> */}
        <Pagination
          total={totalWorkbooks}
          current={currentPage}
          onClick={this.refreshData}
        />
        <NewLecture
          toggleModal={this.toggleWorkbookModal}
          title={<FormattedMessage {...intlMessages.editWorkbook} />}
          workbookType={"edit"}
          visible={toggleWorkbookModal}
          workbook={wb}
        />

        <NewLecture2
          visible={this.state.lecture}
          toggleModal={this.toggleLecture}
          workbookType={this.state.workbookType}
          title={
            this.state.workbookType == "workbook" ? (
              <FormattedMessage {...messages.createNewWorkbook} />
            ) : (
              <FormattedMessage {...messages.startNewLecture} />
            )
          }
        />

        <InvitationPage
          toggleInvitationModal={this.toggleInvitationModal}
          visible={toggleInvitationModal}
          workbook={wb}
        />
      </CustomScrollbars>
    );
  }
}

DashboardPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(null, {
  getUserWorkbookList,
  deleteUserWorkbook,
  exportUserWorkbook,
  startLectureAndInviteStudent,
})(DashboardPage);
