import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Popconfirm,
  message,
  PageHeader,
  Input,
  Statistic,
  Modal,
  Typography,
  Col,
  Space,
  Tooltip,
  Button,
  Row,
} from "antd";
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import map from "lodash/map";
import socketIOClient from "socket.io-client";
import Pagination from "../common/Pagination/Pagination";
import { getSessionId } from "../../actions/workbook";
import actions from "../../actions/votingActivityActions";
import { Link } from "react-router";
import VotingPage from "../lecture/VotingPage";
import CustomScrollbars from "../../utils/CustomScrollbars";
import GoogleShareToClassRoom from "google-classroom-share";

const { Search } = Input;

class NsgComponent extends Component {
  static propTypes = {
    getUserWorkbookList: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      total: 0,
      currentPage: 0,
      search: "",
      folder: "",
      errors: {},
      isLoading: false,
      activity: {},
      sessionId: "",
      studentList: [],
    };
  }

  componentDidMount = async () => {
    const { location } = this.props;
    const folder = location.query.folder;
    if (folder) {
      this.setState({ folder }, () => {
        this.getFolderList();
      });
    } else {
      this.getFolderList();
    }

    try {
      const { auth, getSessionId } = this.props;
      const { email } = auth.user;

      let { data } = await getSessionId();
      this.setState({ sessionId: data.sessionId }, () => {
        this.socket = socketIOClient(process.env.REACT_APP_API_HOST, {
          transports: ["websocket"],
          query: `sessionId=${data.sessionId}&role=Teacher&username=${email}`,
        });
        this.socket.on("connect", (_socket) => {
          this.socket.emit("createRoom", this.state.sessionId);

          this.socket.on("newStudentJoinLecture", (data) => {
            const student = this.state.studentList;
            student.push(data.email);
            this.setState({ studentList: [...new Set(student)] });

            this.socket.emit("startActivityOnly", {
              sessionId: this.state.sessionId,
              isActivity: true,
            });
          });

          this.socket.on("studentLeft", (data) => {
            const studentList = this.state.studentList;
            let slindex = studentList.indexOf(data.email);

            if (slindex > -1) {
              studentList.splice(slindex, 1);
              this.setState({ studentList });
            }

            this.socket.emit("startActivityOnly", {
              sessionId: this.state.sessionId,
              isActivity: true,
            });
          });
        });
      });
    } catch (error) {
      console.error("TCL: NsgComponent -> componentDidMount -> error", error);
    }

    window.onbeforeunload = () => {
      this.socket.emit("endActivityOnly", {
        sessionId: this.state.sessionId,
      });
    };
  };

  refreshData = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getFolderList();
    });
  };
  getFolderList = async () => {
    const { currentPage, search, folder } = this.state;

    try {
      let {
        data: { data: activities, total },
      } = await actions.read(currentPage, search, folder);
      this.setState({ activities, total });
    } catch (error) {
      message.error(
        "Oops, Error while processing your request, Please try again."
      );
    }
  };

  onSearch = (search) => {
    this.setState({ search }, () => {
      this.getFolderList();
    });
  };

  startActivity = async (activity) => {
    this.setState({ activity });
  };

  onDeleteConfirm = async (workbook) => {
    try {
      await actions.delete(workbook._id);
      message.success("Activity Deleted successfully.");
      this.getFolderList();
    } catch (error) {
      message.error("Error while deleting your activity, Please try again.");
      console.log("NsgComponent -> error", error);
    }
  };

  render() {
    const { activities, total, currentPage, activity, folder } = this.state;

    return (
      <CustomScrollbars>
        <PageHeader
          className="gx-mb-4 gx-text-center"
          ghost={false}
          onBack={() => this.context.router.push("/voting-folders")}
          title={`${folder}`}
          subTitle={`You have ${total} ${
            total > 1 ? "activities" : "activity"
          } saved in this folder`}
          extra={[
            <Search
              placeholder="Search Voting Activity"
              allowClear
              onSearch={this.onSearch}
              size="large"
            />,
          ]}
          footer={
            <Row justify="center">
              <Col>
                <span
                  className="gx-pointer"
                  onClick={() => {
                    Modal.info({
                      title: "Voting Activity Session ID",
                      content: (
                        <Typography.Title copyable>
                          {this.state.sessionId}
                        </Typography.Title>
                      ),
                      onOk() {},
                    });
                  }}
                >
                  {" "}
                  <Statistic
                    groupSeparator=""
                    title="Session ID"
                    value={this.state.sessionId}
                  />{" "}
                </span>
              </Col>
              <Col>
                <GoogleShareToClassRoom
                  body="Contribute Cloud voting activity"
                  itemType="assignment"
                  url={`https://join2.contribute.cloud/?sessionId=${this.state.sessionId}`}
                  size={50}
                  title="Contribute Cloud"
                  theme="light"
                  onShare={(type) =>
                    console.log(`GoogleShareToClassRoom:${type}`)
                  }
                  onShareComplete={() =>
                    console.log("GoogleShareToClassRoom:onShareComplete")
                  }
                  onShareStart={() =>
                    console.log("GoogleShareToClassRoom:onShareStart")
                  }
                />
              </Col>
            </Row>
          }
        />

        <Row>
          {map(activities, (item, key) => {
            return (
              <Col key={key} xl={4} lg={8} md={8} sm={12} xs={24}>
                <div
                  style={{ textAlign: "center" }}
                  className="gx-product-item gx-product-vertical"
                >
                  <div className="gx-mb-3">
                    <div
                      style={{
                        textAlign: "right",
                        paddingRight: 10,
                        paddingTop: 10,
                      }}
                      className="gx-text-grey"
                    >
                      <Space>
                        <Tooltip title="Open in workbook editor">
                          <Link to={`/voting-editor?id=${item._id}`}>
                            <EditOutlined
                              style={{
                                fontSize: 30,
                                color: "#02a2de",
                                cursor: "pointer",
                              }}
                            />
                          </Link>
                        </Tooltip>

                        <Popconfirm
                          placement="bottomRight"
                          title={"Are you sure to delete this voting activity?"}
                          onConfirm={() => this.onDeleteConfirm(item)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title="Delete voting activity">
                            <DeleteOutlined
                              style={{
                                fontSize: 30,
                                color: "#02a2de",
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        </Popconfirm>
                      </Space>
                    </div>
                  </div>

                  <div className="gx-product-image">
                    {item.questions && item.questions.length ? (
                      <div
                        style={{ height: 200, width: 200 }}
                        dangerouslySetInnerHTML={{
                          __html: item.questions[0].question,
                        }}
                      ></div>
                    ) : (
                      <img
                        style={{ height: 200, width: 200 }}
                        src={"images/build-lecture.png"}
                      />
                    )}
                  </div>
                  <div className="gx-product-body">
                    <div className="gx-product-name">{item.name}</div>
                  </div>
                  <Button
                    onClick={this.startActivity.bind(this, item)}
                    type="primary"
                    className="gx-mt-sm-4 gx-fs-sm gx-btn-block gx-mb-0 gx-text-uppercase gx-border-radius-top-left-0 gx-border-radius-top-right-0"
                    size="large"
                    block
                  >
                    start activity session
                  </Button>
                </div>
              </Col>
            );
          })}

          <Pagination
            total={total}
            current={currentPage}
            onClick={this.refreshData}
          />
        </Row>

        {activity.name && (
          <VotingPage
            onStopActivity={this.startActivity}
            activity={activity}
            socket={this.socket}
            sessionId={this.state.sessionId}
            studentList={this.state.studentList}
          />
        )}
      </CustomScrollbars>
    );
  }
}

NsgComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, { getSessionId })(NsgComponent);
