import React, { Component } from 'react';

class TCPP extends Component {
    render() {
        return (
            <div className="jumbotron">
                Contribute Cloud Privacy and Cookies Policy for Web-Based Apps and Website

This is the privacy and cookies policy for the website http://contribute.cloud and the Contribute Cloud application.

For the purposes of this privacy policy, both the website and application shall be referred to as the "App". The App is operated by TouchIT Technologies Holdings Inc (we, us and our).

By using the App, you consent to the collection, use and disclosure of information in accordance with this privacy policy.

We reserve the right to change this privacy policy from time to time by changing it on the App.  By using the App, you agree to be bound by the version of the privacy policy displayed on the date upon which you use the App. This privacy policy was last updated on October 1st 2017.

Information we may collect from you
We may collect and process the following information about you:
• personal information (such as your name, email address, and telephone number) that you provide in the App, including if you register as a user of the App, subscribe to any service, upload or submit any material via the App or request any information;
• in connection with any account sign-in facility we may introduce, your log-in details;
• communications you send to us, for example to report a problem or to submit queries, concerns or comments regarding the App or its content;
We also, without you having to do anything, will collect the following information:
• details of your visits to the App, the searches you make, the resources you access and any data you download; this may also be tracked and analyzed by third parties providing us with analytics services which help us understand how users use the App so we can improve it;

How we use data from Google Inc
• with your choosing, you may access your Google Drive account through your Google Login to access any files that you have chosen to store in Google Drive. As Contribute Cloud is a collaborative platform, in order for you to share your documents and files with others, they are copied to our servers from your Google Drive. Only the files you select and use in yourt Contribute Cloud Workbooks are copied to Contribute Cloud Servers. In the event you delete said files or workbooks, the corresponding files are deleted from our servers.
• with your choosing, you may access your Google Apps for Education, Google Classroom account. With your choosing, will import the names and email addresses associated with any class lists that you have stored in your Google Classroom account. Should you with to remove these from your Contribute Cloud Account, you are able to do so with a single click.

Uses made of your information
We will use the information we collect to:
• build a profile of your preferences so we can personalize the App and provide you the relevant information;
• administer your account with us;
• respond to your emails to discuss your use of the App and answer any support queries you may have;
• identify issues with the operation of the App, and provide crash reports in order to find resolution for performance issues;
• improve the design, functionality and/or content of the pages of the App and customize them for users;
• share with social networking sites and third party partner at your request (when this functionality is enabled); and
• disclose to lawful authorities when required to do so by law or where appropriate in our opinion as a responsible company to respond to their request.

Security
We place great importance on the security of all personally identifiable information associated with our users. Whilst we cannot ensure or guarantee that loss, misuse or alteration of information will never occur, we use all reasonable efforts to prevent it. You should bear in mind that submission of information over the internet and mobile networks is never entirely secure. We cannot guarantee the security of information you submit via the App whilst it is in transit over the internet or mobile networks and any such submission is at your own risk.

Storage of your information
Information that you submit via the App is sent to and stored on secure servers located in the United States. Information submitted by you may be transferred by us to our other offices and/or to the third parties mentioned in the circumstances described above, which may be situated outside the United States and may be processed by staff operating outside the European Economic Area. By submitting information via the App, you agree to this storing, processing and/or transfer.

Your rights
If you are accessing the App from the European Economic Area, you have a legal right under the Data Protection Act 1998 to a copy of all the personal information about you held by us. On request, we will provide you with a copy of this information subject to a fee not exceeding $20. You also have a right to correct any errors in that information. As mentioned above, you have a right to prevent the use of your personal information for direct marketing purposes.
If you are accessing the App from the US and wish to keep your personal information accurate, current, and complete, please contact us as specified below. We will take reasonable steps to update or correct personal information in our possession that you have previously submitted via the App. If you no longer want to receive marketing messages from us, please let us know by writing to us at the email address specified below, and you will be unsubscribed accordingly. Please note that sometimes it can take a short amount of time to refresh our records for these purposes.

Additional information
When you visit the App, we may automatically collect additional information about you, such as the type of phone or operating system you use and, if you came to the website, the type of internet browser you use and the website from which you have come to the site. Also, depending on whether you access the App on your personal computer or via your mobile device, we will collect your IP address (the unique address which identifies your home computer on the internet) or the installation of the App on your smartphone which are automatically recognized by our servers. You cannot be identified solely from this information and it is only used to assist us in providing an effective service on the App and to collect broad demographic information for aggregate use.

The App uses cookies to store certain information. Cookies are pieces of information that a website or app transfers to your hard drive or mobile device to store and sometimes track information about you. Although they do identify a user's device, cookies do not personally identify users.

Information sharing
We may disclose your personal information to any of our affiliates, or to our agents or contractors who assist us in providing the services we offer through the App, fulfilling requests for information, receiving and sending communications, updating marketing lists, analyzing data, providing support services or in other tasks, from time to time.

In the event that we undergo re-organization or are sold to a third party, you agree that any personal information we hold about you may be transferred to the entity or entities running the TouchIT Technologies Holdings Inc business (or relevant part of it) or third party buyer.
We may disclose your personal information if required to do so by law or if we believe that such action is necessary to prevent fraud or cybercrime or to protect the App or enforce the terms under which you transact with us or enforce the rights, property or personal safety of any person.

External links
The App may, from time to time, contain links to external sites. We are not responsible for the privacy policies or the content of such sites.

Under 13 year olds
We do not knowingly collect personal information from children under the age of 13. If you are under the age of 13, please do not submit any personal information through the App. We encourage parents and legal guardians to monitor their children’s internet use and to help enforce our privacy policy by instructing their children never to provide personal information on our website without their permission. If you have reason to believe that a child under the age of 13 has provided personal information to us, please contact us as specified below, and we will work to delete that information from our databases.

Contribute Cloud Cookies Policy
When you interact with the App, we try to make that experience simple and meaningful. When you visit our App our server sends a cookie to your computer or mobile device (depending on how you access the site or App). Cookies are small pieces of information which are issued to your computer when you visit a website and which store and sometimes track information about your use of the site. A number of cookies we use last only for the duration of your web session and expire when you close your App. These are known as "session cookies". Other cookies are used to remember you when you return to the App and will last for longer. These are known as "persistent cookies".
We use cookies to:
·       remember that you have visited us before; this means we can identify the number of unique visitors we receive. This allows us to make sure we have enough capacity for the number of users that we get; customise elements of the promotional layout and/or content of the pages of the site; and
·       collect statistical information about how you use the site so that we can improve the site and learn which parts of the website are most popular to visitors.
Some of the cookies used by our App are set by us, and some are set by third parties who are delivering services on our behalf. For example, we use Google Analytics to track what users do on the App so we can improve the design and functionality.
Most browsers automatically accept cookies but, if you prefer, you can change your browser to prevent that or to notify you each time a cookie is set. You can also learn more about cookies by visiting www.allaboutcookies.org which includes additional useful information on cookies and how to block cookies using different types of browser. Please note however, that by blocking or deleting cookies used on the site you may not be able to take full advantage of the App if you do so.

Contacting us
Please submit any questions, concerns or comments you have about this privacy and cookies policy or any requests concerning your personal data by email to customerservice@touchittechnologies.com.

      </div>
        );
    }
}

export default TCPP;
