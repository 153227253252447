import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import { SketchField, Tools } from "../drawing";
import dataJson from "./data.json";
import _ from "lodash";
import { isfileExists } from "../../actions/workbook";
import { uploadBlobFile } from "../../actions/galleryActions";
import s from "./LecturePage.module.css";
import ToolHolder from "./tools/holder/ToolHolder";
import fonts from "../../data/font";
import TextFieldGroup from "../common/TextFieldGroup";
import map from "lodash/map";
// import { SketchPicker } from 'react-color'

class LectureCanvas extends Component {
  static propTypes = {
    onRef: PropTypes.func.isRequired,
    isfileExists: PropTypes.func.isRequired,
    slides: PropTypes.array.isRequired,
    sessionId: PropTypes.string.isRequired,
  };

  static contextTypes = { router: PropTypes.object.isRequired };

  constructor(props) {
    super(props);

    this.state = {
      fillColor: "#68CCCA",
      backgroundColor: "transparent",
      shadowWidth: 0,
      shadowOffset: 0,
      tool: Tools.Select,
      fillWithColor: false,
      fillWithBackgroundColor: false,
      drawings: [],
      canUndo: false,
      canRedo: false,
      controlledSize: false,
      sketchWidth: 600,
      sketchHeight: 600,
      stretched: true,
      stretchedX: false,
      stretchedY: false,
      originX: "left",
      originY: "top",
      width: 800,
      height: 700,
      zoom: 1,
      videoDetails: {
        totalTime: 0,
        currentTime: 0,
      },
      canvasWidth: 0,
      canvasHeight: 0,
    };
  }

  componentDidMount = () => {
    this.setState({
      canvasWidth: Math.abs(
        Math.max(document.documentElement.clientWidth, window.innerWidth || 0) -
          0
      ),
      canvasHeight:
        Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        ) - 72,
    });

    this.props.onRef(this);
    this._video.addEventListener("loadedmetadata", this.loadedmetadata);
    this._video.addEventListener("timeupdate", this.timeupdate);
    // document.addEventListener('fullscreenchange', this.fullscreenchange)
    // document.addEventListener('webkitfullscreenchange', this.webkitfullscreenchange)
    // document.addEventListener('mozfullscreenchange', this.mozfullscreenchange)
    // document.addEventListener('msfullscreenchange', this.msfullscreenchange)

    window.addEventListener("resize", this.resize);
  };

  componentWillUnmount = () => {
    this.props.onRef(undefined);
    this._video.removeEventListener("loadedmetadata", this.onLoadedmetadata);
    this._video.removeEventListener("timeupdate", this.onTimeupdate);

    // document.removeEventListener('fullscreenchange', this.fullscreenchange)
    // document.removeEventListener('webkitfullscreenchange', this.webkitfullscreenchange)
    // document.removeEventListener('mozfullscreenchange', this.mozfullscreenchange)
    // document.removeEventListener('msfullscreenchange', this.msfullscreenchange)

    window.removeEventListener("resize", this.resize);
  };
  resize = () => {
    let { slides, currentSlide } = this.props;
    this.setState({
      canvasWidth: Math.abs(
        Math.max(document.documentElement.clientWidth, window.innerWidth || 0) -
          0
      ),
      canvasHeight:
        Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        ) - 72,
    });

    // this.slideHandler(slides[currentSlide])
  };
  // fullscreenchange = () => {
  //     this.setFullscreenData(!!(document.fullScreen || document.fullscreenElement));
  // }

  // webkitfullscreenchange = () => {
  //     this.setFullscreenData(!!document.webkitIsFullScreen);

  // }
  // mozfullscreenchange = () => {
  //     this.setFullscreenData(!!document.mozFullScreen);
  // }
  // msfullscreenchange = () => {
  //     this.setFullscreenData(!!document.msFullscreenElement);
  // }
  loadedmetadata = () => {
    const { _sketch, state } = this;
    const { stretched, stretchedX, stretchedY, originX, originY } = state;

    const width = this._video.videoWidth;
    const height = this._video.videoHeight;
    this.setState({
      videoDetails: {
        ...this.state.videoDetails,
        totalTime: this._video.duration,
      },
    });
    _sketch.videoHandler(this._video, {
      stretched,
      stretchedX,
      stretchedY,
      originX,
      originY,
      width,
      height,
    });
  };

  timeupdate = () => {
    this.setState({
      videoDetails: {
        ...this.state.videoDetails,
        currentTime: this._video.currentTime,
      },
    });
  };

  _socketEvent = (name, data) => {
    // this.socket.emit(name, {
    //     source: data,
    //     sessionId: this.props.sessionId
    // });
  };

  _onSketchChange = () => {
    const prev = this.state.canUndo;
    if (this._sketch) {
      const now = this._sketch.canUndo();
      if (prev !== now) {
        this.setState({ canUndo: now });
      }

      if (this.state.tool === Tools.IText) {
        this.setState({ tool: Tools.Select });
      }
    }
  };

  _canvasToImage = (email) => {
    debugger;
    if (this._sketch) {
      var dataURL = this._sketch.toJSON(["width", "height"]);

      // if (this.state.fileType == "mp4") {
      //     var video = document.getElementById("videoCanvas");
      //     dataURL.currentTime = video.currentTime;
      // }

      dataURL = JSON.stringify(dataURL);

      // this.socket.emit("object:slide", {
      //     source: { dataURL, email },
      //     sessionId: this.props.sessionId
      // });
    }
  };

  _selectTool = (tool) => {
    if (this.state.tool === tool) {
      this.setState({ tool });
    } else {
      this.setState({ tool });
    }
  };

  _undo = () => {
    if (this._sketch.canUndo()) {
      this._sketch.undo();
      this.setState({
        canUndo: this._sketch.canUndo(),
        canRedo: this._sketch.canRedo(),
      });
    }
  };

  _redo = () => {
    this._sketch.redo();
    this.setState({
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
    });
  };

  _clear = () => {
    if (this._sketch) {
      this._sketch.clear();
      this._sketch.setBackgroundFromDataUrl("");
      this.setState({
        backgroundColor: "transparent",
        fillWithBackgroundColor: false,
        canUndo: this._sketch.canUndo(),
        canRedo: this._sketch.canRedo(),
      });
    }
  };

  _toggleFullScreen = () => {
    var documentElement = document.documentElement;

    let isFullScreen = !!(
      document.fullScreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement
    );
    // If fullscreen mode is active...
    if (isFullScreen) {
      // ...exit fullscreen mode
      // (Note: this can only be called on document)
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitCancelFullScreen)
        document.webkitCancelFullScreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
      documentElement.setAttribute("data-fullscreen", !!false);
    } else {
      // ...otherwise enter fullscreen mode
      // (Note: can be called on document, but here the specific element is used as it will also ensure that the element's children, e.g. the custom controls, go fullscreen also)
      if (documentElement.requestFullscreen)
        documentElement.requestFullscreen();
      else if (documentElement.mozRequestFullScreen)
        documentElement.mozRequestFullScreen();
      else if (documentElement.webkitRequestFullScreen) {
        // Safari 5.1 only allows proper fullscreen on the video element. This also works fine on other WebKit browsers as the following CSS (set in styles.css) hides the default controls that appear again, and
        // ensures that our custom controls are visible:
        // figure[data-fullscreen=true] video::-webkit-media-controls { display:none !important; }
        // figure[data-fullscreen=true] .controls { z-index:2147483647; }
        documentElement.webkitRequestFullScreen();
      } else if (documentElement.msRequestFullscreen)
        documentElement.msRequestFullscreen();
      documentElement.setAttribute("data-fullscreen", !!true);
    }
  };
  _loadFromJSONStream = (streamImage, email) => {
    return new Promise((reject, resolve) => {
      const sketch = this._sketch;
      const currentObject = sketch.toJSON();

      const obj = currentObject.objects.concat(streamImage.objects);
      currentObject.objects = _.uniqWith(obj, _.isEqual);
      if (!sketch.backgroundImage && streamImage.backgroundImage) {
        const {
          stretched,
          stretchedX,
          stretchedY,
          originX,
          originY,
        } = this.state;
        sketch.setBackgroundFromDataUrl(streamImage.backgroundImage.src, {
          stretched,
          stretchedX,
          stretchedY,
          originX,
          originY,
        });
      }

      sketch.fromJSON(streamImage);
      resolve();
    });
  };

  slideType001 = (path) => {
    const { _sketch, state } = this;

    const { stretched, stretchedX, stretchedY, originX, originY } = state;
    _sketch.setBackgroundFromDataUrl(path, {
      stretched,
      stretchedX,
      stretchedY,
      originX,
      originY,
    });
  };
  slideType002 = (path) => {
    this._video.src = path;
  };

  slideType003 = (path) => {
    const { getDocDetails } = this.props;
    // const filePath = path.split(".").slice(0, -1).join(".");
    getDocDetails(path);
  };

  slideHandler = (slide) => {
    const { filetype, path, name } = slide;
    const ext = /^.+\.([^.]+)$/.exec(name);
    const extention = ext == null ? "" : ext[1].toLocaleLowerCase();
    this._clear();
    this.videoControll("stop");

    let slideType001 = ["image", "jpg", "png", "jpeg"];
    let slideType002 = ["video", "mp4"];
    let slideType003 = [
      "excel",
      "word",
      "ppt",
      "notebook",
      "pdf",
      "docx",
      "doc",
      "xlsx",
      "xls",
    ];

    if (
      slideType001.indexOf(filetype) > -1 ||
      slideType001.indexOf(extention) > -1
    ) {
      this["slideType001"](path);
    } else if (
      slideType002.indexOf(filetype) > -1 ||
      slideType002.indexOf(extention) > -1
    ) {
      this["slideType002"](path);
    } else if (
      slideType003.indexOf(filetype) > -1 ||
      slideType003.indexOf(extention) > -1
    ) {
      this["slideType003"](path);
    } else {
      console.error("Invalide File Type", filetype);
    }
  };

  isFullScreen = () => {
    return !!(
      document.fullScreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement
    );
  };
  // setFullscreenData = (state) => {
  //     this._videoContainer.setAttribute('data-fullscreen', !!state);

  //     !!state ? this._video.style.display = 'block' : this._video.style.display = 'none'
  //     !!state ? this._video.style.width = '95%' : this._video.style.width = '10px;';
  //     !!state ? this._video.style.margin = '0 auto' : this._video.style.margin = null
  //     // !!state ? this._video.controls = true : this._video.controls = false;
  // }

  videoControll = (type) => {
    let currentVolume = Math.floor(this._video.volume * 10) / 10;

    switch (type) {
      case "pause":
        this._video.paused || this._video.ended ? "" : this._video.pause();
        break;
      case "play":
        this._video.paused || this._video.ended ? this._video.play() : "";
        break;
      case "stop":
        this._video.pause();
        this._video.currentTime = 0;
        break;
      case "mute":
        this._video.muted = !this._video.muted;
        break;
      case "volDown":
        if (currentVolume > 0) this._video.volume -= 0.1;
        break;
      case "volUp":
        if (currentVolume < 1) this._video.volume += 0.1;
        break;
      default:
        console.error("Invalide video contoll", type);
        break;
    }
  };

  handleProgressChange = (event) => {
    const { value } = event.target;
    this.setState({
      videoDetails: { ...this.state.videoDetails, currentTime: value },
    });
    this._video.currentTime = value;

    this.props._canvasToImage();
  };

  _onBackgroundImageDrop = (accepted /* , rejected */) => {
    const { uploadBlobFile } = this.props;

    if (accepted && accepted.length > 0) {
      accepted.forEach((file) => {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          const base64canvas = reader.result;
          let binary = atob(base64canvas.split(",")[1]);
          let array = [];
          let i = 0;
          while (i < binary.length) {
            array.push(binary.charCodeAt(i));
            i++;
          }

          let blob = new Blob([new Uint8Array(array)], { type: file.type });

          uploadBlobFile({ file: blob, name: file.name })
            .then((res) => {
              this._sketch.addImage(res.data.filePath);
            })
            .catch((err) => {
              debugger;
            });
        });
        reader.readAsDataURL(file);
      });
    }
  };

  addLiveSearchImage = (url) => {
    const sketch = this._sketch;
    sketch.addImage(url);
  };

  onChange = (ev) => {
    let { name, value } = ev.target;
    if (name == "lineWidth") {
      value = Number(value);
    }

    this.setState({ [name]: value });
  };

  zoomHandler = (ev) => {
    let previousValue = this.state.zoom;

    const { name, value } = ev.target;
    this.setState({ [name]: value }, () => {
      if (previousValue * 1 > value * 1) {
        this._sketch.zoom("zoomOut", value);
      } else {
        this._sketch.zoom("zoomIn", value);
      }
    });
  };
  resetZoom = (ev) => {
    this.setState({ zoom: 1 }, () => {
      this._sketch.zoom("zoomReset");
    });
  };

  bringToFront = () => {
    let sketch = this._sketch;
    sketch.bringToFront();
  };

  sendToBack = () => {
    let sketch = this._sketch;
    sketch.sendToBack();
  };

  render() {
    const {
      // fontName,
      fillWithColor,
      // fillColor,
      fillWithBackgroundColor,
      backgroundColor,
      tool,
      canvasWidth,
      canvasHeight,
      zoom,
    } = this.state;

    const {
      slides,
      currentSlide,
      showSetting,
      handleSetting,
      updateTool,
      studentLiveImageSearch,
      studentSettings,
      onChangeStudentLiveSearch,
      onChangToolbarSettings,
      onChangeStudentSettings,
      toolbarSize,
      fillColor,
      fontSize,
      fontName,
    } = this.props;

    const fontOptions = map(fonts, (val, key) => (
      <option key={val} value={val}>
        {key}
      </option>
    ));

    return (
      <>
        <SketchField
          name="sketch"
          ref={(c) => (this._sketch = c)}
          lineColor={this.props.lineColor}
          lineWidth={this.props.lineWidth}
          fontSize={fontSize}
          fontName={fontName}
          fillColor={fillColor}
          backgroundColor={
            fillWithBackgroundColor ? backgroundColor : "transparent"
          }
          width={canvasWidth}
          height={canvasHeight}
          defaultData={dataJson}
          defaultDataType="json"
          onChange={this._onSketchChange}
          canvasToImage={this.props._canvasToImage}
          tool={tool}
          handleSetting={handleSetting}
          socketEvent={this._socketEvent}
          updateSelectedTool={updateTool}
        />
        <figure
          ref={(vc) => (this._videoContainer = vc)}
          data-fullscreen="false"
          className={cx(s["at-videocontrols"], {
            [s["hide"]]: slides[currentSlide]
              ? slides[currentSlide].filetype !== "video"
              : true,
          })}
        >
          <video
            controls={false}
            autoPlay
            loop
            preload="metadata"
            ref={(v) => (this._video = v)}
            id="nsgCanvasVideo"
            style={{ display: "none" }}
          />

          <ul className={s["at-inputrange"]}>
            <li>
              <input
                type="range"
                min="0"
                max={this.state.videoDetails.totalTime}
                value={this.state.videoDetails.currentTime}
                onChange={this.handleProgressChange}
                step="1"
              />
            </li>
          </ul>
          <ul className={s["at-btnplaystop"]}>
            <li>
              <button
                onClick={this.videoControll.bind(this, "pause")}
                type="button"
              >
                <i className="fa fa-pause" />
              </button>
            </li>
            <li>
              <button
                onClick={this.videoControll.bind(this, "play")}
                type="button"
              >
                <i className="fa fa-play" />
              </button>
            </li>
            <li>
              <button
                onClick={this.videoControll.bind(this, "stop")}
                type="button"
              >
                <i className="fa fa-stop" />
              </button>
            </li>
          </ul>
          <ul
            onClick={this._toggleFullScreen}
            className={s["at-fullscreenbtn"]}
          >
            <li>
              <button type="button">
                <i className="fa fa-arrows-alt" />
              </button>
            </li>
          </ul>
          <ul className={s["at-btnvolumupdown"]}>
            <li>
              <button
                onClick={this.videoControll.bind(this, "mute")}
                type="button"
              >
                <i className="fa fa-volume-off" />
              </button>
            </li>
            <li>
              <button
                onClick={this.videoControll.bind(this, "volDown")}
                type="button"
              >
                <i className="fa fa-volume-down" />
              </button>
            </li>
            <li>
              <button
                onClick={this.videoControll.bind(this, "volUp")}
                type="button"
              >
                <i className="fa fa-volume-up" />
              </button>
            </li>
          </ul>
        </figure>
      </>
    );
  }
}

export default connect(null, { isfileExists, uploadBlobFile })(LectureCanvas);
