import React, { Component } from 'react';
import ToolHolder from '../holder/ToolHolder'
import s from './Abacus.module.css';
import cx from 'classnames'
import map from 'lodash/map'
import PropTypes from 'prop-types'


const BALLS_INITIAL_POSITION = {
    ".ball5": "96px",
    ".ball4": "192px",
    ".ball3": "212px",
    ".ball2": "232px",
    ".ball1": "252px"
};

const BALLS_SECOND_POSITION = {
    ".ball4": "263px",
    ".ball3": "283px",
    ".ball2": "303px",
    ".ball1": "323px"
};

var idStr = "";
var numId = "";
var imgId = "";

var baseBottomValue = "";
var newBottomValue = "";
var number = 0;

var expressionArray = [];
var expression = "";


class AbacusTool extends Component {
    static propTypes = {
        closeTool: PropTypes.func.isRequired
    };
    constructor(props) {
        super(props)
        this.state = {
            number: ''
        }
    }

    componentDidMount = () => { }

    move = (numId, imgId, identifier) => {
        var idStr = identifier;

        var tmpImgId = "";

        if (numId == 5) {
            if (this[imgId].style.bottom === "96px") {
                this[imgId].style.bottom = "56px"

                number += parseInt(numId) * Math.pow(10, idStr[3]);
            } else {
                this[imgId].style.bottom = "96px"
                number -= parseInt(numId) * Math.pow(10, idStr[3]);
            }
        }



        if (this[imgId].style.bottom !== newBottomValue) {
            for (var i = numId; i < 5; i++) {

                tmpImgId = "_" + idStr.slice(0, idStr.length - 1) + i;
                baseBottomValue = BALLS_INITIAL_POSITION[".ball" + i];
                newBottomValue = BALLS_SECOND_POSITION[".ball" + i];
                if (this[tmpImgId].style.bottom !== newBottomValue) {
                    this[tmpImgId].style.bottom = newBottomValue

                    number += Math.pow(10, idStr[3]);
                }
            }
        } else {
            for (var i = numId; i > 0; i--) {
                tmpImgId = "_" + idStr.slice(0, idStr.length - 1) + i;

                baseBottomValue = BALLS_INITIAL_POSITION[".ball" + i];

                if (this[tmpImgId].style.bottom !== baseBottomValue) {
                    this[tmpImgId].style.bottom = baseBottomValue

                    number -= Math.pow(10, idStr[3]);
                }
            }
        }

    }

    printNumber = () => {
        const { socket, sessionId } = this.props

        this.setState({ number: expression + number }, () => {
            socket.emit("teacher:abacus", { sessionId: sessionId, abacusState: this.state });
        })
    }

    clean = () => {
        for (var i = 0; i < 11; i++) {
            for (var j = 0; j < 6; j++) {
                let imgId = "_col" + i + "-" + j;
                if (this[imgId]) {
                    this[imgId].style.bottom = BALLS_INITIAL_POSITION[".ball" + j]
                }
            }
        }
        number = 0;
    }

    expressionJoin = () => {
        expression = expressionArray.join("");
    }

    resultPosition = (result) => {
        if (parseInt(result) > 0 && result.length < 10) {
            result = result.split("").reverse().join("");

            for (var i = 0; i < result.length; i++) {
                var counter = 0;
                if (parseInt(result[i]) > 4) {
                    counter += 5;
                    imgId = "_col" + i + "-5";
                    if (this[imgId]) {
                        this[imgId].style.bottom = "56px"
                    }
                }
                for (var j = 4; j > 0; j--) {
                    if (counter < parseInt(result[i])) {
                        imgId = "_col" + i + "-" + j;
                        if (this[imgId]) {
                            this[imgId].style.bottom = BALLS_SECOND_POSITION[".ball" + j]
                        }
                        counter += 1;
                    }
                }
            }
        };
    }

    handleOperations = (idStr) => {
        const { socket, sessionId } = this.props
        socket.emit("teacher:abacus", { sessionId: sessionId, functionName: 'handleOperations', params: [idStr] });

        switch (idStr) {
            case "clean":
                this.clean();
                expression = "";
                expressionArray = [];
                this.setState({ number: '' })
                break;
            case "add":
                expressionArray.push(number + "+");
                this.expressionJoin();
                this.clean();
                this.setState({ number: expression })
                break;
            case "extract":
                expressionArray.push(number + "-");
                this.expressionJoin();
                this.clean();
                this.setState({ number: expression })
                break;
            case "multiply":
                expressionArray.push(number + "*");
                this.expressionJoin();
                this.clean();
                this.setState({ number: expression })
                break;
            case "divide":
                expressionArray.push(number + "/");
                this.expressionJoin();
                this.clean();
                this.setState({ number: expression })
                break;
            case "result":
                var result = 0;
                if (number == 0) {
                    result = eval(expression.slice(0, -1))
                    this.setState({ number: result })
                } else {
                    result = eval(eval(expression + number))
                    this.setState({ number: result })
                }
                this.clean();
                expression = "";
                expressionArray = [];
                this.resultPosition(result.toString());
                break;
        }
    }

    handleUperRow = (identifier) => {
        const { socket, sessionId } = this.props
        let numId = identifier.slice(-1);
        let imgId = "_" + identifier;

        baseBottomValue = BALLS_INITIAL_POSITION[".ball" + numId];
        newBottomValue = BALLS_SECOND_POSITION[".ball" + numId];
        this.move(numId, imgId, identifier);

        socket.emit("teacher:abacus", { sessionId: sessionId, functionName: 'move', params: [numId, imgId, identifier], });

        this.printNumber();
    }

    render() {
        let { closeTool } = this.props
        let upperRow = {
            'col9-5': 'col9-5',
            'col8-5': 'col8-5',
            'col7-5': 'col7-5',
            'col6-5': 'col6-5',
            'col5-5': 'col5-5',
            'col4-5': 'col4-5',
            'col3-5': 'col3-5',
            'col2-5': 'col2-5',
            'col1-5': 'col1-5',
            'col0-5': 'col0-5',
        }
        let urow = map(upperRow, (val, key) => <div key={key} className={s["col-10"]}><div className={s["vertical-line"]} />
            <img ref={node => this['_' + val] = node} onClick={this.handleUperRow.bind(this, val)} style={{ bottom: '96px' }} src="images/widgets/burger.png" alt="icon abacus" />
        </div>)

        let lrow = [];
        for (let i = 9; i >= 0; i--) {
            lrow.push(<div key={i} className={cx(s["col-10"], s["lower"])}><div className={s["vertical-line"]} />
                <img ref={node => this['_' + `col${i}-4`] = node} onClick={this.handleUperRow.bind(this, `col${i}-4`)} style={{ bottom: '192px' }} src="images/widgets/burger.png" alt="icon abacus" />
                <img ref={node => this['_' + `col${i}-3`] = node} onClick={this.handleUperRow.bind(this, `col${i}-3`)} style={{ bottom: '212px' }} src="images/widgets/burger.png" alt="icon abacus" />
                <img ref={node => this['_' + `col${i}-2`] = node} onClick={this.handleUperRow.bind(this, `col${i}-2`)} style={{ bottom: '232px' }} src="images/widgets/burger.png" alt="icon abacus" />
                <img ref={node => this['_' + `col${i}-1`] = node} onClick={this.handleUperRow.bind(this, `col${i}-1`)} style={{ bottom: '252px' }} src="images/widgets/burger.png" alt="icon abacus" />
            </div>)
        }

        return (<ToolHolder name={'Abacus'} classname={'at-abacushholder'} closeTool={closeTool} tool={'Abacus'}>
            <div>
                <div className={s["container-flex"]}>
                    <div id="abacus" className={cx(s["container-flex"], s['abacus'])}>
                        <div id="framework" className={s['framework']}>
                            <div id="upper-row" className={s['upper-row']}>
                                {urow}

                            </div>
                            <div id="lower-row" className={s['lower-row']}>
                                {lrow}
                            </div>
                        </div>
                        <div id="operators" className={s['operators']}>
                            <a onClick={this.handleOperations.bind(this, 'clean')} id="clean" className={s["operator-button"]}>C</a>
                            <a onClick={this.handleOperations.bind(this, 'add')} id="add" src="https://raw.githubusercontent.com/Juankboards/abacus-calculator/master/img/plus.jpg" alt="plus wood texture icon" className={cx(s["operator-button"], s["sing"])}>+</a>
                            <a onClick={this.handleOperations.bind(this, 'extract')} id="extract" className={cx(s["operator-button"], s["sing"])}>-</a>
                            <a onClick={this.handleOperations.bind(this, 'multiply')} id="multiply" className={cx(s["operator-button"], s["sing"])}>x</a>
                            <a onClick={this.handleOperations.bind(this, 'divide')} id="divide" className={cx(s["operator-button"], s["sing"], s["divide"])}>/</a>
                            <a onClick={this.handleOperations.bind(this, 'result')} id="result" className={cx(s["operator-button"], s["sing"])}>=</a>
                        </div>
                        <div id="display" className={s['display']}>
                            <p id="number">{this.state.number}</p>
                        </div>
                    </div>
                </div>
            </div>
        </ToolHolder>);
    }
}
export default AbacusTool;