import { SET_USER_WORKBOOK } from '../actions/types';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  workbook: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER_WORKBOOK:
      return {
        workbook: action.workbook,
      };
    default: return state;
  }
};
