import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import s from './AlertBox.module.css';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages'

class AlertBox extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        backdrop: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
        size: PropTypes.string,
        okButtonText: PropTypes.string,
        cancelButtonText: PropTypes.string,
        alertMessage: PropTypes.string,

        onToggle: PropTypes.func.isRequired,
        onOK: PropTypes.func,
        onCancel: PropTypes.func,

    };

    static defaultProps = {
        title: '',
        size: 'sm',
        backdrop: 'static',
        okButtonText: <FormattedMessage {...messages.yes} />,
        cancelButtonText: <FormattedMessage {...messages.no} />,
        alertMessage: <FormattedMessage {...messages.areYouSure} />,
        isOpen: false,
        onToggle: () => { },
    };

    render() {
        const {
            title,
            params,
            size,
            backdrop,
            isOpen,
            onToggle,
            onOK,
            onCancel,
            okButtonText,
            cancelButtonText,
            alertMessage,
        } = this.props;
        return (
            <Modal className={s['popup-content']} isOpen={isOpen} toggle={onToggle} backdrop={backdrop} size={size}>
                <ModalHeader className={s['popup-header']} toggle={onToggle}>
                    {title}
                    <div className={s['popup-img']}>
                        <img src={'images/alert-box.png'} />
                    </div>
                </ModalHeader>
                <ModalBody>
                    <h1>{alertMessage}</h1>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" color="success" className={cx("btn at-themebtn pull-right")} onClick={onOK}>{okButtonText}</button>
                    <button type="button" color="danger" className={cx("btn at-themebtn pull-right")} onClick={onCancel || onToggle}>{cancelButtonText}</button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default AlertBox;