import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Tooltip, Typography } from "antd";

import { CopyOutlined, CheckOutlined, LogoutOutlined } from "@ant-design/icons";
class Webinar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roomId: Math.floor(Math.random() * (100000 - 10000)) + 100000,
    };
  }
  render() {
    const { auth } = this.props;
    const { username } = auth.user;

    return (
      <>
        <iframe
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allow="camera; microphone"
          src={`${process.env.REACT_APP_MEET_HOST}/${this.state.roomId}?roomId=${this.state.roomId}&username=${username}&type=webinar`}
        />

        <Typography.Paragraph
          style={{
            position: "absolute",
            bottom: 0,
            right: 30,
            background: "#02a2de",
            padding: "15px 20px 15px 12px",
            borderRadius: 30,
          }}
          copyable={{
            text: `${process.env.REACT_APP_JOIN_HOST}?sessionId=${this.state.roomId}&type=webinar`,
            icon: [
              <CopyOutlined style={{ fontSize: 20, color: "#FFF" }} />,
              <CheckOutlined style={{ fontSize: 20, color: "#FFF" }} />,
            ],
            tooltips: ["Click to copy join link", "Join link copied"],
          }}
        />

        <Typography.Paragraph
          style={{
            position: "absolute",
            bottom: 0,
            right: 100,
            background: "#02a2de",
            padding: "15px 20px 15px 12px",
            borderRadius: 30,
          }}
          copyable={{
            onCopy: () => {
              window.location.href = "/folders";
            },
            icon: [
              <LogoutOutlined style={{ fontSize: 20, color: "#FFF" }} />,
              <LogoutOutlined style={{ fontSize: 20, color: "#FFF" }} />,
            ],
            tooltips: ["Click to leave session"],
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, {})(Webinar);
