import React, { Component } from 'react';
import math from 'mathjs'
import he from 'he'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types';
import cx from 'classnames';
import ToolHolder from '../holder'
import s from './Calculator.module.css';

import Key from './Key'


class Calculator extends Component {
    static propTypes = {
        closeTool: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props)

        this.state = {
            result: 0,
            log: '',
            clickedEquals: false,
            ans: 0,
            isScientific: false
        }
    }

    componentDidMount() {
        const { clickedEquals, log } = this.state
        const { socket, sessionId } = this.props
        window.onerror = () => this.setState({ log: 'Syntax Error', clickedEquals: true })

        window.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') this.handleEqualsClick(log)

            if (e.key.match(/[+\-^]/)) {
                if (clickedEquals) this.setState({ log: 'Ans', clickedEquals: false })
            }

            const mathEntities = { '*': he.decode('&#x000D7;'), '/': he.decode('&divide;') }

            if (e.key.match(/[*/]/)) {
                e.preventDefault();
                if (clickedEquals) this.setState({ log: `Ans${mathEntities[e.key]}`, clickedEquals: false });
                else this.setState({ log: log + mathEntities[e.key] });
            }

            if (!e.key.match(/[+\-^*/]|Enter/)) {
                if (clickedEquals) this.setState({ log: '', clickedEquals: false });
            }
        });


        socket.on("student:calculator", (data) => {
            this.setState(data.calculator);
        });
    }

    keyClick = (keyLog, math) => {
        const currentLog = this.state.log;
        const clickedEquals = this.state.clickedEquals;

        const { socket, sessionId } = this.props

        if (math === 'clear') {
            this.setState({ log: '', result: 0 }, () => {
                socket.emit("teacher:calculator", { sessionId: sessionId, calculator: this.state });
            });
        }

        if (math === 'delete') {
            if (currentLog.charAt(currentLog.length - 2).match(/[ns]/)) {
                this.setState({ log: currentLog.slice(0, currentLog.length - 4) }, () => {
                    socket.emit("teacher:calculator", { sessionId: sessionId, calculator: this.state });
                });
            } else {
                this.setState({ log: currentLog.slice(0, currentLog.length - 1) }, () => {
                    socket.emit("teacher:calculator", { sessionId: sessionId, calculator: this.state });
                });
            }
        }

        if (math === 'equals') {
            this.handleEqualsClick(currentLog);
        }

        if (math.match(/trig|log|number|comma|prnths|ans|sqrt|exponent/)) {
            if (clickedEquals) this.setState({ log: keyLog, clickedEquals: false });
            else this.setState({ log: currentLog + keyLog }, () => {
                socket.emit("teacher:calculator", { sessionId: sessionId, calculator: this.state });
            });
        }

        if (math.match(/sum|sub|multiply|divide|power|sqr|inv/)) {
            if (clickedEquals) this.setState({ log: `Ans${keyLog}`, clickedEquals: false });
            else this.setState({ log: currentLog + keyLog }, () => {
                socket.emit("teacher:calculator", { sessionId: sessionId, calculator: this.state });
            });
        }


    }

    handleEqualsClick = (currentLog) => {
        const { socket, sessionId } = this.props

        const times = he.decode('&#x000D7;');
        const divide = he.decode('&divide;');
        const sqrt = he.decode('&radic;');
        const sqrtReg = new RegExp(sqrt, 'g');

        // change log so it's understanable to mathjs eval() method
        const newLog = currentLog.replace(times, '*')
            .replace(divide, '/')
            .replace(/Ans/g, `(${this.state.ans.toString()})`)
            .replace(/E/g, '10^')
            .replace(/log/g, 'log10')
            .replace(/ln/g, 'log')
            .replace(sqrtReg, 'sqrt');

        let result = math.eval(newLog);
        let finalResult;

        if (currentLog === '') {
            result = 0;
        }

        // trim result if too long
        if (result.toString().length > 11) {
            finalResult = result.toString().slice(0, 11);
        } else finalResult = result;

        this.setState({ ans: finalResult, result: finalResult, clickedEquals: true }, () => {
            socket.emit("teacher:calculator", { sessionId: sessionId, calculator: this.state });
        });
    }

    onChange = (e) => {
        const { socket, sessionId } = this.props

        this.setState({ 'log': e.target.value }, () => {
            socket.emit("teacher:calculator", { sessionId: sessionId, calculator: this.state });
        });
    }

    toggleType = () => {
        const { socket, sessionId } = this.props

        this.setState({ isScientific: !this.state.isScientific }, () => {
            socket.emit("teacher:calculator", { sessionId: sessionId, calculator: this.state });
        });
    }

    render() {
        const { log, result, isScientific } = this.state;
        const { closeTool } = this.props
        const { keyClick } = this;

        const x = <span>&#x1D4B3;</span>;
        const inv = <sup>-1</sup>;
        const pow2 = <sup>2</sup>;
        const pow3 = <sup>3</sup>;


        return (
            <ToolHolder name={'Calculator'} closeTool={closeTool} tool={'Calculator'} classname={'at-calculatorholder'}>
                <div className={cx(s["at-opancalculator"])}>
                    <a onClick={this.toggleType} className={s["at-btnopensceintific"]}>View {isScientific ? 'Simple' : 'Scientific'} Calculator</a>
                    <div className={s["at-resultscreen"]}>

                        <input
                            onChange={this.onChange}
                            value={log}
                            type="text"
                            name='log$$$d'
                            id='log'
                            autoComplete="off"
                            className={cx(s['form-control'], s["at-calculation"])}
                        />

                        <div className={s["at-resultarea"]}>{result}</div>
                    </div>

                    <div className={s["at-keysholder"]}>
                        <div className={cx(s["at-scientifickeys"], { [s["at-opensceintificcalculator"]]: isScientific })}>
                            <Key key={`.${1}`} keyLog="sin(" Tag="sin" math="trig" keyClick={keyClick} />
                            <Key key={`.${2}`} keyLog="cos(" Tag="cos" math="trig" keyClick={keyClick} />
                            <Key key={`.${3}`} keyLog="tan(" Tag="tan" math="trig" keyClick={keyClick} />
                            <Key Tag="log" key={`.${4}`} keyLog="log(" math="log" keyClick={keyClick} />
                            <Key Tag="ln" key={`.${5}`} keyLog="ln(" math="log" keyClick={keyClick} />
                            <Key key={`.${6}`} keyLog="" Tag="hyp" math="log" keyClick={keyClick} />
                            <Key Tag={[x, pow2]} key={`.${7}`} keyLog="^2" math="sqr" keyClick={keyClick} />
                            <Key key={`.${8}`} keyLog="^3" Tag={[x, pow3]} math="power" keyClick={keyClick} />
                            <Key Tag="^" key={`.${9}`} keyLog="^" math="power" keyClick={keyClick} />
                            <Key key={`.${10}`} keyLog="(" Tag="(" math="prnths" keyClick={keyClick} />
                            <Key key={`.${11}`} keyLog=")" Tag=")" math="prnths" keyClick={keyClick} />
                            <Key key={`.${12}`} keyLog="-" Tag="(&minus;)" math="sub" keyClick={keyClick} />
                            <Key Tag={[x, inv]} key={`.${13}`} keyLog="^-1" math="inv" keyClick={keyClick} />
                            <Key Tag="&radic;" key={`.${14}`} keyLog="&radic;(" math="sqrt" keyClick={keyClick} />
                            <Key key={`.${15}`} keyLog="" Tag="," math="log" keyClick={keyClick} />
                            <Key key={`.${16}`} keyLog="" Tag="RCL" math="" keyClick={keyClick} />
                            <Key key={`.${17}`} keyLog="" Tag="ENG" math="" keyClick={keyClick} />
                            <Key key={`.${18}`} keyLog="" Tag="M+" math="log" keyClick={keyClick} />
                        </div>
                        <div className={cx(s["at-simplekeys"], { [s["at-simplekeyswidth"]]: isScientific })}>
                            <Key Tag="Ans" key={`.${19}`} keyLog="Ans" className={s["at-btnans"]} math="ans" keyClick={keyClick} />
                            <Key Tag="DEL" key={38} className={s["at-btncusomestyle"]} math="delete" keyClick={keyClick} />
                            <Key Tag="AC" key={37} className={s["at-btncusomestyle"]} math="clear" keyClick={keyClick} />
                            <Key Tag="7" key={`.${20}`} keyLog="7" math="number" keyClick={keyClick} />
                            <Key Tag="8" key={`.${21}`} keyLog="8" math="number" keyClick={keyClick} />
                            <Key Tag="9" key={`.${22}`} keyLog="9" math="number" keyClick={keyClick} />
                            <Key Tag="&divide;" key={`.${23}`} keyLog="&divide;" math="divide" keyClick={keyClick} />
                            <Key Tag="4" key={`.${24}`} keyLog="4" math="number" keyClick={keyClick} />
                            <Key Tag="5" key={`.${25}`} keyLog="5" math="number" keyClick={keyClick} />
                            <Key Tag="6" key={`.${26}`} keyLog="6" math="number" keyClick={keyClick} />
                            <Key Tag="&times;" key={`.${27}`} keyLog="&times;" math="multiply" keyClick={keyClick} />
                            <Key Tag="1" key={`.${28}`} keyLog="1" math="number" keyClick={keyClick} />
                            <Key Tag="2" key={`.${29}`} keyLog="2" math="number" keyClick={keyClick} />
                            <Key Tag="3" key={`.${30}`} keyLog="3" math="number" keyClick={keyClick} />
                            <Key Tag="&minus;" key={`.${31}`} keyLog="-" math="subtract" keyClick={keyClick} />
                            <Key Tag="0" key={`.${32}`} keyLog="0" math="log" keyClick={keyClick} />
                            <Key Tag="." key={`.${33}`} keyLog="." math="comma" keyClick={keyClick} />
                            <Key Tag="EXP" key={`.${34}`} keyLog="E" math="exponent" keyClick={keyClick} />
                            <Key Tag="+" key={`.${35}`} keyLog="+" math="sum" keyClick={keyClick} />
                            <Key Tag="=" key={36} math="equals" className={s["at-equalsign"]} keyClick={keyClick} />
                        </div>
                    </div>


                </div>
            </ToolHolder>
        );
    }
}

export default Calculator;