import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import PopupContainer from '../PopupContainer'

import s from './PopupTypeTwo.module.css';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages'

class PopupTypeTwo extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        popupIcon: PropTypes.string.isRequired,
        backdrop: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
        size: PropTypes.string,
        okButtonText: PropTypes.string,
        cancelButtonText: PropTypes.string,
        alertTitle: PropTypes.string,
        alertMessage: PropTypes.string,

        onToggle: PropTypes.func.isRequired,
        onOK: PropTypes.func,
        onCancel: PropTypes.func,

    };

    static defaultProps = {
        title: '',
        size: 'sm',
        backdrop: 'static',
        okButtonText: <FormattedMessage {...messages.yes} />,
        cancelButtonText: <FormattedMessage {...messages.no} />,
        alertMessage: '',
        alertTitle: <FormattedMessage {...messages.areYouSure} />,
        isOpen: false,
        onToggle: () => { },
    };


    render() {
        const {
            title,
            params,
            size,
            backdrop,
            isOpen,
            popupIcon,
            alertTitle,
            onToggle,
            onOK,
            onCancel,
            okButtonText,
            cancelButtonText,
            alertMessage,
        } = this.props;


        return (
            <PopupContainer isOpen={isOpen}>
                <div className={cx(s["at-popup"], { [s["at-md"]]: size == 'md', [s["at-sm"]]: size != 'md' })}>
                    {title ?
                        <div className={s["at-popuphead"]}>
                            <h3>{title}</h3>
                        </div>
                        : <figure className={s["at-popupimg"]}>
                            <span onClick={onToggle} className={s["at-closepopup"]}>
                                <img src="images/close-icon.png" alt="image description" />
                            </span>
                            <img src="images/popup-img.jpg" alt="image description" />
                        </figure>
                    }

                    <div className={s["at-popupcontent"]}>
                        {/* {popupIcon && <span className={s["at-popupicon"]} dangerouslySetInnerHTML={{ __html: popupIcon }}></span>} */}
                        {this.props.children}
                    </div>
                </div>
            </PopupContainer>
        );
    }
}

export default PopupTypeTwo;