import React, { Component } from 'react';
import DropboxChooser from '../../common/DropboxChooser/DropboxChooser';
import { categorizeMedia } from '../../../utils/helper';
import PropTypes from 'prop-types';
import s from '../WorkbookPage.module.css';
import cx from 'classnames';

class DropboxPage extends Component {
	static propTypes = {
		onProcessData: PropTypes.func.isRequired,
		activeDrive: PropTypes.string.isRequired,
		viewFiles: PropTypes.func.isRequired,
		Dropbox: PropTypes.object.isRequired
	};
	constructor(props) {
		super(props);
	}

	componentDidMount = () => {};

	onSuccess = async (files) => {
		let { onProcessData } = this.props;
		let Dropbox = { IMAGE: [], LAYOUT: [], SPREADSHEET: [], VIDEO: [], TEXT: [], DATA: [], NOTEBOOK: [], count: 0 };
		const categorize = await categorizeMedia(files);
		for (const keys in categorize) {
			Dropbox.count += categorize[keys].length;

			categorize[keys].forEach((i) => {
				Dropbox[keys].push(i);
			});
		}

		onProcessData('Dropbox', Dropbox);
	};

	render() {
		let { Dropbox, viewFiles, activeDrive } = this.props;
		let { count } = Dropbox;
		return (
			<li
				className={cx(s['at-dropox'], {
					[s['at-showcatagory']]: count,
					[s['at-active']]: activeDrive == 'Dropbox'
				})}
			>
				{/* <i onClick={viewFiles.bind(this, 'Dropbox')} className={cx('fa fa-folder-open-o', s['at-btnviewfiles'])}></i> */}
				<DropboxChooser
					appKey={'s9wqk0o36gu5yf6'}
					success={this.onSuccess}
					multiselect={true}
					linkType={'direct'}
				>
					<a href={null}>
						<i className={s['at-divebtnholder']}>
							<img src="images/bropbox-icon.png" />
						</i>
						<span>Dropbox</span>
					</a>
				</DropboxChooser>
			</li>
		);
	}
}

export default DropboxPage;
