import React, { Component } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import { isMobile } from "react-device-detect";
import { Button, Row, Col, Space, Divider } from "antd";
import { UserOutlined, VideoCameraOutlined } from "@ant-design/icons";

import validateInput from "../../validations/login";
import { connect } from "react-redux";
import { Link } from "react-router";
import { login } from "../../actions/authActions";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import messages from "../../messages";

import cx from "classnames";
import s from "./Login.module.css";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: {},
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ errors: {}, isLoading: true });
      this.props.login(this.state).then(
        (res) => this.context.router.push("/folders"),
        (err) =>
          this.setState({ errors: err.response.data.errors, isLoading: false })
      );
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors, email, password, isLoading } = this.state;
    const { toggleForgetPassModal } = this.props;

    return (
      <>
        <div className={s["right-align"]}>
          <div className={s["register-head"]}>
            <h1>
              <FormattedMessage {...messages.loginMessage} />
            </h1>
            <p>
              <FormattedMessage {...messages.loginSubMessage} />
            </p>
          </div>
          <form autoComplete="off" onSubmit={this.onSubmit}>
            {errors.form && (
              <div className="alert alert-danger">{errors.form}</div>
            )}

            <TextFieldGroup
              error={errors.email}
              label={<FormattedMessage {...messages.usernameOrEmail} />}
              onChange={this.onChange}
              value={email}
              field="email"
            />

            <TextFieldGroup
              error={errors.password}
              label={<FormattedMessage {...messages.password} />}
              onChange={this.onChange}
              value={password}
              field="password"
              type="password"
            />

            <div className={s["at-btnholder"]}>
              <a
                className={s.fp}
                onClick={toggleForgetPassModal}
                href="javascript:;"
              >
                <FormattedMessage {...messages.forgottenYourPassword} /> ?
              </a>

              <Button
                style={{ float: "right" }}
                disabled={isLoading}
                htmlType="submit"
                type="primary"
                icon={<UserOutlined />}
                size="large"
              >
                <FormattedMessage {...messages.login} />
              </Button>
            </div>

            <p>
              <FormattedMessage
                {...messages.createYourAccount}
                values={{
                  click_here: (
                    <Link
                      style={{ margin: 0 }}
                      className={s["btn-holo"]}
                      to="/signup"
                    >
                      <FormattedMessage {...messages.createOne} />
                    </Link>
                  ),
                }}
              />
            </p>
          </form>
        </div>

        {!isMobile && (
          <>
            <Divider className="mt-4 mb-4">OR</Divider>

            <Button
              size="large"
              block
              type="primary"
              icon={<VideoCameraOutlined />}
              onClick={() =>
                (window.location.href = `/cloud-call/?sessionId=${Math.random()
                  .toString(36)
                  .substring(2)}`)
              }
            >
              Start Quick Meeting
            </Button>
          </>
        )}
      </>
    );
  }
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  toggleForgetPassModal: PropTypes.func.isRequired,
};

LoginForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(null, { login })(LoginForm);
