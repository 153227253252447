import React, { Component } from 'react';
import PreviewItem from './PreviewItem';
import PropTypes from 'prop-types';

export default function DropboxFormat({ media, previewProp, mouseUp, addClick, delClick }) {
  const mediaList = (
    <div>
      {media.map((item, key) => (<PreviewItem
        addClick={addClick}
        delClick={delClick}
        item={item}
        previewProp={previewProp}
        key={key}
        mouseUp={mouseUp}
      />))}
    </div>
  );
  return (
    <div>
      {media.length === 0 ? null : mediaList}
    </div>
  );
}

DropboxFormat.propTypes = {
  media: PropTypes.array.isRequired,
  previewProp: PropTypes.string.isRequired,
  mouseUp: PropTypes.func.isRequired,
  addClick: PropTypes.func.isRequired,
  delClick: PropTypes.func.isRequired,

};
