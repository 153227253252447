import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addFlashMessage } from '../actions/flashMessages';
import PropTypes from 'prop-types';

export default function (ComposedComponent) {
  class Authenticate extends Component {
    componentWillMount() {
      if (!this.props.isAuthenticated) {
        this.context.router.push('/');
      } else if (this.props.authUser && this.props.authUser.licenseExpiry <= 0 && !this.props.authUser.isLicensed) {
        if (document.location.pathname !== '/profile') {
          document.location.href = '/profile'
        }
      }
    }

    componentWillUpdate(nextProps) {
      if (!nextProps.isAuthenticated) {
        this.context.router.push('/');
      } else if (this.props.authUser && this.props.authUser.licenseExpiry <= 0 && !this.props.authUser.isLicensed) {
        if (document.location.pathname !== '/profile') {
          document.location.href = '/profile'
        }
      }
    }

    render() {
      return (
        <ComposedComponent {...this.props} />
      );
    }
  }

  Authenticate.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    addFlashMessage: PropTypes.func.isRequired,
  };

  Authenticate.contextTypes = {
    router: PropTypes.object.isRequired,
  };

  function mapStateToProps(state) {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      authUser: state.auth.user,
    };
  }

  return connect(mapStateToProps, { addFlashMessage })(Authenticate);
}
