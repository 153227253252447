import _ from "lodash";

const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
  {
    languageId: "portuguese",
    locale: "pt",
    name: "Portuguese",
    icon: "pt",
  },
  {
    languageId: "spanish",
    locale: "es",
    name: "Spanish",
    icon: "es",
  },
];

export const getLanguage = (locale) => {
  return _.find(languageData, { locale });
};

export default languageData;
