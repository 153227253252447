import React, { Component } from "react";

import { Button, Row, Col, Space, Radio } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

import timezones from "../../data/timezones";
import map from "lodash/map";
import classnames from "classnames";
import validateInput from "../../validations/signup";
import TextFieldGroup from "../common/TextFieldGroup";
import { Link } from "react-router";
import PropTypes from "prop-types";
import s from "./signup.module.css";
import cx from "classnames";
import { FormattedMessage } from "react-intl";
import messages from "../../messages";

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      educator: "teacher",
      errors: {},
      isLoading: false,
      invalid: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.checkUserExists = this.checkUserExists.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  checkUserExists(e) {
    const field = e.target.name;
    const val = e.target.value;
    if (val !== "") {
      this.props.isUserExists(val).then((res) => {
        const errors = this.state.errors;
        let invalid;
        if (res.data.user) {
          if (field == "email") {
            errors[
              field
            ] = `Email already in use, please use a different email address`;
          } else if (field == "username") {
            errors[
              field
            ] = `User already exists, please choose a different username`;
          }

          invalid = true;
        } else {
          errors[field] = "";
          invalid = false;
        }
        this.setState({ errors, invalid });
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.isValid()) {
      this.setState({ errors: {}, isLoading: true });
      this.props.userSignupRequest(this.state).then(
        () => {
          this.props.addFlashMessage({
            type: "success",
            text: "You signed up successfully. Welcome!",
          });

          this.context.router.push("/");
        },
        (err) => this.setState({ errors: err.response.data, isLoading: false })
      );
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <div className={s["right-align"]}>
        <div className={s["register-head"]}>
          <h1>
            <FormattedMessage {...messages.signupMessage} />
          </h1>
          <p>
            <FormattedMessage {...messages.signupSubMessage} />{" "}
          </p>
        </div>

        <form onSubmit={this.onSubmit}>
          {errors.form && (
            <div className="alert alert-danger">{errors.form}</div>
          )}
          <Space direction="vertical">
            <TextFieldGroup
              error={errors.firstName}
              label={<FormattedMessage {...messages.firstName} />}
              onChange={this.onChange}
              value={this.state.firstName}
              field="firstName"
            />

            <TextFieldGroup
              error={errors.lastName}
              label={<FormattedMessage {...messages.lastName} />}
              onChange={this.onChange}
              value={this.state.lastName}
              field="lastName"
            />

            <TextFieldGroup
              error={errors.username}
              label={<FormattedMessage {...messages.username} />}
              onChange={this.onChange}
              onBlur={this.checkUserExists}
              value={this.state.username}
              field="username"
            />

            <TextFieldGroup
              error={errors.email}
              label={<FormattedMessage {...messages.email} />}
              onChange={this.onChange}
              onBlur={this.checkUserExists}
              value={this.state.email}
              field="email"
            />

            <TextFieldGroup
              error={errors.password}
              label={<FormattedMessage {...messages.password} />}
              onChange={this.onChange}
              value={this.state.password}
              field="password"
              type="password"
            />

            {/* <Radio.Group
              className="gx-mt-3"
              name="educator"
              onChange={this.onChange}
              value={this.state.educator}
            >
              <Radio value={"teacher"}>Teacher</Radio>
              <Radio value={"student"}>Student</Radio>
            </Radio.Group> */}

            <Button
              className="gx-mt-3"
              disabled={this.state.isLoading || this.state.invalid}
              block
              htmlType="submit"
              type="primary"
              icon={<UserAddOutlined />}
              size="large"
            >
              <FormattedMessage {...messages.signup} />
            </Button>
          </Space>

          <p className="gx-mt-2">
            <FormattedMessage
              {...messages.loginYourAccount}
              values={{
                click_here: (
                  <Link className={s["btn-holo"]} to="/">
                    <FormattedMessage {...messages.clickHere} />
                  </Link>
                ),
              }}
            />
          </p>
        </form>
      </div>
    );
  }
}

SignupForm.propTypes = {
  userSignupRequest: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  isUserExists: PropTypes.func.isRequired,
};

SignupForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default SignupForm;
