import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import map from 'lodash/map';

import { getGallery } from '../../../actions/galleryActions';
import PopupTypeOne from '../../common/PopupTypeOne';
import { getFileThumbnial } from '../../../utils/helper';
import s from '../WorkbookPage.module.css';
// import ls from '../../lecture/LecturePage.css'
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages';

class GalleryPage extends Component {
	static propTypes = {
		addFileFromGallery: PropTypes.func.isRequired,
		gallery: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			isPopupOpen: false
		};
	}
	componentDidMount = () => {
		const { getGallery } = this.props;
		getGallery();
	};

	onToggle = () => {
		this.setState({ isPopupOpen: !this.state.isPopupOpen, errors: {} });
	};

	render() {
		const { isPopupOpen } = this.state;
		const { gallery, addFileFromGallery } = this.props;
		const gContent = map(gallery, (file, key) => (
			<div onClick={addFileFromGallery.bind(this, file)} key={key} className="card">
				<img
					className="card-img-top rounded img-thumbnail"
					src={getFileThumbnial(file.path)}
					alt={file.title}
				/>
				<div className="card-footer">
					<span className="card-title">{file.title}</span>
				</div>
			</div>
		));

		return (
			<Fragment>
				<li onClick={this.onToggle} className={s['at-youtube']}>
					<a href={null}>
						<i className={s['at-divebtnholder']}>
							<img src="images/dashboard-ico.png" />
						</i>
						<span>My Files</span>
					</a>
				</li>
				<PopupTypeOne isOpen={isPopupOpen} onToggle={this.onToggle} size={'lg'} title="My Files">
					<div className="card-columns">{gContent}</div>
					<button type="button" onClick={this.onToggle} className="btn at-themebtn">
						<FormattedMessage {...messages.cancel} />
					</button>
				</PopupTypeOne>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return { gallery: state.gallery.files };
}
export default connect(mapStateToProps, { getGallery })(GalleryPage);
