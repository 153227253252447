import axios from 'axios';

export function getUserWorkbookList(page, search, folder) {
    return dispatch => axios.get(`${process.env.REACT_APP_API_HOST}/api/workbook?limit=20&page=${page}&search=${search}&folder=${folder}`);
}

export function getWorkbookDetails(id) {
    return dispatch => axios.get(`${process.env.REACT_APP_API_HOST}/api/workbook/${id}`);
}
export function getSessionId() {
    return dispatch => axios.get(`${process.env.REACT_APP_API_HOST}/api/workbook/createSessionId`);
}

export function deleteUserWorkbook(workbookId) {
    return dispatch => axios.delete(`${process.env.REACT_APP_API_HOST}/api/workbook/${workbookId}`);
}

export function exportUserWorkbook(workbookId) {
    return dispatch => axios.get(`${process.env.REACT_APP_API_HOST}/api/workbook/${workbookId}/export`);
}

export function updateUserWorkbook(workbookId, data) {
    return dispatch => axios.put(`${process.env.REACT_APP_API_HOST}/api/workbook/${workbookId}`, data);
}

export function startLectureAndInviteStudent(workbookId, data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/${workbookId}/invite`, data);
}

export function isfileExists(path) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/isfileExists`, { path });
}

export function getDocDetails(path) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/docDetails`, { path });
}

export function saveAnnotation(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/saveAnnotation`, data);
}


export function addEmptySlide(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/addEmptySlide`, data);
}
