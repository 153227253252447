import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';

import DropboxPage from './DropboxPage';
import GooglePage from './GooglePage';
import OneDrivePage from './OneDrivePage';
import YoutubePage from './YoutubePage';
import GalleryPage from './GalleryPage';
import ComputerDrivePage from './ComputerDrivePage';
import s from '../WorkbookPage.module.css'
import cx from "classnames"

import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import FileInput from "@uppy/file-input";
import GoogleDrive from "@uppy/google-drive";
import OneDrive from "@uppy/onedrive";
import { DashboardModal } from "@uppy/react";

const restrictions = {
	allowedFileTypes: [
		"application/msword",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/vnd.ms-excel",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/vnd.ms-powerpoint",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation",
		"application/pdf",
		"image/*",
		"video/*",
	],
}

class DrivePage extends Component {
	static propTypes = {
		onProcessData: PropTypes.func.isRequired,
		addFileFromGallery: PropTypes.func.isRequired,
		embedYoutubeVideo: PropTypes.func.isRequired,
		activeDrive: PropTypes.string.isRequired,
		viewFiles: PropTypes.func.isRequired,
		Dropbox: PropTypes.object.isRequired,
		Google: PropTypes.object.isRequired,
		One: PropTypes.object.isRequired,
		Computer: PropTypes.object.isRequired,
	}
	constructor(props) {
		super(props);
		this.state = { driveType: '' };

		this.uppyGoogleDrive = new Uppy({
			id: "GoogleDrive",
			autoProceed: true,
			debug: true,
			restrictions,
		})
			.use(Tus, { endpoint: "https://tus.contribute.cloud/files/" })
			.use(GoogleDrive, { companionUrl: "https://uppy.contribute.cloud" });

		this.uppyGoogleDrive.on("complete", this.uppyOnComplete);

		this.uppyOneDrive = new Uppy({
			id: "OneDrive",
			autoProceed: true,
			debug: true,
			restrictions,
		})
			.use(Tus, { endpoint: "https://tus.contribute.cloud/files/" })
			.use(OneDrive, { companionUrl: "https://uppy.contribute.cloud" });

		this.uppyOneDrive.on("complete", this.uppyOnComplete);


		this.uppyFileInput = new Uppy({
			id: "FileInput",
			autoProceed: true,
			debug: true,
			restrictions,
		})
			.use(Tus, { endpoint: "https://tus.contribute.cloud/files/" })
			.use(FileInput, {
				target: null, companionUrl: "https://uppy.contribute.cloud"
			});

		this.uppyFileInput.on("complete", this.uppyOnComplete);


		// this.uppyDropbox = new Uppy({
		// 	id: "Dropbox",
		// 	autoProceed: true,
		// 	debug: true,
		// 	restrictions,
		// })
		// 	.use(Tus, { endpoint: "https://tus.contribute.cloud/files/" })
		// 	.use(Dropbox, { companionUrl: "https://uppy.contribute.cloud" });

		// this.uppyDropbox.on("complete", this.uppyOnComplete);
	}

	uppyOnComplete = async (result) => {
		if (result.successful.length) {
			for (const file of result.successful) {
				console.log("DrivePage -> uppyOnComplete -> file", file)
				const { addFromUppy } = this.props;
				addFromUppy(file)
			}
		}
	}

	componentDidMount = () => {

	}
	render() {
		const { onProcessData, viewFiles, Dropbox, One, Computer, activeDrive, embedYoutubeVideo, addFileFromGallery } = this.props

		return (<Fragment>
			<ul className={cx(s['at-drivebtnsholder'], s['at-responsivewidth'])}>
				<DropboxPage
					onProcessData={onProcessData}
					Dropbox={Dropbox}
					viewFiles={viewFiles}
					activeDrive={activeDrive}
				/>


				<GooglePage openUppyModal={() => this.setState({ driveType: 'GoogleDrive' })} />
				<OneDrivePage
					onProcessData={onProcessData}
					One={One}
					viewFiles={viewFiles}
					activeDrive={activeDrive}
				/>
				{/* <OneDrivePage openUppyModal={() => this.setState({ driveType: 'OneDrive' })} /> */}

				<ComputerDrivePage
					uppyFileInput={this.uppyFileInput}
					onProcessData={onProcessData}
					Computer={Computer}
					viewFiles={viewFiles}
					activeDrive={activeDrive}
				/>

				<YoutubePage
					embedYoutubeVideo={embedYoutubeVideo}
				/>

				<GalleryPage
					addFileFromGallery={addFileFromGallery}
				/>
			</ul>

			<div id="googleDriveUppyHolder">
				<DashboardModal
					uppy={this.uppyGoogleDrive}
					showProgressDetails={true}
					disableThumbnailGenerator={false}
					showLinkToFileUploadResult={false}
					proudlyDisplayPoweredByUppy={false}
					closeModalOnClickOutside
					open={this.state.driveType === 'GoogleDrive'}
					onRequestClose={() => this.setState({ driveType: '' })}
					plugins={["GoogleDrive"]}
				/>
			</div>


			{/* <DashboardModal
				uppy={this.uppyFileInput}
				showProgressDetails={true}
				disableThumbnailGenerator={false}
				showLinkToFileUploadResult={false}
				proudlyDisplayPoweredByUppy={false}
				closeModalOnClickOutside
				// open={this.state.driveType === 'GoogleDrive'}
				open={true}
				onRequestClose={() => this.setState({ driveType: '' })}
				plugins={[]}
			/> */}

			{/* <DashboardModal
				uppy={this.uppyOneDrive}
				showProgressDetails={true}
				disableThumbnailGenerator={false}
				showLinkToFileUploadResult={false}
				proudlyDisplayPoweredByUppy={false}
				closeModalOnClickOutside
				open={this.state.driveType === 'OneDrive'}
				onRequestClose={() => this.setState({ driveType: '' })}
				plugins={["OneDrive"]}
			/> */}

			{/* <DashboardModal
				uppy={this.uppyDropbox}
				showProgressDetails={true}
				disableThumbnailGenerator={false}
				showLinkToFileUploadResult={false}
				proudlyDisplayPoweredByUppy={false}
				closeModalOnClickOutside
				open={this.state.driveType === 'Dropbox'}
				onRequestClose={() => this.setState({ driveType: '' })}
				plugins={["Dropbox"]}
			/> */}

		</Fragment>);
	}
}

export default DrivePage;
