import React, { Component } from 'react';
import loadScript from 'load-script';
import PropTypes from 'prop-types';

const ONE_DRIVE_SDK_URL = 'https://js.live.net/v7.2/OneDrive.js';

class OneDriveChooser extends Component {
    static propTypes = {
        clientId: PropTypes.string.isRequired,
        success: PropTypes.func.isRequired,

        openInNewWindow: PropTypes.bool,
        advanced: PropTypes.object,
        cancel: PropTypes.func,
        error: PropTypes.func,
        action: PropTypes.oneOf(['share', 'query', 'download']),
        multiSelect: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        cancel: (error) => { console.error(error) },
        error: (error) => { console.error(error) },
        advanced: {},
        action: 'query',
        openInNewWindow: true,
        multiSelect: false,
        disabled: false
    };



    constructor(props) {
        super(props);
        this.scriptLoadingStarted = false;
    }

    componentDidMount = () => {
        if (!this.isOneDriveReady() && !this.scriptLoadingStarted) {
            this.scriptLoadingStarted = true;
            loadScript(ONE_DRIVE_SDK_URL, { attrs: { crossorigin: true } });
        } else {
            console.info('onedrive loading ...')
        }
    }

    isOneDriveReady = () => {
        return !!window.OneDrive;
    }

    onChooser = () => {
        const { disabled } = this.props;
        if (!this.isOneDriveReady() || disabled) { return null; }

        const { clientId, action, multiSelect, advanced, openInNewWindow, success, cancel, error, } = this.props;
        window.OneDrive.open({ clientId, action, multiSelect, advanced, openInNewWindow, success, cancel, error });
    }

    render() {
        return (
            <div onClick={this.onChooser}>
                {this.props.children ? this.props.children : <button>Open One Drive</button>}
            </div>
        );
    }



}

export default OneDriveChooser;