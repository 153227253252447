// CompareModal.js
import React, { Component } from 'react';
import { connect } from 'react-redux';

import classnames from 'classnames';
import PropTypes from 'prop-types';


class CompareModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillMount() { }

	render() {
		return (
			<div aria-hidden="false" className="modal fade" id="compare-lecture" role="dialog">
				<div className="modal-dialog modal-lg" style={{ width: '85%' }}>
					<div className="modal-content">
						{/* <div className="modal-header" style={{ border: 0 }}>
                            <button className="close" data-dismiss="modal" type="button">×</button>
                        </div> */}
						<div className="modal-body clearfix" style={{
							direction: "rtl",
							overflowY: "scroll",
							maxHeight: "calc(90vh)"
						}}>
							<div id="compare-cast-videos" className="row" style={{ direction: "ltr" }}>
							</div>
						</div>
						<div className="clearfix" />
					</div>
				</div>
			</div>

		);
	}
}

CompareModal.contextTypes = {
	router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		auth: state.auth,
	};
}

export default connect(mapStateToProps, {})(CompareModal);
