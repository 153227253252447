import React, { Component } from 'react';
import OpenViduVideoComponent from './OvVideo';
import s from '../LecturePage.module.css'
export default class UserVideoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFullScreen: false
        }

        this.handleVideoClicked = this.handleVideoClicked.bind(this);
    }

    getNicknameTag() {
        // Gets the nickName of the user
        return JSON.parse(this.props.streamManager.stream.connection.data.replace('}%/%{', ',')).clientData;
    }

    handleVideoClicked(event) {
        // Triggers event for the parent component to update its main video display (other UserVideoComponent)
        if (this.props.mainVideoStream) {
            this.props.mainVideoStream(this.props.streamManager);
        }
    }

    handleFullScreenClicked = () => {
        this.setState({ showFullScreen: !this.state.showFullScreen })
    }

    _toggleFullScreen = () => {
        this._ovelm._toggleFullScreen();
    }
    render() {
        return (
            <figure className={s["at-screenimg"]}>
                {this.props.streamManager !== undefined ? (
                    <div className={s['nsg-studentscreenholder']}>
                        <OpenViduVideoComponent onClick={this._toggleFullScreen} onRef={c => (this._ovelm = c)} streamManager={this.props.streamManager} />
                        {/* <span>{this.getNicknameTag()}</span> */}
                        {/* <img onClick={this.handleFullScreenClicked} src="/images/tools-icons/icon-11.png" /> */}
                    </div>
                ) : null}
            </figure>
        );
    }
}
