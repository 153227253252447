// CreateWorkbook.js
import { IMAGE_TYPE } from '../../utils/types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavigationBar from '../NavigationBar';
import { addFlashMessage, deleteFlashMessage } from '../../actions/flashMessages.js';
import classnames from 'classnames';
import DrivePage from '../drive/DrivePage';
import { saveWorkbook, getWorkbook } from '../../actions/workbookActions';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import Youtube from '../model/Youtube';
import Invitation from '../model/Invitation';
// import { ModalContainer, ModalDialog } from 'react-modal-dialog';
import { sendInvitation } from '../../actions/courseActions';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';

const SortableItem = SortableElement(({ item, removeSlide, showImage }) => (<div className="thumbnail-img-row">
  <a onClick={showImage.bind(this, item)} href="javascript:">
    <img src={item.thumbnail} className="img-responsive" />
  </a>
</div>),
);

const SortableList = SortableContainer(({ items, removeSlide, showImage }) => (
  <div>
    {items.map((value, index) => (
      <div key={index}>
        <a href="javascript:" className="btn-view" onClick={showImage.bind(this, value)}>
          <i className="fa fa-eye" aria-hidden="true" />
        </a>

        <a href="javascript:" className="btn-close" onClick={removeSlide.bind(this, value)}>
          <i className="fa fa-times-circle" aria-hidden="true" />
        </a>
        <SortableItem
          key={`item-${index}`}
          index={index}
          item={value}
          removeSlide={removeSlide}
          showImage={showImage}
        />
      </div>

    ))}
  </div>
));

class CreateWorkbook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lectureSlides: [],
      workbookID: '',
      lectureSlideToDelete: '',
      isShowingModal: false,
      isNewLecture: false,
      isDownloading: true,
    };

    window.document.title = ' Contribute | Create Workbook';
    this.mouseUp = this.mouseUp.bind(this);
    this.saveLecture = this.saveLecture.bind(this);
    this.showImge = this.showImge.bind(this);
    this.addClick = this.addClick.bind(this);
    this.removeLectureSlideAlert = this.removeLectureSlideAlert.bind(this);
    this.removeLectureSlide = this.removeLectureSlide.bind(this);
    this.previewSlides = this.previewSlides.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.justSave = this.justSave.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    // this.onChange = this.onChange.bind(this);
    this.addYoutubeVideo = this.addYoutubeVideo.bind(this);
  }

  componentWillMount() {
    const workbookID = this.props.location.query.id;
    this.setState({ workbookID });
  }

  componentDidMount() {
    this.props.getWorkbook({ workbookID: this.state.workbookID, userID: this.props.auth.user.id }).then((res) => {
      this.setState({ lectureSlides: res.data.workbook.slide });
      jcf.customForms.replaceAll();
    });
  }

  componentWillReceiveProps(nextProps) {
    const workbookID = this.props.location.query.id;
    // if(this.state.workbookID !== workbookID){
    this.setState({ isNewLecture: true });
    // }
  }

  mouseUp(item) {
    const lectureSlides = this.state.lectureSlides;
    lectureSlides.push(item);
    this.setState({ lectureSlides });
    jcf.customForms.replaceAll();
  }
  refreshPage() {
    window.location.reload();
  }

  addClick(item, previewProp) {
    const lectureSlides = this.state.lectureSlides;
    item.thumbnail = item[previewProp];
    lectureSlides.push(item);
    this.setState({ lectureSlides });
  }

  addYoutubeVideo(youtubeLink) {
    const video = {
      thumbnail: '/images/file-type-video.png',
      name: `${Math.random().toString(36).substring(7)}.mp4`,
      drive: 'youtube',
      downloadFileType: 'video',
      downloadLink: youtubeLink,
    };

    const lectureSlides = this.state.lectureSlides;
    lectureSlides.push(video);
    this.setState({ lectureSlides });
    jcf.customForms.replaceAll();
  }

  previewSlides() {
    const data = this.state.lectureSlides;
    const dbSlides = [];
    const slidesDownloadLinks = [];

    data.forEach((s, i) => {
      let dbRecored = {};
      if (s.path) {
        dbRecored = s;
      } else {
        const downloadDetails = {};
        dbRecored.name = s.name;
        dbRecored.thumbnail = s.thumbnail;

        const ext = /^.+\.([^.]+)$/.exec(s.name);
        const extention = ext == null
          ? ''
          : ext[1].toLocaleLowerCase();
        const downloadName = `${i}-${this.state.workbookID}.${extention}`;
        if (IMAGE_TYPE.indexOf(extention.toUpperCase()) > -1) {
          dbRecored.thumbnail = `/lecture-attachments/${downloadName}`;
        }
        dbRecored.path = `/lecture-attachments/${downloadName}`;
        dbRecored.filetype = s.downloadFileType;

        downloadDetails.drive = s.drive;
        downloadDetails.downloadLink = s.downloadLink;
        downloadDetails.downloadName = downloadName;
        downloadDetails.downloadLinkType = s.downloadLinkType;
        downloadDetails.downloadFileType = s.downloadFileType;

        slidesDownloadLinks.push(downloadDetails);
      }

      dbSlides.push(dbRecored);
    });
    const myLecture = {};
    myLecture.userID = this.props.auth.user.id;
    myLecture.workbookID = this.state.workbookID;
    myLecture.dbSlides = dbSlides;
    myLecture.slidesDownloadLinks = slidesDownloadLinks;

    this.setState({ isDownloading: false });
    this.props.sendInvitation({ student: [] }).then((res) => {
      this.props.saveWorkbook(myLecture).then(() => {
        this.setState({ isDownloading: true });
        this.context.router.push({
          pathname: '/lecture',
          query: {
            id: this.state.workbookID,
            sessionId: res.data.session_id,
          },
        });
      });
    });
  }

  justSave() {
    const data = this.state.lectureSlides;
    const dbSlides = [];
    const slidesDownloadLinks = [];

    data.forEach((s, i) => {
      let dbRecored = {};
      if (s.path) {
        dbRecored = s;
      } else {
        const downloadDetails = {};
        dbRecored.name = s.name;
        dbRecored.thumbnail = s.thumbnail;

        const ext = /^.+\.([^.]+)$/.exec(s.name);
        const extention = ext == null
          ? ''
          : ext[1].toLocaleLowerCase();
        const downloadName = `${i}-${this.state.workbookID}.${extention}`;
        if (IMAGE_TYPE.indexOf(extention.toUpperCase()) > -1) {
          dbRecored.thumbnail = `/lecture-attachments/${downloadName}`;
        }
        dbRecored.path = `/lecture-attachments/${downloadName}`;
        dbRecored.filetype = s.downloadFileType;


        downloadDetails.drive = s.drive;
        downloadDetails.downloadLink = s.downloadLink;
        downloadDetails.downloadName = downloadName;
        downloadDetails.downloadLinkType = s.downloadLinkType;
        downloadDetails.downloadFileType = s.downloadFileType;

        slidesDownloadLinks.push(downloadDetails);
      }

      dbSlides.push(dbRecored);
    });
    const myLecture = {};
    myLecture.userID = this.props.auth.user.id;
    myLecture.workbookID = this.state.workbookID;
    myLecture.dbSlides = dbSlides;
    myLecture.slidesDownloadLinks = slidesDownloadLinks;
    this.setState({ isDownloading: false });
    this.props.saveWorkbook(myLecture).then((res) => {
      this.props.addFlashMessage({ type: 'success', text: 'Your slides have been saved' });

      if (this.state.isNewLecture) {
        window.location.reload();
      }

      this.setState({ isDownloading: true, isNewLecture: false });
      setTimeout(() => {
        this.props.deleteFlashMessage();
      }, 5000);
    });
  }

  saveLecture(sessionId) {
    const data = this.state.lectureSlides;
    const dbSlides = [];
    const slidesDownloadLinks = [];

    data.forEach((s, i) => {
      let dbRecored = {};
      if (s.path) {
        dbRecored = s;
      } else {
        const downloadDetails = {};
        dbRecored.name = s.name;
        dbRecored.thumbnail = s.thumbnail;

        const ext = /^.+\.([^.]+)$/.exec(s.name);
        const extention = ext == null
          ? ''
          : ext[1].toLocaleLowerCase();
        const downloadName = `${i}-${this.state.workbookID}.${extention}`;
        if (IMAGE_TYPE.indexOf(extention.toUpperCase()) > -1) {
          dbRecored.thumbnail = `/lecture-attachments/${downloadName}`;
        }
        dbRecored.path = `/lecture-attachments/${downloadName}`;
        dbRecored.filetype = s.downloadFileType;

        downloadDetails.drive = s.drive;
        downloadDetails.downloadLink = s.downloadLink;
        downloadDetails.downloadName = downloadName;
        downloadDetails.downloadLinkType = s.downloadLinkType;
        downloadDetails.downloadFileType = s.downloadFileType;

        slidesDownloadLinks.push(downloadDetails);
      }

      dbSlides.push(dbRecored);
    });
    const myLecture = {};
    myLecture.userID = this.props.auth.user.id;
    myLecture.workbookID = this.state.workbookID;
    myLecture.dbSlides = dbSlides;
    myLecture.slidesDownloadLinks = slidesDownloadLinks;

    this.setState({ isDownloading: false });
    this.props.saveWorkbook(myLecture).then((res) => {
      this.setState({ isDownloading: true });
      this.props.addFlashMessage({ type: 'success', text: 'Your slides have been saved' });

      setTimeout(() => {
        this.props.deleteFlashMessage();
      }, 5000);

      this.context.router.push({
        pathname: '/lecture',
        query: {
          id: this.state.workbookID,
          sessionId,
        },
      });
    });
  }

  showImge(item) {
    this.refs.centerImg.src = item.thumbnail;
  }

  removeLectureSlideAlert(item) {
    this.setState({ isShowingModal: true, lectureSlideToDelete: item });
  }

  removeLectureSlide() {
    const lectureSlides = this.state.lectureSlides;
    let isDeleted = false;
    lectureSlides.forEach((i, j) => {
      const eq = JSON.stringify(this.state.lectureSlideToDelete) == JSON.stringify(i);
      if (eq && !isDeleted) {
        isDeleted = true;
        lectureSlides.splice(j, 1);
        console.log(i);
        this.refs.centerImg.src = 'images/drophere.png';
        this.setState({ lectureSlides });
        this.setState({ isShowingModal: false, lectureSlideToDelete: null });
      }
      jcf.customForms.replaceAll();
    });
  }

  handleClose() {
    this.setState({ isShowingModal: false, lectureSlideToDelete: null });
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState({
      lectureSlides: arrayMove(this.state.lectureSlides, oldIndex, newIndex),
    });
    jcf.customForms.replaceAll();
  }

  render() {
    return (
      <div className="dashboard-header lecture-page">
        <div className={classnames('loading-lecture', { hide: this.state.isDownloading })}>
          <img src="images/loader.gif" />
        </div>
        <NavigationBar
          saveIcon="show"
          saveSlides={this.saveLecture}
          previewSlides={this.previewSlides}
          justSave={this.justSave}
        />
        {!this.state.isDownloading ?
          <div className="loader-wrapper">
            <Spinner name="ball-clip-rotate" color="steelblue" className="loader" />
          </div>

          : ''
        }


        <div onClick={this.handleClick}>
          {this.state.isShowingModal && <ModalContainer>
            <ModalDialog>
              <h1 style={{
                margin: '0px 0px 15px 0px',
              }}
              >Do you want to delete slide ?</h1>
              <div className="text-center">
                <p className="btn btn-lg btn-danger" onClick={this.removeLectureSlide}>YES</p>
                &nbsp;&nbsp;&nbsp;
                <p className="btn btn-lg btn-info" onClick={this.handleClose}>NO</p>
              </div>
            </ModalDialog>

          </ModalContainer>
          }
        </div>


        <div onClick={this.handleClick}>
          {this.state.isNewLecture && <ModalContainer>
            <ModalDialog>
              <h1 style={{
                margin: '0px 0px 15px 0px',
              }}
              >Do you want to save currect Lecture ?</h1>
              <div className="text-center">
                <p className="btn btn-lg btn-danger" onClick={this.justSave}>YES</p>
                &nbsp;&nbsp;&nbsp;
                <p className="btn btn-lg btn-info" onClick={this.refreshPage}>NO</p>
              </div>
            </ModalDialog>

          </ModalContainer>
          }
        </div>


        <main id="main" className="createWorkbook-page" style={{ minHeight: 886 }}>
          <div className=" container-fluid">
            <div className="row">

              <DrivePage
                workbookId={this.state.workbookID}
                mouseUp={this.mouseUp}
                addClick={this.addClick}
              />

              <div className="col-md-7 col-sm-7 col-xs-12">
                <div id="dropableImg" ref="dropImg" className="file-drop-here" style={{ height: 887 }}>
                  <img ref="centerImg" src="images/drophere.png" alt className="img-responsive" />
                </div>
              </div>

              <div id="dragableImg" className="hide" ref="refImg" style={{ position: 'absolute' }}>
                <img
                  draggable="false"
                  ref="imageToDragUrl"
                  style={{ width: '100px', height: '100px', position: 'absolute' }}
                />
              </div>

              <div className="thumbnail_col col-md-2 col-sm-2 col-xs-12">
                <div
                  className="scrollable-area-wrapper noscroll-vertical"
                  style={{ width: 189, height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 200 }}
                >
                  <div
                    className="thumbnail_wrapper  scrollable-area"
                    style={{ height: 796, position: 'relative', overflow: 'hidden', width: 189 }}
                  >

                    <SortableList
                      removeSlide={this.removeLectureSlideAlert}
                      showImage={this.showImge}
                      items={this.state.lectureSlides}
                      onSortEnd={this.onSortEnd.bind(this)}
                    />
                  </div>
                  <div className="vscrollbar" style={{ height: 796, display: 'none' }}>
                    <div className="vscroll-up btn-disabled" />
                    <div className="vscroll-line" style={{ position: 'relative', height: 0 }}>
                      <div
                        className="vscroll-slider"
                        style={{ position: 'absolute', height: 0, top: 0 }}
                      >
                        <div className="scroll-bar-top" />
                        <div className="scroll-bar-bottom" />
                      </div>
                    </div>
                    <div className="vscroll-down btn-disabled" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Youtube
            onChange={this.onChange}
            value={this.state.youtubueLink}
            onClick={this.addYoutubeVideo}
          />
          <Invitation onSendNotification={this.saveLecture} />
        </main>

      </div>

    );
  }
}

// export default CreateWorkbook;
CreateWorkbook.propTypes = {
  saveWorkbook: PropTypes.func.isRequired,
  getWorkbook: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

CreateWorkbook.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, {
  saveWorkbook,
  getWorkbook,
  addFlashMessage,
  deleteFlashMessage,
  sendInvitation,
})(CreateWorkbook);
