import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Typography,
  Form,
  Input,
  Alert,
  Switch,
  Space,
  Radio,
  AutoComplete,
} from "antd";

import { createWorkbook, fetchFolders } from "../../../actions/workbookActions";
import { updateUserWorkbook } from "../../../actions/workbook";
import validateInput from "../../../validations/workbook";
import PropTypes from "prop-types";
import map from "lodash/map";
import { FormattedMessage } from "react-intl";
import messages from "../../../messages";

const { Text } = Typography;

function lectreTimeStamp() {
  var date = new Date();
  return (
    date.getMonth() +
    1 +
    "-" +
    date.getDate() +
    "-" +
    date.getFullYear() +
    "  " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds()
  );
}

const autoCompleteOptions = (foldersList) =>
  map(foldersList, (val, key) => ({ value: key }));

class NewLecture extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      subject: "",
      description: "",
      folder: "",
      foldersList: [],
      isNewFolder: false,
      errors: {},
      isLoading: false,
    };
  }

  componentDidMount = () => {
    const { workbook, workbookType, createWorkbook, toggleModal } = this.props;
    if (workbookType == "edit") {
      this.setState({
        name: workbook.name,
        subject: workbook.subject,
        description: workbook.description,
        folder: workbook.folder,
      });
    } else if (workbookType == "lecture") {
      toggleModal();
      createWorkbook({
        name: lectreTimeStamp(),
        subject: lectreTimeStamp(),
        folder: "Contribute Cloud Workbooks",
        type: workbookType,
      })
        .then((res) => {
          this.setState({
            name: "",
            subject: "",
            description: "",
            folder: "",
            isLoading: false,
          });
          if (workbookType == "lecture") {
            this.context.router.push({
              pathname: "/lecture",
              query: { id: res.data.book._id, sessionId: res.data.sessionId },
            });
          }
        })
        .catch((err) => {
          this.setState({
            errors: err.response ? err.response.data.errors : err,
            isLoading: false,
          });
        });
    }

    fetchFolders()
      .then((res) => {
        let {
          data: { folders },
        } = res;
        this.setState({ foldersList: folders, isLoading: false });
      })
      .catch((err) => {
        this.setState({
          errors: err.response ? err.response.data.errors : err,
          isLoading: false,
        });
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { workbook, workbookType, createWorkbook, toggleModal } = nextProps;
    if (nextProps.workbookType === "edit" && nextProps.visible) {
      console.log(
        "NewLecture -> UNSAFE_componentWillReceiveProps -> workbook",
        workbook
      );
      this.setState({
        name: workbook.name,
        subject: workbook.subject,
        description: workbook.description,
        folder: workbook.folder,
      });
    } else if (nextProps.workbookType === "lecture") {
      createWorkbook({
        name: lectreTimeStamp(),
        subject: lectreTimeStamp(),
        folder: "Contribute Cloud Workbooks",
        type: workbookType,
      })
        .then((res) => {
          this.setState({
            name: "",
            subject: "",
            description: "",
            folder: "",
            isLoading: false,
          });
          if (workbookType == "lecture") {
            this.context.router.push({
              pathname: "/lecture",
              query: { id: res.data.book._id, sessionId: res.data.sessionId },
            });
          }
        })
        .catch((err) => {
          this.setState({
            errors: err.response ? err.response.data.errors : err,
            isLoading: false,
          });
        });
    }
  }

  onSelectFolder = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value == "New Folder" ? "" : value,
      isNewFolder: value == "New Folder",
    });
  };

  onFinish = (values) => {
    const {
      workbookType,
      createWorkbook,
      updateUserWorkbook,
      toggleModal,
      workbook,
    } = this.props;

    if (workbookType == "edit") {
      updateUserWorkbook(workbook._id, values)
        .then((res) => {
          toggleModal();
        })
        .catch((err) => {
          this.setState({
            errors: err.response ? err.response.data.errors : err,
            isLoading: false,
          });
        });
    } else {
      createWorkbook({ ...values, type: workbookType })
        .then((res) => {
          this.setState({
            name: "",
            subject: "",
            description: "",
            folder: "",
            isLoading: false,
          });
          if (workbookType == "lecture") {
            this.context.router.push({
              pathname: "/lecture",
              query: { id: res.data.book._id, sessionId: res.data.sessionId },
            });
          } else {
            window.location.href = `/workbook?id=${res.data.book._id}`;
          }
          toggleModal();
        })
        .catch((err) => {
          this.setState({
            errors: err.response ? err.response.data.errors : err,
            isLoading: false,
          });
        });
    }
  };

  render() {
    const {
      errors,
      name,
      subject,
      folder,
      description,
      foldersList,
    } = this.state;
    const { title, toggleModal, workbookType } = this.props;
    const { intl } = this.context;

    const buttonText =
      workbookType == "workbook" ? (
        <FormattedMessage {...messages.createWorkbook} />
      ) : workbookType == "edit" ? (
        <FormattedMessage {...messages.updateWorkbook} />
      ) : (
        <FormattedMessage {...messages.startLecture} />
      );

    if (workbookType === "lecture") {
      return null;
    }

    return (
      <Modal
        title={title}
        visible={this.props.visible}
        okText={buttonText}
        okButtonProps={{
          form: "cwbForm",
          key: "submit",
          htmlType: "submit",
          size: "large",
        }}
        onCancel={toggleModal}
        cancelButtonProps={{
          size: "large",
        }}
      >
        <Form
          name="cwbForm"
          size="large"
          onFinish={this.onFinish}
          ref={this.formRef}
          initialValues={{ name, subject, folder, description }}
        >
          {errors.form && <Alert message={errors.form} type="error" />}

          <Form.Item
            name="name"
            {...(errors.name && {
              help: errors.name,
              validateStatus: "error",
            })}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input
              placeholder={intl.formatMessage({ ...messages.workbookName })}
            />
          </Form.Item>

          <Form.Item
            name="subject"
            {...(errors.subject && {
              help: errors.subject,
              validateStatus: "error",
            })}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input
              placeholder={intl.formatMessage({ ...messages.workbookSubject })}
            />
          </Form.Item>
          <Form.Item
            name="folder"
            {...(errors.folder && {
              help: errors.folder,
              validateStatus: "error",
            })}
            normalize={(value) => {
              return `${(value || "").replace(/[^a-zA-Z0-9-_ ]/g, "")}`;
            }}
            rules={[{ required: true, message: "This is required" }]}
          >
            <AutoComplete
              options={autoCompleteOptions(foldersList)}
              placeholder="Select or Add Folder name"
              filterOption={(inputValue, option) => {
                return _.find(autoCompleteOptions(foldersList), (item) => {
                  return item?.value.toLowerCase() === inputValue.toLowerCase();
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name="description"
            {...(errors.description && {
              help: errors.description,
              validateStatus: "error",
            })}
            rules={[{ required: false }]}
          >
            <Input.TextArea
              row={4}
              placeholder={intl.formatMessage({ ...messages.description })}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

NewLecture.propTypes = {
  createWorkbook: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  updateUserWorkbook: PropTypes.func.isRequired,
  workbookType: PropTypes.string.isRequired,
  workbook: PropTypes.object,
};

NewLecture.contextTypes = {
  router: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { createWorkbook, updateUserWorkbook })(
  NewLecture
);
