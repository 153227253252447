
import React from "react";
import ReactDOM from "react-dom";

function WordPuzzle() {
    return <iframe src={"/wordpuzzle"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenWordPuzzle(location) { ReactDOM.render(<WordPuzzle />, document.getElementById(location)); }

function CompleteTheSequence() {
    return <iframe src={"/completethesequence"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenCompleteTheSequence(location) { ReactDOM.render(<CompleteTheSequence />, document.getElementById(location)); }

function SolveMath() {
    return <iframe src={"/solvemath"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenSolveMath(location) { ReactDOM.render(<SolveMath />, document.getElementById(location)); }

function MathBoy() {
    return <iframe src={"/mathboy"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenMathBoy(location) { ReactDOM.render(<MathBoy />, document.getElementById(location)); }

function SnakeAndLadders() {
    return <iframe src={"/snakesandladder"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenSnakeAndLadders(location) { ReactDOM.render(<SnakeAndLadders />, document.getElementById(location)); }

function Piano() {
    return <iframe src={"/virtualpiano"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenPiano(location) { ReactDOM.render(<Piano />, document.getElementById(location)); }

function Addition() {
    return <iframe src={"/games/addition/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenAddition(location) { ReactDOM.render(<Addition />, document.getElementById(location)); }

function CountCars() {
    return <iframe src={"/games/countCars/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenCountCars(location) { ReactDOM.render(<CountCars />, document.getElementById(location)); }

function CountFingers() {
    return <iframe src={"/games/countFingers/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenCountFingers(location) { ReactDOM.render(<CountFingers />, document.getElementById(location)); }

function Division() {
    return <iframe src={"/games/division/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenDivision(location) { ReactDOM.render(<Division />, document.getElementById(location)); }

function EvenOdd() {
    return <iframe src={"/games/evenOdd/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenEvenOdd(location) { ReactDOM.render(<EvenOdd />, document.getElementById(location)); }

function EvenOddPop() {
    return <iframe src={"/games/evenOddPop/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenEvenOddPop(location) { ReactDOM.render(<EvenOddPop />, document.getElementById(location)); }

function EvenOddShip() {
    return <iframe src={"/games/evenOddShip/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenEvenOddShip(location) { ReactDOM.render(<EvenOddShip />, document.getElementById(location)); }

function FoodCount() {
    return <iframe src={"/games/foodCount/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenFoodCount(location) { ReactDOM.render(<FoodCount />, document.getElementById(location)); }

function FreeWords() {
    return <iframe src={"/games/freeWords/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenFreeWords(location) { ReactDOM.render(<FreeWords />, document.getElementById(location)); }

function GolfSport() {
    return <iframe src={"/games/golfSport/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenGolfSport(location) { ReactDOM.render(<GolfSport />, document.getElementById(location)); }

function LetsReach1000() {
    return <iframe src={"/games/letsReach1000/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenLetsReach1000(location) { ReactDOM.render(<LetsReach1000 />, document.getElementById(location)); }

function LetsReach1000ASAP() {
    return <iframe src={"/games/letsReach1000ASAP/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenLetsReach1000ASAP(location) { ReactDOM.render(<LetsReach1000ASAP />, document.getElementById(location)); }

function LetsReachZero() {
    return <iframe src={"/games/letsReachZero/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenLetsReachZero(location) { ReactDOM.render(<LetsReachZero />, document.getElementById(location)); }

function LetsReachZeroASAP() {
    return <iframe src={"/games/letsReachZeroASAP/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenLetsReachZeroASAP(location) { ReactDOM.render(<LetsReachZeroASAP />, document.getElementById(location)); }

function MagicWords() {
    return <iframe src={"/games/magicWords/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenMagicWords(location) { ReactDOM.render(<MagicWords />, document.getElementById(location)); }

function MathsTrueorFalse() {
    return <iframe src={"/games/mathsTrueorFalse/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenMathsTrueorFalse(location) { ReactDOM.render(<MathsTrueorFalse />, document.getElementById(location)); }

function MissingNumber() {
    return <iframe src={"/games/missingNumber/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenMissingNumber(location) { ReactDOM.render(<MissingNumber />, document.getElementById(location)); }

function Multiplication() {
    return <iframe src={"/games/multiplication/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenMultiplication(location) { ReactDOM.render(<Multiplication />, document.getElementById(location)); }

function NumbersMemory() {
    return <iframe src={"/games/numbersMemory/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenNumbersMemory(location) { ReactDOM.render(<NumbersMemory />, document.getElementById(location)); }

function PickTheLock() {
    return <iframe src={"/games/pickTheLock/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenPickTheLock(location) { ReactDOM.render(<PickTheLock />, document.getElementById(location)); }

function PiggyBank() {
    return <iframe src={"/games/piggyBank/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenPiggyBank(location) { ReactDOM.render(<PiggyBank />, document.getElementById(location)); }

function Pool8ball() {
    return <iframe src={"/games/pool8ball/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenPool8ball(location) { ReactDOM.render(<Pool8ball />, document.getElementById(location)); }

function QuizMath() {
    return <iframe src={"/games/quizMath/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenQuizMath(location) { ReactDOM.render(<QuizMath />, document.getElementById(location)); }

function Subtraction() {
    return <iframe src={"/games/subtraction/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenSubtraction(location) { ReactDOM.render(<Subtraction />, document.getElementById(location)); }

function TextMaths() {
    return <iframe src={"/games/textMaths/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenTextMaths(location) { ReactDOM.render(<TextMaths />, document.getElementById(location)); }

function TextMathsNegative() {
    return <iframe src={"/games/textMathsNegative/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenTextMathsNegative(location) { ReactDOM.render(<TextMathsNegative />, document.getElementById(location)); }

function WaffleWord() {
    return <iframe src={"/games/waffleWord/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenWaffleWord(location) { ReactDOM.render(<WaffleWord />, document.getElementById(location)); }

function WordMaker() {
    return <iframe src={"/games/wordMaker/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenWordMaker(location) { ReactDOM.render(<WordMaker />, document.getElementById(location)); }

function WordUp() {
    return <iframe src={"/games/wordUp/"} style={{ width: "100%", height: "100%" }} />;
}
function GoldenWordUp(location) { ReactDOM.render(<WordUp />, document.getElementById(location)); }


function Language_rearrangeLetters2() {
    return <iframe src={"/games/Language_rearrangeLetters2/"} style={{ width: "100%", height: "100%" }} />;
}
function Golden_Language_rearrangeLetters2(location) { ReactDOM.render(<Language_rearrangeLetters2 />, document.getElementById(location)); }


function Language_wordFinder() {
    return <iframe src={"/games/Language_wordFinder/"} style={{ width: "100%", height: "100%" }} />;
}
function Golden_Language_wordFinder(location) { ReactDOM.render(<Language_wordFinder />, document.getElementById(location)); }


function Language_quiz2() {
    return <iframe src={"/games/Language_quiz2/"} style={{ width: "100%", height: "100%" }} />;
}
function Golden_Language_quiz2(location) { ReactDOM.render(<Language_quiz2 />, document.getElementById(location)); }


function Math_genius2() {
    return <iframe src={"/games/Math_genius2/"} style={{ width: "100%", height: "100%" }} />;
}
function Golden_Math_genius2(location) { ReactDOM.render(<Math_genius2 />, document.getElementById(location)); }

function Math_guessHowMany() {
    return <iframe src={"/games/Math_guessHowMany/"} style={{ width: "100%", height: "100%" }} />;
}
function Golden_Math_guessHowMany(location) { ReactDOM.render(<Math_guessHowMany />, document.getElementById(location)); }

function Math_WhatIsInside() {
    return <iframe src={"/games/Math_WhatIsInside/"} style={{ width: "100%", height: "100%" }} />;
}
function Golden_Math_WhatIsInside(location) { ReactDOM.render(<Math_WhatIsInside />, document.getElementById(location)); }


function Geography_unitedStatesMapGame50States() {
    return <iframe src={"/games/Geography_unitedStatesMapGame50States/"} style={{ width: "100%", height: "100%" }} />;
}
function Golden_Geography_unitedStatesMapGame50States(location) { ReactDOM.render(<Geography_unitedStatesMapGame50States />, document.getElementById(location)); }


function PreK_coloringBook() {
    return <iframe src={"/games/PreK_coloringBook/"} style={{ width: "100%", height: "100%" }} />;
}
function Golden_PreK_coloringBook(location) { ReactDOM.render(<PreK_coloringBook />, document.getElementById(location)); }


function PreK_memoryGame() {
    return <iframe src={"/games/PreK_memoryGame/"} style={{ width: "100%", height: "100%" }} />;
}
function Golden_PreK_memoryGame(location) { ReactDOM.render(<PreK_memoryGame />, document.getElementById(location)); }

export {
    GoldenWordPuzzle,
    GoldenCompleteTheSequence,
    GoldenSolveMath,
    GoldenMathBoy,
    GoldenSnakeAndLadders,
    GoldenPiano,

    GoldenAddition,
    GoldenCountCars,
    GoldenCountFingers,
    GoldenDivision,
    GoldenEvenOdd,
    GoldenEvenOddPop,
    GoldenEvenOddShip,
    GoldenFoodCount,
    GoldenFreeWords,
    GoldenGolfSport,
    GoldenLetsReach1000,
    GoldenLetsReach1000ASAP,
    GoldenLetsReachZero,
    GoldenLetsReachZeroASAP,
    GoldenMagicWords,
    GoldenMathsTrueorFalse,
    GoldenMissingNumber,
    GoldenMultiplication,
    GoldenNumbersMemory,
    GoldenPickTheLock,
    GoldenPiggyBank,
    GoldenPool8ball,
    GoldenQuizMath,
    GoldenSubtraction,
    GoldenTextMaths,
    GoldenTextMathsNegative,
    GoldenWaffleWord,
    GoldenWordMaker,
    GoldenWordUp,

    Golden_Language_rearrangeLetters2,
    Golden_Language_wordFinder,
    Golden_Language_quiz2,
    Golden_Math_genius2,
    Golden_Math_guessHowMany,
    Golden_Math_WhatIsInside,
    Golden_Geography_unitedStatesMapGame50States,
    Golden_PreK_coloringBook,
    Golden_PreK_memoryGame


};

