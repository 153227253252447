import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import socketIOClient from "socket.io-client";
import DropZone from "react-dropzone";
import QRCode from "qrcode.react";
import Draggable from "react-draggable";
import { ScreenCapture } from "react-screen-capture";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import { OpenVidu } from "openvidu-browser";
import map from "lodash/map";
import { SketchPicker, CirclePicker } from "react-color";
import TextFieldGroup from "../common/TextFieldGroup";
import GallerySearch from "./GallerySearch";
import { Tools } from "../drawing";
import PopupTypeTwo from "../common/PopupTypeTwo";

import {
  getWorkbookDetails,
  exportUserWorkbook,
  getDocDetails,
  saveAnnotation,
  getSessionId,
  addEmptySlide,
} from "../../actions/workbook";
import { getUserProfile } from "../../actions/users";
import {
  saveWorkbook,
  addSlideToEnd,
  getWorkbook,
} from "../../actions/workbookActions";
import { canvasImageToBase64 } from "../../actions/galleryActions";

import { getToken } from "../../actions/openviduActions";
import { addFlashMessage } from "../../actions/flashMessages.js";

import s from "./LecturePage.module.css";
import LectureTopBar from "./LectureTopBar";
import LectureCanvas from "./LectureCanvas";
import LectureTools from "./LectureTools";
import GroupChat from "./GroupChat";
import OpenViduVideoComponent from "./OpenVidu/OvVideo";
import { FormattedMessage } from "react-intl";
import messages from "../../messages";
import {
  Popover,
  Popconfirm,
  Slider,
  Space,
  Button,
  Tooltip,
  message,
  Spin,
  Badge,
  Layout,
} from "antd";
import {
  createFromIconfontCN,
  ArrowRightOutlined,
  FileAddOutlined,
  FundProjectionScreenOutlined,
  PlayCircleOutlined,
  SaveOutlined,
  UsergroupAddOutlined,
  ExpandOutlined,
  DragOutlined,
  FontSizeOutlined,
  HighlightOutlined,
  SelectOutlined,
  VideoCameraOutlined,
  ZoomInOutlined,
  SearchOutlined,
  ZoomOutOutlined,
  PhoneOutlined,
  StopOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import CustomScrollbars from "../../utils/CustomScrollbars";
import CKEditor from "../workbook/CKEditor";
import InvitationPage from "../dashboard/InvitationPage";
import UppyPage from "../workbook/UppyPage";
import Topbar from "./Topbar";
import Settings from "./Settings";
import CastPopup from "./CastPopup";
import Sidebar from "./Sidebar";
import { isMobile } from "react-device-detect";

const CloudIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2263175_tkn0h9jacf.js", // 在 iconfont.cn 上生成
});

const DragHandle = SortableHandle(({ item }) => (
  <img
    src={`${item.path}?${+(+new Date())}`}
    onError={(e) => {
      e.target.onerror = null;
      e.target.src = `${item.thumbnail}`;
    }}
  />
));

const SortableItem = SortableElement(
  ({ count, item, removeSlide, previewSlide }) => (
    <li className={s["at-slide"]}>
      <figure className={s["at-slideimg"]}>
        <Popconfirm
          okText="Yes"
          placement="topRight"
          cancelText="No"
          onConfirm={() => removeSlide(count)}
          title={"Are you sure you want to remove this slide?"}
        >
          <a className={s["at-btncloseslide"]} href="javascript:void(0)">
            <i className="fa fa-close" />
          </a>
        </Popconfirm>
        <DragHandle item={item} />
      </figure>
      <div className={s["at-btnviewname"]}>
        <span>{item.name}</span>
        <a
          onClick={previewSlide.bind(this, count)}
          className={s["at-btnview"]}
          href="javascript:void(0)"
        >
          <img src="images/view-btn-icon.png" />
        </a>
      </div>
    </li>
  )
);

const SortableList = SortableContainer(
  ({ items, removeSlide, previewSlide }) => (
    <div className={s["at-slidesholder"]}>
      <ul>
        {items.map((item, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            item={item}
            count={index}
            removeSlide={removeSlide}
            previewSlide={previewSlide}
          />
        ))}
      </ul>
    </div>
  )
);

class LecturePage extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    getUserProfile: PropTypes.func.isRequired,
    getWorkbookDetails: PropTypes.func.isRequired,
    exportUserWorkbook: PropTypes.func.isRequired,
    canvasImageToBase64: PropTypes.func.isRequired,
    addSlideToEnd: PropTypes.func.isRequired,
    saveWorkbook: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      workbook: {},
      isLoading: false,
      alertBox: {
        showAlert: false,
        alertType: "endLecture",
        alertMessage: "Agreed ?",
      },

      flags: {
        mainTools: true,
        comparePopup: false,
      },

      subscribers: [],

      slides: [],
      slideHandler: {
        currentSlide: -1,
        totalSlides: 0,
        isDirty: 0,
        preview: true,
      },
      sessionId: "",

      multiPageDoc: {
        current: -1,
        totalSlides: 0,
        details: [],
      },
      tool: Tools.Select,
      videoCall: false,
      imgGalleryFlag: false,

      publisher: null,
      unMutedStudent: [],
      requestFromStudent: [],
      studentList: [],
      accessToStudent: [],
      compareArraySubscribers: [],
      compareArray: [],
      comparePopupFlag: false,
      lectureToolsFlag: true,
      isVideoPaused: false,
      isAudioPaused: true,
      screenShare: false,
      navTypeAfterSave: null,
      isRecording: false,
      recordingState: "",
      showSetting: false,

      //join session flags
      pause: false,
      studentNavigation: true,
      showColors: false,
      compareAll: false,
      isComparePopupEnabled: false,
      isNotificationPopupEnabled: false,
      showImageUplaoder: false,
      workbookPdfName: "",
      pdfNamePopupFlag: false,
      studentLiveImageSearch: true,
      studentSettings: true,
      toolbarSize: "md",

      // Sketch Props
      lineWidth: 3,
      fontSize: 20,
      fontName: "Agency FB",
      lineColor: "black",
      fillColor: "transparent",
      toolsPosition: "both",
    };
  }

  componentWillMount = () => {
    const { location } = this.props;
    const { sessionId } = location.query;

    this.setState({ sessionId });
  };
  componentDidMount = () => {
    if (localStorage.getItem("toolbarSize")) {
      this.setState({ toolbarSize: localStorage.getItem("toolbarSize") });
    }

    if (localStorage.getItem("toolsPosition")) {
      this.setState({ toolsPosition: localStorage.getItem("toolsPosition") });
    }

    this.getWorkbookDetails();
    this.handleSocketIO();

    const { getUserProfile } = this.props;
    this.setState({ isLoading: true });
    getUserProfile()
      .then((res) => {
        const { user } = res.data;
        this.setState({
          isLoading: false,
          lectureToolsFlag: user.lectureTools,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        addFlashMessage({
          type: "error",
          text:
            "Oops, something went wrong while fetching data from the server",
        });

        console.log(err);
      });
    this.OV = new OpenVidu();

    this.teacherStreamResizHandler.addEventListener(
      "mousedown",
      this.initTeacherStreamResizeDrag,
      false
    );
    this.teacherStreamResizHandler.addEventListener(
      "touchstart",
      this.initTeacherStreamResizeDrag,
      false
    );

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // dev code
      this.createSession();
    } else {
      this.createSession();
      window.onbeforeunload = "confirmOnPageExit";
      window.addEventListener("beforeunload", this.exitConfirmation);
      this.promptUnsavedChange(true);
    }
  };
  promptUnsavedChange = (
    isUnsaved = false,
    leaveMessage = "Are you sure you want to exit the session?"
  ) => {
    // Detecting page transition (prevent leaving by setting true)
    // for React Router version > 2.4
    this.props.router.setRouteLeaveHook(
      this.props.route,
      () => isUnsaved && confirm(leaveMessage)
    );

    // Detecting browser close
    window.onbeforeunload = isUnsaved && (() => leaveMessage);
  };
  // exitConfirmation = (e) => {
  //   var confirmationMessage = 'It looks like you have been editing something. '
  //     + 'If you leave before saving, your changes will be lost.';

  //   e.returnValue = confirmationMessage; //Gecko + IE
  //   return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  // }

  initTeacherStreamResizeDrag = (e) => {
    var ev = /touch/.test(e.type) ? e.targetTouches[0] : e;
    this.startX = ev.clientX;
    this.startY = ev.clientY;

    this.startWidth = parseInt(
      document.defaultView.getComputedStyle(this.teacherStreamContainer).width,
      10
    );
    this.startHeight = parseInt(
      document.defaultView.getComputedStyle(this.teacherStreamContainer).height,
      10
    );
    document.documentElement.addEventListener("mousemove", this.doDrag, false);
    document.documentElement.addEventListener("touchmove", this.doDrag, false);
    document.documentElement.addEventListener("mouseup", this.stopDrag, false);
    document.documentElement.addEventListener("touchend", this.stopDrag, false);
  };
  doDrag = (e) => {
    var ev = /touch/.test(e.type) ? e.targetTouches[0] : e;
    let width = this.startWidth + ev.clientX - this.startX;
    this.teacherStreamContainer.style.width = width + "px";
  };

  stopDrag = (e) => {
    document.documentElement.removeEventListener(
      "mousemove",
      this.doDrag,
      false
    );
    document.documentElement.removeEventListener(
      "touchmove",
      this.doDrag,
      false
    );
    document.documentElement.removeEventListener(
      "mouseup",
      this.stopDrag,
      false
    );
    document.documentElement.removeEventListener(
      "touchend",
      this.stopDrag,
      false
    );
  };

  componentWillUnmount = () => {
    this.leaveSession();
    window.onbeforeunload = null;
    // window.removeEventListener("beforeunload", this.exitConfirmation);
  };
  restartLecture = async () => {
    const { getSessionId } = this.props;
    const { workbook } = this.state;
    let result = await getSessionId();
    const { sessionId } = result.data;
    this.setState({ sessionId });
    window.open(
      `${process.env.REACT_APP_API_HOST}/lecture?id=${workbook._id}&sessionId=${sessionId}`,
      "_self"
    );
  };

  handleSocketIO = () => {
    const { sessionId } = this.state;
    const { auth } = this.props;
    const { email } = auth.user;
    this.socket = socketIOClient(`${process.env.REACT_APP_API_HOST}`, {
      transports: ["websocket"],
      query: `sessionId=${sessionId}&role=Teacher&username=${email}`,
    });
    this.socket.on("connect", (_socket) => {
      this.socket.emit("createRoom", sessionId);

      this.socket.on("galleryImageLink", (data) => {
        this.studentImage(data.filePath);
      });

      this.socket.on("studentLeft", (data) => {
        const accessToStudent = this.state.accessToStudent;
        const requestFromStudent = this.state.requestFromStudent;
        const studentList = this.state.studentList;

        let atsindex = accessToStudent.indexOf(data.email);
        let rfsindex = requestFromStudent.indexOf(data.email);
        let slindex = studentList.indexOf(data.email);
        if (atsindex > -1) {
          accessToStudent.splice(atsindex, 1);
          this.setState({ accessToStudent });
        }

        if (rfsindex > -1) {
          requestFromStudent.splice(rfsindex, 1);
          this.setState({ requestFromStudent });
        }

        if (slindex > -1) {
          studentList.splice(slindex, 1);
          this.setState({ studentList });
        }
      });

      this.socket.on("newStudentJoinLecture", (data) => {
        const {
          sessionId,
          workbook,
          slides,
          lectureToolsFlag,
          slideHandler,
        } = this.state;
        const student = this.state.studentList;
        student.push(data.email);
        this.setState({ studentList: [...new Set(student)] });
        this._canvasToImage();

        this.socket.emit("object:workbook", {
          sessionId: sessionId,
          workbook: workbook,
          lectureToolsFlag: lectureToolsFlag,
          slideHandler,
        });
      });

      this.socket.on("syncStudent", (data) => {
        var dataURL = this._canvas._sketch.toJSON(["width", "height"]);

        // if (this.state.fileType == "mp4") {
        //   var video = document.getElementById("videoCanvas");
        //   dataURL.currentTime = video.currentTime;
        // }

        dataURL = JSON.stringify(dataURL);

        this.socket.emit("syncStudent_join", {
          sessionId: this.state.sessionId,
          email: data.email,
          dataURL: dataURL,
        });
      });

      this.socket.on("studentRequstForAcess", (data) => {
        const type = data.type;
        if (type === "revoke") {
          this.grantAcess(data.email);
        } else if (type === "grant") {
          const student = this.state.requestFromStudent;
          student.push(data.email);
          this.setState({ requestFromStudent: [...new Set(student)] }, () => {
            let s = this.state.studentList;
            s.sort(function (a, b) {
              return s.indexOf(a) < s.indexOf(b);
            });

            this.setState({ studentList: s });
          });
        }
      });

      this.socket.on("drawingByStudent", (data) => {
        if (this.state.accessToStudent.indexOf(data.email) > -1) {
          if (
            data.dataUri.type == "json" &&
            data.dataUri.renderType !== "sendFull"
          ) {
            this._canvas._loadFromJSONStream(data.dataUri.object);
          } else if (
            data.dataUri.type == "json" &&
            data.dataUri.renderType === "sendFull"
          ) {
            this._canvas._sketch.fromJSON(
              data.dataUri.object,
              () => {},
              data.email
            );
          } else {
            this._canvas._sketch.loadObjectStream(data.dataUri, data.email);
            setTimeout(() => {
              this._canvasToImage(data.email);
            }, 2000);
          }
        }
      });

      this.socket.on("drawingModifiedByStudent", (data) => {
        if (this.state.accessToStudent.indexOf(data.email) > -1) {
          // debugger;
          this._canvas._sketch.fromJSON(
            JSON.parse(data.source.dataURL),
            data.email
          );
          // setTimeout(() => {
          //   this._canvasToImage(data.email)
          // }, 2000)
        }
      });

      this.socket.on("exportPdf", (data) => {
        // const email = data;
        // const lectureId = this.props.workbookId;
        // this.props.downloadPDF({ lectureId }).then(res => {
        //   let pdfLink = '';
        //   let _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);
        //   if (_iOSDevice) {
        //     pdfLink = `https://app.contribute.cloud/lecture-attachments/lectureSlide-${lectureId}.pdf`;
        //   } else {
        //     pdfLink = `https://app.contribute.cloud/api/workbook/download/${lectureId}`;
        //   }
        //   this.socket.emit("pdfLink", {
        //     sessionId: this.props.sessionId,
        //     pdfLink,
        //     email
        //   });
        // });
      });
    });
  };

  grantAcess = (email) => {
    const { sessionId, workbook, slides, lectureToolsFlag } = this.state;
    const student = this.state.accessToStudent;

    const index = student.indexOf(email);
    if (index > -1) {
      student.splice(index, 1);
      this.socket.emit("granntAccessToStudent", {
        granderStudent: student,
        sessionId: sessionId,
      });

      const requestFromStudent = this.state.requestFromStudent;
      const emailIndex = requestFromStudent.indexOf(email);
      requestFromStudent.splice(index, 1);
      this.setState({ requestFromStudent });
    } else {
      student.push(email);
      this.socket.emit("granntAccessToStudent", {
        granderStudent: [...new Set(student)],
        sessionId: sessionId,
      });

      const requestFromStudent = this.state.requestFromStudent;
      requestFromStudent.push(email);
      this.setState({ requestFromStudent: [...new Set(requestFromStudent)] });
    }
    this.setState({
      accessToStudent: [...new Set(student)],
    });
  };

  _canvasToImage = async (email) => {
    if (!this._canvas || (this._canvas && !this._canvas._sketch)) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    if (!this._canvas || !this._canvas._sketch) {
      return true;
    }

    let dataURL = this._canvas._sketch.toJSON(["width", "height"]);

    if (this._canvas._video) {
      dataURL.currentTime = this._canvas._video.currentTime;
    }

    dataURL = JSON.stringify(dataURL);

    this.socket.emit("object:slide", {
      source: { dataURL, email, slideHandler: this.state.slideHandler },
      sessionId: this.state.sessionId,
    });
  };

  _grantAcess = (email) => {
    const student = this.state.accessToStudent;

    const index = student.indexOf(email);
    if (index > -1) {
      student.splice(index, 1);
      this.socket.emit("granntAccessToStudent", {
        granderStudent: student,
        sessionId: this.state.sessionId,
      });

      const requestFromStudent = this.state.requestFromStudent;
      requestFromStudent.splice(index, 1);
      this.setState({ requestFromStudent });
    } else {
      student.push(email);
      this.socket.emit("granntAccessToStudent", {
        granderStudent: [...new Set(student)],
        sessionId: this.state.sessionId,
      });

      const requestFromStudent = this.state.requestFromStudent;
      requestFromStudent.splice(index, 1);
      this.setState({ requestFromStudent });
    }
    this.setState({
      accessToStudent: [...new Set(student)],
    });
  };

  getWorkbookDetails = () => {
    const {
      getWorkbookDetails,
      addEmptySlide,
      addFlashMessage,
      location,
    } = this.props;
    const { id, sessionId } = location.query;
    this.setState({ sessionId, isLoading: true });

    getWorkbookDetails(id)
      .then((res) => {
        const { message, workbook } = res.data;
        this.setState({
          isLoading: false,
          workbook,
          slides: workbook.slide,
          slideHandler: {
            ...this.state.slideHandler,
            currentSlide: 0,
            totalSlides: workbook.slide.length,
          },
        });

        if (!workbook.slide.length) {
          this.setState({ isLoading: true });
          addEmptySlide({ workbookId: workbook._id, currentSlide: -1 })
            .then((res) => {
              this.setState({ isLoading: false });
              addFlashMessage({
                type: "success",
                text: "Whiteboard is added, next to current slide",
              });

              let savedWorkbook = res.data.workbook;

              this.setState(
                {
                  isLoading: false,
                  workbook: savedWorkbook,
                  slides: savedWorkbook.slide,
                  slideHandler: {
                    ...this.state.slideHandler,
                    currentSlide: 0,
                    totalSlides: savedWorkbook.slide.length,
                  },
                },
                () => {
                  this.navigateSlide("refresh");
                }
              );
            })
            .catch((error) => {
              console.error(error);
              this.setState({ isLoading: false });
              addFlashMessage({
                type: "error",
                text:
                  "ooops, not sure what went wrong then. Please try again in a couple of minutes. Please try refreshing your page",
              });
            });
        } else {
          this._canvas.slideHandler(workbook.slide[0]);
        }

        addFlashMessage({
          type: "success",
          text: message || "Lecture slides imported sucessfully.",
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
        addFlashMessage({
          type: "error",
          text:
            "Oops, something went wrong while fetching data from the server",
        });
      });
  };

  createSession = () => {
    const { getToken, addFlashMessage, location, auth } = this.props;
    const { sessionId } = location.query;

    this.OV = new OpenVidu();
    this.OV.setAdvancedConfiguration({
      screenShareChromeExtension:
        "https://chrome.google.com/webstore/detail/contribute-cast/gbmlmheknojbpmagkgfcjlalpmeiflko",
    });

    this.session = this.OV.initSession();

    this.session.on("streamCreated", (event) => {
      let subscriber = this.session.subscribe(event.stream, undefined);

      this.setState({ subscribers: [...this.state.subscribers, subscriber] });
    });

    this.session.on("streamDestroyed", (event) => {
      this.deleteSubscriber(event.stream.streamManager);
    });

    getToken({ sessionId })
      .then((res) => {
        const { token, username } = res.data;
        this.session
          .connect(token, { clientData: `${username} - Teacher` })
          .then(() => {
            // let publisher = this.OV.initPublisher(undefined, {
            //   audioSource: undefined, // The source of audio. If undefined default microphone
            //   videoSource: undefined, // The source of video. If undefined default webcam
            //   publishAudio: true,     // Whether you want to start publishing with your audio unmuted or not
            //   publishVideo: true,     // Whether you want to start publishing with your video enabled or not
            //   resolution: '640x480',  // The resolution of your video
            //   frameRate: 30,          // The frame rate of your video
            //   insertMode: 'APPEND',   // How the video is inserted in the target element 'video-container'
            //   mirror: false           // Whether to mirror your local video or not
            // });
            // this.session.publish(publisher);
            // this.publisher = publisher;
          })
          .catch((error) => {
            addFlashMessage({
              type: "error",
              text:
                error.message ||
                "There was an error connecting to the session.",
            });
          });
      })
      .catch((error) => {
        const { message } = error.response.data;
        addFlashMessage({
          type: "error",
          text: message || "There was an error while getting session token.",
        });
      });
  };

  leaveSession() {
    if (this.session) {
      this.session.disconnect();
    }

    this.setState({ subscribers: [] });

    delete this.publisher;
    delete this.session;
    delete this.OV;
  }

  deleteSubscriber = (streamManager) => {
    const { subscribers } = this.state;
    let index = subscribers.indexOf(streamManager, 0);
    if (index > -1) {
      subscribers.splice(index, 1);
    }

    this.setState({ subscribers });
  };

  manageTecherStream = (type) => {
    const { publisher, screenShare, isVideoPaused, isAudioPaused } = this.state;

    switch (type) {
      case "call":
        if (!publisher) {
          let localPublisher = this.OV.initPublisher(undefined, {
            audioSource: undefined, // The source of audio. If undefined default microphone
            videoSource: undefined, // The source of video. If undefined default webcam
            publishAudio: !isAudioPaused, // Whether you want to start publishing with your audio unmuted or not
            publishVideo: !isVideoPaused, // Whether you want to start publishing with your video enabled or not
            resolution: "640x480", // The resolution of your video
            frameRate: 25, // The frame rate of your video
            insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
            mirror: false, // Whether to mirror your local video or not
          });

          this.session.publish(localPublisher);
          localPublisher.addVideoElement(this.teacherStream);
          this.setState({ publisher: localPublisher });
          // this.setState({ subscribers: [...this.state.subscribers, localPublisher, localPublisher, localPublisher, localPublisher, localPublisher] })
        }
        break;
      case "audio":
        publisher.publishAudio(!publisher.stream.audioActive);
        this.setState({ isAudioPaused: !publisher.stream.audioActive });
        break;
      case "screen":
        if (publisher.properties.videoSource !== "screen") {
          let localPublisher = this.OV.initPublisher(undefined, {
            audioSource: undefined, // The source of audio. If undefined default microphone
            videoSource: "screen", // The source of video. If undefined default webcam
            publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
            publishVideo: true, // Whether you want to start publishing with your video enabled or not
            resolution: "640x480", // The resolution of your video
            frameRate: 25, // The frame rate of your video
            insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
            mirror: false, // Whether to mirror your local video or not
            screen: true, // Whether to mirror your local video or not
          });
          this.session.unpublish(publisher);

          this.session.publish(localPublisher);
          localPublisher.addVideoElement(this.teacherStream);
          this.setState({ publisher: localPublisher, screenShare: true });
        } else {
          this.session.unpublish(publisher);
          this.setState({ publisher: null, screenShare: false }, () => {
            this.manageTecherStream("call");
          });
        }
        break;
      case "video":
        publisher.publishVideo(!publisher.stream.videoActive);

        this.setState({ isVideoPaused: !publisher.stream.videoActive });
        break;
      case "end":
        this.session.unpublish(publisher);
        this.setState({ publisher: null });
        break;
      default:
        console.error(
          "switch case not handled in function manageTecherStream",
          type
        );
    }

    // if (!this.publisher) {
    //   let publisher = this.OV.initPublisher(undefined, {
    //     audioSource: undefined, // The source of audio. If undefined default microphone
    //     videoSource: undefined, // The source of video. If undefined default webcam
    //     publishAudio: true,     // Whether you want to start publishing with your audio unmuted or not
    //     publishVideo: true,     // Whether you want to start publishing with your video enabled or not
    //     resolution: '640x480',  // The resolution of your video
    //     frameRate: 30,          // The frame rate of your video
    //     insertMode: 'APPEND',   // How the video is inserted in the target element 'video-container'
    //     mirror: false           // Whether to mirror your local video or not
    //   });

    //   this.session.publish(publisher);
    //   this.publisher = publisher;
    //   this.publisher.addVideoElement(this.teacherStream)
    // } else {
    //   this.session.unpublish(this.publisher);
    //   this.publisher = null;
    // }
  };

  lectureRecording = (type) => {
    const { addFlashMessage } = this.props;

    switch (type) {
      case "start":
        if (!this.localRecorder) {
          let localRecorder = this.OV.initPublisher(
            undefined,
            {
              audioSource: undefined, // The source of audio. If undefined default microphone
              videoSource: "screen", // The source of video. If undefined default webcam
              publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
              publishVideo: true, // Whether you want to start publishing with your video enabled or not
              resolution: "640x480", // The resolution of your video
              frameRate: 30, // The frame rate of your video
              insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
              mirror: false, // Whether to mirror your local video or not
              screen: true,
            },
            (error) => {
              if (error) {
                if (error.name == "SCREEN_EXTENSION_NOT_INSTALLED") {
                  addFlashMessage({
                    type: "error",
                    text:
                      error.message ||
                      "Install chrome extention for lecture recording.",
                  });
                } else if (error.name == "SCREEN_SHARING_NOT_SUPPORTED") {
                  addFlashMessage({
                    type: "error",
                    text: "Your browser does not support screen sharing",
                  });
                } else if (error.name == "SCREEN_EXTENSION_DISABLED") {
                  addFlashMessage({
                    type: "error",
                    text: "You need to enable screen sharing extension.",
                  });
                } else if (error.name == "SCREEN_CAPTURE_DENIED") {
                  addFlashMessage({
                    type: "error",
                    text:
                      "You need to choose a window or application to share.",
                  });
                }
              } else {
                this.localRecorder = this.OV.initLocalRecorder(
                  localRecorder.stream
                );
                this.localRecorder.record();
                this.setState({
                  isRecording: true,
                  recordingState: "SCREEN RECORDING",
                });
              }
            }
          );
        }

        // else if (this.localRecorder && this.localRecorder.state == 'FINISHED') {
        //   this.localRecorder.record();
        //   this.setState({ isRecording: true, recordingState: 'RECORDING' })
        // }

        break;
      case "pause":
        if (this.localRecorder && this.localRecorder.state == "RECORDING") {
          this.localRecorder.pause();
          this.setState({ isRecording: true, recordingState: "PAUSED" });
        } else if (this.localRecorder && this.localRecorder.state == "PAUSED") {
          this.localRecorder.resume();
          this.setState({
            isRecording: true,
            recordingState: "SCREEN RECORDING",
          });
        }
        break;
      case "save":
        if (
          this.localRecorder &&
          (this.localRecorder.state == "RECORDING" ||
            this.localRecorder.state == "PAUSED")
        ) {
          this.localRecorder.stop().then(() => {
            this.setState({ isRecording: false, recordingState: "FINISHED" });
            this.localRecorder.download();
            this.localRecorder.clean();
            setTimeout(() => {
              this.localRecorder = null;
            }, 1000);
          });
        }

        break;

      default:
        break;
    }
  };

  syncWithClass = () => {
    const { sessionId } = this.state;
    let dataURL = this._canvas._sketch.toJSON(["width", "height"]);

    if (this._canvas._video) {
      dataURL.currentTime = this._canvas._video.currentTime;
    }

    dataURL = JSON.stringify(dataURL);
    this.socket.emit("object:slide", {
      source: { dataURL, sync: true, slideHandler: this.state.slideHandler },
      sessionId: sessionId,
    });
  };
  pauseStudentScreen = () => {
    this.setState({ pause: !this.state.pause }, () => {
      this.socket.emit("pauseStudentAccess", {
        pause: this.state.pause,
        sessionId: this.state.sessionId,
      });

      if (!this.state.pause) {
        this._canvasToImage();
      }
    });
  };

  toggleAlert = (type) => {
    const { alertBox, navTypeAfterSave } = this.state;
    const { alertType } = alertBox;

    let alertMessage = "";
    if (type == "endLecture") {
      alertMessage = "you want to end your session";
    } else if (type == "clearCanvas") {
      alertMessage = "You want to clear your annotations.";
    } else if (type == "saveAnnotation") {
      alertMessage = "You want to save your annotations.";
    } else if (type == "navConfirmation") {
      alertMessage = "Save Your Annotations?";
    }

    if (alertType == "navConfirmation") {
      this._canvas._clear();
      this.navigateSlide(navTypeAfterSave);

      this.setState({ navTypeAfterSave: null });
    }

    this.setState({
      alertBox: {
        showAlert: !this.state.alertBox.showAlert,
        alertType: type,
        alertMessage,
      },
    });
  };

  toggleFlag = (name) => {
    this.setState({ flags: { [name]: !this.state.flags[name] } });
  };

  toggleBoolFlag = (flagName) => {
    switch (flagName) {
      case "isComparePopupEnabled":
        this.setState({
          isComparePopupEnabled: !this.state.isComparePopupEnabled,
          isNotificationPopupEnabled: false,
        });
        break;
      case "isNotificationPopupEnabled":
        this.setState({
          isComparePopupEnabled: false,
          isNotificationPopupEnabled: !this.state.isNotificationPopupEnabled,
        });
        break;
      default:
        this.setState({
          isComparePopupEnabled: false,
          isNotificationPopupEnabled: false,
        });
        break;
    }
  };

  handleSetting = (flag) => {
    this.setState({ showSetting: flag || !this.state.showSetting });
  };

  alertOnOK = () => {
    const { alertType } = this.state.alertBox;
    if (alertType == "clearCanvas") {
      this._canvas._sketch.clearObjects();
    } else if (alertType == "saveAnnotation") {
      this.saveAnnotation();
    } else if (alertType == "navConfirmation") {
      this.saveAnnotation();
    }

    this.setState({
      alertBox: {
        showAlert: !this.state.alertBox.showAlert,
        alertType: "",
        alertMessage: "",
      },
    });
  };

  exportUserWorkbook = () => {
    const { exportUserWorkbook, addFlashMessage, location } = this.props;
    const { id } = location.query;
    this.setState({ isLoading: true });
    addFlashMessage({
      type: "success",
      text:
        "Your workbook export request is in progress, workbook download will start soon.",
    });

    exportUserWorkbook(id)
      .then((res) => {
        this.setState({ isLoading: false });
        addFlashMessage({
          type: "success",
          text: res.data.message || "Your workbook exported successfully.",
        });
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_API_HOST}/lecture-attachments/lectureSlide-${id}.pdf`;
        link.type = "application/pdf;";
        link.download = this.state.workbookPdfName || id;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.togglePdfNameAlert();
        // window.open(`${process.env.REACT_APP_API_HOST}/api/workbook/download/${id}`);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        addFlashMessage({
          type: "error",
          text:
            error.response.message ||
            "Oops, something went wrong while fetching data from the server",
        });
      });
  };

  _canvasFunction = (type) => {
    this._canvas[type]();
  };

  saveAnnotation = async () => {
    const { saveAnnotation, canvasImageToBase64, addFlashMessage } = this.props;

    const {
      slideHandler,
      slides,
      multiPageDoc,
      navTypeAfterSave,
      workbook,
    } = this.state;
    const { currentSlide } = slideHandler;
    const { current, details } = multiPageDoc;
    const workbookId = workbook._id;

    const slide = slides[currentSlide];

    if (!slide) return true;

    const { filetype, path, name } = slide;
    const ext = /^.+\.([^.]+)$/.exec(name);
    const extention = ext == null ? "" : ext[1].toLocaleLowerCase();

    let slideType001 = ["image", "jpg", "png", "jpeg"];
    let slideType002 = [
      "excel",
      "word",
      "ppt",
      "notebook",
      "pdf",
      "docx",
      "doc",
      "xlsx",
      "xls",
    ];
    let slideType003 = ["video", "mp4"];

    let drivePath = "";
    if (
      slideType001.indexOf(filetype) > -1 ||
      slideType001.indexOf(extention) > -1
    ) {
      drivePath = path;
    } else if (
      slideType002.indexOf(filetype) > -1 ||
      slideType002.indexOf(extention) > -1
    ) {
      drivePath = details[current];
    } else if (
      slideType003.indexOf(filetype) > -1 ||
      slideType003.indexOf(extention) > -1
    ) {
      addFlashMessage({
        type: "error",
        text: "Saving annotation on video is not supported yet.",
      });
      return true;
    } else {
      console.error("Invalide File Type", filetype);
      console.error("Invalide path", path);
      return true;
    }

    let canvas = this._canvas._sketch._fc;
    var transform = canvas.viewportTransform;
    let dataURL = this._canvas._sketch.toJSON(["width", "height"]);
    var canvasCenter = new fabric.Point(
      canvas.getWidth() / 2,
      canvas.getHeight() / 2
    );

    if (transform[4] || transform[5]) {
      canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
      let wratio = 0;
      let hratio = 0;
      const maxWidth = dataURL.width;
      const maxHeight = dataURL.height;

      let newWidth = maxWidth + Math.abs(transform[4]);
      let newHeight = maxHeight + Math.abs(transform[5]);

      if (newWidth > maxWidth) {
        wratio = maxWidth / newWidth;
      }

      if (newHeight > maxHeight) {
        hratio = maxHeight / newHeight;
      }

      canvas.zoomToPoint(canvasCenter, Math.min(wratio, hratio) / 1.5);
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }

    // let aWidth = dataURL.width + Math.abs(transform[4])
    // let aHeight = dataURL.height + Math.abs(transform[5])
    // debugger

    // canvas.setWidth(aWidth)
    // canvas.setHeight(aHeight)
    // dataURL = this._canvas._sketch.toJSON(["width", "height"]);
    // debugger

    this.setState({ isLoading: true });
    let objects = dataURL.objects;
    canvasImageToBase64({ objects })
      .then((res) => {
        dataURL.objects = res.data.objects;
        this._canvas._sketch.fromJSON(dataURL, () => {
          const base64 = this._canvas._sketch.toDataURL({
            format: "png",
            quality: 0.8,
          });

          let binary = atob(base64.split(",")[1]);
          //Create 8-bit unsigned array
          let array = [];
          let i = 0;
          while (i < binary.length) {
            array.push(binary.charCodeAt(i));
            i++;
          }

          let blob = new Blob([new Uint8Array(array)], { type: "image/png" });

          let formData = new FormData();
          formData.append("file", blob, drivePath);
          formData.append("localPath", drivePath);

          // canvas.viewportTransform = transform;
          canvas.zoomToPoint(canvasCenter, 1);

          saveAnnotation(formData)
            .then(() => {
              this._canvas._clear();
              setTimeout(() => {
                this._canvas._sketch.setBackgroundFromDataUrl(base64);
                this._canvas.setState({ canRedo: false });
              }, 500);

              addFlashMessage({
                type: "success",
                text: "Success, your annotations have been saved",
              });

              this.setState(
                { isLoading: false, navTypeAfterSave: null },
                () => {
                  setTimeout(() => {
                    this.props.getWorkbookDetails(workbookId).then((res) => {
                      const { workbook } = res.data;
                      this.setState({
                        isLoading: false,
                        workbook,
                        slides: workbook.slide,
                      });
                    });
                    this.navigateSlide(navTypeAfterSave);
                  }, 1500);
                }
              );
            })
            .catch((error) => {
              console.error(error);
              this.setState({ isLoading: false });
              addFlashMessage({
                type: "error",
                text:
                  "Oops, something went wrong while saving data from the server",
              });
            });
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.error(error);
        addFlashMessage({
          type: "error",
          text: "Oops, something went wrong while saving data from the server",
        });
      });
  };

  backToHomeSlide = () => {
    const { slides } = this.state;
    this.setState({
      slideHandler: { ...this.state.slideHandler, currentSlide: 0 },
    });

    this._canvas.slideHandler(slides[0]);
  };

  navigateSlide = (type, index) => {
    const { slideHandler, slides } = this.state;
    const { currentSlide, totalSlides } = slideHandler;

    let slide = slides[currentSlide];
    if (!slide) return true;

    let slideType002 = ["video", "mp4"];
    const { filetype, path, name } = slides[currentSlide];
    const ext = /^.+\.([^.]+)$/.exec(name);
    const extention = ext == null ? "" : ext[1].toLocaleLowerCase();

    if (
      slideType002.indexOf(filetype) > -1 ||
      slideType002.indexOf(extention) > -1
    ) {
      this._canvas._clear();
    } else if (
      this._canvas._sketch.toJSON().objects &&
      this._canvas._sketch.toJSON().objects.length &&
      this._canvas.state.canUndo
    ) {
      this.setState({ navTypeAfterSave: type });
      return this.toggleAlert("navConfirmation");
    }

    if (type == "next" && !slides[currentSlide + 1]) {
      this._addWhiteBoardSlide();
    }

    let canvas = this._canvas._sketch._fc;
    canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
    this._canvas._sketch.zoom("zoomReset");

    switch (type) {
      case "next":
        let nextIndex = currentSlide + 1;
        if (totalSlides > nextIndex) {
          this.setState({
            slideHandler: {
              ...this.state.slideHandler,
              currentSlide: nextIndex,
            },
          });
          this._canvas.slideHandler(slides[nextIndex]);
        }
        break;
      case "previous":
        let previousIndex = currentSlide - 1;
        if (previousIndex >= 0) {
          this.setState({
            slideHandler: {
              ...this.state.slideHandler,
              currentSlide: previousIndex,
            },
          });
          this._canvas.slideHandler(slides[previousIndex]);
        }
        break;
      case "jump":
        this.setState({
          slideHandler: { ...this.state.slideHandler, currentSlide: index },
        });
        this._canvas.slideHandler(slides[index]);
        break;
      case "refresh":
        this.setState({
          slideHandler: {
            ...this.state.slideHandler,
            currentSlide: currentSlide,
          },
        });
        this._canvas.slideHandler(slides[currentSlide]);
        break;
      default:
        console.error("Navigate slide handler invalid", type);
        break;
    }
  };

  getDocDetails = async (path) => {
    const { getDocDetails } = this.props;

    try {
      let result = await getDocDetails(path);
      const { details } = result.data;
      this.setState({
        multiPageDoc: {
          current: -1,
          totalSlides: details.length,
          details,
          next: !!details.length,
          previous: false,
        },
      });
      this.navMultiPage("next");
    } catch (error) {
      addFlashMessage({
        type: "error",
        text: "Oops, something went wrong while extracting your slide deatils",
      });
    }
  };
  navMultiPage = (type) => {
    const { multiPageDoc } = this.state;
    const { current, details } = multiPageDoc;

    switch (type) {
      case "previous":
        this.setState(
          {
            multiPageDoc: { ...this.state.multiPageDoc, current: current - 1 },
          },
          () => {
            this._canvas.slideType001(details[current - 1]);
          }
        );
        break;
      case "next":
        this.setState(
          {
            multiPageDoc: { ...this.state.multiPageDoc, current: current + 1 },
          },
          () => {
            this._canvas.slideType001(details[current + 1]);
          }
        );
        break;
      default:
        this.setState({
          multiPageDoc: { ...this.state.multiPageDoc, current: 0 },
        });
        this._canvas.slideType001(details[0]);
    }
  };

  _selectTool = (tool) => {
    if (this.state.tool === tool) {
      this.setState({ tool });
    } else {
      this.setState({ tool });
    }
    if (this._canvas) {
      this._canvas._selectTool(tool);
    }
  };

  _onBackgroundImageDrop = (accepted) => {
    this._canvas._onBackgroundImageDrop(accepted);
  };
  toggleLiveSearch = () => {
    this.setState({ imgGalleryFlag: !this.state.imgGalleryFlag });
  };
  _toggleFullScreen = () => {
    this._canvas._toggleFullScreen();
  };
  addLiveSearchImage = (url) => {
    this._canvas.addLiveSearchImage(url);
  };

  secToDHMS = (seconds) => {
    // day, h, m and s
    var days = Math.floor(seconds / (24 * 60 * 60));
    seconds -= days * (24 * 60 * 60);
    var hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * (60 * 60);
    var minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return (
      (0 < days ? days + " day, " : "") +
      (0 < hours ? hours + " h, " : "") +
      minutes +
      "m and " +
      seconds +
      "s"
    );
  };
  toggleSlidePreview = () => {
    this.setState(
      {
        slideHandler: {
          ...this.state.slideHandler,
          preview: !this.state.slideHandler.preview,
        },
      },
      () => {
        this.socket.emit("teacher:showSlides", {
          sessionId: this.state.sessionId,
          preview: this.state.slideHandler.preview,
        });
      }
    );
  };
  mannageCompare = (value) => {
    const { compareArray } = this.state;
    let index = compareArray.indexOf(value * 1);
    if (index == -1) {
      compareArray.push(value * 1);
    } else {
      compareArray.splice(index, 1);
    }
    this.setState({ compareArray });
  };

  addAllToCompare = (ev) => {
    this.setState(
      {
        compareAll: !this.state.compareAll,
        comparePopupFlag: !this.state.comparePopupFlag,
      },
      () => {
        const { subscribers } = this.state;
        this.setState({
          compareArraySubscribers: [...subscribers],
          compareArray: [],
        });
      }
    );
  };

  toggleComparePopup = () => {
    let { compareArray, subscribers } = this.state;
    this.setState(
      {
        comparePopupFlag: !this.state.comparePopupFlag,
        compareAll: false,
        compareArraySubscribers: [],
      },
      () => {
        let streamArrays = [];
        compareArray.forEach((i) => {
          streamArrays.push(subscribers[i]);
        });

        this.setState({ compareArraySubscribers: [...streamArrays] });
      }
    );
  };

  _addWhiteBoardSlide = () => {
    console.log(this.state);
    const { workbook, slideHandler } = this.state;
    const { currentSlide } = slideHandler;
    this.setState({ isLoading: true });
    const { addEmptySlide, addFlashMessage } = this.props;

    addEmptySlide({ workbookId: workbook._id, currentSlide })
      .then((res) => {
        this.setState({ isLoading: false });
        addFlashMessage({
          type: "success",
          text: "Whiteboard is added, next to current slide",
        });

        let savedWorkbook = res.data.workbook;

        this.setState(
          {
            workbook: savedWorkbook,
            slides: savedWorkbook.slide,
            slideHandler: {
              ...this.state.slideHandler,
              totalSlides: savedWorkbook.slide.length,
            },
          },
          () => {
            this.navigateSlide("next");

            this.socket.emit("object:workbook", {
              sessionId: this.state.sessionId,
              workbook: this.state.workbook,
              lectureToolsFlag: this.state.lectureToolsFlag,
              slideHandler: this.state.slideHandler,
            });
            this._canvasToImage();
          }
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
        addFlashMessage({
          type: "error",
          text:
            "ooops, not sure what went wrong then. Please try again in a couple of minutes. Please try refreshing your page",
        });
      });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      this.setState(
        { slides: arrayMove(this.state.slides, oldIndex, newIndex) },
        () => {
          this.saveSlide();
          this.navigateSlide("jump", newIndex);
        }
      );
    } else {
      this.navigateSlide("jump", newIndex);
    }
  };

  removeSlide = (slideIndex) => {
    const { slideHandler, slides } = this.state;
    const { currentSlide } = slideHandler;

    let removeSlide = slides[slideIndex || currentSlide];

    if (removeSlide) {
      removeSlide = JSON.stringify(removeSlide);
    }

    let removed;
    const filtedSlide = slides.filter((obj) => {
      if (JSON.stringify(obj) === removeSlide && !removed) {
        removed = true;
        return JSON.stringify(obj) !== removeSlide;
      } else {
        return true;
      }
    });
    this.setState(
      {
        slides: filtedSlide,
        slideHandler: {
          ...this.state.slideHandler,
          totalSlides: this.state.slideHandler.totalSlides - 1,
        },
      },
      () => {
        this.saveSlide();
        this.navigateSlide("refresh");
        let { slides } = this.state;

        this.socket.emit("object:workbook:refresh", {
          sessionId: this.state.sessionId,
          slides,
        });
        this._canvasToImage();
      }
    );
  };

  prepareSlides = () => {
    const { slides, workbook } = this.state;
    const workbookId = workbook._id;

    const data = slides;
    const dbSlides = [];
    const slidesDownloadLinks = [];

    data.forEach((s, i) => {
      let dbRecored = {};
      if (s.path) {
        dbRecored = s;
      } else {
        const downloadDetails = {};
        dbRecored.name = s.name;
        dbRecored.thumbnail = s.thumbnail;

        const ext = /^.+\.([^.]+)$/.exec(s.name);
        const extention = ext == null ? "" : ext[1].toLocaleLowerCase();
        const downloadName = `${i}-${workbookId}.${extention}`;
        if (IMAGE_TYPE.indexOf(extention.toUpperCase()) > -1) {
          dbRecored.thumbnail = `/lecture-attachments/${downloadName}`;
        }

        dbRecored.path = `/lecture-attachments/${downloadName}`;
        dbRecored.filetype = s.downloadFileType;

        downloadDetails.drive = s.drive;
        downloadDetails.downloadLink = s.downloadLink;
        downloadDetails.downloadName = downloadName;
        downloadDetails.downloadLinkType = s.downloadLinkType;
        downloadDetails.downloadFileType = s.downloadFileType;

        slidesDownloadLinks.push(downloadDetails);
      }

      dbSlides.push(dbRecored);
    });

    return { workbookId, dbSlides, slidesDownloadLinks };
  };

  saveSlide = () => {
    const { saveWorkbook, addFlashMessage } = this.props;
    const { workbookId, dbSlides, slidesDownloadLinks } = this.prepareSlides();
    this.setState({ isLoading: true });
    saveWorkbook({ workbookId, dbSlides, slidesDownloadLinks })
      .then((res) => {
        this.setState({ isLoading: false });
        addFlashMessage({
          type: "success",
          text: "Your workbook slides are updated.",
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
        addFlashMessage({
          type: "error",
          text:
            "Oops, something went wrong while fetching data from the server",
        });
      });
  };

  previewSlide = (index) => {
    this.navigateSlide("jump", index);
  };

  toggleStudentNavigation = () => {
    this.setState({ studentNavigation: !this.state.studentNavigation }, () => {
      this.socket.emit("toggleStudentNav", {
        studentNavigation: this.state.studentNavigation,
        sessionId: this.state.sessionId,
      });
    });
  };

  toggleStudentLiveImageSearch = () => {
    this.setState(
      { studentLiveImageSearch: !this.state.studentLiveImageSearch },
      () => {
        this.socket.emit("toggleStudentLiveImageSearch", {
          studentLiveImageSearch: this.state.studentLiveImageSearch,
          sessionId: this.state.sessionId,
        });
      }
    );
  };

  onChangeStudentSettings = () => {
    this.setState({ studentSettings: !this.state.studentSettings }, () => {
      this.socket.emit("toggleStudentSettings", {
        studentSettings: this.state.studentSettings,
        sessionId: this.state.sessionId,
      });
    });
  };

  toggleColorPicker = () => {
    this.setState({ showColors: !this.state.showColors });
  };

  removeFromCompareList = (index) => {
    const { compareArraySubscribers } = this.state;
    compareArraySubscribers.splice(index, 1);
    this.setState({ compareArraySubscribers });
  };

  toggleUserAudio = (sub) => {
    let email = JSON.parse(sub.stream.connection.data.replace("}%/%{", ","))
      .clientData;
    email = email.split(" - Student")[0];
    this.socket.emit("toggleStudentStreamAudio", {
      email: email,
      sessionId: this.state.sessionId,
    });

    let student = this.state.unMutedStudent;
    let index = student.indexOf(email);
    if (index > -1) {
      student.splice(index, 1);
    } else {
      student.push(email);
    }
    this.setState({ unMutedStudent: student });
  };

  changeCastQuality = (sub, quality) => {
    let email = JSON.parse(sub.stream.connection.data.replace("}%/%{", ","))
      .clientData;
    email = email.split(" - Student")[0];

    this.socket.emit("changeStudentCastQuality", {
      email: email,
      sessionId: this.state.sessionId,
      quality: quality,
    });
  };

  _showImageUplaoder = () => {
    this.setState({ showImageUplaoder: !this.state.showImageUplaoder }, () => {
      this._selectTool(Tools.Select);
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  togglePdfNameAlert = () => {
    this.setState({ pdfNamePopupFlag: !this.state.pdfNamePopupFlag });
  };

  changeToolbarSize = (event) => {
    const { value } = event.target;
    this.setState(
      { toolbarSize: value == 1 ? "small" : value == 2 ? "md" : "lg" },
      () => {
        localStorage.setItem("toolbarSize", this.state.toolbarSize);
      }
    );
  };

  endSession = () => {
    this.socket.emit("exitLecture", { sessionId: this.state.sessionId });
    this.context.router.push({ pathname: "/" });
  };

  render() {
    const {
      workbook,
      isLoading,
      alertBox,
      flags,
      slides,
      sessionId,
      slideHandler,
      multiPageDoc,
      groupChat,
      compareArraySubscribers,
      comparePopupFlag,
      tool,
      lineColor,
      imgGalleryFlag,
      publisher,
      requestFromStudent,
      studentList,
      accessToStudent,
      subscribers,
      lectureToolsFlag,
      isVideoPaused,
      isAudioPaused,
      isRecording,
      recordingState,
      showSetting,
      screenShare,
      studentNavigation,
      showColors,
      compareAll,
      compareArray,
      isComparePopupEnabled,
      isNotificationPopupEnabled,
      showImageUplaoder,
      workbookPdfName,
      pdfNamePopupFlag,
      studentLiveImageSearch,
      studentSettings,
      toolbarSize,
      videoCall,
      unMutedStudent,
    } = this.state;
    const { currentSlide, preview } = slideHandler;
    const { mainTools } = flags;
    const { name, subject } = workbook;
    const { showAlert, alertMessage, alertType } = alertBox;

    const { isLicensed, licenseExpiry } = this.props.auth.user;
    const { auth } = this.props;
    const { username } = auth.user;

    return (
      <Layout>
        <Layout.Content>
          <Spin spinning={isLoading} size="large">
            <Topbar
              workbookTitle={name || "Untitled Lecture"}
              sessionId={sessionId}
              syncWithClass={this.syncWithClass}
              endSession={this.endSession}
              saveAnnotation={this.saveAnnotation}
              removeSlide={this.removeSlide}
              previewSlide={this.previewSlide}
              preview={!preview}
              toggleSlidePreview={this.toggleSlidePreview}
              slides={slides}
              onSortEnd={this.onSortEnd}
              backToHomeSlide={this.backToHomeSlide}
              navigateSlide={this.navigateSlide}
              selectTool={this._selectTool}
              toggleLiveSearch={this.toggleLiveSearch}
              toggleFullScreen={this._toggleFullScreen}
              toolsPosition={this.state.toolsPosition}
              pauseStudentScreen={this.pauseStudentScreen}
              togglePdfNameAlert={this.togglePdfNameAlert}
              toggleStudentNavigation={this.toggleStudentNavigation}
              // this._selectTool.bind(this, Tools.Select)
            />

            <CastPopup
              visible={isComparePopupEnabled}
              subscribers={subscribers}
              toggleDrawer={() =>
                this.setState({
                  isComparePopupEnabled: !isComparePopupEnabled,
                })
              }
              requestFromStudent={requestFromStudent}
              accessToStudent={accessToStudent}
              studentList={studentList}
              manageAccess={this._grantAcess}
              compareArray={compareArray}
              mannageCompare={this.mannageCompare}
              toggleUserAudio={this.toggleUserAudio}
              compareAll={compareAll}
              addAllToCompare={this.addAllToCompare}
              toggleComparePopup={this.toggleComparePopup}
              lectureRecording={() => this.lectureRecording("start")}
              manageTecherStream={() => this.manageTecherStream("call")}
              changeCastQuality={this.changeCastQuality}
              sessionId={sessionId}
              videoCall={videoCall}
              toggleVideoCall={() => {
                this.setState({ videoCall: !videoCall }, () => {
                  this.socket.emit("teacher:videoCall", {
                    source: { videoCall: this.state.videoCall },
                    sessionId: sessionId,
                  });
                });
              }}
              username={username}
              pauseStudentScreen={this.pauseStudentScreen}
              syncWithClass={this.syncWithClass}
              togglePdfNameAlert={this.togglePdfNameAlert}
              toggleStudentNavigation={this.toggleStudentNavigation}
              studentNavigation={this.state.studentNavigation}
              pause={this.state.pause}
              unMutedStudent={unMutedStudent}
            />
            {/* <div className="gx-test-class gx-pointer">
              <Button
                type="primary"
                shape="circle"
                size="large"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: -80,
                  zIndex: 3,
                  width: 150,
                  height: 150,
                  textAlign: "right",
                }}
                icon={
                  <LeftOutlined
                    style={{
                      fontSize: 80,
                    }}
                  />
                }
              />

              <Button
                type="primary"
                shape="circle"
                size="large"
                style={{
                  position: "fixed",
                  top: "50%",
                  right: -80,
                  zIndex: 3,
                  width: 150,
                  height: 150,
                  textAlign: "left",
                }}
                icon={
                  <RightOutlined
                    style={{
                      fontSize: 80,
                    }}
                  />
                }
              />
            </div> */}
            <div className="gx-customizer-option">
              <Button type="primary" onClick={() => this.handleSetting()}>
                <i className="icon icon-setting gx-d-block" />
              </Button>
            </div>

            <Tooltip placement="left" title={"Show Lecture Slides"}>
              <div style={{ top: 100 }} className="gx-customizer-option">
                <Button type="primary" onClick={this.toggleSlidePreview}>
                  <FundProjectionScreenOutlined className="icon" />
                </Button>
              </div>
            </Tooltip>

            <Tooltip placement="left" title={"Manage Participants"}>
              <div style={{ top: 200 }} className="gx-customizer-option">
                <Badge count={requestFromStudent.length} offset={[-60, 20]}>
                  <Button
                    type="primary"
                    // {...(requestFromStudent.length ? { danger: true } : {})}
                    onClick={() =>
                      this.setState({
                        isComparePopupEnabled: !isComparePopupEnabled,
                      })
                    }
                  >
                    <UsergroupAddOutlined className="icon" />
                  </Button>
                </Badge>
              </div>
            </Tooltip>

            <div
              className={cx(s["nsg-comparecastpopupcontainer"], {
                [s["nsg-comparecastopenpopup"]]: comparePopupFlag,
              })}
            >
              <a
                onClick={this.toggleComparePopup}
                className={s["nsg-comparecastclonsebtn"]}
              >
                <i className="fa fa-times" />
              </a>

              {compareArraySubscribers.map((sub, i) => (
                <div className={s["nsg-comparecast"]}>
                  <figure className={s["at-screenimg"]}>
                    <div className={s["nsg-studentscreenholder"]}>
                      <label className={s["nsg-studentname"]}>
                        {
                          JSON.parse(
                            sub.stream.connection.data.replace("}%/%{", ",")
                          ).clientData
                        }
                        <a onClick={this.removeFromCompareList.bind(this, i)}>
                          <i className="fa fa-times" />
                        </a>
                      </label>
                      <OpenViduVideoComponent streamManager={sub} />
                    </div>
                  </figure>
                </div>
              ))}
            </div>

            <PopupTypeTwo
              onToggle={this.togglePdfNameAlert}
              isOpen={pdfNamePopupFlag}
              title={"PDF Name"}
              onOK={this.exportUserWorkbook}
              onCancel={this.togglePdfNameAlert}
              popupIcon={
                '<img src="images/icon-smile.png" alt="images description">'
              }
            >
              {/* <h3>PDF Name ? </h3> */}
              <TextFieldGroup
                field="workbookPdfName"
                label={"Download PDF Name ?"}
                value={workbookPdfName}
                error={""}
                onChange={this.onChange}
              />
              <em />
              <div className="at-btnarea">
                <a
                  onClick={this.exportUserWorkbook}
                  className="at-themebtn"
                  href="javascript:void(0);"
                >
                  <FormattedMessage {...messages.yes} />
                </a>
                <a
                  onClick={this.togglePdfNameAlert}
                  className="at-themebtn"
                  href="javascript:void(0);"
                >
                  <FormattedMessage {...messages.cancel} />
                </a>
              </div>
            </PopupTypeTwo>
            {!isLicensed ? (
              <div
                style={{
                  top: 0,
                  width: "100%",
                  height: "20px",
                  background: "#294957",
                  textAlign: "center",
                  zIndex: 100,
                }}
              >
                <span style={{ color: "#fff", fontSize: 15 }}>
                  {" "}
                  {Math.round(licenseExpiry) <= 0
                    ? "Your trial is expired."
                    : `Your trial will expire in ${Math.round(
                        licenseExpiry
                      )} days.`}{" "}
                  <a href="/profile">Click Here</a> to enter your TouchIT Serial
                  Number from your Interactive LED.(On the side of product)
                </span>
              </div>
            ) : null}

            <PopupTypeTwo
              onToggle={this.toggleAlert}
              isOpen={showAlert}
              title={alertMessage}
              onOK={this.alertOnOK}
              onCancel={this.toggleAlert}
              popupIcon={
                '<img src="images/icon-smile.png" alt="images description">'
              }
            >
              <h3>
                <FormattedMessage {...messages.areYouSure} />
              </h3>
              <em>{alertMessage}</em>
              <div className="at-btnarea">
                <a
                  onClick={this.alertOnOK}
                  className="at-themebtn"
                  href="javascript:void(0);"
                >
                  <FormattedMessage {...messages.yes} />
                </a>
                <a
                  onClick={this.toggleAlert}
                  className="at-themebtn"
                  href="javascript:void(0);"
                >
                  {alertType == "navConfirmation" ? (
                    "No, Don't Save"
                  ) : (
                    <FormattedMessage {...messages.cancel} />
                  )}
                </a>
              </div>
            </PopupTypeTwo>

            <div className="gx-main-content">
              <GallerySearch
                addToSlide={this.addLiveSearchImage}
                toggleLiveSearch={this.toggleLiveSearch}
                isOpen={imgGalleryFlag}
              />

              {lectureToolsFlag && this.socket ? (
                <LectureTools
                  socket={this.socket}
                  sessionId={sessionId}
                  studentList={studentList}
                />
              ) : null}

              {this.socket ? (
                <GroupChat
                  studentList={studentList}
                  socket={this.socket}
                  sessionId={sessionId}
                />
              ) : null}

              {(this.state.toolsPosition === "float" ||
                this.state.toolsPosition === "both") && (
                <Draggable handle=".dragable">
                  <div
                    className={cx(
                      s["at-toolsholder"],
                      {
                        [s["at-toolsholder-nsg-small"]]: toolbarSize == "small",
                      },
                      { [s["at-toolsholder-nsg-md"]]: toolbarSize == "md" }
                    )}
                  >
                    <div className={cx("dragable", s["at-dragable"])} />
                    <ul className={s["at-tools"]}>
                      <li
                        onClick={this._selectTool.bind(this, Tools.Select)}
                        className={cx({
                          [s["at-active"]]: tool == Tools.Select,
                        })}
                      >
                        <a href="javascript:void(0);">
                          <SelectOutlined />
                        </a>
                      </li>

                      <Popover
                        placement="right"
                        title={null}
                        content={
                          <Fragment>
                            <CirclePicker
                              colors={[
                                "#000000",
                                "#f44336",
                                "#e91e63",
                                "#9c27b0",
                                "#673ab7",
                                "#3f51b5",
                                "#2196f3",
                                "#03a9f4",
                                "#00bcd4",
                                "#009688",
                                "#4caf50",
                                "#8bc34a",
                                "#cddc39",
                                "#ffeb3b",
                                "#ffc107",
                                "#ff9800",
                                "#ff5722",
                                "#795548",
                              ]}
                              color={this.state.lineColor}
                              onChangeComplete={(color) =>
                                this.setState({ lineColor: color.hex })
                              }
                            />

                            <Slider
                              defaultValue={this.state.lineWidth}
                              min={3}
                              max={50}
                              onChange={(value) =>
                                this.setState({ lineWidth: value })
                              }
                              marks={{
                                25: {
                                  style: {
                                    color: "#02a2de",
                                  },
                                  label: <strong>Thickness</strong>,
                                },
                              }}
                            />
                          </Fragment>
                        }
                        trigger={["click"]}
                      >
                        <li
                          onClick={this._selectTool.bind(this, Tools.Pencil)}
                          className={cx({
                            [s["at-active"]]: tool == Tools.Pencil,
                          })}
                        >
                          <a href="javascript:void(0);">
                            <HighlightOutlined />
                          </a>
                        </li>
                      </Popover>

                      <Popover
                        placement="right"
                        title={null}
                        content={
                          <Fragment>
                            <CirclePicker
                              colors={[
                                "#000000",
                                "#f44336",
                                "#e91e63",
                                "#9c27b0",
                                "#673ab7",
                                "#3f51b5",
                                "#2196f3",
                                "#03a9f4",
                                "#00bcd4",
                                "#009688",
                                "#4caf50",
                                "#8bc34a",
                                "#cddc39",
                                "#ffeb3b",
                                "#ffc107",
                                "#ff9800",
                                "#ff5722",
                                "#795548",
                              ]}
                              color={this.state.lineColor}
                              onChangeComplete={(color) =>
                                this.setState({ lineColor: color.hex })
                              }
                            />

                            <Slider
                              defaultValue={this.state.lineWidth}
                              min={3}
                              max={50}
                              marks={{
                                25: {
                                  style: {
                                    color: "#02a2de",
                                  },
                                  label: <strong>Thickness</strong>,
                                },
                              }}
                              onChange={(value) =>
                                this.setState({ lineWidth: value })
                              }
                            />

                            <Space>
                              <Tooltip
                                placement="bottom"
                                title={<span>Rectangle</span>}
                              >
                                <Button
                                  type="link"
                                  onClick={this._selectTool.bind(
                                    this,
                                    Tools.Rectangle
                                  )}
                                >
                                  <img
                                    className={s["at-shapeimg"]}
                                    src="images/rect-img.png"
                                    alt="image description"
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip
                                placement="bottom"
                                title={<span>Circle</span>}
                              >
                                <Button
                                  type="link"
                                  onClick={this._selectTool.bind(
                                    this,
                                    Tools.Circle
                                  )}
                                >
                                  <img
                                    className={s["at-shapeimg"]}
                                    src="images/circ-img.png"
                                    alt="image description"
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip
                                placement="bottom"
                                title={<span>Line</span>}
                              >
                                <Button
                                  type="link"
                                  onClick={this._selectTool.bind(
                                    this,
                                    Tools.Line
                                  )}
                                >
                                  <img
                                    className={s["at-shapeimg"]}
                                    src="images/line-img.png"
                                    alt="image description"
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip
                                placement="bottom"
                                title={<span>Arrow</span>}
                              >
                                <Button
                                  type="link"
                                  onClick={this._selectTool.bind(
                                    this,
                                    Tools.Arrow
                                  )}
                                >
                                  <img
                                    className={s["at-shapeimg"]}
                                    src="images/arrow-img.png"
                                    alt="image description"
                                  />
                                </Button>
                              </Tooltip>
                            </Space>
                          </Fragment>
                        }
                        trigger={["click"]}
                      >
                        <li>
                          <a href="javascript:void(0);">
                            <CloudIcon type="icon-shapes" />
                          </a>
                        </li>
                      </Popover>

                      <li onClick={this._showImageUplaoder}>
                        <a href="javascript:void(0);">
                          <CloudIcon type="icon-gallery" />
                        </a>
                        {showImageUplaoder ? (
                          <ul className={s["at-searchimgholder"]}>
                            <li>
                              <label
                                onClick={() => {
                                  this.dropzoneRef.open();
                                }}
                              >
                                <img
                                  src="images/upload-img-icon.png"
                                  alt="image description"
                                />
                                <span>upload image</span>
                              </label>
                              <DropZone
                                ref="dropzone"
                                accept="image/*"
                                multiple={true}
                                style={{ opacity: 0, height: 40 }}
                                onDrop={this._onBackgroundImageDrop}
                                ref={(c) => (this.dropzoneRef = c)}
                              />
                            </li>
                            <li>
                              <label onClick={this.toggleLiveSearch}>
                                <i className="fa fa-search" />
                                <span>Live search</span>
                              </label>
                            </li>
                          </ul>
                        ) : null}
                      </li>

                      <li
                        onClick={this._selectTool.bind(this, Tools.IText)}
                        className={cx({
                          [s["at-active"]]: tool == Tools.IText,
                        })}
                      >
                        <a href="javascript:void(0);">
                          <FontSizeOutlined />
                          {/*<img*/}
                          {/*  src="images/tools-icons/icon-06.png"*/}
                          {/*  alt="image description"*/}
                          {/*/>*/}
                        </a>
                      </li>

                      <li
                        onClick={this._selectTool.bind(this, Tools.Erase)}
                        className={cx({
                          [s["at-active"]]: tool == Tools.Erase,
                        })}
                      >
                        <a href="javascript:void(0);">
                          {/*<img*/}
                          {/*  src="images/tools-icons/icon-07.png"*/}
                          {/*  alt="image description"*/}
                          {/*/>*/}
                          <CloudIcon type="icon-erase" />
                        </a>
                      </li>
                      <li onClick={this.toggleAlert.bind(this, "clearCanvas")}>
                        <a href="javascript:void(0);">
                          {/*<img*/}
                          {/*  className={s["nsg-clearallbtn"]}*/}
                          {/*  src="images/icons/icon-08.png"*/}
                          {/*  alt="image description"*/}
                          {/*/>*/}
                          <CloudIcon type="icon-erase-all" />
                        </a>
                      </li>
                    </ul>
                    <ul className={cx(s["at-btnfullscreen"], "nsg-bgwhite")}>
                      <li
                        onClick={this._selectTool.bind(this, Tools.Pan)}
                        className={cx({
                          [s["at-active"]]: tool == Tools.Pan,
                        })}
                      >
                        <a href="javascript:void(0);">
                          <DragOutlined />
                        </a>
                      </li>
                      <li onClick={this._toggleFullScreen}>
                        <a href="javascript:void(0);">
                          <ExpandOutlined />
                        </a>
                      </li>
                      <li onClick={() => this._canvas._sketch.bringToFront()}>
                        <a href="javascript:void(0);">
                          <CloudIcon type="icon-send-to-front" />
                        </a>
                      </li>
                      <li onClick={() => this._canvas._sketch.sendToBack()}>
                        <a href="javascript:void(0);">
                          <CloudIcon type="icon-send-to-back" />
                        </a>
                      </li>
                    </ul>
                    <ul className={cx(s["at-btnfullscreen"], "nsg-bgwhite")}>
                      <li onClick={this._addWhiteBoardSlide}>
                        <a href="javascript:void(0);">
                          <i className="fa fa-plus-square-o" />
                        </a>
                      </li>
                      {/* <li onClick={this._toggleFullScreen}><a href="javascript:void(0);"><img src="images/tools-icons/icon-11.png" alt="image description" /></a></li> */}
                    </ul>

                    <div
                      className={cx(
                        "dragable",
                        s["at-dragable"],
                        "nsg-bgwhite"
                      )}
                    />
                  </div>
                </Draggable>
              )}

              <Draggable handle={`.teachervideodraghandler`}>
                <div
                  ref={(tsc) => (this.teacherStreamContainer = tsc)}
                  className={cx(s["at-videocallscreen"], {
                    [s["at-openvideoscreen"]]: publisher,
                  })}
                >
                  <figure className={cx(s["at-videoimg"])}>
                    <video
                      className="teachervideodraghandler"
                      ref={(v) => (this.teacherStream = v)}
                    />
                    <figcaption>
                      <ul className={cx(s["at-callactions"])}>
                        <li
                          className={cx({ [s["at-callend"]]: isAudioPaused })}
                          onClick={this.manageTecherStream.bind(this, "audio")}
                        >
                          <a>
                            <i className="fa fa-microphone" />
                          </a>
                        </li>
                        {!isMobile && (
                          <li
                            className={cx({ [s["at-callend"]]: screenShare })}
                            onClick={this.manageTecherStream.bind(
                              this,
                              "screen"
                            )}
                          >
                            <a>
                              <i className="fa fa-desktop" />
                            </a>
                          </li>
                        )}
                        <li
                          className={cx({ [s["at-callend"]]: isVideoPaused })}
                          onClick={this.manageTecherStream.bind(this, "video")}
                        >
                          <a>
                            <i className="fa fa-video-camera" />
                          </a>
                        </li>
                        <li
                          onClick={this.manageTecherStream.bind(this, "end")}
                          className={cx(s["at-callend"])}
                        >
                          <a>
                            <i className="fa fa-phone" />
                          </a>
                        </li>
                        <li
                          ref={(tsrh) =>
                            (this.teacherStreamResizHandler = tsrh)
                          }
                        >
                          <a className={s["nsg-resizeHandler"]}>
                            <i className="fa fa-expand" />
                          </a>
                        </li>
                      </ul>
                    </figcaption>
                  </figure>
                </div>
              </Draggable>

              {/*<div className={s['at-contenthead']}>*/}
              {/*	<div className={cx(s['at-mainbtnholder'], {[s['at-close']]: mainTools})}>*/}
              {/*		<ul className={s['at-homebtns']}>*/}
              {/*			<li onClick={this.backToHomeSlide}>*/}
              {/*				<a href="javascript:void(0);">*/}
              {/*					<i className="fa fa-home"/>*/}
              {/*				</a>*/}
              {/*			</li>*/}
              {/*			<li onClick={this.handleSetting}>*/}
              {/*				<a href="javascript:void(0);">*/}
              {/*					<i className="fa fa-gear"/>*/}
              {/*				</a>*/}
              {/*			</li>*/}
              {/*		</ul>*/}
              {/*		<ul className={s['at-redobtns']}>*/}
              {/*			<li onClick={this._canvasFunction.bind(this, '_undo')}>*/}
              {/*				<a href="javascript:void(0);">*/}
              {/*					<img src={'images/icons/icon-06.png'} alt="image description"/>*/}
              {/*				</a>*/}
              {/*			</li>*/}
              {/*			<li onClick={this._canvasFunction.bind(this, '_redo')}>*/}
              {/*				<a href="javascript:void(0);">*/}
              {/*					<img src={'images/icons/icon-07.png'} alt="image description"/>*/}
              {/*				</a>*/}
              {/*			</li>*/}
              {/*		</ul>*/}
              {/*		<ul className={s['at-actionbtns']}>*/}
              {/*			<li*/}
              {/*				className={cx({[s['mic-unmute']]: this.state.pause})}*/}
              {/*				onClick={this.pauseStudentScreen}*/}
              {/*			>*/}
              {/*				<a href="javascript:void(0);">*/}
              {/*					<i className="fa fa-pause"/>*/}
              {/*				</a>*/}
              {/*			</li>*/}
              {/*			<li*/}
              {/*				className={cx({[s['mic-unmute']]: isRecording})}*/}
              {/*				onClick={this.lectureRecording.bind(this, 'start')}*/}
              {/*			>*/}
              {/*				<a href="javascript:void(0);">*/}
              {/*					<i className="fa fa-dot-circle-o"/>*/}
              {/*				</a>*/}
              {/*			</li>*/}
              {/*			<li onClick={this.syncWithClass}>*/}
              {/*				<a href="javascript:void(0);"/>*/}
              {/*				<a href="javascript:void(0);">*/}
              {/*					<i className="fa fa-refresh"/>*/}
              {/*				</a>*/}
              {/*			</li>*/}
              {/*			<li onClick={this.manageTecherStream.bind(this, 'call')}>*/}
              {/*				<a href="javascript:void(0);">*/}
              {/*					<i className="fa fa-phone"/>*/}
              {/*				</a>*/}
              {/*			</li>*/}
              {/*		</ul>*/}
              {/*		<ul className={s['at-btndownload']}>*/}
              {/*			<li onClick={this.togglePdfNameAlert}>*/}
              {/*				<a href="javascript:void(0);">*/}
              {/*					<i className="fa fa-download"/>*/}
              {/*				</a>*/}
              {/*			</li>*/}
              {/*			<li onClick={this.toggleStudentNavigation}>*/}
              {/*				<a href="javascript:void(0);">*/}
              {/*					{studentNavigation ? (*/}
              {/*						<i className="fa fa-times"/>*/}
              {/*					) : (*/}
              {/*						<i className="fa fa-check"/>*/}
              {/*					)}{' '}*/}
              {/*				</a>*/}
              {/*			</li>*/}
              {/*		</ul>*/}
              {/*		<ul className={s['at-btncloseholder']} onClick={this.toggleFlag.bind(this, 'mainTools')}>*/}
              {/*			<li>*/}
              {/*				<a href="javascript:void(0);">*/}
              {/*					<i className="fa fa-caret-left"/>*/}
              {/*				</a>*/}
              {/*			</li>*/}
              {/*		</ul>*/}
              {/*	</div>*/}
              {/*	<ul className={s['at-btnnextprev']}>*/}
              {/*		<li onClick={this.navigateSlide.bind(this, 'previous')}>*/}
              {/*			<a className={s['at-btnprev']} href="javascript:void(0);">*/}
              {/*				<i className="fa fa-angle-left"/>*/}
              {/*			</a>*/}
              {/*		</li>*/}
              {/*		<li onClick={this.navigateSlide.bind(this, 'next')}>*/}
              {/*			<a className={s['at-btnnext']} href="javascript:void(0);">*/}
              {/*				<i className="fa fa-angle-right"/>*/}
              {/*			</a>*/}
              {/*		</li>*/}
              {/*	</ul>*/}
              {/*</div>*/}

              {showSetting && (
                <Settings
                  lineColor={this.state.lineColor}
                  lineWidth={this.state.lineWidth}
                  fillColor={this.state.fillColor}
                  fontSize={this.state.fontSize}
                  fontName={this.state.fontName}
                  toolsPosition={this.state.toolsPosition}
                  liveSearch={this.state.studentLiveImageSearch}
                  toolbarSize={this.state.toolbarSize}
                  studentSettings={this.state.studentSettings}
                  onChangeSettings={(property, value) => {
                    this.setState({ [property]: value });
                  }}
                  changeAndEmitSettings={(property, value, sev) => {
                    this.setState({ [property]: value }, () => {
                      this.socket.emit(sev, {
                        [property]: value,
                        sessionId: this.state.sessionId,
                      });
                    });
                  }}
                  zoomHandler={(property) =>
                    this._canvas._sketch.zoom(property)
                  }
                  onClose={() => this.handleSetting()}
                />
              )}

              <div style={{ margin: 0 }} className="gx-app-module">
                <div style={{ maxWidth: "100%" }} className="gx-module-box">
                  <div className="gx-module-box-content">
                    <LectureCanvas
                      onRef={(c) => (this._canvas = c)}
                      slides={slides}
                      lineColor={this.state.lineColor}
                      lineWidth={this.state.lineWidth}
                      fillColor={this.state.fillColor}
                      fontSize={this.state.fontSize}
                      fontName={this.state.fontName}
                      sessionId={sessionId}
                      currentSlide={currentSlide}
                      getDocDetails={this.getDocDetails}
                      multiPageDoc={multiPageDoc}
                      _canvasToImage={this._canvasToImage}
                      showSetting={showSetting}
                      handleSetting={this.handleSetting}
                      updateTool={this._selectTool}
                      studentLiveImageSearch={studentLiveImageSearch}
                      studentSettings={studentSettings}
                      onChangeStudentLiveSearch={
                        this.toggleStudentLiveImageSearch
                      }
                      onChangeStudentSettings={this.onChangeStudentSettings}
                      onChangToolbarSettings={this.changeToolbarSize}
                      toolbarSize={toolbarSize}
                    />

                    <ul
                      className={cx(s["at-btnpdf"], {
                        [s["hide"]]:
                          slides.length && slides[currentSlide]
                            ? [
                                "excel",
                                "word",
                                "ppt",
                                "notebook",
                                "pdf",
                                "docx",
                                "doc",
                                "xlsx",
                                "xls",
                              ].indexOf(slides[currentSlide].filetype) === -1
                            : true,
                      })}
                    >
                      <li>
                        <button
                          onClick={this.navMultiPage.bind(this, "previous")}
                          disabled={multiPageDoc.current <= 0}
                        >
                          Previous
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={this.navMultiPage.bind(this, "next")}
                          disabled={
                            multiPageDoc.totalSlides == multiPageDoc.current
                          }
                        >
                          Next
                        </button>
                      </li>
                    </ul>

                    {isRecording ? (
                      <Draggable handle={`.${s["at-recordinghandler"]}`}>
                        <div className={cx(s["at-recordinghandler"])}>
                          <span>{recordingState}</span>
                          <i
                            onClick={this.lectureRecording.bind(this, "pause")}
                            className={
                              recordingState == "PAUSED"
                                ? "fa fa-play"
                                : "fa fa-pause"
                            }
                          />
                          <i
                            onClick={this.lectureRecording.bind(this, "save")}
                            className="fa fa-save"
                          />
                        </div>
                      </Draggable>
                    ) : null}

                    <div style={{ position: "fixed", bottom: 30, left: 30 }}>
                      <Button.Group>
                        <Button
                          size="large"
                          onClick={() => this._canvas._sketch.zoom("zoomIn")}
                          icon={<ZoomInOutlined />}
                        />
                        <Button
                          size="large"
                          onClick={() => this._canvas._sketch.zoom("zoomReset")}
                          icon={<SearchOutlined />}
                        />
                        <Button
                          size="large"
                          onClick={() => this._canvas._sketch.zoom("zoomOut")}
                          icon={<ZoomOutOutlined />}
                        />
                      </Button.Group>
                    </div>
                    <div style={{ position: "fixed", bottom: 30, left: "47%" }}>
                      <Button.Group type="primary">
                        <Tooltip title={"Navigate to first slide"}>
                          <Button
                            size="large"
                            onClick={() => this.backToHomeSlide()}
                            icon={<i className="gx-icon-btn icon icon-home" />}
                          />
                        </Tooltip>
                        <Tooltip title={"Previous slide"}>
                          <Button
                            size="large"
                            onClick={() => this.navigateSlide("previous")}
                            icon={<LeftOutlined />}
                          />
                        </Tooltip>

                        <Tooltip title={"Next Slide"}>
                          <Button
                            type="primary"
                            size="large"
                            onClick={() => this.navigateSlide("next")}
                            icon={<RightOutlined />}
                          />
                        </Tooltip>
                      </Button.Group>
                    </div>
                    <div style={{ position: "fixed", bottom: 30, left: 180 }}>
                      <Button.Group>
                        {!isMobile && (
                          <Tooltip title="Lecture Recording">
                            <Button
                              size="large"
                              onClick={() => this.lectureRecording("start")}
                              icon={<VideoCameraOutlined />}
                            />
                          </Tooltip>
                        )}
                        {!isMobile && (
                          <Tooltip
                            title={
                              this.state.videoCall
                                ? "Stop Video Call"
                                : "Start Video Call"
                            }
                          >
                            <Button
                              size="large"
                              onClick={this.manageTecherStream.bind(
                                this,
                                "call"
                              )}
                              icon={
                                this.state.videoCall ? (
                                  <StopOutlined />
                                ) : (
                                  <PhoneOutlined />
                                )
                              }
                            />
                          </Tooltip>
                        )}
                        {/* <Button
                          size="large"
                          onClick={() => this._canvas._sketch.zoom("zoomReset")}
                          icon={<SearchOutlined />}
                        />
                        <Button
                          size="large"
                          onClick={() => this._canvas._sketch.zoom("zoomOut")}
                          icon={<ZoomOutOutlined />}
                        /> */}
                      </Button.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </Layout.Content>

        {/* <Layout.Sider className="gx-mediasoup-holder">
          <iframe
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allow="camera; microphone"
            src={`${process.env.REACT_APP_MEET_HOST}/${sessionId}?roomId=${sessionId}&username=${username}&type=lecture`}
          />
        </Layout.Sider> */}
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, {
  getUserProfile,
  getSessionId,
  getWorkbookDetails,
  saveWorkbook,
  addEmptySlide,
  addSlideToEnd,
  exportUserWorkbook,
  getDocDetails,
  saveAnnotation,
  canvasImageToBase64,
  getToken,
  addFlashMessage,
})(LecturePage);

// class LecturePage extends Component {
// 	constructor() {
// 		super();

// 		this.state = {
// 			workbook: {},
// 			isLoading: false,
// 			alertBox: {
// 				showAlert: false,
// 				alertType: 'endLecture',
// 				alertMessage: 'Agreed ?'
// 			},

// 			flags: {
// 				mainTools: true,
// 				comparePopup: false
// 			},

// 			subscribers: [],

// 			slides: [],
// 			slideHandler: {
// 				currentSlide: -1,
// 				totalSlides: 0,
// 				isDirty: 0,
// 				preview: true
// 			},
// 			sessionId: '',

// 			multiPageDoc: {
// 				current: -1,
// 				totalSlides: 0,
// 				details: []
// 			},
// 			tool: Tools.Select,

// 			imgGalleryFlag: false,

// 			publisher: null,
// 			requestFromStudent: [],
// 			studentList: [],
// 			accessToStudent: [],
// 			compareArraySubscribers: [],
// 			compareArray: [],
// 			comparePopupFlag: false,
// 			showSessionIdModal: false,
// 			lectureToolsFlag: true,
// 			isVideoPaused: false,
// 			isAudioPaused: true,
// 			screenShare: false,
// 			navTypeAfterSave: null,
// 			isRecording: false,
// 			recordingState: '',
// 			showSetting: false,

// 			//join session flags
// 			pause: false,
// 			studentNavigation: true,
// 			showColors: false,
// 			compareAll: false,
// 			isComparePopupEnabled: false,
// 			isNotificationPopupEnabled: false,
// 			showImageUplaoder: false,
// 			removeSlide: '',
// 			workbookPdfName: '',
// 			pdfNamePopupFlag: false,
// 			studentLiveImageSearch: true,
// 			studentSettings: true,
// 			toolbarSize: 'lg',

// 			// Sketch Props
// 			lineWidth: 3,
// 			lineColor: 'black',
// 		};
// 	}

// 	componentDidMount = () => {
// 		const { location, getWorkbook, addFlashMessage } = this.props;
// 		const workbookId = location.query.id;

// 		getWorkbook({ workbookId })
// 			.then((res) => {
// 				const { workbook } = res.data;
// 				this.setState({
// 					workbookId,
// 					workbook: workbook,
// 					slides: workbook.slide,
// 					slideHandler: { ...this.state.slideHandler, totalSlides: workbook.slide.length }
// 				});
// 				// this.navigateSlide('next');
// 			})
// 			.catch((error) => {
// 				console.error(error);
// 				message.error('Oops, you request failed. Please try in again.')
// 			});
// 	};

// 		_canvasToImage = (email) => {
// 		if (!this._canvas || !this._canvas._sketch) {
// 			return true;
// 		}

// 		let dataURL = this._canvas._sketch.toJSON(['width', 'height']);

// 		if (this._canvas._video) {
// 			dataURL.currentTime = this._canvas._video.currentTime;
// 		}

// 		dataURL = JSON.stringify(dataURL);

// 		this.socket.emit('object:slide', {
// 			source: { dataURL, email, slideHandler: this.state.slideHandler },
// 			sessionId: this.state.sessionId
// 		});
// 	};

// 	addFromUppy = (file) => {
// 		let { slides } = this.state;
// 		const record = {
// 			drive: 'uppy',
// 			name: file.name,
// 			mimeType: file.type,
// 			downloadFileType: file.extension,
// 			downloadLink: file.uploadURL,
// 			thumbnail: file.preview || file.data.thumbnail || file.data.icon || '/images/thumbnails/img-04.jpg',
// 		};
// 		slides.push(record);

// 		this.setState({ slides, slideHandler: { ...this.state.slideHandler, totalSlides: slides.length } });
// 	};

// 	onSortEnd = ({ oldIndex, newIndex }) => {
// 		if (oldIndex !== newIndex) {
// 			this.setState({ slides: arrayMove(this.state.slides, oldIndex, newIndex) });
// 		}
// 	};

// 	render() {
// 		const {
// 			workbook,
// 			isLoading,
// 			alertBox,
// 			flags,
// 			slides,
// 			sessionId,
// 			slideHandler,
// 			multiPageDoc,
// 			groupChat,
// 			compareArraySubscribers,
// 			comparePopupFlag,
// 			showSessionIdModal,
// 			tool,
// 			lineColor,
// 			imgGalleryFlag,
// 			publisher,
// 			requestFromStudent,
// 			studentList,
// 			accessToStudent,
// 			subscribers,
// 			lectureToolsFlag,
// 			isVideoPaused,
// 			isAudioPaused,
// 			isRecording,
// 			recordingState,
// 			showSetting,
// 			screenShare,
// 			studentNavigation,
// 			showColors,
// 			compareAll,
// 			compareArray,
// 			isComparePopupEnabled,
// 			isNotificationPopupEnabled,
// 			showImageUplaoder,
// 			workbookPdfName,
// 			pdfNamePopupFlag,
// 			studentLiveImageSearch,
// 			studentSettings,
// 			toolbarSize
// 		} = this.state;
// 		const { currentSlide, preview } = slideHandler;
// 		const { mainTools } = flags;
// 		const { name, subject } = workbook;
// 		const { showAlert, alertMessage, alertType } = alertBox;

// 		const { isLicensed, licenseExpiry } = this.props.auth.user;

// 		return (<>
// <Topbar
// 	toggleCollapse={this.toggleCollapse}
// 	toggleLecture={this.toggleLecture}
// 	toggleVotingPopup={this.toggleVotingPopup} />

// 			<div className="gx-main-content">
// 				<div style={{ margin: 0 }} className="gx-app-module">
// 					<div style={{ maxWidth: '100%' }} className="gx-module-box">
// 						<div className="gx-module-box-content">
// 							<LectureCanvas
// 								onRef={(c) => (this._canvas = c)}
// 								slides={slides}
// 								lineWidth={this.state.lineWidth}
// 								sessionId={sessionId}
// 								currentSlide={currentSlide}
// 								getDocDetails={this.getDocDetails}
// 								multiPageDoc={multiPageDoc}
// 								lineColor={lineColor}
// 								_canvasToImage={this._canvasToImage}
// 								showSetting={showSetting}
// 								handleSetting={this.handleSetting}
// 								updateTool={this._selectTool}
// 								studentLiveImageSearch={studentLiveImageSearch}
// 								studentSettings={studentSettings}
// 								onChangeStudentLiveSearch={this.toggleStudentLiveImageSearch}
// 								onChangeStudentSettings={this.onChangeStudentSettings}
// 								onChangToolbarSettings={this.changeToolbarSize}
// 								toolbarSize={toolbarSize}
// 							/>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 			<Sidebar visible={true} />
// 		</>)
// 	}
// }

// LecturePage.contextTypes = {
// 	router: PropTypes.object.isRequired
// };

// function mapStateToProps(state) {
// 	return { auth: state.auth };
// }

// export default connect(mapStateToProps, { saveWorkbook, getWorkbook, getSessionId, getDocDetails, addFlashMessage })(LecturePage);
