import React from "react";
import { Avatar, Button, Input, Space } from "antd";
import { Link } from "react-router";

const ProfileHeader = ({
  userProfile,
  editProfile,
  onChangeProfilePic,
  onChangeLicense,
  onRegisterLicense,
  licenseError,
}) => {
  return (
    <div className="gx-profile-banner">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar">
              <form style={{ position: "relative" }}>
                <input
                  onChange={onChangeProfilePic}
                  type="file"
                  id="mediaFile"
                  accept="image/*"
                  name="photo"
                  style={{ display: "none" }}
                />
                <label htmlFor="mediaFile" className="gx-pointer">
                  <Avatar
                    className="gx-size-90"
                    alt="Profile Photo"
                    src={userProfile.profile_photo_path}
                  />
                  <i
                    style={{
                      position: "absolute",
                      left: 65,
                      background: "#fff",
                      borderRadius: 10,
                    }}
                    className="icon icon-camera gx-fs-xxl gx-mr-2 gx-mr-sm-3 gx-font-weight-medium gx-d-inline-flex gx-vertical-align-middle gx-text-primary"
                  />
                </label>
              </form>
            </div>
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">{`${userProfile.first_name} ${userProfile.last_name}`}</h2>
              <p className="gx-mb-0 gx-fs-lg">{userProfile.email}</p>
            </div>
          </div>
          <div className="gx-profile-banner-top-right">
            <ul className="gx-follower-list">
              <li>
                <Link to="/folders">
                  <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                    {userProfile.workbookCount}
                  </span>
                  <span className="gx-fs-sm">Total Workbook</span>
                </Link>
              </li>
              <li>
                <Link to="/voting-folders">
                  <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                    {userProfile.votingActivityCount}
                  </span>
                  <span className="gx-fs-sm">Total Voting Activities</span>
                </Link>
              </li>
              <li>
                <Link to="/students">
                  <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                    {userProfile.courseCount}
                  </span>
                  <span className="gx-fs-sm">Total Classrooms</span>
                </Link>
              </li>
              <li>
                <Link to="/mGallery">
                  <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                    {userProfile.galleryCount}
                  </span>
                  <span className="gx-fs-sm">Total Gallery Files</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="gx-profile-banner-bottom">
          <div className="gx-tab-list">
            <ul className="gx-navbar-nav">
              <li>
                {userProfile.isLicensed ? (
                  "Your are using a Licensed Account"
                ) : (
                  <Space direction="vertical">
                    <Space direction="horizontal">
                      <Input
                        placeholder="Enter your license"
                        name="license"
                        onChange={onChangeLicense}
                      />
                      <Button className="gx-mt-3" onClick={onRegisterLicense}>
                        Product Serial
                      </Button>
                    </Space>
                    <span>{licenseError}</span>
                  </Space>
                )}
              </li>
              {/* <li>
                <span className="gx-link">About</span>
              </li>
              <li>
                <span className="gx-link">Photos</span>
              </li>
              <li>
                <span className="gx-link">
                  Friends <span className="gx-fs-xs">287</span>
                </span>
              </li>
              <li>
                <span className="gx-link">More</span>
              </li> */}
            </ul>
          </div>
          <span onClick={editProfile} className="gx-link gx-profile-setting">
            <i className="icon icon-edit gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
            <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">
              Edit Profile
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
