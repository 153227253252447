import { defineMessages } from "react-intl";

export default defineMessages({
  appName: {
    id: "appName",
    defaultMessage: "Contribute Cloud",
  },
  loginWithSocial: {
    id: "loginWithSocial",
    defaultMessage: "Login with social media",
  },
  loginMessage: {
    id: "loginMessage",
    defaultMessage: "LOGIN YOUR ACCOUNT",
  },
  loginSubMessage: {
    id: "loginSubMessage",
    defaultMessage: "Contribute, Collaborate, Cast",
  },
  usernameOrEmail: {
    id: "usernameOrEmail",
    defaultMessage: "Username or Email",
  },
  password: {
    id: "password",
    defaultMessage: "Password",
  },
  passwordConfirmation: {
    id: "passwordConfirmation",
    defaultMessage: "Password Confirmation",
  },
  forgottenYourPassword: {
    id: "forgottenYourPassword",
    defaultMessage: "Forgotten Your Password",
  },
  forgottenPopupMessage: {
    id: "forgottenPopupMessage",
    defaultMessage:
      "If you have forgotten your password, you can reset it here. Enter your username or email address and we will send you a reset link. This can take 2-3 minutes and don’t forget to check your junk mail.",
  },
  sendResetLink: {
    id: "sendResetLink",
    defaultMessage: "Send Reset Link",
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel",
  },
  login: {
    id: "login",
    defaultMessage: "Login",
  },
  createYourAccount: {
    id: "createYourAccount",
    defaultMessage: "Don’t have an account? {click_here}",
  },
  createOne: {
    id: "createOne",
    defaultMessage: "Create One",
  },
  signupMessage: {
    id: "signupMessage",
    defaultMessage: "CREATE YOUR ACCOUNT",
  },
  signupSubMessage: {
    id: "signupSubMessage",
    defaultMessage: "It takes less than a minute.",
  },
  fullName: {
    id: "fullName",
    defaultMessage: "Full Name",
  },
  firstName: {
    id: "firstName",
    defaultMessage: "First Name",
  },
  lastName: {
    id: "lastName",
    defaultMessage: "Last Name",
  },
  username: {
    id: "username",
    defaultMessage: "Username",
  },
  email: {
    id: "email",
    defaultMessage: "Email",
  },
  signup: {
    id: "signup",
    defaultMessage: "Signup",
  },
  loginYourAccount: {
    id: "loginYourAccount",
    defaultMessage: "If you have an account {click_here} to Login",
  },
  resetMessage: {
    id: "resetMessage",
    defaultMessage: "RESET YOUR PASSWORD",
  },
  resetSubMessage: {
    id: "resetSubMessage",
    defaultMessage: "Type your new password.",
  },
  startWhiteBoardSession: {
    id: "startWhiteBoardSession",
    defaultMessage: "Start White Board Session",
  },
  createWorkbook: {
    id: "createWorkbook",
    defaultMessage: "Create Workbook",
  },
  dashboardInfoText: {
    id: "dashboardInfoText",
    defaultMessage: "You have {count} workbooks saved in your account",
  },
  startNewLecture: {
    id: "startNewLecture",
    defaultMessage: "Start New Lecture",
  },
  workbookName: {
    id: "workbookName",
    defaultMessage: "Workbook Name",
  },
  workbookSubject: {
    id: "workbookSubject",
    defaultMessage: "Workbook Subject/Topic",
  },
  description: {
    id: "description",
    defaultMessage: "Description",
  },
  optional: {
    id: "optional",
    defaultMessage: "Optional",
  },
  startLecture: {
    id: "startLecture",
    defaultMessage: "Start Lecture",
  },
  createNewWorkbook: {
    id: "createNewWorkbook",
    defaultMessage: "Create New Worbook",
  },
  areYouSure: {
    id: "areYouSure",
    defaultMessage: "Are you sure?",
  },
  yes: {
    id: "yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "no",
    defaultMessage: "no",
  },
  wantToLogout: {
    id: "wantToLogout",
    defaultMessage: "You want to logout",
  },
  dashboard: {
    id: "dashboard",
    defaultMessage: "Dashboard",
  },
  participantLists: {
    id: "participantLists",
    defaultMessage: "Participant Lists",
  },
  videoTutorials: {
    id: "videoTutorials",
    defaultMessage: "Video Tutorials",
  },
  onlineManual: {
    id: "onlineManual",
    defaultMessage: "Online Manual",
  },
  latestUpdate: {
    id: "latestUpdate",
    defaultMessage: "Latest Update",
  },
  reportABug: {
    id: "reportABug",
    defaultMessage: "Report a Bug",
  },
  profilePage: {
    id: "profilePage",
    defaultMessage: "Profile Page",
  },
  wantToolsInSession: {
    id: "wantToolsInSession",
    defaultMessage:
      "Do you want access to the Education Tools in you sessions?",
  },
  updateProfile: {
    id: "updateProfile",
    defaultMessage: "Update Profile",
  },
  newList: {
    id: "newList",
    defaultMessage: "New List",
  },
  googleClassroon: {
    id: "googleClassroon",
    defaultMessage: "Google Classroon",
  },
  listName: {
    id: "listName",
    defaultMessage: "List Name",
  },
  actions: {
    id: "actions",
    defaultMessage: "Actions",
  },
  add: {
    id: "add",
    defaultMessage: "Add",
  },
  edit: {
    id: "edit",
    defaultMessage: "Edit",
  },
  ManageParticipants: {
    id: "ManageParticipants",
    defaultMessage: " Manage Participants",
  },
  editListName: {
    id: "editListName",
    defaultMessage: "Edit List Name",
  },
  delete: {
    id: "delete",
    defaultMessage: "Delete",
  },
  WorkbookSlides: {
    id: "WorkbookSlides",
    defaultMessage: "Workbook Slides",
  },
  categoryCount: {
    id: "categoryCount",
    defaultMessage: "YOU HAVE {count} CATEGORIES",
  },
  videoFiles: {
    id: "videoFiles",
    defaultMessage: "Video Files",
  },
  pdfFiles: {
    id: "pdfFiles",
    defaultMessage: "PDF Files",
  },
  notebookFiles: {
    id: "notebookFiles",
    defaultMessage: "Notebook Files",
  },
  wordDocumentFiles: {
    id: "wordDocumentFiles",
    defaultMessage: "Word Document Files",
  },
  imagesFiles: {
    id: "imagesFiles",
    defaultMessage: "Images Files",
  },
  excelFiles: {
    id: "excelFiles",
    defaultMessage: "Excel Files",
  },
  presentationsFiles: {
    id: "presentationsFiles",
    defaultMessage: "Presentations Files",
  },
  manageList: {
    id: "manageList",
    defaultMessage: "Manage {name} List",
  },
  participantName: {
    id: "participantName",
    defaultMessage: "Participant Name",
  },
  participantEmail: {
    id: "participantEmail",
    defaultMessage: "Participant Email",
  },
  addParticipantToList: {
    id: "addParticipantToList",
    defaultMessage: "Add Participant to list",
  },
  deleteWorkbook: {
    id: "deleteWorkbook",
    defaultMessage: "Delete Workbook",
  },
  inviteParticipants: {
    id: "inviteParticipants",
    defaultMessage: "Invite participants",
  },
  groupName: {
    id: "groupName",
    defaultMessage: "Group Name",
  },
  sendEmailInvitation: {
    id: "sendEmailInvitation",
    defaultMessage: "Send Email Invitation",
  },
  editorActions: {
    id: "editorActions",
    defaultMessage: "Save, Invite Participants or Play your Workbook.",
  },
  totalSlideMessage: {
    id: "totalSlideMessage",
    defaultMessage: "Total {count} Slides in this Workbook",
  },
  saveSlide: {
    id: "saveSlide",
    defaultMessage: "Save Slide",
  },
  endSession: {
    id: "endSession",
    defaultMessage: "End Session",
  },
  wantToSaveAnnotations: {
    id: "wantToSaveAnnotations",
    defaultMessage: "You want to save your annotations.",
  },
  oldPassword: {
    id: "oldPassword",
    defaultMessage: "Old Password",
  },
  newPassword: {
    id: "newPassword",
    defaultMessage: "New Password",
  },
  retypeNewPassword: {
    id: "retypeNewPassword",
    defaultMessage: "Retype New Password",
  },
  editWorkbook: {
    id: "editWorkbook",
    defaultMessage: "Edit Workbook",
  },
  updateWorkbook: {
    id: "updateWorkbook",
    defaultMessage: "Update Workbook",
  },
  serialNumber: {
    id: "serialNumber",
    defaultMessage: "Sr.",
  },
  deleteParticipant: {
    id: "deleteParticipant",
    defaultMessage: "Delete Participant",
  },
  createNewList: {
    id: "createNewList",
    defaultMessage: "Create New List",
  },
  participantList: {
    id: "participantList",
    defaultMessage: "Participant List",
  },
  deleteParticipantList: {
    id: "deleteParticipantList",
    defaultMessage: "Delete Participant List",
  },
  videoPercentage: {
    id: "videoPercentage",
    defaultMessage: "Video Percentage",
  },
  pdfPercentage: {
    id: "pdfPercentage",
    defaultMessage: "PDF Percentage",
  },
  notebookPercentage: {
    id: "notebookPercentage",
    defaultMessage: "Notebook Percentage",
  },
  wordDocumentPercentage: {
    id: "wordDocumentPercentage",
    defaultMessage: "Word Document Percentage",
  },
  imagesPercentage: {
    id: "imagesPercentage",
    defaultMessage: "Images Percentage",
  },
  excelPercentage: {
    id: "excelPercentage",
    defaultMessage: "Excel Percentage",
  },
  presentationsPercentage: {
    id: "presentationsPercentage",
    defaultMessage: "Presentations Percentage",
  },
  updateListName: {
    id: "updateListName",
    defaultMessage: "Update List Name",
  },
  close: {
    id: "close",
    defaultMessage: "Close",
  },
  addParticipant: {
    id: "addParticipant",
    defaultMessage: "Add Participant",
  },
  participants: {
    id: "participants",
    defaultMessage: "Participants",
  },
  noOfUsers: {
    id: "noOfUsers",
    defaultMessage: "No. of Users",
  },
  wantToDeleteSlide: {
    id: "wantToDeleteSlide",
    defaultMessage: "You want to Delete Slide.",
  },
  youtubeLink: {
    id: "youtubeLink",
    defaultMessage: "Add Youtube Video URL",
  },
  embed: {
    id: "embed",
    defaultMessage: "Embed",
  },
  clickHere: {
    id: "clickHere",
    defaultMessage: "Click Here",
  },
  reset: {
    id: "reset",
    defaultMessage: "Reset",
  },
});
