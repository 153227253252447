import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ToolHolder from '../holder/ToolHolder'
import s from './Protractor.module.css';
import Draggable from 'react-draggable'


class Protractor extends Component {
    static propTypes = {
        closeTool: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props)

        this.state = {
            isRotating: false,
            isMoving: false,
            isScaling: false,
            isScalingX: false,
            isScalingY: false,

            boxScaleX: 1.0,
            boxScaleY: 1.0,

            boxX: 200,
            boxY: 200,

            lastX: 0,
            lastY: 0,

            boxWidth: 0,
            boxHeight: 0,
            boxLeft: 0,
            boxTop: 0,

            initialAngle: 0,
            lastAngle: 0,
            currentAngle: 0,
            origin: {
                x: 0,
                y: 0
            }

        }
    }

    componentDidMount = () => {
        this.handler1.addEventListener("mousedown", this.handlerMouseDown)
        this.handler1.addEventListener('touchstart', this.handlerMouseDown, false);

        this.handler2.addEventListener("mousedown", this.handlerMouseDown)
        this.handler2.addEventListener('touchstart', this.handlerMouseDown, false);

        this.handler3.addEventListener("mousedown", this.handlerMouseDown)
        this.handler3.addEventListener('touchstart', this.handlerMouseDown, false);

        this.handler4.addEventListener("mousedown", this.handlerMouseDown)
        this.handler4.addEventListener('touchstart', this.handlerMouseDown, false);


    }

    componentWillUnmount = () => {
        this.handler1.removeEventListener("mousedown", this.handlerMouseDown)
        this.handler1.removeEventListener('touchstart', this.handlerMouseDown, false);

        this.handler2.removeEventListener("mousedown", this.handlerMouseDown)
        this.handler2.removeEventListener('touchstart', this.handlerMouseDown, false);

        this.handler3.removeEventListener("mousedown", this.handlerMouseDown)
        this.handler3.removeEventListener('touchstart', this.handlerMouseDown, false);

        this.handler4.removeEventListener("mousedown", this.handlerMouseDown)
        this.handler4.removeEventListener('touchstart', this.handlerMouseDown, false);

    }

    isScaleHandlerX = (handler) => {
        return handler.classList.contains(s["sl"]) ||
            handler.classList.contains(s["sr"]);
    }

    isScaleHandlerY = (handler) => {
        return handler.classList.contains(s["st"]) ||
            handler.classList.contains(s["sb"]);
    }

    isScaleHandler = (handler) => {
        return handler.classList.contains(s["sl"]) ||
            handler.classList.contains(s["sr"]) ||
            handler.classList.contains(s["st"]) ||
            handler.classList.contains(s["sb"]);
    }

    isRotationHandler = (handler) => {
        return handler.classList.contains(s["lt"]) ||
            handler.classList.contains(s["rt"]) ||
            handler.classList.contains(s["lb"]) ||
            handler.classList.contains(s["rb"]);
    }

    boxMouseMove = (e) => {
        let { boxX, boxY, lastX, lastY, currentAngle, boxScaleX, boxScaleY } = this.state
        boxX += e.clientX - lastX;
        boxY += e.clientY - lastY;

        lastX = e.clientX;
        lastY = e.clientY;
        this.setState({ boxX, boxY, lastX, lastY })

        this.box.style.transform = "transform-origin: 0% 0%; translate(" + boxX + "px," + boxY + "px) rotate(" + Math.round(currentAngle * 180 / Math.PI) + "deg) scale(" + boxScaleX + "," + boxScaleY + ")";
    }

    boxMouseUp = (e) => {
        if (e.button === 0) {
            document.removeEventListener("mousemove", this.handlerMouseMove);
            document.removeEventListener('touchmove', this.handlerMouseMove, false);

            document.removeEventListener("mouseup", this.handlerMouseUp);
            document.removeEventListener('touchend', this.handlerMouseUp, false);
            this.setState({ isMoving: false })
        }
    }

    handlerMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let currentTarget = e.currentTarget
        if (e.touches && e.touches.length) {
            currentTarget = e.touches[0].target;
            e.clientX = e.touches[0].clientX;
            e.clientY = e.touches[0].clientY;

        }

        let { isMoving, origin } = this.state

        if (isMoving === false) {
            var _box$getBoundingClien2 = this.box.getBoundingClientRect(),
                _boxWidth = _box$getBoundingClien2.width,
                _boxHeight = _box$getBoundingClien2.height,
                _boxLeft = _box$getBoundingClien2.left,
                _boxTop = _box$getBoundingClien2.top;

            let isScalingX = this.isScaleHandlerX(currentTarget);
            let isScalingY = this.isScaleHandlerY(currentTarget);





            if (isScalingX) {
                let lastX = e.clientX;
                this.setState({ lastX })
            } else if (isScalingY) {
                let lastY = e.clientY;
                this.setState({ lastY })
            }

            let initialAngle = Math.atan2(e.clientY - origin.y, e.clientX - origin.x);


            this.setState({
                initialAngle,
                isScalingX,
                isScalingY,
                isScaling: this.isScaleHandler(currentTarget),
                isRotating: this.isRotationHandler(currentTarget),
                origin: { ...this.state.origin, x: _boxLeft + (_boxWidth >> 1), y: _boxTop + (_boxHeight >> 1) }
            })
        }
        document.addEventListener("mousemove", this.handlerMouseMove);
        document.addEventListener('touchmove', this.handlerMouseMove, false);

        document.addEventListener("mouseup", this.handlerMouseUp);
        document.addEventListener('touchend', this.handlerMouseUp, false);
        // }
    }

    handlerMouseMove = (e) => {
        let { currentAngle, isScaling, isScalingX, isScalingY, isRotating, origin, initialAngle, lastX, lastY, lastAngle, boxX, boxY, boxScaleX, boxScaleY } = this.state
        console.log(this.box.style.transform)

        if (e.touches && e.touches.length) {
            e.clientX = e.touches[0].clientX;
            e.clientY = e.touches[0].clientY;

        }

        var style = window.getComputedStyle(this.box);
        var matrix = new WebKitCSSMatrix(style.webkitTransform);
        let tranlateX = matrix.m41
        let tranlateY = matrix.m42

        if (isRotating) {
            var angleToOrigin = Math.atan2(e.clientY - origin.y, e.clientX - origin.x);
            var angle = angleToOrigin - initialAngle;
            currentAngle = angle + lastAngle;

            this.setState({ currentAngle })
            this.box.style.transform = "translate(" + tranlateX + "px," + tranlateY + "px) rotate(" + Math.round(currentAngle * 180 / Math.PI) + "deg) scale(" + boxScaleX + "," + boxScaleY + ")";
        } else if (isScaling) {
            if (isScalingX) {
                boxScaleX -= (e.clientX - lastX) / 200;
                lastX = e.clientX;

                this.setState({ boxScaleX, lastX })
            } else if (isScalingY) {
                boxScaleY -= (e.clientY - lastY) / 200;
                lastY = e.clientY;

                this.setState({ boxScaleY, lastY })
            }
            this.box.style.transform = "translate(" + tranlateX + "px," + tranlateY + "px) rotate(" + Math.round(currentAngle * 180 / Math.PI) + "deg) scale(" + boxScaleX + "," + boxScaleY + ")";
        }
    }

    handlerMouseUp = (e) => {
        let { lastAngle, currentAngle, isRotating, isScaling } = this.state
        if (e.button === 0) {
            document.removeEventListener("mousemove", this.handlerMouseMove);
            document.removeEventListener('touchmove', this.handlerMouseMove, false);

            document.removeEventListener("mouseup", this.handlerMouseUp);
            document.removeEventListener('touchend', this.handlerMouseUp, false);

            lastAngle = currentAngle;
            if (isRotating === true) {
                isRotating = false;
            } else if (isScaling === true) {
                isScaling = false;
            }
            this.setState({ lastAngle, isRotating, isScaling })
        }
    }

    handleStop = () => {
        let { currentAngle, boxScaleX, boxScaleY } = this.state

        var style = window.getComputedStyle(this.box);
        var matrix = new WebKitCSSMatrix(style.webkitTransform);
        let tranlateX = matrix.m41
        let tranlateY = matrix.m42

        this.box.style.transform = "translate(" + tranlateX + "px," + tranlateY + "px) rotate(" + Math.round(currentAngle * 180 / Math.PI) + "deg) scale(" + boxScaleX + "," + boxScaleY + ")";
    }



    render() {
        let { closeTool } = this.props
        return (<Draggable handle={`.${s["box"]}`} onStop={this.handleStop}>
            <div ref={b => this.box = b} id="protector" className={s["box"]}>
                {/* <img src="images/protractor.png" /> */}
                <a onClick={closeTool.bind(this, 'Protractor')} className={s["at-btnclosetool"]}><i className="fa fa-times"></i></a>

                <div ref={h => this.handler1 = h} className={cx(s["handler"], s["lt"])}></div>
                <div ref={h => this.handler2 = h} className={cx(s["handler"], s["rt"])}></div>
                <div ref={h => this.handler3 = h} className={cx(s["handler"], s["lb"])}></div>
                <div ref={h => this.handler4 = h} className={cx(s["handler"], s["rb"])}>   </div>            </div>
        </Draggable>);
    }
}

export default Protractor;
