import "assets/vendors/style";

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Router, browserHistory } from "react-router";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./rootReducer";
import setAuthorizationToken from "./utils/setAuthorizationToken";
import jwtDecode from "jwt-decode";
import { setCurrentUser, getAuthToken } from "./actions/authActions";
import routes from "./routes";
import LanguageProvider from "./LanguageProvider";
import { translationMessages } from "./i18n";
import DevTools from "./DevTools";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

let store;

if (process.env.NODE_ENV === "production") {
  store = createStore(rootReducer, compose(applyMiddleware(thunk)));
} else {
  store = createStore(
    rootReducer,
    compose(applyMiddleware(thunk, createLogger()), DevTools.instrument())
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./rootReducer", () => {
      store.replaceReducer(rootReducer);
    });
  }
}

if (localStorage.jwtToken) {
  (async () => {
    setAuthorizationToken(localStorage.jwtToken);
    store.dispatch(setCurrentUser(jwtDecode(localStorage.jwtToken)));
    try {
      let result = await getAuthToken();
      const token = result.data.token;
      localStorage.setItem("jwtToken", token);
      setAuthorizationToken(token);
      store.dispatch(setCurrentUser(jwtDecode(token)));
    } catch (error) {
      console.log(error);
    }
  })();
}

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider messages={translationMessages}>
      <Router history={browserHistory} routes={routes} />
    </LanguageProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
