import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import s from './ModalPopup.module.css';

class ModalPopup extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        backdrop: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
        size: PropTypes.string,

        onToggle: PropTypes.func.isRequired,

    };

    static defaultProps = {
        title: '',
        size: 'md',
        backdrop: 'static',
        isOpen: false,
        onToggle: () => { },
    };

    render() {
        const {
            title,
            size,
            backdrop,
            isOpen,
            onToggle
        } = this.props;
        return (
            <Modal className={s['popup-content']} isOpen={isOpen} toggle={onToggle} backdrop={backdrop} size={size}>
                <ModalHeader className={s['popup-header']} toggle={onToggle}>
                    {title}
                    <div className={s['popup-img']}>
                        <img src={'images/popup-img.png'} />
                    </div>
                </ModalHeader>
                <ModalBody>
                    {this.props.children}
                </ModalBody>
            </Modal>
        );
    }
}

export default ModalPopup;