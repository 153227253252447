import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import TextFieldGroup from '../../common/TextFieldGroup';
import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import PopupTypeOne from '../../common/PopupTypeOne';

import s from '../WorkbookPage.module.css';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages';

class YoutubePage extends Component {
	static propTypes = {
		embedYoutubeVideo: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			youtubeLink: '',
			youtubeThumbnail: '',
			errors: {},
			isLoading: false,
			isPopupOpen: false
		};
	}

	isValid = () => {
		const errors = {};

		if (Validator.isNull(this.state.youtubeLink)) {
			errors.youtubeLink = 'This field is required';
		}
		let youtubeRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;
		const results = this.state.youtubeLink.match(youtubeRegex);
		if (!results) {
			errors.youtubeLink = 'Enter Valid URL';
		}

		const isValid = isEmpty(errors);

		if (!isValid) {
			this.setState({ errors });
		}

		return isEmpty(errors);
	};

	onSubmit = (e) => {
		e.preventDefault();
		if (this.isValid()) {
			const { embedYoutubeVideo } = this.props;
			this.setState({ errors: {}, isLoading: true });
			embedYoutubeVideo({ youtubeLink: this.state.youtubeLink, youtubeThumbnail: this.state.youtubeThumbnail });
			this.setState({ youtubeLink: '', youtubeThumbnail: '' });
			this.onToggle();
		}
	};

	onChange = (e) => {
		const errors = {};
		let videoID = '';
		let nsgYoutubeVideoRegex = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
		var match = e.target.value.match(nsgYoutubeVideoRegex);
		if (match && match[2].length == 11) {
			videoID = match[2];
		} else {
			errors.youtubeLink = 'Enter Valid URL';
		}

		const isValid = isEmpty(errors);

		if (!isValid) {
			this.setState({
				errors,
				[e.target.name]: e.target.value,
				youtubeThumbnail: `https://img.youtube.com/vi/${videoID}/0.jpg`
			});
		} else {
			let youtubeLink = e.target.value.replace('m.youtube.com', 'youtube.com');
			this.setState({
				[e.target.name]: youtubeLink,
				youtubeThumbnail: `https://img.youtube.com/vi/${videoID}/0.jpg`
			});
		}
	};

	onToggle = () => {
		this.setState({ isPopupOpen: !this.state.isPopupOpen, errors: {}, isLoading: false });
	};

	render() {
		const { isPopupOpen, youtubeLink, youtubeThumbnail, errors, isLoading } = this.state;

		return (
			<Fragment>
				<li onClick={this.onToggle} className={s['at-youtube']}>
					<a href={null}>
						<i className={s['at-divebtnholder']}>
							<img src="images/youtube-icon.png" />
						</i>
						<span>Youtube</span>
					</a>
				</li>

				<PopupTypeOne isOpen={isPopupOpen} onToggle={this.onToggle} size={'md'} title={'Youtube Link'}>
					{/* <h3>Enter Youtube Link</h3> */}
					<form onSubmit={this.onSubmit}>
						<TextFieldGroup
							field="youtubeLink"
							label={<FormattedMessage {...messages.youtubeLink} />}
							value={youtubeLink}
							error={errors.youtubeLink}
							onChange={this.onChange}
						/>

						<img src={youtubeThumbnail} style={{width: '100%', height: 'auto', paddingTop: 20}} className="img-responsive" />
						<div className={s['at-btnarea']}>
							<button type="submit" disabled={isLoading} className="btn at-themebtn">
								<FormattedMessage {...messages.embed} />
							</button>
							<button type="button" onClick={this.onToggle} className="btn at-themebtn">
								<FormattedMessage {...messages.cancel} />
							</button>
						</div>
					</form>
				</PopupTypeOne>
			</Fragment>
		);
	}
}

export default YoutubePage;
