import axios from 'axios';
import { SET_USER_WORKBOOK } from './types';

export function setUserWorkbook(workbook) {
  return { type: SET_USER_WORKBOOK, workbook };
}

export function createWorkbook(workbook) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/new`, workbook);
}

export function fetchWorkbooks() {
  return dispatch => axios.get(`${process.env.REACT_APP_API_HOST}/api/workbook/fetch`).then((res) => {
    dispatch(setUserWorkbook(res.data.workbooks));
  });
}

export function fetchFolders() {
  return axios.get(`${process.env.REACT_APP_API_HOST}/api/workbook/folders`);
}

export function changeFolderTitle(data) {
  return axios.put(`${process.env.REACT_APP_API_HOST}/api/workbook/folders`, data);
}

export function updateWorkbook(workbook) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/update`, workbook);
}

export function saveWorkbook(workbook) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/saveData`, workbook);
}

export function getWorkbook(workbook) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/getWorkbook`, workbook);
}

export function deleteLecture(workbookID, userID) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/deleteWorkbook`, { workbookID, userID }).then((res) => {
    dispatch(setUserWorkbook(res.data.workbooks));
  });
}

export function downloadFile() {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/downloadFile`);
}
export function downloadPDF(data) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/downloadPDF`, data);
}
export function addSlideToEnd(data) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/addSlideToEnd`, data);
}
export function downloadLecture(id) {
  return dispatch => axios.get(`${process.env.REACT_APP_API_HOST}/api/workbook/${id}`);
}

export function addWhiteBoard(data) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/workbook/addWhiteBoard`, data);
}

