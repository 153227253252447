import React from "react";
import ReactDOM from "react-dom";
import cx from 'classnames'
var createReactClass = require('create-react-class');
import map from "lodash/map";
import { myLayout } from "./golden_layout_setup";

let toolList = {
  English: [
    { icon: "/_word_puzzle/icon-16.png", text: "Word Puzzle", type: "word-puzzle" },
    { icon: "/games/freeWords/sprites/200x200.jpg", text: "Free Words", type: "GoldenFreeWords" },
    { icon: "/games/magicWords/assets/logo.png", text: "Magic Words", type: "GoldenMagicWords" },
    { icon: "/games/waffleWord/sprites/200x200.jpg", text: "Waffle Word", type: "GoldenWaffleWord" },
    { icon: "/games/wordMaker/images/title.png", text: "Word Maker", type: "GoldenWordMaker" },
    { icon: "/games/wordUp/img/game-icon.png", text: "Word Up", type: "GoldenWordUp" },
    { icon: "/games/Language_quiz2/assets/logo.png", text: "Quiz 2", type: "Language_quiz2" },
    { icon: "/games/Language_rearrangeLetters2/icon.ico", text: "Rearrange Letters 2", type: "Language_rearrangeLetters2" },
    { icon: "/games/Language_wordFinder/sprites/but_credits.png", text: "Word Finder", type: "Language_wordFinder" },
  ],
  Math: [
    { icon: "/games/countCars/images/how_rightcar-sheet0.png", text: "Count Cars", type: "GoldenCountCars" },
    { icon: "/_solve_math/icon.ico", text: "Solve Math", type: "solve-math" },
    { icon: "/_mathboy/icons/icon-114.png", text: "Math Boy", type: "math-boy" },
    { icon: "/games/evenOdd/images/evenodd_task-sheet0.png", text: "Even Odd", type: "GoldenEvenOdd" },
    { icon: "/games/evenOddPop/images/numbers_menu-sheet0.png", text: "Even Odd Pop", type: "GoldenEvenOddPop" },
    { icon: "/games/evenOddShip/images/player-sheet0.png", text: "Even Odd Ship", type: "GoldenEvenOddShip" },
    { icon: "/games/foodCount/images/mission-sheet0.png", text: "Food Count", type: "GoldenFoodCount" },
    { icon: "/games/letsReach1000/images/title2-sheet0.png", text: "Lets Reach 1000", type: "GoldenLetsReach1000" },
    { icon: "/games/letsReach1000ASAP/images/title2-sheet0.png", text: "Lets Reach 1000 ASAP", type: "GoldenLetsReach1000ASAP" },
    { icon: "/games/letsReachZero/images/title2-sheet0.png", text: "Lets Reach Zero", type: "GoldenLetsReachZero" },
    { icon: "/games/letsReachZeroASAP/images/title2-sheet0.png", text: "Lets Reach Zero ASAP", type: "GoldenLetsReachZeroASAP" },
    { icon: "/games/mathsTrueorFalse/images/play-sheet0.png", text: "Maths True or False", type: "GoldenMathsTrueorFalse" },
    { icon: "/games/missingNumber/images/title-sheet0.png", text: "Missing Number", type: "GoldenMissingNumber" },
    { icon: "/games/textMaths/images/title-sheet0.png", text: "Text Maths", type: "GoldenTextMaths" },
    { icon: "/games/numbersMemory/images/number-sheet1.png", text: "Numbers Memory", type: "GoldenNumbersMemory" },
    { icon: "/games/pickTheLock/images/play_button-sheet0.png", text: "Pick The Lock", type: "GoldenPickTheLock" },
    { icon: "/games/countFingers/images/menu_hand-sheet0.png", text: "Count Fingers", type: "GoldenCountFingers" },
    { icon: "/_complete_the_sequence/icons/icon-114.png", text: "Complete The Sequence", type: "complete-the-sequence" },
    { icon: "/games/addition/images/plus_sign-sheet0.png", text: "Addition", type: "GoldenAddition" },
    { icon: "/games/division/images/plus_sign-sheet0.png", text: "Division", type: "GoldenDivision" },
    { icon: "/games/multiplication/images/plus_sign-sheet0.png", text: "Multiplication", type: "GoldenMultiplication" },
    { icon: "/games/subtraction/images/plus_sign-sheet0.png", text: "Subtraction", type: "GoldenSubtraction" },
    { icon: "/games/textMathsNegative/images/title-sheet0.png", text: "Text Maths Negative", type: "GoldenTextMathsNegative" },


    { icon: "/games/Math_genius2/images/symbols-sheet0.png", text: "Genius 2", type: "Math_genius2" },
    { icon: "/games/Math_guessHowMany/images/animals-sheet1.png", text: "Guess How Many", type: "Math_guessHowMany" },
    { icon: "/games/Math_WhatIsInside/images/boxthin-sheet0.png", text: "What Is Inside", type: "Math_WhatIsInside" },
    { icon: "/games/quizMath/images/title-sheet0.png", text: "Quiz Math", type: "GoldenQuizMath" },


  ],
  Geography: [
    { icon: "/games/Geography_unitedStatesMapGame50States/icon-128.png", text: "United States Map 50 States", type: "Geography_unitedStatesMapGame50States" },
  ],

  'Pre-K': [
    { icon: "/games/PreK_coloringBook/images/selectscreen-sheet0.png", text: "Coloring Book", type: "PreK_coloringBook" },
    { icon: "/games/Math_guessHowMany/images/animals-sheet1.png", text: "Memory Game", type: "PreK_memoryGame" },

  ],
  Fun: [
    { icon: "/_snakes_and_ladders/thumbs/80x80.png", text: "Snake And Ladders", type: "snake-and-ladders" },
    { icon: "/games/golfSport/sprites/bg_menu.jpg", text: "Golf Sport", type: "GoldenGolfSport" },
    { icon: "/games/piggyBank/loading-logo.png", text: "Piggy Bank", type: "GoldenPiggyBank" },
    { icon: "/games/pool8ball/loading-logo.png", text: "Pool 8 ball", type: "GoldenPool8ball" },
  ],
  Music: [
    { icon: "/_virtual_piano/icons/icon-128.png", text: "Piano", type: "virtual-piano" },
  ]
};


var MenuItem = createReactClass({
  componentWillMount: function () {
    var newItemConfig = {
      title: this.props.text,
      type: "component",
      componentName: this.props.type,
      componentState: { text: this.props.text }
    };
    this.setState({ newItemConfig: newItemConfig });
  },

  componentDidMount: function () {
    myLayout.createDragSource(ReactDOM.findDOMNode(this), this.state.newItemConfig);
  },

  render: function () {
    return <li><img src={this.props.icon} /></li>;
  }
});

var GamesList = createReactClass({
  getInitialState: function () {
    return { toolType: 'Math' };
  },

  selectTool: function (type) {
    this.setState({ toolType: type })
  },

  componentWillMount: function () { },

  componentDidMount: function () { },

  render: function () {
    const { toolType } = this.state
    const nsgEnglishTools = map(toolList['English'], (value, key) => (
      <MenuItem icon={value.icon} text={value.text} type={value.type} />
    ));

    const nsgFunTools = map(toolList['Fun'], (value, key) => (
      <MenuItem icon={value.icon} text={value.text} type={value.type} />
    ));

    const nsgMathTools = map(toolList['Math'], (value, key) => (
      <MenuItem icon={value.icon} text={value.text} type={value.type} />
    ));

    const nsgMusicTools = map(toolList['Music'], (value, key) => (
      <MenuItem icon={value.icon} text={value.text} type={value.type} />
    ));

    const nsgGeographyTools = map(toolList['Geography'], (value, key) => (
      <MenuItem icon={value.icon} text={value.text} type={value.type} />
    ));

    const nsgPreKTools = map(toolList['Pre-K'], (value, key) => (
      <MenuItem icon={value.icon} text={value.text} type={value.type} />
    ));

    return (<div className="golder-layout-header">
      <img src="images/logo-b.png" />
      <ul className="at-gametabmenu">
        <li className={cx({ 'at-active': toolType == 'Math' })} onClick={this.selectTool.bind(this, 'Math')}>Math</li>
        <li className={cx({ 'at-active': toolType == 'English' })} onClick={this.selectTool.bind(this, 'English')}>Language</li>
        <li className={cx({ 'at-active': toolType == 'Geography' })} onClick={this.selectTool.bind(this, 'Geography')}>Geography</li>
        <li className={cx({ 'at-active': toolType == 'Pre-K' })} onClick={this.selectTool.bind(this, 'Pre-K')}>Pre-K</li>
        <li className={cx({ 'at-active': toolType == 'Music' })} onClick={this.selectTool.bind(this, 'Music')}>Music</li>
        <li className={cx({ 'at-active': toolType == 'Fun' })} onClick={this.selectTool.bind(this, 'Fun')}>Fun</li>
      </ul>
      <ul className={cx('at-allgames', { 'd-none': toolType !== 'English' })} >
        {nsgEnglishTools}
      </ul>

      <ul className={cx('at-allgames', { 'd-none': toolType !== 'Math' })}>
        {nsgMathTools}

      </ul>

      <ul className={cx('at-allgames', { 'd-none': toolType !== 'Geography' })}>
        {nsgGeographyTools}

      </ul>
      <ul className={cx('at-allgames', { 'd-none': toolType !== 'Pre-K' })}>
        {nsgPreKTools}

      </ul>
      <ul className={cx('at-allgames', { 'd-none': toolType !== 'Fun' })}>
        {nsgFunTools}

      </ul>

      <ul className={cx('at-allgames', { 'd-none': toolType !== 'Music' })}>
        {nsgMusicTools}
      </ul>
    </div>);
  }
});


function buildMenu() {
  ReactDOM.render(
    <GamesList />,
    document.getElementById("goldenlayoutmenuContainer")
  );
}


export { buildMenu };