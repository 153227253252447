import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ToolHolder from '../holder/ToolHolder'
import s from './index.module.css';

class PianoHolder extends Component {
    static propTypes = {
        closeTool: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props)

        this.state = {}
    }
    render() {
        let { closeTool } = this.props
        return (<ToolHolder name={'Piano'} closeTool={closeTool} tool={'Piano'}>
            <div className={cx(s["at-opancalculator"])}>
                <iframe src={"/virtualpiano"} style={{ width: "100%", height: "100%" }} />
            </div>
        </ToolHolder>);
    }
}

export default PianoHolder;
