import axios from 'axios';

export function getToken(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/openvidu/tokens`, data);
}

export function getSessions(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/openvidu/sessions`, data);
}

export function getSession(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/openvidu`, data);
}

export function destroySession(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/openvidu/destroySession`, data);
}
