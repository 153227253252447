
import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateInput(data) {
  const errors = {};

  if (Validator.isNull(data.first_name)) {
    errors.first_name = 'First Name field is required';
  }

  if (Validator.isNull(data.last_name)) {
    errors.last_name = 'Last Name field is required';
  }

  if (Validator.isNull(data.username)) {
    errors.username = 'Username field is required';
  }

  if (Validator.isNull(data.email)) {
    errors.email = 'Email field is required';
  }

  if (!errors.email && !Validator.isEmail(data.email)) {
    errors.email = 'Email is invalid';
  }


  if (!Validator.isNull(data.new_pass) && Validator.isNull(data.old_pass)) {
    errors.old_pass = 'Old password is require to update password';
  }

  if (!Validator.equals(data.new_pass, data.re_new_pass)) {
    errors.re_new_pass = 'Passwords must match';
  }

  return { errors, isValid: isEmpty(errors) };
}
