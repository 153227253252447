import React, { Component } from "react";
import { connect } from "react-redux";
import validateInput from "../../validations/profile";
import TextFieldGroup from "../common/TextFieldGroup/TextFieldGroup";
import PropTypes from "prop-types";
import { Link } from "react-router";
import cx from "classnames";
import map from "lodash/map";
import { addFlashMessage } from "../../actions/flashMessages";
import { isUserExists } from "../../actions/authActions";
import { updateUserProfile } from "../../actions/users";
import supportedLocale from "../../data/locale";
import s from "./ProfilePage.module.css";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import messages from "../../messages";
class ProfileForm extends Component {
  static propTypes = {
    userProfile: PropTypes.object.isRequired,
    getUserProfile: PropTypes.func.isRequired,
    updateUserProfile: PropTypes.func.isRequired,
    isUserExists: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      locale: "",
      old_pass: "",
      new_pass: "",
      re_new_pass: "",
      errors: {},
      isLoading: false,
      invalid: false,
    };
  }

  componentDidMount = () => {};

  componentWillReceiveProps = (nextProps) => {
    const { userProfile } = nextProps;
    if (userProfile) {
      const { email, first_name, last_name, username, locale } = userProfile;
      this.setState({
        email,
        first_name,
        last_name,
        username,
        locale,
        old_pass: "",
        new_pass: "",
        re_new_pass: "",
      });
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  checkUserExists = (e) => {
    const { isUserExists, addFlashMessage } = this.props;
    const { name, value } = e.target;
    const { errors } = this.state;
    let invalid = false;

    if (value !== "") {
      errors[name] = "";

      this.setState({ errors, invalid });

      isUserExists(value)
        .then((res) => {
          const { success } = res.data;
          if (success) {
            if (name == "email") {
              errors[
                name
              ] = `Email already in use, please use a different email address`;
            } else if (name == "username") {
              errors[
                name
              ] = `User already exists, please choose a different username`;
            }
            invalid = true;
          }
          this.setState({ errors, invalid });
        })
        .catch((err) => {
          const { status } = err.response;

          if (status == 404) return true;

          addFlashMessage({
            type: "error",
            text:
              "Oops, something went wrong while fetching data from the server.",
          });

          console.error(err);
        });
    }
  };

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { updateUserProfile, getUserProfile, addFlashMessage } = this.props;

    if (this.isValid()) {
      this.setState({ errors: {}, isLoading: true });
      updateUserProfile(this.state)
        .then((res) => {
          addFlashMessage({
            type: "success",
            text: res.data.message || "Your profile updated successfully.",
          });

          getUserProfile();
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          const { errors } = err.response.data;
          this.setState({ errors: errors || {}, isLoading: false });
        });
    }
  };

  render() {
    const {
      email,
      first_name,
      last_name,
      username,
      old_pass,
      new_pass,
      re_new_pass,
      isLoading,
      invalid,
      locale,
      errors,
    } = this.state;

    const options = map(supportedLocale, (val, key) => (
      <option key={key} value={key}>
        {val}
      </option>
    ));

    return (
      <form onSubmit={this.onSubmit}>
        <TextFieldGroup
          error={errors.first_name}
          label={<FormattedMessage {...messages.firstName} />}
          onChange={this.onChange}
          value={first_name}
          field="first_name"
        />

        <TextFieldGroup
          error={errors.last_name}
          label={<FormattedMessage {...messages.lastName} />}
          onChange={this.onChange}
          value={last_name || ""}
          field="last_name"
        />

        <TextFieldGroup
          error={errors.username}
          label={<FormattedMessage {...messages.username} />}
          onChange={this.onChange}
          value={username}
          field="username"
        />
        <TextFieldGroup
          error={errors.email}
          label={<FormattedMessage {...messages.email} />}
          onChange={this.onChange}
          onBlur={this.checkUserExists}
          value={email}
          field="email"
        />

        <TextFieldGroup
          error={errors.old_pass}
          label={<FormattedMessage {...messages.oldPassword} />}
          onChange={this.onChange}
          value={old_pass}
          field="old_pass"
          type="password"
        />

        <TextFieldGroup
          error={errors.new_pass}
          label={<FormattedMessage {...messages.newPassword} />}
          onChange={this.onChange}
          value={new_pass}
          field="new_pass"
          type="password"
        />

        <TextFieldGroup
          error={errors.re_new_pass}
          label={<FormattedMessage {...messages.retypeNewPassword} />}
          onChange={this.onChange}
          value={re_new_pass}
          field="re_new_pass"
          type="password"
        />

        <div className={cx("form-group", { "has-error": errors.locale })}>
          <label className="control-label"></label>
          <select
            className="form-control"
            name="locale"
            onChange={this.onChange}
            value={locale}
          >
            <option value="" disabled>
              Choose Your Language
            </option>
            {options}
          </select>
          {errors.locale && <span className="help-block">{errors.locale}</span>}
        </div>
        <div className="form-group">
          <Button
            disabled={isLoading || invalid}
            htmlType="submit"
            type="primary"
            size="large"
            block
          >
            <FormattedMessage {...messages.updateProfile} />
          </Button>
          {/* <Button type="primary">
            <FormattedMessage {...messages.cancel} />
          </Button>
          <button
            type="submit"
            color="success"
            className={cx("btn at-themebtn pull-left")}
            disabled={isLoading || invalid}
          >
            <FormattedMessage {...messages.updateProfile} />
          </button>
          <Link to="/folders" className={cx("btn at-themebtn pull-right")}>
            <FormattedMessage {...messages.cancel} />
          </Link> */}
        </div>
      </form>
    );
  }
}

ProfileForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(null, {
  addFlashMessage,
  updateUserProfile,
  isUserExists,
})(ProfileForm);
