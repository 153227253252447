import React from "react";
import axios from "axios";

// Require Codemirror
// import "codemirror/lib/codemirror.js"
// import "codemirror/mode/xml/xml.js"
// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/align.min.js";
// import "froala-editor/js/plugins/code_beautifier.min.js";
// import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/table.min.js";
// import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/plugins/entities.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/save.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
// import "froala-editor/css/plugins/code_view.min.css";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/css/plugins/emoticons.min.css";
import "froala-editor/css/plugins/image_manager.min.css";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/plugins/line_breaker.min.css";
import "froala-editor/css/plugins/table.min.css";
import "froala-editor/css/plugins/char_counter.min.css";
// import "froala-editor/css/plugins/video.min.css";

// Require Font Awesome.
import "font-awesome/css/font-awesome.css";

import FroalaEditor from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import CKEditor from "react-ckeditor-component";
import {
  Card,
  Button,
  Space,
  Tooltip,
  message,
  Select,
  Divider,
  Input,
} from "antd";
import {
  SaveOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import html2canvas from "html2canvas";
const { Option } = Select;

class FEditor extends React.Component {
  constructor(props) {
    super(props);
    this.updateContent = this.updateContent.bind(this);
    this.state = {
      content: "<span></span>",
      //   options: ["Yes", "No", "True", "False", "A", "B", "C"],
      options: [],
      item: "",
    };
    this.handleModelChange = this.handleModelChange.bind(this);
  }

  async saveQuiz() {
    try {
      let contentData = this.state.content || "<p>&nbsp;&nbsp;</p>";
      console.log(
        "🚀 ~ file: froalaEditor.js ~ line 84 ~ FEditor ~ slide2Lecture ~ contentData",
        contentData
      );
    } catch (error) {
      message.error(
        error.message || "Oops, Error while converting your content to image."
      );
    }
  }

  updateContent(newContent) {
    this.setState({
      content: newContent,
    });
  }

  onChange(evt) {
    const newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
  }

  afterPaste(evt) {
    console.log("afterPaste event called with event info: ", evt);
  }

  contentChanged(htmlStr) {
    this.setState({
      content: htmlStr,
    });
  }
  handleModelChange(model) {
    this.setState({
      content: model,
    });
  }

  addItem = () => {
    console.log("addItem");
    const { options, item } = this.state;
    if (item) {
      let finalOption = [...new Set([...options, item])];
      this.setState({
        options: finalOption,
        item: "",
      });
    }
  };

  removeItem = (itm) => {
    const { options } = this.state;
    const filteredItems = options.filter((item) => item !== itm);
    this.setState({
      options: filteredItems,
      item: "",
    });
  };

  render() {
    const { options, item } = this.state;
    return (
      <Card className="gx-card">
        <FroalaEditor
          model={this.state.content}
          onModelChange={this.handleModelChange}
          config={{
            attribution: false,
            charCounterCount: true,
            key: "AV:4~?3xROKLJKYHROLDXDR@d2YYGR_Bc1A8@5@4:1B2D2F2F1?1?2A3@1C1",
          }}
        />

        <div className="gx-pt-5 gx-d-flex gx-justify-content-between">
          <div>
            <Select
              style={{ width: 240 }}
              placeholder="Select Correct Option"
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                  >
                    <Input
                      style={{ flex: "auto" }}
                      value={item}
                      onChange={(event) => {
                        let value = event.target.value;
                        this.setState({ item: value });
                      }}
                    />
                    <a
                      style={{
                        flex: "none",
                        padding: "8px",
                        display: "block",
                        cursor: "pointer",
                      }}
                      onClick={this.addItem}
                    >
                      Add Option
                    </a>
                  </div>
                </div>
              )}
            >
              {options.map((itm) => (
                <Option key={itm}>
                  <Button.Group>
                    <Button type="text" style={{ width: 150 }}>
                      {itm}
                    </Button>
                    <Button
                      type="text"
                      style={{ width: 50 }}
                      icon={<CloseCircleOutlined />}
                      onClick={() => {
                        this.removeItem(itm);
                      }}
                    />
                  </Button.Group>
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <Button
              onClick={this.saveQuiz.bind(this)}
              size={"large"}
              type="primary"
              icon={<SaveOutlined />}
            >
              Save Quiz & clear Content
            </Button>

            <Button
              onClick={() => this.setState({ content: "" })}
              size={"large"}
              type="primary"
              icon={<CloseCircleOutlined />}
            >
              Clear Quiz Content
            </Button>
          </div>
        </div>
      </Card>
    );
  }
}

export default FEditor;
