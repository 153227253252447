import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Row, Col, Button, message} from "antd";
import {FolderOpenOutlined} from '@ant-design/icons'
import map from 'lodash/map';
import actions from '../../actions/votingActivityActions'
import CustomScrollbars from "../../utils/CustomScrollbars";

class NsgComponent extends Component {
	static propTypes = {}

	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			folders: [],
			votingPopup: false,
			errors: {},
			isLoading: false,
		};
	}

	componentDidMount = () => {
		this.getFolderList();
	}

	getFolderList = async () => {
		try {
			let {data: {data: nsgData}} = await actions.readFolder();
			this.setState({folders: nsgData, isLoading: false})
		} catch (error) {
			message.error("Oops, Error while processing your request, Please try again.")
			this.setState({errors: error.response ? error.response.data.errors : error, isLoading: false});
		}
	}

	render() {
		const {folders} = this.state

		return (<CustomScrollbars>
			<Row>
				{map(folders, (folder, key) => {
					return <Col style={{cursor: "pointer"}}
								onClick={() => this.context.router.push(`/voting-list?folder=${folder}`)} key={key}
								xl={4} lg={8} md={8} sm={12} xs={24}>
						<div style={{textAlign: 'center'}} className="gx-product-item gx-product-vertical">
							<div className="gx-product-image">
								<FolderOpenOutlined style={{fontSize: 200, color: "#294957"}}/>
							</div>
							<div className="gx-product-body">
								<div className="gx-product-name">
									{folder}
								</div>
							</div>
							<Button type="primary"
									className="gx-mt-sm-4 gx-fs-sm gx-btn-block gx-mb-0 gx-text-uppercase gx-border-radius-top-left-0 gx-border-radius-top-right-0"
									size="large" htmlType="submit" block>
								open voting activity folder
							</Button>
						</div>
					</Col>
				})}
			</Row>
		</CustomScrollbars>);
	}
}

NsgComponent.contextTypes = {
	router: PropTypes.object.isRequired
};

export default connect(null, null)(NsgComponent);
