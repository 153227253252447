export const LAYOUT_TYPE = ['INDD', 'PCT', 'PDF'];
export const SPREADSHEET_TYPE = ['XLR', 'XLS', 'XLSX'];
export const IMAGE_TYPE = ['AI', 'EPS', 'PS', 'SVG', 'BMP', 'DDS', 'GIF', 'JPG', 'PNG', 'PSD', 'PSPIMAGE', 'TGA', 'THM', 'TIF', 'TIFF', 'YUV'];
export const VIDEO_TYPE = ['3DM', '3DS', 'MAX', 'OBJ', '3G2', '3GP', 'ASF', 'AVI', 'FLV', 'M4V', 'MOV', 'MP4', 'MPG', 'RM', 'SRT', 'SWF', 'VOB', 'WMV'];
export const DATA_TYPE = ['CSV', 'DAT', 'GED', 'KEY', 'KEYCHAIN', 'PPS', 'PPT', 'PPTX', 'SDF', 'TAR', 'TAX2014', 'TAX2015', 'TAX2016', 'VCF', 'XML'];
export const TEXT_TYPE = ['DOC', 'DOCX', 'LOG', 'MSG', 'ODT', 'PAGES', 'RTF', 'TEX', 'TXT', 'WPD', 'WPS'];
export const NOTEBOOK_TYPE = ['NOTEBOOK'];

// Text Files
// .DOC	Microsoft Word Document
// .DOCX	Microsoft Word Open XML Document
// .LOG	Log File
// .MSG	Outlook Mail Message
// .ODT	OpenDocument Text Document
// .PAGES	Pages Document
// .RTF	Rich Text Format File
// .TEX	LaTeX Source Document
// .TXT	Plain Text File
// .WPD	WordPerfect Document
// .WPS	Microsoft Works Word Processor Document
// Data Files
// .CSV	Comma Separated Values File
// .DAT	Data File
// .GED	GEDCOM Genealogy Data File
// .KEY	Keynote Presentation
// .KEYCHAIN	Mac OS X Keychain File
// .PPS	PowerPoint Slide Show
// .PPT	PowerPoint Presentation
// .PPTX	PowerPoint Open XML Presentation
// .SDF	Standard Data File
// .TAR	Consolidated Unix File Archive
// .TAX2014	TurboTax 2014 Tax Return
// .TAX2015	TurboTax 2015 Tax Return
// .TAX2016	TurboTax 2016 Tax Return
// .VCF	vCard File
// .XML	XML File
// Audio Files
// .AIF	Audio Interchange File Format
// .IFF	Interchange File Format
// .M3U	Media Playlist File
// .M4A	MPEG-4 Audio File
// .MID	MIDI File
// .MP3	MP3 Audio File
// .MPA	MPEG-2 Audio File
// .WAV	WAVE Audio File
// .WMA	Windows Media Audio File
// Video Files
// .3G2	3GPP2 Multimedia File
// .3GP	3GPP Multimedia File
// .ASF	Advanced Systems Format File
// .AVI	Audio Video Interleave File
// .FLV	Animate Video File
// .M4V	iTunes Video File
// .MOV	Apple QuickTime Movie
// .MP4	MPEG-4 Video File
// .MPG	MPEG Video File
// .RM	RealMedia File
// .SRT	SubRip Subtitle File
// .SWF	Shockwave Flash Movie
// .VOB	DVD Video Object File
// .WMV	Windows Media Video File
// 3D Image Files
// .3DM	Rhino 3D Model
// .3DS	3D Studio Scene
// .MAX	3ds Max Scene File
// .OBJ	Wavefront 3D Object File
// Raster Image Files
// .BMP	Bitmap Image File
// .DDS	DirectDraw Surface
// .GIF	Graphical Interchange Format File
// .JPG	JPEG Image
// .PNG	Portable Network Graphic
// .PSD	Adobe Photoshop Document
// .PSPIMAGE	PaintShop Pro Image
// .TGA	Targa Graphic
// .THM	Thumbnail Image File
// .TIF	Tagged Image File
// .TIFF	Tagged Image File Format
// .YUV	YUV Encoded Image File
// Vector Image Files
// .AI	Adobe Illustrator File
// .EPS	Encapsulated PostScript File
// .PS	PostScript File
// .SVG	Scalable Vector Graphics File
// Page Layout Files
// .INDD	Adobe InDesign Document
// .PCT	Picture File
// .PDF	Portable Document Format File
// Spreadsheet Files
// .XLR	Works Spreadsheet
// .XLS	Excel Spreadsheet
// .XLSX	Microsoft Excel Open XML Spreadsheet
// Database Files
// .ACCDB	Access 2007 Database File
// .DB	Database File
// .DBF	Database File
// .MDB	Microsoft Access Database
// .PDB	Program Database
// .SQL	Structured Query Language Data File
// Executable Files
// .APK	Android Package File
// .APP	Mac OS X Application
// .BAT	DOS Batch File
// .CGI	Common Gateway Interface Script
// .COM	DOS Command File
// .EXE	Windows Executable File
// .GADGET	Windows Gadget
// .JAR	Java Archive File
// .WSF	Windows Script File
// Game Files
// .DEM	Video Game Demo File
// .GAM	Saved Game File
// .NES	Nintendo (NES) ROM File
// .ROM	N64 Game ROM File
// .SAV	Saved Game
// CAD Files
// .DWG	AutoCAD Drawing Database File
// .DXF	Drawing Exchange Format File
// GIS Files
// .GPX	GPS Exchange File
// .KML	Keyhole Markup Language File
// .KMZ	Google Earth Placemark File
// Web Files
// .ASP	Active Server Page
// .ASPX	Active Server Page Extended File
// .CER	Internet Security Certificate
// .CFM	ColdFusion Markup File
// .CSR	Certificate Signing Request File
// .CSS	Cascading Style Sheet
// .HTM	Hypertext Markup Language File
// .HTML	Hypertext Markup Language File
// .JS	JavaScript File
// .JSP	Java Server Page
// .PHP	PHP Source Code File
// .RSS	Rich Site Summary
// .XHTML	Extensible Hypertext Markup Language File
// Plugin Files
// .CRX	Chrome Extension
// .PLUGIN	Mac OS X Plugin
// Font Files
// .FNT	Windows Font File
// .FON	Generic Font File
// .OTF	OpenType Font
// .TTF	TrueType Font
// System Files
// .CAB	Windows Cabinet File
// .CPL	Windows Control Panel Item
// .CUR	Windows Cursor
// .DESKTHEMEPACK	Windows 8 Desktop Theme Pack File
// .DLL	Dynamic Link Library
// .DMP	Windows Memory Dump
// .DRV	Device Driver
// .ICNS	Mac OS X Icon Resource File
// .ICO	Icon File
// .LNK	Windows File Shortcut
// .SYS	Windows System File
// Settings Files
// .CFG	Configuration File
// .INI	Windows Initialization File
// .PRF	Outlook Profile File
// Encoded Files
// .HQX	BinHex 4.0 Encoded File
// .MIM	Multi-Purpose Internet Mail Message File
// .UUE	Uuencoded File
// Compressed Files
// .7Z	7-Zip Compressed File
// .CBR	Comic Book RAR Archive
// .DEB	Debian Software Package
// .GZ	Gnu Zipped Archive
// .PKG	Mac OS X Installer Package
// .RAR	WinRAR Compressed Archive
// .RPM	Red Hat Package Manager File
// .SITX	StuffIt X Archive
// .TAR.GZ	Compressed Tarball File
// .ZIP	Zipped File
// .ZIPX	Extended Zip File
// Disk Image Files
// .BIN	Binary Disc Image
// .CUE	Cue Sheet File
// .DMG	Mac OS X Disk Image
// .ISO	Disc Image File
// .MDF	Media Disc Image File
// .TOAST	Toast Disc Image
// .VCD	Virtual CD
// Developer Files
// .C	C/C++ Source Code File
// .CLASS	Java Class File
// .CPP	C++ Source Code File
// .CS	Visual C# Source Code File
// .DTD	Document Type Definition File
// .FLA	Adobe Animate Animation
// .H	C/C++/Objective-C Header File
// .JAVA	Java Source Code File
// .LUA	Lua Source File
// .M	Objective-C Implementation File
// .PL	Perl Script
// .PY	Python Script
// .SH	Bash Shell Script
// .SLN	Visual Studio Solution File
// .SWIFT	Swift Source Code File
// .VB	Visual Basic Project Item File
// .VCXPROJ	Visual C++ Project
// .XCODEPROJ	Xcode Project
// Backup Files
// .BAK	Backup File
// .TMP	Temporary File
// Misc Files
// .CRDOWNLOAD	Chrome Partially Downloaded File
// .ICS	Calendar File
// .MSI	Windows Installer Package
// .PART	Partially Downloaded File
// .TORRENT	BitTorrent File
