import React, { Component } from 'react';
// import ToolHolder from '../holder/ToolHolder'
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './DiceRoller.module.css';
// import './DiceRoller.css';

class DiceRoller extends Component {
    static propTypes = {
        closeTool: PropTypes.func.isRequired,
        onRef: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props)

        this.state = {
            min: 1,
            max: 24,
        }
    }

    componentDidMount = () => {
        // this.props.onRef(this)

        // this.rollDice()
    }
    componentWillUnmount = () => {
        // this.props.onRef(undefined)
    }

    rollCube = () => {
        const { min, max } = this.state;

        var xRand = (Math.floor(Math.random() * (max - min)) + min) * 90;
        var yRand = (Math.floor(Math.random() * (max - min)) + min) * 90;

        this._cube.style.webkitTransform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)';
        this._cube.style.transform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)';
    }

    render() {
        return (<section className={s["at-container"]}>
            <div ref={d => this._cube = d} onClick={this.rollCube} className={s["cube"]}>
                <div className={s["cubefront"]}>
                    <span className={cx(s["dot"], s["frontdot1"])}></span>
                </div>
                <div className={s["cubeback"]}>
                    <span className={cx(s["dot"], s["backdot1"])}></span>
                    <span className={cx(s["dot"], s["backdot2"])}></span>
                </div>
                <div className={s["cuberight"]}>
                    <span className={cx(s["dot"], s["rightdot1"])}></span>
                    <span className={cx(s["dot"], s["rightdot2"])}></span>
                    <span className={cx(s["dot"], s["rightdot3"])}></span>
                </div>
                <div className={s["cubeleft"]}>
                    <span className={cx(s["dot"], s["leftdot1"])}></span>
                    <span className={cx(s["dot"], s["leftdot2"])}></span>
                    <span className={cx(s["dot"], s["leftdot3"])}></span>
                    <span className={cx(s["dot"], s["leftdot4"])}></span>
                </div>
                <div className={s["cubetop"]}>
                    <span className={cx(s["dot"], s["topdot1"])}></span>
                    <span className={cx(s["dot"], s["topdot2"])}></span>
                    <span className={cx(s["dot"], s["topdot3"])}></span>
                    <span className={cx(s["dot"], s["topdot4"])}></span>
                    <span className={cx(s["dot"], s["topdot5"])}></span>
                </div>
                <div className={s["cubebottom"]}>
                    <span className={cx(s["dot"], s["bottomdot1"])}></span>
                    <span className={cx(s["dot"], s["bottomdot2"])}></span>
                    <span className={cx(s["dot"], s["bottomdot3"])}></span>
                    <span className={cx(s["dot"], s["bottomdot4"])}></span>
                    <span className={cx(s["dot"], s["bottomdot5"])}></span>
                    <span className={cx(s["dot"], s["bottomdot6"])}></span>
                </div>
            </div>
        </section>);
    }
}

export default DiceRoller;