import React, { Component } from 'react';

import ResetForm from './ResetForm';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (<ResetForm userData={this.props.location.query} />);
  }
}

export default ResetPassword;
