// NewLecture.js
import React, { Component } from 'react';
import { connect } from 'react-redux';

import TextFieldGroup from '../common/TextFieldGroup';
import classnames from 'classnames';
import { createWorkbook, fetchWorkbooks } from '../../actions/workbookActions';
import validateInput from '../../validations/workbook';
import PropTypes from 'prop-types';


class NewLecture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      subject: '',
      description: '',
      userEmail: '',
      userId: '',
      errors: {},
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    const { email, id } = this.props.auth.user;
    this.setState({ userEmail: email, userId: id });
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ errors: {}, isLoading: true });
      this.props.createWorkbook(this.state)
        .then(
        (res) => {
          this.setState({ name: '', subject: '', description: '', isLoading: false });
          $('#my-new-lecture').modal('hide');
          this.context.router.push({ pathname: '/createworkbook', query: { id: res.data.book._id } });
        },
      ).catch(
        err => this.setState({ errors: err.response.data, isLoading: false }),
      );
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors, name, subject, description, isLoading } = this.state;
    return (
      <div aria-hidden="false" className="modal fade" id="my-new-lecture" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" style={{ border: 0 }}>
              <button className="close" data-dismiss="modal" type="button">×</button>
              <h3 className="modal-title">Create a Workbook</h3>
            </div>
            <div className="modal-body clearfix">
              <form data-dpmaxz-eid={41} onSubmit={this.onSubmit}>

                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <TextFieldGroup
                    field="name"
                    label="Workbook Name"
                    value={name}
                    error={errors.name}
                    isDisabled={false}
                    onChange={this.onChange}
                  />
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <TextFieldGroup
                    field="subject"
                    label="Workbook Subject/Topic"
                    value={subject}
                    error={errors.subject}
                    isDisabled={false}
                    onChange={this.onChange}
                  />
                </div>
                <div className="col-xs-12">
                  <label>Description <i>(optional)</i></label>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      onChange={this.onChange}
                      name="description"
                      placeholder="Description"
                      rows={6}
                      value={description}
                    />
                  </div>
                </div>

                <div className="col-xs-12 text-center">
                  <div className="form-group btn-modal-holder">
                    <button disabled={isLoading} className="btn btn-default" data-dpmaxz-eid={44}>
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>

    );
  }
}

NewLecture.propTypes = {
  createWorkbook: PropTypes.func.isRequired,
  fetchWorkbooks: PropTypes.func.isRequired,
};

NewLecture.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { createWorkbook, fetchWorkbooks })(NewLecture);
