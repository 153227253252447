import React, { Component, Fragment } from "react";
import cx from "classnames";
import map from "lodash/map";
import isEqual from "lodash/isEqual";
import filter from "lodash/filter";
import {
  Modal,
  Statistic,
  Row,
  Col,
  Switch,
  Button,
  Typography,
  Space,
  Divider,
  Checkbox,
} from "antd";
import { Bar } from "react-chartjs-2";
import uuidv4 from "uuid/v4";
import _ from "lodash";
import actions from "../../actions/votingActivityActions";
import PopupTypeTwo from "../common/PopupTypeTwo";
import TextFieldGroup from "../common/TextFieldGroup";

import s from "./LecturePage.module.css";
import is from "../dashboard/InvitationPage.module.css";

import { globalFolders, globalFoldersActivities } from "./voting";

class VotingPage extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      votingDrivenType: "Self Paced",
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Voting Activity Results",
            backgroundColor: "rgba(0,100,0,0.2)",
            borderColor: "rgba(0,100,0,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(0,100,0,0.4)",
            hoverBorderColor: "rgba(0,100,0,1)",
            data: [],
          },
        ],
      },
      votingAnswer: {},
      votingStudentList: [],

      activity: {},

      folder: "",
      folderPopup: true,
      reportingPopup: false,
      questionIndex: 0,
      text: "<br><br><br><br><br><br><br>",
      optionInputText: "",
      answer: "",
      questionOptions: [],
      votingFolders: [],
      votingFolderQuestions: [],
      votingFolderName: "",
      error: "",
      showResults: false,
      completedBy: 0,
      addAllToActivity: false,
    };
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  componentDidMount = () => {
    if (this.props.activity) {
      this.setState({
        votingFolderName: this.props.activity.folder,
        votingFolderQuestions: [this.props.activity],
      });
      this.startVoting(this.props.activity);
    } else {
      this.getFolderList();
    }
    this.props.socket.on("votingAnswer", (data) => {
      let chartData = this.state.chartData;

      if (this.state.chartData.labels.indexOf(data.email) == -1) {
        this.setState({ labels: this.state.chartData.labels.push(data.email) });
      }

      chartData["datasets"][0].label = this.state.activity.name;

      if (this.state.votingFolderName == "Ask a Verbal Question") {
        chartData["datasets"][0].data[
          this.state.chartData.labels.indexOf(data.email)
        ] =
          this.state.activity.questions[0].options.indexOf(
            data.votingAnswer[0]
          ) + 1;
        this.setState(
          {
            votingAnswer: {
              ...this.state.votingAnswer,
              [data.email]: {
                votingAnswer: data.votingAnswer,
                attempt: Object.keys(data.votingAnswer).length,
                correct: this.correctAnswers(data.votingAnswer),
              },
            },
            chartData,
          },
          () => {
            const totalQuestions = this.state.activity?.questions?.length;
            const completedBy = filter(
              this.state.votingAnswer,
              (item) => item.attempt === totalQuestions
            );
            this.setState({ completedBy: completedBy.length });
          }
        );
      } else {
        chartData["datasets"][0].data[
          this.state.chartData.labels.indexOf(data.email)
        ] = this.correctAnswers(data.votingAnswer);

        this.setState(
          {
            votingAnswer: {
              ...this.state.votingAnswer,
              [data.email]: {
                votingAnswer: data.votingAnswer,
                attempt: Object.keys(data.votingAnswer).length,
                correct: this.correctAnswers(data.votingAnswer),
              },
            },
            chartData,
          },
          () => {
            const totalQuestions = this.state.activity?.questions?.length;
            const completedBy = filter(
              this.state.votingAnswer,
              (item) => item.attempt === totalQuestions
            );
            this.setState({ completedBy: completedBy.length });
          }
        );
      }
    });

    this.props.socket.on("syncVotingActivity", (data) => {
      this.props.socket.emit("onSyncVotingActivity", {
        votingStudentList: this.state.votingStudentList,
        sessionId: this.props.sessionId,
        data: this.state.activity.questions,
        questionIndex: this.state.questionIndex,
        votingDrivenType: this.state.votingDrivenType,
        email: data.email,
      });
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !isEqual(this.props.studentList, prevProps.studentList) &&
      this.state.addAllToActivity
    ) {
      this.manageAddAllToVotingActivity();
    }
  }

  componentWillUnmount = () => {
    this.props.socket.emit("startVoting", {
      votingStudentList: [],
      sessionId: this.props.sessionId,
      questionIndex: 0,
      data: [],
      votingDrivenType: this.state.votingDrivenType,
    });
  };

  getFolderList = async () => {
    try {
      let {
        data: { data: nsgData },
      } = await actions.readFolder();
      this.setState({ votingFolders: [...globalFolders, ...nsgData] });
    } catch (error) {
      this.setState({
        errors: error.response ? error.response.data.errors : error,
      });
    }
  };
  correctAnswers = (answers) => {
    let votingQuestions = this.state.activity.questions;
    let nsgCorrectAnswers = 0;
    Object.keys(answers).forEach((key) => {
      if (answers[key] == votingQuestions[key].answer) {
        nsgCorrectAnswers++;
      }
    });
    return nsgCorrectAnswers;
  };

  onSelectVotingDrivenType = (val) => {
    if (val == "Teacher Driven") {
      this.setState(
        {
          votingStudentList: JSON.parse(JSON.stringify(this.props.studentList)),
          votingDrivenType: val,
          votingPopup: val == "Teacher Driven" && this.state.activity.name,
        },
        () => {
          this.props.socket.emit("votingDrivenType", {
            votingStudentList: this.props.studentList,
            votingDrivenType: this.state.votingDrivenType,
            sessionId: this.props.sessionId,
            questionIndex: this.state.questionIndex,
          });
        }
      );
      // });
    } else {
      this.setState(
        {
          votingDrivenType: val,
          votingPopup: val == "Teacher Driven" && this.state.activity.name,
        },
        () => {
          this.props.socket.emit("votingDrivenType", {
            votingDrivenType: this.state.votingDrivenType,
            sessionId: this.props.sessionId,
            questionIndex: this.state.questionIndex,
          });
        }
      );
    }
  };

  startVoting = (item) => {
    let { votingStudentList, votingDrivenType, activity } = this.state;

    if (item.name == activity.name) {
      this.setState({ activity: {}, votingAnswer: {} }, () => {
        this.props.socket.emit("startVoting", {
          votingStudentList: [],
          sessionId: this.props.sessionId,
          questionIndex: 0,
          data: [],
          votingDrivenType,
        });
      });

      if (this.props.onStopActivity) {
        this.props.onStopActivity({});
        window.location.reload();
      }
    } else {
      this.setState(
        { activity: item, votingPopup: votingDrivenType == "Teacher Driven" },
        () => {
          this.props.socket.emit("startVoting", {
            votingStudentList: votingStudentList,
            sessionId: this.props.sessionId,
            data: item.questions,
            questionIndex: this.state.questionIndex,
            votingDrivenType,
          });
        }
      );
    }
  };

  onSelectVotingStudentCheckbox = (val) => {
    const votingStudentList = this.state.votingStudentList;

    const index = votingStudentList.indexOf(val);
    if (index > -1) {
      votingStudentList.splice(index, 1);
      this.setState({ votingStudentList, error: "" }, () => {
        this.props.socket.emit("startVoting", {
          votingStudentList: this.state.votingStudentList,
          sessionId: this.props.sessionId,
          data: this.state.activity.questions,
          questionIndex: this.state.questionIndex,
          votingDrivenType: this.state.votingDrivenType,
        });
      });
    } else {
      votingStudentList.push(val);
      this.setState(
        { votingStudentList: [...new Set(votingStudentList)], error: "" },
        () => {
          this.props.socket.emit("startVoting", {
            votingStudentList: this.state.votingStudentList,
            sessionId: this.props.sessionId,
            data: this.state.activity.questions,
            questionIndex: this.state.questionIndex,
            votingDrivenType: this.state.votingDrivenType,
          });
        }
      );
    }
  };

  navHandler = (type) => {
    let { activity, questionIndex, votingDrivenType } = this.state;
    let nsgQuizIndex = questionIndex;
    if (type == "next" && activity.questions[nsgQuizIndex + 1]) {
      nsgQuizIndex++;
    } else if (type == "previous" && activity.questions[nsgQuizIndex - 1]) {
      nsgQuizIndex--;
    }
    this.setState({ questionIndex: nsgQuizIndex }, () => {
      if (votingDrivenType == "Teacher Driven") {
        this.props.socket.emit("votingDrivenType", {
          votingDrivenType,
          sessionId: this.props.sessionId,
          questionIndex: this.state.questionIndex,
        });
      }
    });
  };

  toggleVotingPopup = () => {
    this.setState({ votingPopup: !this.state.votingPopup });
  };

  showTestInFolder = () => {
    this.setState({ votingFolderName: "", votingFolderQuestions: [] });
  };

  toggleFolderPopup = () => {
    this.setState({ folderPopup: !this.state.folderPopup });
  };

  toggleReportingPopup = () => {
    this.setState({ reportingPopup: !this.state.reportingPopup });
  };
  toggleQuestionPopup = () => {
    this.setState({
      questionPopup: !this.state.questionPopup,
      optionInputText: "",
      questionOptions: [],
      text: "<br><br><br><br><br><br><br>",
    });
  };

  handleQuillChange = (value) => {
    this.setState({ text: value });
  };

  onOptionInputTextChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };
  addOption = (e) => {
    e.preventDefault();

    let { questionOptions, optionInputText } = this.state;
    if (!optionInputText) {
      return true;
    }

    questionOptions.push(optionInputText);

    this.setState({
      questionOptions: [...new Set(questionOptions)],
      optionInputText: "",
    });
  };

  removeQuestionOption = (option) => {
    let questionOptions = this.state.questionOptions;

    const index = questionOptions.indexOf(option);
    if (index > -1) {
      questionOptions.splice(index, 1);
      this.setState({ questionOptions });
    }
  };

  fetchFolderActivities = async (folderName) => {
    if (!folderName) {
      this.setState({ votingFolderName: "", votingFolderQuestions: [] });
    } else if (globalFoldersActivities[folderName]) {
      this.setState({
        votingFolderName: folderName,
        votingFolderQuestions: globalFoldersActivities[folderName],
      });
    } else {
      try {
        let {
          data: { data: activities },
        } = await actions.read(0, "", folderName);
        this.setState({
          votingFolderName: folderName,
          votingFolderQuestions: activities,
        });
      } catch (error) {
        console.log("TCL: getFolderList -> error", error);
      }
    }
  };

  askQuestion = () => {
    let activity = {
      folder: "Ask a Verbal Question",
      name: "Ask a Verbal Question",
      questions: [
        {
          options: this.state.questionOptions,
          type: "html",
          question: `<h1>Your Presenter / Teacher / Instructor will now ask you a verbal question. Please respond by clicking on one of the options below. </h1>`,
          answer: "",
        },
      ],
    };

    this.setState({ votingFolderName: "Ask a Verbal Question" }, () => {
      this.startVoting(activity);
      this.toggleQuestionPopup();
    });
  };

  stopActivityAndBackToFolder = () => {
    this.startVoting(this.state.activity);
    this.fetchFolderActivities("");
  };

  downloadReportingFile = () => {
    const { activity } = this.state;
    const element = document.createElement("a");
    let nagActivityName = activity.name;
    let nsgQuestions = activity.questions;

    let nsgFileCSV = ``;
    let nsgData = this.state.votingAnswer;

    for (let index = 0; index < nsgQuestions.length; index++) {
      const element = nsgQuestions[index];
      nsgFileCSV += `\n${element.answer || ""},`;

      Object.keys(nsgData).forEach((key) => {
        let nsgStudentAnswer = nsgData[key]["votingAnswer"];
        nsgFileCSV += `${nsgStudentAnswer[index] || ""},`;
      });
    }

    nsgFileCSV += `\n\n`;

    nsgFileCSV += ",";
    Object.keys(nsgData).forEach((key) => {
      let nsgStudentAnswer = nsgData[key]["votingAnswer"];
      nsgFileCSV += `${this.correctAnswers(nsgStudentAnswer)}/${
        nsgQuestions.length
      } (${
        Math.round(
          (this.correctAnswers(nsgStudentAnswer) / nsgQuestions.length) *
            100 *
            100
        ) / 100
      }%),`;
    });

    //

    let nsgCSV = `
    Activity Name,${nagActivityName}


    Correct Answer,${Object.keys(nsgData).toString()}
    ${nsgFileCSV}
    `;

    const file = new Blob([nsgCSV], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${this.state.activity.name}_contribute_cloud_report.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  showResultToStudent = () => {
    this.setState({ showResults: !this.state.showResults }, () => {
      this.props.socket.emit("showResults", {
        votingStudentList: this.state.votingStudentList,
        sessionId: this.props.sessionId,
        showResults: this.state.showResults,
      });
    });
  };

  setAskQuestionOptions = (options) => {
    this.setState({
      questionOptions: [...new Set(options.split("/"))],
      optionInputText: "",
    });
  };

  manageAddAllToVotingActivity = () => {
    const { votingStudentList } = this.state;
    const { studentList } = this.props;
    _.forEach(studentList, (value) => {
      if (
        this.state.addAllToActivity &&
        votingStudentList.indexOf(value) === -1
      ) {
        this.onSelectVotingStudentCheckbox(value);
      } else if (
        !this.state.addAllToActivity &&
        votingStudentList.indexOf(value) > -1
      ) {
        this.onSelectVotingStudentCheckbox(value);
      }
    });
  };

  render() {
    const {
      chartData,
      votingDrivenType,
      votingStudentList,
      reportingPopup,
      questionPopup,
      folderPopup,
      questionIndex,
      votingPopup,
      questionOptions,
      optionInputText,
      votingFolders,
      votingFolderQuestions,
      votingFolderName,
      activity,
      answer,
    } = this.state;

    console.log("TCL: render -> this.props.sessionId", this.props.sessionId);
    let nsgVotingSelectedStudentList = map(
      this.props.studentList,
      (val, key) => (
        <span key={key}>
          <input
            onChange={this.onSelectVotingStudentCheckbox.bind(this, val)}
            checked={!!(votingStudentList.indexOf(val) > -1)}
            type="checkbox"
            name="join-chat"
            id={`${key + "+" + val}`}
          />
          <label
            className={"ml-2 mr-3 text-black"}
            htmlFor={`${key + "+" + val}`}
          >
            {" "}
            <b>{val}</b> &nbsp;
          </label>
        </span>
      )
    );

    return (
      <Fragment>
        <Modal
          visible={folderPopup}
          title={votingFolderName ? `${votingFolderName}` : "Voting Folders"}
          closable={false}
          footer={null}
          width="60%"
        >
          {this.state.error && (
            <div className="alert alert-primary" role="alert">
              {this.state.error}
            </div>
          )}

          <Row justify="center" gutter={16}>
            <Col>
              <Statistic
                groupSeparator={""}
                title="Session ID"
                value={this.props.sessionId}
              />
            </Col>

            {votingFolderName && (
              <>
                <div className="gx-pl-5 gx-ml-5"></div>
                <Col>
                  <Statistic
                    groupSeparator={""}
                    title={`Activity Type (${this.state.votingDrivenType})`}
                    value={` `}
                  />
                  <Switch
                    onChange={(value) =>
                      this.onSelectVotingDrivenType(
                        value ? "Self Paced" : "Teacher Driven"
                      )
                    }
                    checked={this.state.votingDrivenType === "Self Paced"}
                    checkedChildren="Self Paced"
                    unCheckedChildren="Teacher Driven"
                    style={{ marginTop: -30 }}
                  />
                </Col>
              </>
            )}

            <Divider />

            <Col>
              {!votingFolderName ? (
                `Please choose a category of Voting Activities from the activities and folders you have
								already created. To create a new activity, please end your session and choose the
								“Create Voting Activity” from your dashboard.`
              ) : activity.name ? (
                <Statistic
                  groupSeparator={""}
                  title="Activity Name"
                  value={activity.name}
                />
              ) : (
                `Click the play Icon to Start an Activity`
              )}
            </Col>
            <Divider />
          </Row>

          {votingFolderName && (
            <>
              <Checkbox
                checked={this.state.addAllToActivity}
                onChange={(ev) => {
                  this.setState({ addAllToActivity: ev.target.checked }, () => {
                    this.manageAddAllToVotingActivity();
                  });
                }}
              >
                Add All To Activity
              </Checkbox>
              <Divider />
              <div className={s["at-notification"]}>
                {nsgVotingSelectedStudentList}
              </div>
            </>
          )}

          <Row justify="center" gutter={16}>
            <Col>
              <div className={s["at-folderholder"]}>
                <ul>
                  {votingFolderName
                    ? map(votingFolderQuestions, (item, key) => (
                        <li key={key} className={s["at-slide"]}>
                          <figure className={s["at-slideimg"]}>
                            {item.questions[0] &&
                            item.questions[0].type == "html" ? (
                              <div
                                style={{ width: 170, height: 140 }}
                                dangerouslySetInnerHTML={{
                                  __html: item.questions[0].question,
                                }}
                              />
                            ) : item.questions[0] &&
                              item.questions[0].type == "image" ? (
                              <img
                                src={`${
                                  item.questions[0].question
                                }?${Date.now()}`}
                              />
                            ) : null}
                          </figure>
                          <div className={s["at-btnviewname"]}>
                            <span>{item.name}</span>
                            <a
                              onClick={this.startVoting.bind(this, item)}
                              className={s["at-btnview"]}
                              href="javascript:void(0)"
                            >
                              {activity.name == item.name ? (
                                <i className="fa fa-stop" />
                              ) : (
                                <i className="fa fa-play" />
                              )}
                            </a>
                          </div>
                        </li>
                      ))
                    : null}

                  {!votingFolderName
                    ? map(votingFolders, (folderName, key) => (
                        <li
                          onClick={this.fetchFolderActivities.bind(
                            this,
                            folderName
                          )}
                          className={s["at-slide"]}
                        >
                          <figure className={s["at-slideimg"]}>
                            <img src={`images/folder.jpeg`} />
                          </figure>
                          <div className={s["at-folderbtnviewname"]}>
                            <span>{folderName}</span>
                          </div>
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            </Col>
          </Row>

          <Row justify="center" gutter={16}>
            <Col>
              {votingFolderName && (
                <Button type="primary" onClick={this.toggleReportingPopup}>
                  Reporting and Results
                </Button>
              )}

              {!votingFolderName ? (
                <>
                  <Button type="primary" onClick={this.toggleQuestionPopup}>
                    Ask a Verbal Question
                  </Button>

                  <Button type="primary" onClick={this.toggleFolderPopup}>
                    Close
                  </Button>
                </>
              ) : !activity.name || activity.name == "Ask a Verbal Question" ? (
                <>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.stopActivityAndBackToFolder(activity);
                      this.fetchFolderActivities("");
                    }}
                  >
                    Back to folders
                  </Button>

                  <Button type="primary" onClick={this.askQuestion}>
                    Ask Another Question
                  </Button>
                </>
              ) : (
                <Button
                  type="primary"
                  onClick={this.stopActivityAndBackToFolder}
                >
                  End Activity & Return to Dashboard
                </Button>
              )}
            </Col>
          </Row>
        </Modal>

        <Modal
          visible={reportingPopup}
          title={"Reporting and Results"}
          onOk={this.toggleReportingPopup}
          onCancel={this.toggleReportingPopup}
          footer={[
            <Button key="cancel" onClick={this.toggleReportingPopup}>
              Cancel
            </Button>,
            <Button
              type="primary"
              key="ExportResults"
              onClick={this.downloadReportingFile}
            >
              Export Results
            </Button>,
            <Button
              type={this.state.showResults ? "danger" : "primary"}
              key="ShowResultToStudents"
              onClick={this.showResultToStudent}
            >
              Show Result To Students
            </Button>,
          ]}
          width="60%"
        >
          <Row justify="center" gutter={16}>
            <Col align="center">
              <Statistic
                groupSeparator={""}
                title="Total Questions"
                value={activity.questions ? activity.questions.length : 0}
              />
            </Col>
            <Col align="center">
              <Statistic
                groupSeparator={""}
                title="Total Students"
                value={this.state.votingStudentList?.length}
              />
            </Col>
            <Col align="center">
              <Statistic
                groupSeparator={""}
                title="Completed By"
                value={this.state.completedBy}
              />
            </Col>
          </Row>
          <div className={s["at-folderholder"]}>
            {reportingPopup && (
              <Bar
                key={uuidv4()}
                data={chartData}
                options={{
                  scales: {
                    yAxes: [
                      votingFolderName == "Ask a Verbal Question"
                        ? {
                            display: true,
                            scaleLabel: {
                              display: true,
                              labelString: "Answer",
                            },
                            ticks: {
                              min: 0,
                              max: this.state.activity.questions[0].options
                                .length,
                              stepSize: 1,
                              callback: (value, index, values) => {
                                if (
                                  this.state.activity.questions[0].options[
                                    value
                                  ]
                                ) {
                                  return this.state.activity.questions[0]
                                    .options[value];
                                }
                              },
                            },
                          }
                        : {
                            display: true,
                            scaleLabel: {
                              display: true,
                              labelString: "Correct Answers",
                            },
                            ticks: {
                              min: 0,
                              max: activity.questions
                                ? activity.questions.length
                                : 10,

                              // forces step size to be 5 units
                              stepSize: 1,
                            },
                          },
                    ],
                  },
                }}
              />
            )}
          </div>
        </Modal>

        <Modal
          onToggle={this.toggleQuestionPopup}
          visible={questionPopup}
          title={"Ask a Verbal Question"}
          onOK={this.toggleQuestionPopup}
          cancelButtonText={"Close"}
          onCancel={this.toggleQuestionPopup}
          width="60%"
          footer={[
            <Button key="cancel" onClick={this.toggleQuestionPopup}>
              Cancel
            </Button>,
            ...(questionOptions.length
              ? [
                  <Button key="ok" type="primary" onClick={this.askQuestion}>
                    Ask Question
                  </Button>,
                ]
              : []),
          ]}
        >
          <div className={s["at-folderholder"]}>
            <h4>
              You can use this feature to ask your participants a question
              verbally that they are able to respond digitally from their
              device. Chose a pre-defined response or add your own. When you are
              ready, click the “Ask a Verbal Question” button then pose your
              question to your participants. This is for verbally posed
              questions only, not written. On the next page, you will need to
              select your participants.
            </h4>
            <button
              onClick={this.setAskQuestionOptions.bind(this, "Yes/No")}
              className={cx(s["nsg-question_option"])}
            >
              {" "}
              Yes/ No{" "}
              <span className="badge badge-light">
                <i className="fa fa-check" />
              </span>
            </button>
            <button
              onClick={this.setAskQuestionOptions.bind(this, "True/False")}
              className={cx(s["nsg-question_option"])}
            >
              {" "}
              True/ False{" "}
              <span className="badge badge-light">
                <i className="fa fa-check" />
              </span>
            </button>
            <button
              onClick={this.setAskQuestionOptions.bind(
                this,
                "Understand/Don't Understand"
              )}
              className={cx(s["nsg-question_option"])}
            >
              {" "}
              Understand/ Don't Understand{" "}
              <span className="badge badge-light">
                <i className="fa fa-check" />
              </span>
            </button>
            <button
              onClick={this.setAskQuestionOptions.bind(this, "Like/Dislike")}
              className={cx(s["nsg-question_option"])}
            >
              {" "}
              Like/ Dislike{" "}
              <span className="badge badge-light">
                <i className="fa fa-check" />
              </span>
            </button>
            <button
              onClick={this.setAskQuestionOptions.bind(
                this,
                "Positive/Zero/Negative"
              )}
              className={cx(s["nsg-question_option"])}
            >
              {" "}
              Positive/ Zero/ Negative{" "}
              <span className="badge badge-light">
                <i className="fa fa-check" />
              </span>
            </button>
            <button
              onClick={this.setAskQuestionOptions.bind(this, "Up/Down ")}
              className={cx(s["nsg-question_option"])}
            >
              {" "}
              Up/ Down{" "}
              <span className="badge badge-light">
                <i className="fa fa-check" />
              </span>
            </button>
          </div>

          <div className={is["at-inputholder"]}>
            <form onSubmit={this.addOption}>
              <TextFieldGroup
                field="optionInputText"
                label={"Add an option"}
                value={optionInputText}
                error={""}
                onChange={this.onOptionInputTextChange}
              />

              <button type="submit" className="at-themebtn">
                Add Option
              </button>
            </form>
          </div>

          <div className={cx(s["at-folderholder"], "gx-mb-5")}>
            {map(questionOptions, (option, key) => (
              <button
                className={cx(s["nsg-question_option"], {
                  [s["nsg-question_option_answer"]]: option == answer,
                })}
              >
                {option}{" "}
                <span
                  onClick={this.removeQuestionOption.bind(this, option)}
                  className="badge badge-light"
                >
                  <i className="fa fa-times" />
                </span>
              </button>
            ))}
          </div>
        </Modal>

        <Modal
          visible={votingFolderName && votingPopup}
          title={
            activity
              ? `${activity.name} (${questionIndex + 1}/${
                  activity?.questions?.length
                })`
              : null
          }
          cancelButtonText={"Close"}
          onCancel={this.toggleVotingPopup}
          width="60%"
          footer={[
            <Button key={uuidv4()} onClick={this.toggleVotingPopup}>
              Cancel
            </Button>,

            <Button
              disabled={!questionIndex}
              type="primary"
              key={uuidv4()}
              onClick={this.navHandler.bind(this, "previous")}
            >
              Previous
            </Button>,
            <Button
              disabled={activity?.questions?.length === questionIndex + 1}
              type="primary"
              key={uuidv4()}
              onClick={this.navHandler.bind(this, "next")}
            >
              Next
            </Button>,
          ]}
        >
          <>
            {activity.name ? (
              <Fragment>
                {activity.questions[questionIndex].type == "html" ? (
                  <div
                    className={s["nsg-votingslideimg"]}
                    dangerouslySetInnerHTML={{
                      __html: activity.questions[questionIndex].question,
                    }}
                  />
                ) : activity.questions[questionIndex].type == "image" ? (
                  <img
                    className={s["nsg-votingslideimg"]}
                    src={`${activity.questions[questionIndex].question}`}
                  />
                ) : null}

                {!activity.questions[questionIndex].options ? (
                  <h3>{activity.questions[questionIndex].message}</h3>
                ) : (
                  activity.questions[questionIndex].options.map((option) => {
                    return (
                      <button
                        key={option}
                        value={option}
                        disabled={true}
                        onClick={() => {}}
                        className={s["nsg-question_option"]}
                      >
                        {option}
                      </button>
                    );
                  })
                )}
              </Fragment>
            ) : null}
          </>
        </Modal>
      </Fragment>
    );
  }
}

export default VotingPage;
