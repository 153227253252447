import React, { Component } from "react";
import PropTypes from "prop-types";
import s from "./LecturePage.module.css";
import cx from "classnames";
import map from "lodash/map";
import UserVideoComponent from "./OpenVidu/UserVideoComponent";

import { FormattedMessage } from "react-intl";
import messages from "../../messages";

function LectureTopBar({
  name,
  subject,
  toggleAlert,
  restartLecture,
  requestFromStudent,
  accessToStudent,
  studentList,
  manageAccess,
  sessionId,
  subscribers,
  compareArray,
  mannageCompare,
  toggleComparePopup,
  comparePopupFlag,
  compareAll,
  toggleSessionIdPopup,
  addAllToCompare,
  isComparePopupEnabled,
  toggleBoolFlag,
  isNotificationPopupEnabled,
  toggleUserAudio,
  removeCurrentSlideHandler,
  changeCastQuality,
  syncWithClass,
}) {
  const requestedList = requestFromStudent.filter((obj) => {
    return accessToStudent.indexOf(obj) == -1;
  });

  let requestedStudent = map(requestedList, (val, key) => (
    <div key={key} className={s["at-notification"]}>
      <div className={s["at-checkbox"]}>
        <input
          onChange={manageAccess.bind(this, val)}
          checked={false}
          type="checkbox"
          name="join-chat"
          id={`$join${key + "+" + val}`}
        />
        <label htmlFor={`$join${key + "+" + val}`}>
          {" "}
          {val} has requested to collaborate with you
        </label>
      </div>
    </div>
  ));

  let accessedStudent = map(accessToStudent, (val, key) => (
    <div key={key} className={s["at-notification"]}>
      <div className={s["at-checkbox"]}>
        <input
          onChange={manageAccess.bind(this, val)}
          checked={true}
          type="checkbox"
          name="join-chat"
          id={`$join${key + "+" + val}`}
        />
        <label htmlFor={`$join${key + "+" + val}`}>
          {" "}
          {val} is now able to collaborate with you
        </label>
      </div>
    </div>
  ));

  const joined = studentList.filter((obj) => {
    return (
      accessToStudent.indexOf(obj) == -1 &&
      requestFromStudent.indexOf(obj) == -1
    );
  });
  let joinedStudent = map(joined, (val, key) => (
    <div key={key} className={s["at-notification"]}>
      <div className={s["at-checkbox"]}>
        <input
          onChange={manageAccess.bind(this, val)}
          checked={false}
          type="checkbox"
          name="join-chat"
          id={`$join${key + "+" + val}`}
        />
        <label htmlFor={`$join${key + "+" + val}`}>
          {" "}
          {val} has joined the session.
        </label>
      </div>
    </div>
  ));

  return (
    <div className={s["at-topbar"]}>
      <div className={s["container-fluid"]}>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
            <ul className={s["at-notificationsbtns"]}>
              <li
                className={cx(s["at-positionstatic"], {
                  [s["at-positionstatic_active"]]: isComparePopupEnabled,
                })}
              >
                <a
                  onClick={toggleBoolFlag.bind(this, "isComparePopupEnabled")}
                  href="javascript:void(0);"
                >
                  <img
                    src={"images/icons/icon-03.png"}
                    alt="image description"
                  />
                </a>

                <div
                  className={cx(s["at-themedropdown"], {
                    [s["hide"]]: !isComparePopupEnabled,
                  })}
                >
                  <div className={s["at-dropdownhead"]}>
                    <h2>Contribute Multi-Cast</h2>
                    {compareArray.length ? (
                      <a
                        onClick={toggleComparePopup}
                        className={s["at-btnfullscreentwo"]}
                      >
                        <img
                          src="images/icon-fullscreen.png"
                          alt="image description"
                        />
                      </a>
                    ) : null}
                  </div>
                  <div className={cx(s["at-dropdowncontentholder"])}>
                    <div className={s["at-screensholder"]}>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="nsgaddallcompare"
                          checked={!!compareAll}
                          onChange={addAllToCompare}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="nsgaddallcompare"
                        >
                          Add All to Multi-Cast
                        </label>
                      </div>

                      {/* <div className={s["at-checkbox"]}>
                                            <input type="checkbox"
                                                onChange={addAllToCompare}
                                                id={`select-all-student`}
                                                name={`select-all-student`}
                                                id={`select-all-student`}
                                                className={s["css-checkbox"]} />
                                            <label htmlFor={`select-all-student`} className={cx(s["css-label"], s['nsg-selectall'])}>Add All to Multi-Cast</label>
                                        </div> */}
                      {subscribers.map((sub, i) => (
                        <div
                          key={i}
                          className={cx(
                            s["at-screenrequest"],
                            s["at-dropdownvideo"]
                          )}
                        >
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`cast-student-${i}`}
                              checked={compareArray.indexOf(i) > -1}
                              onChange={mannageCompare.bind(this, i)}
                            />
                            <label
                              className={cx(
                                s["nsg-comparelable"],
                                "custom-control-label"
                              )}
                              htmlFor={`cast-student-${i}`}
                            >
                              {" "}
                              Add to Compare
                            </label>
                            <a
                              onClick={toggleUserAudio.bind(this, sub)}
                              className={cx(s["mic-btn"])}
                            >
                              <i className="fa fa-microphone"></i>
                            </a>
                          </div>
                          <span className={s["nsg-usernameellipsis"]}>
                            {
                              JSON.parse(
                                sub.stream.connection.data.replace("}%/%{", ",")
                              ).clientData
                            }
                          </span>
                          <UserVideoComponent streamManager={sub} />
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              name={`cast-quality-${i}`}
                              id={`quality-144-${i}`}
                              value="144"
                              className="custom-control-input"
                              onChange={changeCastQuality.bind(this, sub, 12)}
                            />
                            <label
                              className={cx(
                                s["nsg-qualitylabel"],
                                "custom-control-label"
                              )}
                              htmlFor={`quality-144-${i}`}
                            >
                              {" "}
                              &nbsp;&nbsp;&nbsp; Low
                            </label>
                          </div>

                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              name={`cast-quality-${i}`}
                              id={`quality-250-${i}`}
                              value="250"
                              className="custom-control-input"
                              onChange={changeCastQuality.bind(this, sub, 18)}
                            />
                            <label
                              className={cx(
                                s["nsg-qualitylabel"],
                                "custom-control-label"
                              )}
                              htmlFor={`quality-250-${i}`}
                            >
                              &nbsp;&nbsp;&nbsp; Med
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              name={`cast-quality-${i}`}
                              id={`quality-360-${i}`}
                              value="360"
                              className="custom-control-input"
                              onChange={changeCastQuality.bind(this, sub, 25)}
                            />
                            <label
                              className={cx(
                                s["nsg-qualitylabel"],
                                "custom-control-label"
                              )}
                              htmlFor={`quality-360-${i}`}
                            >
                              {" "}
                              &nbsp;&nbsp;&nbsp; Hi
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a
                  onClick={toggleBoolFlag.bind(
                    this,
                    "isNotificationPopupEnabled"
                  )}
                  href="javascript:void(0);"
                  className={cx({
                    [s["nsg-colleborating"]]: accessToStudent.length,
                  })}
                >
                  {requestedStudent.length ? (
                    <span className={s["at-notibadge"]}>
                      {requestedStudent.length}
                    </span>
                  ) : null}
                  <img
                    src={"images/icons/icon-04.png"}
                    alt="image description"
                  />
                </a>
                {isNotificationPopupEnabled ? (
                  <div
                    className={cx(
                      s["at-themedropdown"],
                      s["at-addtocompareholder"]
                    )}
                  >
                    <div className={s["at-dropdownhead"]}>
                      <h2>Manage collaboratation</h2>
                      <span className={s["at-totalnotifications"]}>
                        {studentList.length}
                      </span>
                    </div>
                    <div className={s["at-dropdowncontentholder"]}>
                      <div className={s["at-notifications"]}>
                        <h3 className={s["at-joinhead"]}>
                          {accessToStudent.length} Collaborating
                        </h3>
                        {accessedStudent}
                      </div>
                      <div
                        className={cx(
                          s["at-notifications"],
                          s["at-requestjoin"]
                        )}
                      >
                        <h3 className={s["at-joinhead"]}>
                          {requestedStudent.length} Requested{" "}
                        </h3>
                        {requestedStudent}
                      </div>
                      <div
                        className={cx(
                          s["at-notifications"],
                          s["at-requestjoin"]
                        )}
                      >
                        <h3 className={s["at-joinhead"]}>
                          {joinedStudent.length} In Session{" "}
                        </h3>
                        {joinedStudent}
                      </div>
                    </div>
                  </div>
                ) : null}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

LectureTopBar.propTypes = {
  name: PropTypes.string.isRequired,
  compareArray: PropTypes.array.isRequired,
  subscribers: PropTypes.array.isRequired,
  sessionId: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  toggleAlert: PropTypes.func.isRequired,
  toggleComparePopup: PropTypes.func.isRequired,
  mannageCompare: PropTypes.func.isRequired,
  restartLecture: PropTypes.func.isRequired,
  comparePopupFlag: PropTypes.bool.isRequired,
  isComparePopupEnabled: PropTypes.bool.isRequired,
  addAllToCompare: PropTypes.func.isRequired,
  toggleBoolFlag: PropTypes.func.isRequired,
  toggleUserAudio: PropTypes.func.isRequired,
  changeCastQuality: PropTypes.func.isRequired,
};
export default LectureTopBar;
