import React from "react";
import { Route, IndexRoute } from "react-router";

import App from "./components/App";
import DashboardPage from "./components/dashboard/DashboardPage";
import DashboardFolder from "./components/dashboardFolders/DashboardPage";
import VotingFolder from "./components/votingFolder";
import VotingList from "./components/votingList";
import VotingEditor from "./components/votingEditor";
import GalleryPage from "./components/gallery";
import WorkbookPage from "./components/workbook/WorkbookPage";
import CreateWorkbook from "./components/workbook_old/CreateWorkbook";
import ResetPassword from "./components/signup/ResetPassword";
import SignupPage from "./components/signup/SignupPage";
import LoginPage from "./components/login/LoginPage";
import ProfilePage from "./components/profile/ProfilePage";
import Storage from "./components/profile/Storage";
import StudentPage from "./components/students/StudentPage";
import LecturePage from "./components/lecture/LecturePage";
import CoursesPage from "./components/courses/CoursesPage";
import TCPP from "./components/genral/TCPP";
import Webinar from "./components/webinar";
import GetRoom from "./components/getRoom";
import { AdminArea } from "./components/admin-area";

import requireAuth from "./utils/requireAuth";

export default (
  <Route path="/" component={App}>
    <IndexRoute component={LoginPage} />
    <Route path="reset" component={ResetPassword} />
    <Route path="signup" component={SignupPage} />
    <Route path="get-a-room" component={GetRoom} />
    <Route path="webinar" component={requireAuth(Webinar)} />
    <Route path="courses" component={requireAuth(CoursesPage)} />
    <Route path="mGallery" component={requireAuth(GalleryPage)} />
    <Route path="folders" component={requireAuth(DashboardFolder)} />
    <Route path="voting-folders" component={requireAuth(VotingFolder)} />
    <Route path="voting-list" component={requireAuth(VotingList)} />
    <Route path="admin-area" component={requireAuth(AdminArea)} />
    <Route path="voting-editor" component={requireAuth(VotingEditor)} />
    <Route path="dashboard" component={requireAuth(DashboardPage)} />
    <Route path="workbook" component={requireAuth(WorkbookPage)} />
    <Route path="createworkbook" component={requireAuth(CreateWorkbook)} />
    <Route path="profile" component={requireAuth(ProfilePage)} />
    <Route path="storage" component={requireAuth(Storage)} />
    <Route path="students" component={requireAuth(StudentPage)} />
    <Route path="lecture" component={requireAuth(LecturePage)} />
    <Route path="tc&pp" component={TCPP} />
  </Route>
);
