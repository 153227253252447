import React, { Component } from "react";
import PropTypes from "prop-types";
import s from "../WorkbookPage.module.css";
import cx from "classnames";
class GooglePage extends Component {
	static propTypes = {
		openUppyModal: PropTypes.func.isRequired,
	};
	constructor(props) {
		super(props);
	}

	render() {
		return (<li
			className={cx(s["at-gdrive"])}
			onClick={this.props.openUppyModal}
		>
			<a href={null}>
				<i className={s["at-divebtnholder"]}>
					<img src="images/googledrive-icon.png" />
				</i>
				<span>G-Drive</span>
			</a>
		</li>);
	}
}

export default GooglePage;
