import React from "react";
import {
  Layout,
  Space,
  Popover,
  Menu,
  Button,
  Tooltip,
  Modal,
  Avatar,
} from "antd";
import {
  PlusCircleOutlined,
  MenuOutlined,
  VideoCameraOutlined,
  FolderOpenOutlined,
  PlusOutlined,
  BookOutlined,
  FileOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Link } from "react-router";
import languageData from "./languageData";
import CustomScrollbars from "../../../utils/CustomScrollbars";
import { FormattedMessage } from "react-intl";
import messages from "../../../messages";
import PropTypes from "prop-types";
import { UserMenuOptions } from "./Sidebar";
import { isMobile } from "react-device-detect";

const { Header } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const languageMenu = () => (
  <CustomScrollbars className="gx-popover-lang-scroll">
    <ul className="gx-sub-popover">
      {languageData.map((language) => (
        <li
          className="gx-media gx-pointer"
          key={JSON.stringify(language)}
          onClick={(e) => console.log("herere!!!")}
        >
          <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
          <span className="gx-language-text">{language.name}</span>
        </li>
      ))}
    </ul>
  </CustomScrollbars>
);

const FBAM = ({ manageRoutes, toggleLecture, toggleVotingPopup }) => {
  return (
    <Space direction="vertical">
      <Tooltip placement="left" color="#02a2de" title="View Workbooks">
        <Button
          onClick={() => manageRoutes("folders")}
          type="primary"
          shape="circle"
          icon={<BookOutlined />}
        />
      </Tooltip>
      {/* <Tooltip
        placement="left"
        color="#02a2de"
        title={<FormattedMessage {...messages.createWorkbook} />}
      >
        <Button
          onClick={() => toggleLecture("workbook")}
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
        />
      </Tooltip> */}
      <Tooltip
        placement="left"
        color="#02a2de"
        title="Start Whiteboard Session"
      >
        <Button
          onClick={() => toggleLecture("lecture")}
          type="primary"
          shape="circle"
          icon={<FileOutlined />}
        />
      </Tooltip>
      <Tooltip placement="left" color="#02a2de" title="View Classrooms List">
        <Button
          onClick={() => manageRoutes("students")}
          type="primary"
          shape="circle"
          icon={<UsergroupAddOutlined />}
        />
      </Tooltip>
      <Tooltip placement="left" color="#02a2de" title="View Voting Activities">
        <Button
          onClick={() => manageRoutes("voting-folders")}
          type="primary"
          shape="circle"
          icon={<FolderOpenOutlined />}
        />
      </Tooltip>
      <Tooltip placement="left" color="#02a2de" title="Create Voting Activity">
        <Button
          onClick={toggleVotingPopup}
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
        />
      </Tooltip>
      <Tooltip placement="left" color="#02a2de" title="Video Session">
        <Button
          onClick={() => (window.location.href = `/webinar`)}
          type="primary"
          shape="circle"
          icon={<VideoCameraOutlined />}
        />
      </Tooltip>
    </Space>
  );
};
const Topbar = ({
  toggleCollapse,
  toggleLecture,
  toggleVotingPopup,
  nsgContext,
  logout,
  currentUser,
}) => {
  const manageRoutes = (itmPath) => {
    switch (itmPath) {
      case "folders":
        nsgContext.router.push("/folders");
        break;
      case "students":
        nsgContext.router.push("/students");
        break;
      case "voting-folders":
        nsgContext.router.push("/voting-folders");
        break;
      case "webinar":
        nsgContext.router.push("/webinar");
        break;
      case "files":
        nsgContext.router.push("/mGallery");
        break;
      // case "courses":
      //   nsgContext.router.push("/courses");
      //   break;

      default:
        break;
    }
  };
  return (
    <Header
      className="gx-header-horizontal-main"
      style={{ backgroundColor: "#02a2de", color: "#FFFFFF", padding: 5 }}
    >
      <div style={{ width: "95%" }} className="gx-container">
        <div className="gx-header-horizontal-main-flex">
          <div className="gx-linebar gx-mr-3 6e">
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={toggleCollapse}
            />
            {/* <i
              className="gx-icon-btn icon icon-menu"
              onClick={() => {
                console.log("🚀 ~ file: Topbar.js ~ line 166 ~ d");
                debugger;
              }}
            /> */}
          </div>

          <Link
            to="/"
            className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
          >
            <img alt="" src="images/logo-w.png" />
          </Link>
          <Link
            to="/"
            className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
          >
            <img alt="" src="images/logo-w.png" />
          </Link>

          <div className="gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
            {/* {currentUser.educator && ( */}
            <Menu className="gx-ml-3" selectedKeys={[]} mode="horizontal">
              <Menu.Item
                onClick={() => manageRoutes("folders")}
                style={{ color: "#fff" }}
                key="Workbooks"
              >
                Dashboard
              </Menu.Item>
              <Menu.Item
                onClick={() => manageRoutes("voting-folders")}
                style={{ color: "#fff" }}
                key="Voting Activities"
              >
                Voting Activities
              </Menu.Item>
              <Menu.Item
                onClick={() => manageRoutes("students")}
                style={{ color: "#fff" }}
                key="Participant Lists"
              >
                Participant Lists
              </Menu.Item>
              <Menu.Item
                onClick={() => manageRoutes("files")}
                style={{ color: "#fff" }}
                key="My Files"
              >
                My Files
              </Menu.Item>
              <Menu.Item style={{ color: "#fff" }} key="New in V3">
                <a
                  style={{ color: "#fff" }}
                  href="https://touchittechnologies.com/contribute_cloud_3/"
                  target="_blank"
                >
                  What's New in V3
                </a>
              </Menu.Item>
              <Menu.Item style={{ color: "#fff" }} key="V3 Tutorials">
                <a
                  style={{ color: "#fff" }}
                  href="https://touchittechnologies.com/contribute-cloud-v3-tutorials/"
                  target="_blank"
                >
                  V3 Tutorials
                </a>
              </Menu.Item>

              {/* <Menu.Item
                  onClick={() => manageRoutes("courses")}
                  style={{ color: "#fff" }}
                  key="Courses"
                >
                  Courses
                </Menu.Item> */}
            </Menu>
            {/* )} */}
          </div>
          <ul className="gx-header-notifications gx-ml-auto">
            {/* <li className="gx-notify gx-notify-search">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={
                  <div className="gx-d-flex">
                    <Dropdown overlay={menu}>
                      <Button>
                        Category <Icon type="down" />
                      </Button>
                    </Dropdown>
                    <SearchBox
                      styleName="gx-popover-search-bar"
                      placeholder="Search in app..."
                      onChange={updateSearchChatUser}
                      value={searchText}
                    />
                  </div>
                }
                trigger="click"
              >
                <span className="gx-pointer gx-d-block">
                  <i className="icon icon-search-new" />
                </span>
              </Popover>
            </li>

            <li className="gx-notify">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<AppNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-d-block">
                  <i className="icon icon-notification" />
                </span>
              </Popover>
            </li>*/}

            {/* {currentUser.educator && ( */}
            <>
              {!isMobile && (
                <li className="gx-msg">
                  <Tooltip title="Video Session">
                    <span
                      onClick={() => (window.location.href = `/webinar`)}
                      style={{ color: "#fff" }}
                      className="gx-pointer gx-status-pos gx-d-block"
                    >
                      <VideoCameraOutlined className="icon" />
                    </span>
                  </Tooltip>
                </li>
              )}
              <li className="gx-msg">
                <Tooltip title="Whiteboard Session">
                  <span
                    onClick={() => toggleLecture("lecture")}
                    style={{ color: "#fff" }}
                    className="gx-pointer gx-status-pos gx-d-block"
                  >
                    <FileOutlined className="icon" />
                  </span>
                </Tooltip>
              </li>

              <li className="gx-msg">
                <Tooltip title="Create Voting Activity">
                  <span
                    onClick={toggleVotingPopup}
                    style={{ color: "#fff" }}
                    className="gx-pointer gx-status-pos gx-d-block"
                  >
                    <i className="icon icon-treeselect" />
                  </span>
                </Tooltip>
              </li>
            </>
            {/* )} */}

            {/* <li className="gx-language">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                trigger="click"
              >
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-en`} />
                </span>
              </Popover>
            </li> */}
            <li className="gx-user-nav">
              <Popover
                trigger={["click"]}
                placement="bottomRight"
                overlayClassName="gx-popover-horizantal"
                content={<UserMenuOptions logout={logout} />}
              >
                <Avatar
                  src={currentUser.profile_photo_path}
                  className="gx-avatar gx-pointer"
                  alt={currentUser.first_name || currentUser.username}
                />
              </Popover>
            </li>
          </ul>

          {/* <div className="gx-linebar gx-mr-3">
          <Space>
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={toggleCollapse}
            />

            <Link to="/" className="gx-d-block gx-pointer">
              <img alt="lo" src={"images/logo-w.png"} />
            </Link>
          </Space>
        </div> */}
          {/* <ul className="gx-header-notifications gx-ml-auto">
          <div className="gx-linebar gx-mr-3 gx-mt-3">
            <Popover
              overlayClassName="gx-fba"
              content={
                <FBAM
                  manageRoutes={manageRoutes}
                  toggleLecture={toggleLecture}
                  toggleVotingPopup={toggleVotingPopup}
                />
              }
              trigger={["click", "hover"]}
              title={null}
            >
              <Button
                size="large"
                shape="circle"
                icon={<MenuOutlined className="gx-fs-xxl" />}
              />
            </Popover>
          </div>
        </ul> */}
          {/* <Tooltip placement="bottom" color="#02a2de" title="Logout"> */}
          {/* <div className="gx-linebar gx-ml-3 gx-pointer">
          <Popover
            trigger={["click", "hover"]}
            placement="bottomRight"
            content={<UserMenuOptions logout={logout} />}
          >
            <Avatar
              src={currentUser.profile_photo_path}
              className="gx-size-40 gx-pointer gx-mr-3"
              alt={currentUser.first_name || currentUser.username}
            />
            <span className="gx-avatar-name">
              {currentUser.first_name || currentUser.username}
              <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
            </span>
          </Popover>
        </div> */}
          {/* <div
        onClick={() => {
          return Modal.confirm({
            title: "Are you sure?",
            icon: <LogoutOutlined />,
            content: "You want to logout",
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
              logout();
            },
          });
        }}
        className="gx-linebar gx-ml-3 gx-pointer"
      >
        <LogoutOutlined />
      </div> */}
          {/* </Tooltip> */}
        </div>
      </div>
    </Header>
  );
};

export default Topbar;
