
import FabricCanvasTool from './fabrictool';

class Erase extends FabricCanvasTool {
  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = true;
    canvas.defaultCursor = 'move';
    canvas.forEachObject((o) => {
      o.selectable = o.evented = true;
    });
  }

  doMouseUp(o) {
    const canvas = this._canvas;

    let objects = canvas.getActiveObjects();
    for (let i = 0; i < objects.length; i++) {
      let o = objects[i];
      canvas.remove(o);
    }
    canvas.renderAll();
  }
}

export default Erase;
