import isEmpty from 'lodash/isEmpty';

export default function validateInput(data) {
  const errors = {};
  if (isEmpty(data.name)) {
    errors.name = 'This field is required';
  }

  if (isEmpty(data.subject)) {
    errors.subject = 'This field is required';
  }

  if (isEmpty(data.folder)) {
    errors.folder = 'This field is required';
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
