import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Modal,
  Form,
  Input,
  Button,
  Table,
  Tooltip,
  Divider,
  message,
} from "antd";
import {
  DeleteOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import map from "lodash/map";
import { getUserClassroomList } from "../../actions/student";
import { startLectureAndInviteStudent } from "../../actions/workbook";

import { FormattedMessage } from "react-intl";
import messagesIntl from "../../messages";

const columns = [
  {
    title: "Participant Email",
    dataIndex: "email",
    key: "email",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Action",
    dataIndex: "",
    key: "x",
    fixed: "right",
    width: 50,
    align: "center",
    render: (dataRow) => (
      <Tooltip title={"Delete Participant"}>
        <DeleteOutlined
          onClick={() => dataRow.onRemoveStudent(dataRow.email)}
          style={{ cursor: "pointer", color: "#02a2de" }}
        />
      </Tooltip>
    ),
  },
];

const expandedRowRender = (record) => {
  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: 50,
      align: "center",
      render: (dataRow) => (
        <Tooltip title={"Add Participant"}>
          <UserAddOutlined
            onClick={() => dataRow.onAddStudent(dataRow)}
            style={{
              cursor: "pointer",
              color: "#02a2de",
            }}
          />
        </Tooltip>
      ),
    },
  ];

  const data = map(record.students, (rec, key) => {
    return {
      key: key,
      name: rec.name,
      email: rec.email,
      onAddStudent: record.onAddStudent,
    };
  });

  return (
    <Table columns={columns} dataSource={data} pagination={false} bordered />
  );
};

const classroomColumns = [
  { title: "Classroom name", dataIndex: "classroomName", key: "classroomName" },
  { title: "No. Of Students", dataIndex: "noOfStudent", key: "noOfStudent" },
  {
    title: "Action",
    dataIndex: "",
    key: "x",
    fixed: "right",
    width: 50,
    align: "center",
    render: (text, record) => (
      <Tooltip title={"Add All Students"}>
        <UsergroupAddOutlined
          onClick={() => record.onAddList(record.students)}
          style={{ cursor: "pointer", color: "#02a2de" }}
        />
      </Tooltip>
    ),
  },
];

class InvitationPage extends Component {
  static propTypes = {
    getUserClassroomList: PropTypes.func.isRequired,
    toggleInvitationModal: PropTypes.func.isRequired,

    workbook: PropTypes.object.isRequired,
  };

  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      courses: [],
      students: [],
      errors: {},
      isLoading: false,
    };
  }

  componentDidMount = () => {
    getUserClassroomList()
      .then((res) => {
        this.setState({ courses: res.data.classrooms });
      })
      .catch((error) => {
        console.error(error);
        message.error("Oops, Fail to process, Please try again.");
      });
  };

  addStudentsToInvitationList = (students) => {
    let result = students.map((std) => std.email);

    let myStudents = this.state.students.concat(result);
    this.setState({ students: [...new Set(myStudents)] });
  };

  addStudentsEmailToInvitationList = (student) => {
    let result = student.email;
    let myStudents = this.state.students.concat(result);
    this.setState({ students: [...new Set(myStudents)] });
  };

  removeStudentsFromInvitationList = (email) => {
    const students = this.state.students.filter((obj) => {
      return obj != email;
    });
    this.setState({ students });
  };

  startLectureAndInviteStudent = () => {
    const {
      startLectureAndInviteStudent,
      toggleInvitationModal,
      workbook,
    } = this.props;
    this.setState({ errors: {}, isLoading: true });
    startLectureAndInviteStudent(workbook._id, {
      students: this.state.students,
    })
      .then((res) => {
        message.success(
          res.data.message ||
            "Email notification of lecture is sent successfully."
        );

        this.setState({ isLoading: false });
        toggleInvitationModal();

        this.context.router.push({
          pathname: "/lecture",
          query: { id: workbook._id, sessionId: res.data.sessionId },
        });
      })
      .catch((err) => {
        console.error(err);
        message.error("Oops, Fail to process, Please try again.");

        this.setState({
          errors: err.response ? err.response.data.errors : err,
          isLoading: false,
        });
      });
  };

  render() {
    const { courses, students } = this.state;

    const classroomData = map(courses, (classroom, key) => {
      return {
        key: key,
        classroomName: classroom.name,
        noOfStudent: classroom.student.length,
        students: classroom.student,
        onAddList: this.addStudentsToInvitationList,
        onAddStudent: this.addStudentsEmailToInvitationList,
      };
    });

    const invitationData = map(students, (student, key) => {
      return {
        key: key,
        email: student,
        onRemoveStudent: this.removeStudentsFromInvitationList,
      };
    });

    const { intl } = this.context;

    return (
      <Modal
        title={intl.formatMessage({ ...messagesIntl.inviteParticipants })}
        visible={this.props.visible}
        okText={"Send Invitation"}
        okButtonProps={{
          size: "large",
        }}
        onOk={this.startLectureAndInviteStudent}
        onCancel={this.props.toggleInvitationModal}
        cancelButtonProps={{
          size: "large",
        }}
      >
        <Form
          name="inviteForm"
          layout="inline"
          size="large"
          ref={this.formRef}
          onFinish={(values) => {
            this.addStudentsEmailToInvitationList(values);

            this.formRef.current.setFieldsValue({
              email: "",
            });
          }}
        >
          <Form.Item
            name="email"
            style={{ width: "65%" }}
            rules={[
              {
                type: "email",
                message: "Please enter valid email",
              },
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input placeholder={"Participant Email"} />
          </Form.Item>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit">
              <FormattedMessage {...messagesIntl.addParticipant} />
            </Button>
          </Form.Item>
        </Form>

        <Table
          columns={columns}
          dataSource={invitationData}
          pagination={false}
          bordered
        />
        <Divider orientation="left">Select From Classrooms</Divider>
        <Table
          columns={classroomColumns}
          expandable={{ expandedRowRender }}
          dataSource={classroomData}
          pagination={false}
          bordered
        />
      </Modal>
    );
  }
}

InvitationPage.contextTypes = {
  router: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default connect(null, { startLectureAndInviteStudent })(InvitationPage);
