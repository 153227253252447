/* eslint no-unused-vars: 0 */

import FabricCanvasTool from "./fabrictool";

const fabric = require("fabric").fabric;

class IText extends FabricCanvasTool {
  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.defaultCursor = "default";

    this._size = props.fontSize;
    this._color = props.lineColor;
    this._font = props.fontName;

    const text = new fabric.IText("  Type Your Text  ", {
      left: canvas.width / 2,
      top: canvas.height / 2,
      padding: 7,
      fontFamily: this._font,
      fill: this._color,
      fontSize: this._size,
      cornerSize: 20,
    });
    canvas.add(text);

    canvas.setActiveObject(text);
    text.enterEditing();
    text.hiddenTextarea.focus();
    canvas.renderAll();
  }
}

export default IText;
