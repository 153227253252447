import axios from 'axios';
import { SET_GALLERY_FILES } from './types';

export function setGalleryFiles(files) {
    return { type: SET_GALLERY_FILES, files };
}

export function saveGalleryLocalUpload(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/download/galleryLocalUpload`, data)
}

export function canvasImageToBase64(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/download/canvasImageToBase64`, data)
}


export function uploadBlobFile({ file, name }) {
    let data = new FormData();
    data.append('file', file);
    data.append('originalname', name);
    data.append('name', name);

    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/download/uploadBlobFile`, data);
}

export function saveSlide(file, path) {
    let data = new FormData();
    data.append('file', file);
    data.append('name', path);
    data.append('path', path);

    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/download/saveSlide`, data);
}

export function tempUpload(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/gallery/tempUpload`, data);
}

export function galleryUpload(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/gallery/`, data);
}

export function getGallery() {
    return dispatch => axios.get(`${process.env.REACT_APP_API_HOST}/api/gallery/`).then((res) => {
        dispatch(setGalleryFiles(res.data.files));
    });
}

export function galleryDelete(data) {
    return dispatch => axios.delete(`${process.env.REACT_APP_API_HOST}/api/gallery/${data.id}`);
}