import React, { Component } from 'react';
import SignupForm from './SignupForm';
import { connect } from 'react-redux';
import { userSignupRequest, isUserExists } from '../../actions/signupActions';
import { addFlashMessage } from '../../actions/flashMessages.js';
import PropTypes from 'prop-types';

class SignupPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { userSignupRequest, addFlashMessage, isUserExists } = this.props;
    return (<SignupForm
          isUserExists={isUserExists}
          userSignupRequest={userSignupRequest}
          addFlashMessage={addFlashMessage}
        />);
  }
}

SignupPage.propTypes = {
  userSignupRequest: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  isUserExists: PropTypes.func.isRequired,
};

export default connect(null, { userSignupRequest, addFlashMessage, isUserExists })(SignupPage);
