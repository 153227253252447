import React, { Component, Fragment } from 'react';
import { categorizeLocalMedia } from '../../../utils/helper';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import s from '../WorkbookPage.module.css';
import cx from 'classnames';

const baseStyle = {
	// margin: 'auto',
	// // position: 'absolute',
	// top: 0,
	// left: 0,
	// bottom: 0,
	// right: 0,
	// background: 'radial-gradient(ellipse at center,#EB6A5A 0,#c9402f 100%)',
	// // width: '80%',
	// // height: '50%',
	// border: '5px dashed white',
	// textAlign: 'center',
	// color: 'white',
	// zIndex: 20,
	// transition: 'all 0.3s ease-out',
	// boxShadow: '0 0 0 1px rgba(255,255,255,.05),inset 0 0 .25em 0 rgba(0,0,0,.25)'
	height: '95%',
	display: 'table',
	padding: 5,
	borderWidth: 3,
	borderRadius: 8,
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out'
};

const activeStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

class ComputerDrivePage extends Component {
	static propTypes = {
		onProcessData: PropTypes.func.isRequired,
		activeDrive: PropTypes.string.isRequired,
		viewFiles: PropTypes.func.isRequired,
		Computer: PropTypes.object.isRequired,
		displayStyle: PropTypes.sting
	};
	constructor(props) {
		super(props);
	}

	componentDidMount = () => { };

	onSuccess = async (accepted, rejected) => {
		// let { onProcessData } = this.props;
		// let Computer = {
		// 	IMAGE: [],
		// 	LAYOUT: [],
		// 	SPREADSHEET: [],
		// 	VIDEO: [],
		// 	TEXT: [],
		// 	DATA: [],
		// 	NOTEBOOK: [],
		// 	count: 0
		// };
		if (accepted && accepted.length > 0) {
			accepted.forEach((file) => {
				try {
					this.props.uppyFileInput.addFile({
						source: 'file input',
						name: file.name,
						type: file.type,
						data: file
					})
				} catch (err) {
					if (err.isRestriction) {
						// handle restrictions
						console.log('Restriction error:', err)
					} else {
						// handle other errors
						console.error(err)
					}
				}
			})

			// let categorize = await categorizeLocalMedia(accepted);

			// for (const keys in categorize) {
			// 	Computer.count += categorize[keys].length;

			// 	categorize[keys].forEach((i) => {
			// 		Computer[keys].push(i);
			// 	});
			// }

			// onProcessData('Computer', Computer);
		}
	};

	render() {
		let { Computer, viewFiles, activeDrive, displayStyle } = this.props;
		let { count } = Computer;
		return (
			<Fragment>
				{displayStyle === 'dropZone' ? (
					<DropZone
						style={baseStyle}
						acceptStyle={acceptStyle}
						activeStyle={activeStyle}
						rejectStyle={rejectStyle}
						accept=".pdf,.jpg,.jpeg,.png,.xlsx,.xlx,.doc,.docx,.ppt,.pptx,.mp4"
						ref={(c) => (this.dropzoneRef = c)}
						onDrop={this.onSuccess}
					>
						<div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
							<i className="fa fa-upload fa-2x" />

							<div>Drag Drop Files Here Or Click To Upload.</div>
						</div>
					</DropZone>
				) : (
						<li
							className={cx(s['at-mycomputer'], {
								[s['at-showcatagory']]: count,
								[s['at-active']]: activeDrive == 'Computer'
							})}
						>
							<a
								onClick={() => {
									this.dropzoneRef.open();
								}}
								href={null}
							>
								<i className={s['at-divebtnholder']}>
									<img src="images/mycomputer-icon.png" />
								</i>
								<span>My Computer</span>
							</a>
							<DropZone
								accept=".pdf,.jpg,.jpeg,.png,.xlsx,.xlx,.doc,.docx,.ppt,.pptx,.mp4"
								style={{ display: 'none' }}
								ref={(c) => (this.dropzoneRef = c)}
								onDrop={this.onSuccess}
							>
								<p>Drop files here.</p>
							</DropZone>
						</li>
					)}
			</Fragment>
		);
	}
}

export default ComputerDrivePage;
