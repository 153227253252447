import React from "react";
import { Link } from "react-router";
import { Drawer, Layout, Avatar, Popover, Menu, Modal } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import CustomScrollbars from "../../../utils/CustomScrollbars";
import messages from "../../../messages";

const { Sider } = Layout;
const MenuItemGroup = Menu.ItemGroup;
const SidebarLogo = () => {
  return (
    <div className="gx-layout-sider-header">
      <Link to="/" className="gx-site-logo">
        <img alt="lo" src={"images/touchit-logo.png"} />
      </Link>
    </div>
  );
};

export const UserMenuOptions = ({ logout }) => {
  return (
    <ul className="gx-user-popover">
      <li>
        <Link to="/profile" className="gx-site-logo">
          My Profile
        </Link>
      </li>
      <li
        onClick={() => {
          return Modal.confirm({
            title: "Are you sure?",
            icon: <LogoutOutlined />,
            content: "You want to logout",
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
              logout();
            },
          });
        }}
      >
        Logout
      </li>
    </ul>
  );
};

const UserMenuLinks = ({ currentUser }) => (
  <Menu
    defaultOpenKeys={["folders"]}
    selectedKeys={["folders"]}
    theme={"lite"}
    mode="inline"
  >
    <MenuItemGroup
      key="app-links"
      className="gx-menu-group"
      title={"App Links"}
    >
      <Menu.Item key="folders">
        <Link to="/folders">
          <i className="icon icon-calendar" />
          <span>
            <FormattedMessage {...messages.dashboard} />
          </span>
        </Link>
      </Menu.Item>

      <Menu.Item key="voting-folders">
        <Link to="/voting-folders">
          <i className="icon icon-check-square-o" />
          <span>Voting Activities</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="students">
        <Link to="/students">
          <i className="icon icon-auth-screen" />
          <span>
            <FormattedMessage {...messages.participantLists} />
          </span>
        </Link>
      </Menu.Item>

      <Menu.Item key="mGallery">
        <Link to="/mGallery">
          <i className="icon icon-copy" />
          <span>My Files</span>
        </Link>
      </Menu.Item>
      {currentUser.role === "super_admin" && (
        <Menu.Item key="admin-area">
          <Link to="/admin-area">
            <i className="icon icon-auth-screen" />
            <span>Admin Area</span>
          </Link>
        </Menu.Item>
      )}
    </MenuItemGroup>

    <MenuItemGroup
      key="in-built-apps"
      className="gx-menu-group"
      title={"External Links"}
    >
      <Menu.Item key="in-built-apps/mail">
        <a href="https://app.contribute.cloud" target="_blank">
          <i className="icon icon-refer" />
          <span>Contribute Cloud v2</span>
        </a>
      </Menu.Item>

      <Menu.Item key="in-built-apps/mail">
        <a
          href="https://touchittechnologies.com/contribute-cloud-v3-tutorials/"
          target="_blank"
        >
          <i className="icon icon-refer" />
          <span>
            <FormattedMessage {...messages.videoTutorials} />
          </span>
        </a>
      </Menu.Item>

      <Menu.Item key="onlineManual">
        <a
          href="https://touchittechnologies.com/contribute_cloud_3/"
          target="_blank"
        >
          <i className="icon icon-product-list" />
          <span>
            <FormattedMessage {...messages.onlineManual} />
          </span>
        </a>
      </Menu.Item>

      <Menu.Item key="latestUpdate">
        <a href="https://contribute.cloud/timeline" target="_blank">
          <i className="icon icon-timeline-with-icons" />
          <span>
            <FormattedMessage {...messages.latestUpdate} />
          </span>
        </a>
      </Menu.Item>

      <Menu.Item key="reportABug">
        <a href="http://touchittechnologies.com/contribute_bug" target="_blank">
          <i className="icon icon-spam" />
          <span>
            <FormattedMessage {...messages.reportABug} />
          </span>
        </a>
      </Menu.Item>
    </MenuItemGroup>
  </Menu>
);

const Sidebar = ({ visible, toggleCollapse, currentUser, logout }) => {
  return (
    <Sider
      className={`gx-app-sidebar gx-collapsed-sidebar`}
      trigger={null}
      collapsed={false}
      collapsible
    >
      <Drawer
        className={`gx-drawer-sidebar`}
        placement="left"
        closable={true}
        onClose={toggleCollapse}
        visible={visible}
      >
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications`}>
            <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
              <Popover
                trigger="click"
                placement="bottomRight"
                content={<UserMenuOptions logout={logout} />}
              >
                <Avatar
                  src={currentUser.profile_photo_path}
                  className="gx-size-40 gx-pointer gx-mr-3"
                  alt={currentUser.first_name || currentUser.username}
                />
                <span className="gx-avatar-name">
                  {currentUser.first_name || currentUser.username}
                  <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
                </span>
              </Popover>
            </div>
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <UserMenuLinks currentUser={currentUser} />
          </CustomScrollbars>
        </div>
      </Drawer>
    </Sider>
  );
};

export default Sidebar;
