import React, { Component } from 'react';
import loadScript from 'load-script';
import PropTypes from 'prop-types';

const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
const SCRIPT_ID = 'dropboxjs';

class DropboxChooser extends Component {
	static propTypes = {
		appKey: PropTypes.string.isRequired,
		success: PropTypes.func.isRequired,

		cancel: PropTypes.func,
		linkType: PropTypes.oneOf([ 'preview', 'direct' ]),
		multiselect: PropTypes.bool,
		extensions: PropTypes.arrayOf(PropTypes.string),
		disabled: PropTypes.bool
	};

	static defaultProps = {
		cancel: () => {},
		linkType: 'preview',
		multiselect: false,
		disabled: false
	};

	constructor(props) {
		super(props);
		this.scriptLoadingStarted = false;
	}

	componentDidMount = () => {
		const { appKey } = this.props;
		if (!this.isDropboxReady() && !this.scriptLoadingStarted) {
			this.scriptLoadingStarted = true;
			loadScript(DROPBOX_SDK_URL, { attrs: { id: SCRIPT_ID, 'data-app-key': appKey } });
		} else {
			console.info('dropbox loading ...');
		}
	};

	isDropboxReady = () => {
		return !!window.Dropbox;
	};

	onChooser = () => {
		const { disabled } = this.props;
		if (!this.isDropboxReady() || disabled) {
			return null;
		}

		const { success, cancel, linkType, multiselect, extensions } = this.props;
		window.Dropbox.choose({ success, cancel, linkType, multiselect, extensions });
	};

	render() {
		return (
			<div onClick={this.onChooser}>
				{this.props.children ? this.props.children : <button>Dropbox Chooser</button>}
			</div>
		);
	}
}

export default DropboxChooser;
