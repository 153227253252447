// Invitation.js
import React, { Component } from 'react';
import { connect } from 'react-redux';

import TextFieldGroup from '../common/TextFieldGroup';
import classnames from 'classnames';
import { createWorkbook, fetchWorkbooks } from '../../actions/workbookActions';
import validateInput from '../../validations/workbook';
import { sendInvitation, saveCourse, fetchCourses, deleteCourse, addNewList, addNewEmail } from '../../actions/courseActions';
import PropTypes from 'prop-types';


class Invitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newStudentEmail: '',
      errors: {},
      isLoading: false,
      course: [],
      student: [],
    };

    // this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addToInvitationList = this.addToInvitationList.bind(this);
    this.removeStudent = this.removeStudent.bind(this);
    this.fetchCourses = this.fetchCourses.bind(this);
    this.sendInvitation = this.sendInvitation.bind(this);
    this.addNewStudent = this.addNewStudent.bind(this);
  }

  componentDidMount() {
    const { email, id } = this.props.auth.user;
    this.props.fetchCourses({ userID: id }).then((res) => {
      this.setState({ course: res.data.course });
    });
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  fetchCourses() {
    const { email, id } = this.props.auth.user;
    this.props.fetchCourses({ userID: id }).then((res) => {
      this.setState({ course: res.data.course });
    });
  }

  // onSubmit(e) {
  //     e.preventDefault();
  //     if (this.isValid()) {

  //         this.setState({ errors: {}, isLoading: true });
  //         this.props.createWorkbook(this.state)
  //             .then(
  //             (res) => {
  //                 this.setState({ name: '', subject: '', description: '', isLoading: false });
  //                 $('#my-new-lecture').modal('hide');
  //                 let { email, id } = this.props.auth.user;
  //                 this.props.fetchWorkbooks({ userID: id }).then(() => {
  //                     this.context.router.push('/workbooks');
  //                 });
  //             },
  //         ).catch(
  //             (err) => this.setState({ errors: err.response.data, isLoading: false })
  //             );
  //     }
  // }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  addNewStudent(e) {
    e.preventDefault();
    this.setState({ errors: {} });
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(this.state.newStudentEmail)) {
      let student = this.state.student;
      student.push(this.state.newStudentEmail);
      student = [...new Set(student)];
      this.setState({ student });
      this.setState({ newStudentEmail: '' });
    } else {
      const errors = {};
      errors.newStudentEmail = 'Please Enter Valid Email';
      this.setState({ errors });
    }
  }
  addToInvitationList(e) {
    // let student = Object.assign({}, e.student);

    // this.setState({ student });

    let student = this.state.student;
    e.student.forEach((i) => {
      student.push(i.email);
    });
    student = [...new Set(student)];
    this.setState({ student });
  }
  removeStudent(email) {
    const student = this.state.student;
    const isDeleted = false;
    const index = student.indexOf(email);

    if (index > -1) {
      student.splice(index, 1);
    }

    this.setState({ student });

    // Object.keys(student).forEach((i, j) => {
    //     var eq = student[i].email == email;
    //     if (eq && !isDeleted) {
    //         isDeleted = true;
    //         delete student[i]
    //         this.setState({ student });
    //     }
    // })
  }

  sendInvitation() {
    this.props.sendInvitation({ student: this.state.student }).then((res) => {
      $('#invite_students').modal('hide');

      this.props.onSendNotification(res.data.session_id);
    });
  }

  render() {
    const { errors, course, student, isLoading } = this.state;
    const { newStudentEmail } = this.state;

    const allCourse = [];
    const studentList = [];

    course.forEach((c, i) => {
      console.log(c);
      allCourse.push(
        <tr key={i}>
          <td>{ i + 1 }</td>
          <td>{ c.name }</td>
          <td>{ c.student.length }            </td>
          <td>
            <a onClick={this.addToInvitationList.bind(this, c)} className="btn btn-default btn-xs"> Add</a>
          </td>
        </tr>,
      );
    });

    student.forEach((i) => {
      studentList.push(
        <a key={i} className="btn btn-invited">{ i }&nbsp;&nbsp;<i onClick={this.removeStudent.bind(this, i)} className="fa fa-times" /></a>,
      );
    });
    return (
      <div id="invite_students" className="modal fade" role="dialog">
        <div className="modal-dialog invite_dialogue">
          {/* Lecture content */ }
          <div className="modal-content clearfix">
            <div className="flex-box">
              <div className="col-lg-6 col-sm-6 col-xs-12 invite-left">
                <h2 className="invite_title">Invite Participants</h2>
                <form onSubmit={this.addNewStudent}>
                  <div className="form-group">
                    <br />
                    <div className="input-group">
                      <TextFieldGroup
                        field="newStudentEmail"
                        label="Add Student"
                        value={newStudentEmail}
                        error={errors.newStudentEmail}
                        onChange={this.onChange}
                      />

                      <span className="input-group-btn">
                        <button className="btn btn-default" type="submit">
                                                Add Student
                        </button>
                      </span>

                    </div>
                  </div>
                </form>
                <div className="form-group">
                  <table className="table text-center">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Course Name</th>
                        <th>Number of Student</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      { allCourse }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-12 invite-right">
                <div className="pop-up-wrapper">
                  <h2 className="invite-right-title">Invited Participants</h2>
                  <div className="list">
                    { studentList }
                  </div>
                  <div className="clearfix">
                    <div className="popup-controller col-lg-12">
                      <a disabled={!(this.state.student.length > 0)} onClick={this.sendInvitation} className="btn btn-success">Send Invitation</a>
                      <a className="btn btn-default" data-dismiss="modal">Close</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

Invitation.propTypes = {
  createWorkbook: PropTypes.func.isRequired,
  fetchCourses: PropTypes.func.isRequired,
  sendInvitation: PropTypes.func.isRequired,
  fetchWorkbooks: PropTypes.func.isRequired,
  onSendNotification: PropTypes.func.isRequired,
};

Invitation.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { createWorkbook, fetchWorkbooks, fetchCourses, sendInvitation })(Invitation);
