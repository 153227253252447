import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
var $ = require('jquery');
import loadScript from 'load-script';
import ToolHolder from '../../holder/ToolHolder'
import s from './ClickTheDots.module.css';


function initClickTheDotsGame(params) {
    var a, z = 50, x, y = z / 2;
    /////////////////////////////////////////////////////////////
    //--------------------------------------------------

    $('.start-button').click(function () {
        var x = $('.main-screen');
        var h = x.height(); var h2 = 0 - h;
        TweenLite.to(x, 1, { top: h2, ease: Power3.easeInOut, onComplete: theGame })
    })

    //--------------------------------------------------
    function theGame() {

        setTimeout(function () {
            clearInterval(a);
        }, 30000)
        var x = $('.at-gamedots');
        var i = 0;
        go(i);
        function go() {

            a = setInterval(function () {
                var n = Math.ceil(Math.random() * 80);
                TweenLite.to(x[n], 0.1, { background: '#88ff88' })
                TweenLite.to(x[n], 0.1, { background: 'rgb(0, 34, 0)', delay: 1 })
            }, 260);
        }
        function backTo() {
            go();
        }

        $('.at-gamedots').mouseout(function () {
            i = 0;
        })
        $('.pop').mouseenter(function () {
            i = 1;
        })
        $('.pop').mouseleave(function () {
            i = 1;
        })
        var curmp = new Object();

        function scorePopUp(x, y, val, plusminus) {
            $('.pop').remove();
            $('.wrapper').append('<div class="pop">' + plusminus + val + '</div>');
            $('.pop').css({ 'top': y, 'left': x, 'font-size': '1.5em' });
            if (plusminus == '-')
                TweenLite.to('.pop', 1, { top: y + 20, opacity: 0, onComplete: clearIt });
            else
                TweenLite.to('.pop', 1, { top: y - 20, opacity: 0, onComplete: clearIt });
            function clearIt() {
                $('.pop').remove();
            }
        }

        //---------------------------------------------------------  
        var popCount = 0;
        $('.at-gamedots').click(function (event) {

            curmp.x = event.pageX;
            curmp.y = event.pageY;
            console.log(curmp.x, curmp.y)
            if (i == 0) {
                i = 1;
                var c = $(this).css('background-color');
                if (c != 'rgb(0, 34, 0)') {
                    console.log('got it');
                    scorePopUp(curmp.x, curmp.y, 2, '+');
                    var x = Number($('.score span.current').text());
                    x++ , x++;
                    $('.score span .current').text(x)
                    logScore(x);
                }
                else {
                    console.log('missed');
                    scorePopUp(curmp.x, curmp.y, 1, '-');
                    var x = Number($('.score span.current').text());
                    if (x > 0) x--;
                    $('.score span .current').text(x);
                    logScore(x);
                }
            }

        });

        //-------------------------------------------------------
        //localStorage.getItem
        //localStorage.setItem('score', 0);
        function logScore(score) {
            if (score > localStorage.getItem('score'))
                localStorage.setItem('score', score);
            $('.score span.current').text(score);
        }
        loadScore()
        function loadScore() {
            $('.score span.high').text(localStorage.getItem('score') ? localStorage.getItem('score') : 0);
            $('.score span.current').text(0);
        }

        //------------------------------------------------------

        timer(30);
        function timer(t) {
            $('.time span').text(t);
            a = setInterval(function () {

                t--;
                if (t == 0) {
                    clearInterval(a)
                    endScreen();
                }
                $('.time span').text(t)
            }, 1000);
        }
    };

    function endScreen() {
        $('.start-group').css('display', 'none');
        $('.end-group').show();
        setTimeout(function () {
            TweenLite.to($('.main-screen'), 0.5, { top: 0 });
            $('.score-recap .high-score').html(
                'Your High Score: <br />' + localStorage.getItem('score')
            )
            $('.score-recap .your-score').html(
                "This Round's Score: <br />" + $('.score .current').text() + '<br /><br />'
            )
        }, 1000);
    }

}

/////////////////////////////////////////////////////////////
//--------------------------------------------------


function endScreen() {
    $('.start-group').css('display', 'none');
    $('.end-group').show();
    setTimeout(function () {
        TweenLite.to($('.main-screen'), 0.5, { top: 0 });
        $('.score-recap .high-score').html(
            'Your High Score: <br />' + localStorage.getItem('score')
        )
        $('.score-recap .your-score').html(
            "This Round's Score: <br />" + $('.score .current').text() + '<br /><br />'
        )
    }, 1000);
}



class ClickTheDots extends Component {
    static propTypes = {
        closeTool: PropTypes.func.isRequired
    };

    constructor() {
        super();
    }

    componentDidMount = () => {
        // initClickTheDotsGame()
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/gsap/latest/TweenMax.min.js', initClickTheDotsGame);
    }


    render() {
        const { closeTool } = this.props

        return (
            <ToolHolder name={'Click The Dots Games'} closeTool={closeTool} tool={'ClickTheDots'} classname={'at-excelholder'}>
                <div className={cx(s["at-opancalculator"])}>
                    <div>
                        <div className="score-box">
                            <h1>Click The Dots [Game]</h1>
                            <div className="at-score">
                                <h5>Your High Score</h5>
                                <span className="high" />
                            </div>
                            <div className="time">
                                <span>time</span>
                            </div>
                            <div className="at-score">
                                <h5>Your Current Score</h5>
                                <span className="current" />
                            </div>
                        </div>
                        <article className="wrapper">
                            <div className="dotsholder" id="dotsholder-1">
                                <div className="at-gamedots at-gamedots-1" />
                                <div className="at-gamedots at-gamedots-2" />
                                <div className="at-gamedots at-gamedots-3" />
                                <div className="at-gamedots at-gamedots-4" />
                                <div className="at-gamedots at-gamedots-5" />
                                <div className="at-gamedots at-gamedots-6" />
                                <div className="at-gamedots at-gamedots-7" />
                                <div className="at-gamedots at-gamedots-8" />
                                <div className="at-gamedots at-gamedots-9" />
                            </div>
                            <div className="dotsholder" id="dotsholder-2">
                                <div className="at-gamedots at-gamedots-1" />
                                <div className="at-gamedots at-gamedots-2" />
                                <div className="at-gamedots at-gamedots-3" />
                                <div className="at-gamedots at-gamedots-4" />
                                <div className="at-gamedots at-gamedots-5" />
                                <div className="at-gamedots at-gamedots-6" />
                                <div className="at-gamedots at-gamedots-7" />
                                <div className="at-gamedots at-gamedots-8" />
                                <div className="at-gamedots at-gamedots-9" />
                            </div>
                            <div className="dotsholder" id="dotsholder-3">
                                <div className="at-gamedots at-gamedots-1" />
                                <div className="at-gamedots at-gamedots-2" />
                                <div className="at-gamedots at-gamedots-3" />
                                <div className="at-gamedots at-gamedots-4" />
                                <div className="at-gamedots at-gamedots-5" />
                                <div className="at-gamedots at-gamedots-6" />
                                <div className="at-gamedots at-gamedots-7" />
                                <div className="at-gamedots at-gamedots-8" />
                                <div className="at-gamedots at-gamedots-9" />
                            </div>
                            <div className="dotsholder" id="dotsholder-4">
                                <div className="at-gamedots at-gamedots-1" />
                                <div className="at-gamedots at-gamedots-2" />
                                <div className="at-gamedots at-gamedots-3" />
                                <div className="at-gamedots at-gamedots-4" />
                                <div className="at-gamedots at-gamedots-5" />
                                <div className="at-gamedots at-gamedots-6" />
                                <div className="at-gamedots at-gamedots-7" />
                                <div className="at-gamedots at-gamedots-8" />
                                <div className="at-gamedots at-gamedots-9" />
                            </div>
                            <div className="dotsholder" id="dotsholder-5">
                                <div className="at-gamedots at-gamedots-1" />
                                <div className="at-gamedots at-gamedots-2" />
                                <div className="at-gamedots at-gamedots-3" />
                                <div className="at-gamedots at-gamedots-4" />
                                <div className="at-gamedots at-gamedots-5" />
                                <div className="at-gamedots at-gamedots-6" />
                                <div className="at-gamedots at-gamedots-7" />
                                <div className="at-gamedots at-gamedots-8" />
                                <div className="at-gamedots at-gamedots-9" />
                            </div>
                            <div className="dotsholder" id="dotsholder-6">
                                <div className="at-gamedots at-gamedots-1" />
                                <div className="at-gamedots at-gamedots-2" />
                                <div className="at-gamedots at-gamedots-3" />
                                <div className="at-gamedots at-gamedots-4" />
                                <div className="at-gamedots at-gamedots-5" />
                                <div className="at-gamedots at-gamedots-6" />
                                <div className="at-gamedots at-gamedots-7" />
                                <div className="at-gamedots at-gamedots-8" />
                                <div className="at-gamedots at-gamedots-9" />
                            </div>
                            <div className="dotsholder" id="dotsholder-7">
                                <div className="at-gamedots at-gamedots-1" />
                                <div className="at-gamedots at-gamedots-2" />
                                <div className="at-gamedots at-gamedots-3" />
                                <div className="at-gamedots at-gamedots-4" />
                                <div className="at-gamedots at-gamedots-5" />
                                <div className="at-gamedots at-gamedots-6" />
                                <div className="at-gamedots at-gamedots-7" />
                                <div className="at-gamedots at-gamedots-8" />
                                <div className="at-gamedots at-gamedots-9" />
                            </div>
                            <div className="dotsholder" id="dotsholder-8">
                                <div className="at-gamedots at-gamedots-1" />
                                <div className="at-gamedots at-gamedots-2" />
                                <div className="at-gamedots at-gamedots-3" />
                                <div className="at-gamedots at-gamedots-4" />
                                <div className="at-gamedots at-gamedots-5" />
                                <div className="at-gamedots at-gamedots-6" />
                                <div className="at-gamedots at-gamedots-7" />
                                <div className="at-gamedots at-gamedots-8" />
                                <div className="at-gamedots at-gamedots-9" />
                            </div>
                            <div className="dotsholder" id="dotsholder-9">
                                <div className="at-gamedots at-gamedots-1" />
                                <div className="at-gamedots at-gamedots-2" />
                                <div className="at-gamedots at-gamedots-3" />
                                <div className="at-gamedots at-gamedots-4" />
                                <div className="at-gamedots at-gamedots-5" />
                                <div className="at-gamedots at-gamedots-6" />
                                <div className="at-gamedots at-gamedots-7" />
                                <div className="at-gamedots at-gamedots-8" />
                                <div className="at-gamedots at-gamedots-9" />
                            </div>
                        </article>
                        <div className="main-screen">
                            <div className="mid-wrap">
                                <div className="start-group">
                                    <span className="title">Click <span><div>th</div>e</span>Dots</span>
                                    <div className="start-button">
                                        <span>play</span>
                                    </div>
                                </div>
                                <div className="end-group">
                                    <span className="title">Well done!</span>
                                    <div className="score-recap">
                                        <span className="your-score" />
                                        <span className="high-score" />
                                    </div>
                                    <div className="start-button">
                                        <span>play again</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ToolHolder>
        );
    }
}

export default ClickTheDots;