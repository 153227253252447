import axios from "axios";

export function getUserFilesDetails() {
  return (dispatch) =>
    axios.get(`${process.env.REACT_APP_API_HOST}/api/workbook/userFiles`);
}

export function getUserClassroomList() {
  return axios.get(`${process.env.REACT_APP_API_HOST}/api/course/fetch`);
}

export function isUserExistInClassroom(courseId, email) {
  return (dispatch) =>
    axios.get(
      `${process.env.REACT_APP_API_HOST}/api/course/${courseId}/${email}`
    );
}

export function addUserToClassroom(courseId, data) {
  return axios.post(
    `${process.env.REACT_APP_API_HOST}/api/course/${courseId}/student`,
    data
  );
}

export function removeUserFromClassroom(courseId, studentId) {
  return axios.delete(
    `${process.env.REACT_APP_API_HOST}/api/course/${courseId}/student/${studentId}`
  );
}

export function removeClassroom(courseId) {
  return axios.delete(
    `${process.env.REACT_APP_API_HOST}/api/course/${courseId}`
  );
}

export function isClassroomExists(name) {
  return (dispatch) =>
    axios.get(`${process.env.REACT_APP_API_HOST}/api/course/${name}/name`);
}
export function createNewClassroom(data) {
  return axios.post(`${process.env.REACT_APP_API_HOST}/api/course/`, data);
}
export function updateClassroomDetails(classroomId, data) {
  return axios.put(
    `${process.env.REACT_APP_API_HOST}/api/course/${classroomId}`,
    data
  );
}

export function saveGoogleClassrooms(data) {
  return axios.post(
    `${process.env.REACT_APP_API_HOST}/api/course/gclassroom`,
    data
  );
}
