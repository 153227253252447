import React from "react";
import {
  FileImageOutlined,
  AudioOutlined,
  VideoCameraOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FilePptOutlined,
  FileTextOutlined,
  CodeOutlined,
  FileZipOutlined,
  Html5Outlined,
  FileOutlined,
} from "@ant-design/icons";

export const getIconFromMIME = (mimeType: string) => {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
  var icon_classes = {
    // Media
    image: <FileImageOutlined />,
    audio: <AudioOutlined />,
    video: <VideoCameraOutlined />,
    // Documents
    "application/pdf": <FilePdfOutlined />,
    "application/msword": <FileWordOutlined />,
    "application/vnd.ms-word": <FileWordOutlined />,
    "application/vnd.oasis.opendocument.text": <FileWordOutlined />,
    "application/vnd.openxmlformats-officedocument.wordprocessingml": (
      <FileWordOutlined />
    ),
    "application/vnd.ms-excel": <FileExcelOutlined />,
    "application/vnd.openxmlformats-officedocument.spreadsheetml": (
      <FileExcelOutlined />
    ),
    "application/vnd.oasis.opendocument.spreadsheet": <FileExcelOutlined />,
    "application/vnd.ms-powerpoint": <FilePptOutlined />,
    "application/vnd.openxmlformats-officedocument.presentationml": (
      <FilePptOutlined />
    ),
    "application/vnd.oasis.opendocument.presentation": <FilePptOutlined />,
    "text/plain": <FileTextOutlined />,
    "text/html": <Html5Outlined />,
    "application/json": <CodeOutlined />,
    // Archives
    "application/gzip": <FileZipOutlined />,
    "application/zip": <FileZipOutlined />,
  };

  for (var key in icon_classes) {
    if (icon_classes.hasOwnProperty(key)) {
      if (mimeType.search(key) === 0) {
        // Found it
        return icon_classes[key];
      }
    } else {
      return <FileOutlined />;
    }
  }
};

export const getImageFromMIME = (mimeType: string) => {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
  var icon_classes = {
    // Media
    image: "dd-jpg",
    audio: "dd-mp",
    video: "dd-file_video",
    "text/javascript": "dd-html",
    "text/plain": "dd-txt",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "dd-excel",
    "application/x-gzip": "dd-file_zip",
    "application/x-rar": "dd-YS",
    // Documents
    "application/pdf": "dd-file_pdf",
    "application/msword": "dd-white",
    "application/vnd.ms-word": "dd-white",
    "application/vnd.oasis.opendocument.text": "dd-white",
    "application/vnd.openxmlformats-officedocument.wordprocessingml":
      "dd-white",
    "application/vnd.ms-excel": "dd-white",
    "application/vnd.openxmlformats-officedocument.spreadsheetml": "dd-white",
    "application/vnd.oasis.opendocument.spreadsheet": "dd-white",
    "application/vnd.ms-powerpoint": "dd-white",
    "application/vnd.openxmlformats-officedocument.presentationml": "dd-white",
    "application/vnd.oasis.opendocument.presentation": "dd-white",
    "text/html": "dd-white",
    "application/json": "dd-white",
    // Archives
    "application/gzip": "dd-white",
    "application/zip": "dd-white",
  };

  for (var key in icon_classes) {
    if (icon_classes.hasOwnProperty(key)) {
      if (mimeType.search(key) === 0) {
        // Found it
        return icon_classes[key];
      }
    } else {
      return "dd-white";
    }
  }
  return "dd-white";
};
