import React, { Component } from 'react';
import { Link } from "react-router";
import { connect } from "react-redux";
import { logout } from "../actions/authActions";
import NewLecture from "./model/NewLecture";
import CompareModal from "./model/CompareModal";
import classnames from "classnames";
import PropTypes from 'prop-types';
import { map } from "lodash";
// import { ModalContainer, ModalDialog } from "react-modal-dialog";
import TextFieldGroup from './common/TextFieldGroup'

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotificationPanel: false,
      isShowingModal: false
    };
    this.handleBellClick = this.handleBellClick.bind(this);
    this.previewClick = this.previewClick.bind(this);
    this.showSessionModal = this.showSessionModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  logout(e) {
    e.preventDefault();
    this.props.logout();
    this.context.router.push("/");
  }

  saveLecture() {
    this.props.saveSlides();
  }

  componentDidMount() {
    $(document).ready(() => {
      const fulldivheight = $(window).height();
      const headerheight = $("header").height(); // alert(sideMenu);
      const slide_height02 = fulldivheight + headerheight - 150;
      $(".side_menu").height(slide_height02);
      $(".thumbnail_wrapper").height(slide_height02 - 60);
      $(".slide-review").height(slide_height02 - 80);
      $(".file-drop-here").height(slide_height02 - 60);
      $(".left-accordion_block").height(slide_height02 - 60);
      // $("#wrapper").css({'min-height':fulldivheight});
      $("#main").css({
        "min-height": fulldivheight - 94
      });

      $('#compare-lecture').on('show.bs.modal', function () {
        var modalButton = document.getElementById('compare-lecture-button')
        modalButton.innerText = 'Close Multi-Cast Window'
      })

      $('#compare-lecture').on('hidden.bs.modal', function () {
        var modalButton = document.getElementById('compare-lecture-button')
        modalButton.innerText = 'Launch Multi-Cast Window'
      })

      // /*Notifications section*/
      // $(".btn-notification").click(function () {
      //     $(".notification-block").toggleClass("active");
      //     $(".btn-notification").toggleClass("active");
      // });

      // /* Side Menu section */
      // $(".side_menu-opener").click(() => {
      //   $(".drawing_annotations_tools").toggleClass("active");
      // });
      // /* Side Menu section */
      // $(".annotation_settings_opener").click(() => {
      //   $(".annotation_settings").toggleClass("open");
      // });
      $("ul.tabs li").click(function () {
        const tab_id = $(this).attr("data-tab");
        $("ul.tabs li").removeClass("current");
        $(".tab-content").removeClass("current");
        $(this).addClass("current");
        $(`#${tab_id}`).addClass("current");
      });
    });
  }

  handleBellClick() {
    this.setState({
      showNotificationPanel: !this.state.showNotificationPanel
    });
  }

  previewClick(number) {
    console.log(number);
    this.props.previewClick(number);
  }

  showSessionModal() {
    this.setState({ isShowingModal: true });
  }

  handleClose() {
    this.setState({ isShowingModal: false });
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const { email, first_name, imageUrl } = this.props.auth.user;
    const slideShow = map(this.props.lectureSlides, (item, key) => (
      <div
        onClick={this.previewClick.bind(this, key)}
        key={item._id}
        className="thumbnail-img-row"
      >
        <img
          src={item.thumbnail}
          className="img-responsive"
          style={{ filter: "drop-shadow(16px 16px 20px blue)" }}
        />
      </div>
    ));

    return (
      <header className="container-fluid">
        <div onClick={this.handleClick}>
          {this.state.isShowingModal && (
            <ModalContainer>
              <ModalDialog>
                <h1
                  style={{
                    margin: "0px 0px 15px 0px",
                    'font-size': '125px'
                  }}
                >
                  {this.props.sessionId}
                </h1>
                <div className="text-center">
                  <p className="btn btn-lg btn-info" onClick={this.handleClose}>
                    OK
                  </p>
                </div>
              </ModalDialog>
            </ModalContainer>
          )}
        </div>

        <div className="row">
          <div className="logo-section col-sm-6 col-xs-12">
            <strong className="md-logo">
              <Link href="/">
                <img src="images/logo.png" alt className="img-responsive" />
              </Link>
            </strong>

            <strong className="touch-it-logo">
              <Link href="javascript:;">
                <img
                  src="images/touch_it_md_logo.png"
                  alt
                  className="img-responsive"
                />
              </Link>
            </strong>
          </div>

          <div className="menu-section col-sm-6 col-xs-12">
            <ul className="top-header-menu" style={{ position: "relative", zIndex: 6 }}>
              <li>
                <a
                  data-target="#my-new-lecture"
                  data-toggle="modal"
                  href="javascript:;"
                >
                  <i
                    className="icon-new-lecture"
                    aria-hidden="true"
                    title="New Workbook"
                  />
                </a>
              </li>
              <li>
                <Link href="/workbooks">
                  <i
                    className="icon-recent-files"
                    aria-hidden="true"
                    title="Open Workbook"
                  />
                </Link>
              </li>

              {this.props.saveIcon === "show" ? (
                <li>
                  <a href="javascript:;" onClick={this.props.justSave}>
                    <i className="icon-save" aria-hidden="true" />
                  </a>
                </li>
              ) : (
                  ""
                )}
              {this.props.saveIcon === "show" ? (
                <li>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#invite_students"
                  >
                    <i className="fa fa-users" aria-hidden="true" />
                  </a>
                </li>
              ) : (
                  ""
                )}
              {this.props.saveIcon === "show" ? (
                <li>
                  <a href="javascript:;" onClick={this.props.previewSlides}>
                    <i className="icon-play" aria-hidden="true" />
                  </a>
                </li>
              ) : (
                  ""
                )}

              <li className="dropdown">
                <a
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  href="javascript:;"
                >
                  {imageUrl ? (
                    <img
                      alt="image"
                      className="img-circle"
                      src={imageUrl}
                      alt="Account"
                    />
                  ) : (
                      <i
                        className="icon-user"
                        aria-hidden="true"
                        title="Account"
                      />
                    )}
                </a>

                <ul className="dropdown-menu dropdown-alerts">
                  <li>
                    <Link to="javascript:;">
                      <i className="fa fa-user" /> {first_name}
                    </Link>
                  </li>
                  <li>
                    <Link to="javascript:;">
                      <i className="fa fa-envelope-o" /> {email}
                    </Link>
                  </li>
                  <li>
                    <Link to="/students">
                      <i className="fa fa-list" />
                      Student Lists
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile">
                      <i className="fa fa-cog" />
                      Settings
                    </Link>
                  </li>

                  <li>
                    <a
                      target="_banck"
                      href="http://touchittechnologies.com/contribute_cloud_manual"
                    >
                      <i className="fa fa-external-link" />
                      Online Manual
                    </a>
                  </li>
                  <li>
                    <a
                      target="_banck"
                      href="http://touchittechnologies.com/contribute_cloud_tutorials"
                    >
                      <i className="fa fa-external-link" />
                      Video Tutorials
                    </a>
                  </li>
                  <li>
                    <a
                      target="_banck"
                      href="https://contribute.cloud/timeline"
                    >
                      <i className="fa fa-external-link" />
                      Latest Updates
                    </a>
                  </li>
                  <li>
                    <a
                      target="_banck"
                      href="http://touchittechnologies.com/contribute_bug"
                    >
                      <i className="fa fa-external-link" />
                      Report a Bug
                    </a>
                  </li>

                  <li>
                    <Link to="javascript:;" onClick={this.logout.bind(this)}>
                      <i className="fa fa-sign-out" />
                      Logout
                    </Link>
                  </li>
                </ul>
              </li>
              {this.props.showBell ? (
                <li>
                  <a
                    href="javascript:;"
                    className={
                      this.props.requested ? (
                        "btn-notification shake"
                      ) : (
                          "btn-notification"
                        )
                    }
                    onClick={this.handleBellClick}
                  >
                    <i
                      className={classnames(
                        {
                          "fa fa-users green-users-icon": this.props
                            .noOfContributer
                        },
                        { "icon-notification": !this.props.noOfContributer }
                      )}
                      aria-hidden="true"
                      title="Notifications"
                    />
                  </a>
                </li>
              ) : (
                  ""
                )}
            </ul>
          </div>
        </div>
        {/* Notifications Section */}
        <div
          className={
            ("scrollable-area",
              classnames("notification-block", {
                active: this.state.showNotificationPanel
              }))
          }
          data-spy="affix" data-offset-top="100" >
          <ul>
            <span
              onClick={this.showSessionModal}
              style={{ fontSize: "xx-large", color: "white" }}
            >
              {this.props.sessionId}
            </span>
          </ul>
          <ul className="tabs">
            <li className="tab-link current" data-tab="tab-1">
              Notifications
            </li>
            <li className="tab-link" data-tab="tab-2">
              Slides Review
            </li>
            <li className="tab-link" data-tab="tab-3">
              Contribute Chat
            </li>
            <li className="tab-link" data-tab="tab-4">
              Contribute Cast
            </li>
          </ul>
          <div id="tab-1" className="tab-content current">
            <div className="slide-review scrollable-area">
              <div className="chatbox">
                <div className="chatbox__body">
                  {this.props.studentRequested}
                </div>
              </div>
            </div>
          </div>
          <div id="tab-2" className="tab-content">
            <div className="slide-review scrollable-area ">{slideShow}</div>
          </div>
          <div id="tab-3" className="tab-content">
            <div className="slide-review scrollable-area ">
              <div className="chatbox">
                <div className="chatbox__body">
                  {this.props.messageHistory}
                </div>
                <form className="formchatbox" onSubmit={this.props.sendChatMessage}>
                  <fieldset>

                    <TextFieldGroup
                      error={this.props.errors ? this.props.errors.chatMessage : ''}
                      label="Type Your Message Here"
                      onChange={this.props.onChange}
                      value={this.props.chatMessage}
                      field="chatMessage"
                    />
                    <button className="btn-sendtext"><i className="fa fa-paper-plane-o"></i></button>
                  </fieldset>
                </form>

              </div>
            </div>
          </div>
          <div id="tab-4" className="tab-content">

            {this.props.inCallMode ? <div>
              <button className={classnames('btn', { "end-call": !this.props.inCameraMode })} onTouchTap={this.props.cameraToggle}><i className="fa fa-video-camera"></i></button>
              <button className={classnames('btn', { "end-call": !this.props.inMicMode })} onTouchTap={this.props.micToggle}><i className="fa fa-microphone-slash"></i></button>
            </div> : ''}

            <div className="slide-review scrollable-area ">
              <div className="chatbox">
                <button data-toggle="modal" id="compare-lecture-button" data-target="#compare-lecture">Launch Multi-Cast Window</button>
                <div className="chatbox__body">
                  <div id="video-container"></div>
                </div>
              </div>
            </div>
            {/* <div className="chatbox chatbox__body slide-review scrollable-area" style={{ marginBottom: 50 }} id="video-container"> */}
            {/* </div> */}
          </div>
        </div>
        <NewLecture />
        <CompareModal />
      </header>

      /** <div className="container-fluid">
             <div className="row border-bottom">
             <nav className="navbar navbar-static-top" role="navigation" style={ { marginBottom: 0 } }>

             <Link to="/" className="header-logo"> <img src="images/logo.png" width="92px;" /></Link>
             <img src="images/touch_it_logo.png" style={{ paddingTop: "15px", width: "170px" }}className="col-lg-2 col-md-2 col-sm-2 col-xs-12" />

             <ul className="nav navbar-top-links navbar-right">

             { this.props.saveIcon === "show" ?
               <li>
                 <a href="javascript:;" onClick={ this.props.justSave } >
                   <img src="images/icons/Save.png" width="44px" />
                 </a>
               </li> :
               <li><a href="javascript:;" data-toggle="modal" data-target="#my-new-lecture"><img
                 src="images/icons/new_lecture.png" width="44px" /></a>
               </li> }

             { this.props.saveIcon === "show" ?
               <li>
                 <a href="javascript:;" data-toggle="modal" data-target="#invite_students">
                   <img src="images/icons/Collaborate.png" width="44px" />
                 </a>
               </li> : ""
             }
             { this.props.saveIcon === "show" ?
               <li>
                 <a href="javascript:;" onClick={ this.props.previewSlides }>
                   <img src="images/icons/play.png" width="44px" />
                 </a>
               </li> : ""
             }

             <li><Link to="/workbooks"><img src="images/icons/recent_files.png" width="44px" /></Link></li>
             <li className="dropdown">
             <a className="dropdown-toggle count-info" data-toggle="dropdown" href="javascript:;">
             <img alt="image" className="img-circle" width="44px"
             src={ imageUrl ? imageUrl : "images/buddy.png" } />
             </a>
             <ul className="dropdown-menu dropdown-alerts">
             <li><Link to="/"> <i className="fa fa-user" /> { first_name } </Link></li>
             <li><Link to="/"><i className="fa fa-envelope-o" /> { email }</Link></li>
             <li className="divider" />
             { isAuthenticated ? userLinks : "" }
             </ul>
             </li>
             </ul>
             </nav>
             </div>
             <NewLectureModal />
             </div>
             * */
    );
  }
}

NavigationBar.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  saveIcon: PropTypes.string,
  saveSlides: PropTypes.func,
  previewSlides: PropTypes.func,
  justSave: PropTypes.func,
  requested: PropTypes.bool.isRequired,
  noOfContributer: PropTypes.number
};

NavigationBar.contextTypes = {
  router: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, { logout })(NavigationBar);
