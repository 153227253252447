import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Loader.module.css';

class Loader extends Component {
    static propTypes = { isLoading: PropTypes.bool };

    static defaultProps = { isLoading: false };

    render() {
        const { isLoading } = this.props;
        if (isLoading) {
            return <div>
                <div className={s["preloaderholder"]}>
                    <div className={s["preloader"]}>
                        <span className={cx(s["line"], s["line-1"])}></span>
                        <span className={cx(s["line"], s["line-2"])}></span>
                        <span className={cx(s["line"], s["line-3"])}></span>
                        <span className={cx(s["line"], s["line-4"])}></span>
                        <span className={cx(s["line"], s["line-5"])}></span>
                        <span className={cx(s["line"], s["line-6"])}></span>
                        <span className={cx(s["line"], s["line-7"])}></span>
                        <span className={cx(s["line"], s["line-8"])}></span>
                        <span className={cx(s["line"], s["line-9"])}></span>
                        <div>In Progress</div>
                    </div>
                </div>
            </div>
        }
        return null
    }
}

export default Loader;