import React from "react";
import { Link } from "react-router";
import {
  Drawer,
  Layout,
  Row,
  Col,
  Avatar,
  Popover,
  Menu,
  Modal,
  Image,
  Tag,
  Statistic,
  Space,
  Tooltip,
  Checkbox,
  Divider,
  Typography,
} from "antd";
import {
  DownloadOutlined,
  FilePdfOutlined,
  LogoutOutlined,
  PauseOutlined,
  StopOutlined,
  FullscreenOutlined,
  PhoneOutlined,
  VideoCameraAddOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import CustomScrollbars from "../../utils/CustomScrollbars";
import messages from "../../messages";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { createPreviewImgSrc } from "../../utils/utils";
import s from "./LecturePage.module.css";
import UserVideoComponent from "./OpenVidu/UserVideoComponent";
import map from "lodash/map";
import _ from "lodash";
import cx from "classnames";
const CheckboxGroup = Checkbox.Group;
const { Text, Title } = Typography;

const { Sider } = Layout;
const MenuItemGroup = Menu.ItemGroup;

const SidebarLogo = () => {
  return (
    <div className="gx-layout-sider-header">
      <Link to="/" className="gx-site-logo">
        <img alt="lo" src={"images/touchit-logo.png"} />
      </Link>
    </div>
  );
};

const CastPopup = ({
  visible,
  toggleDrawer,
  subscribers,
  requestFromStudent,
  accessToStudent,
  studentList,
  manageAccess,
  compareArray,
  mannageCompare,
  toggleUserAudio,
  compareAll,
  addAllToCompare,
  toggleComparePopup,
  lectureRecording,
  manageTecherStream,
  sessionId,
  username,
  videoCall,
  toggleVideoCall,
  changeCastQuality,
  pauseStudentScreen,
  syncWithClass,
  togglePdfNameAlert,
  toggleStudentNavigation,
  unMutedStudent,
  studentNavigation,
  pause,
}) => {
  const requestedList = requestFromStudent.filter((obj) => {
    return accessToStudent.indexOf(obj) == -1;
  });

  const joined = studentList.filter((obj) => {
    return (
      accessToStudent.indexOf(obj) == -1 &&
      requestFromStudent.indexOf(obj) == -1
    );
  });

  return (
    <Sider
      className={`gx-app-sidebar gx-collapsed-sidebar`}
      trigger={null}
      collapsed={false}
      collapsible
    >
      <Drawer
        className={`gx-drawer-sidebar`}
        placement="right"
        closable={true}
        onClose={toggleDrawer}
        visible={visible}
      >
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={`gx-pt-3`}>
            <div className="gx-flex-column gx-align-items-center gx-mb-4 gx-avatar-row">
              <div className="gx-linebar">
                <Space>
                  <Tooltip title={pause ? "Pause Lecture" : "Continue Lecture"}>
                    <PauseOutlined
                      style={{
                        ...(!pause
                          ? {
                              color: "#fff",
                              background: "#f00",
                              borderRadius: 20,
                            }
                          : {}),
                      }}
                      onClick={pauseStudentScreen}
                      className="gx-icon-btn icon"
                    />
                  </Tooltip>

                  <Tooltip title={"Sync Now"}>
                    <SyncOutlined
                      onClick={syncWithClass}
                      className="gx-icon-btn icon"
                    />
                  </Tooltip>

                  <Tooltip
                    title={
                      studentNavigation ? "Hide Navigation" : "Show Navigation"
                    }
                  >
                    <StopOutlined
                      style={{
                        ...(!studentNavigation
                          ? {
                              color: "#fff",
                              background: "#f00",
                              borderRadius: 20,
                            }
                          : {}),
                      }}
                      onClick={toggleStudentNavigation}
                      className="gx-icon-btn icon"
                    />
                  </Tooltip>

                  <Tooltip title={"Download Lecture As PDF"}>
                    <FilePdfOutlined
                      onClick={togglePdfNameAlert}
                      className="gx-icon-btn icon"
                    />
                  </Tooltip>
                </Space>
              </div>
            </div>
          </div>

          <Divider />
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Space direction={"vertical"}>
              {joined.length ? (
                <Space direction="vertical">
                  <Text>Participants In Lecture</Text>
                  <CheckboxGroup
                    options={joined}
                    value={[]}
                    onChange={(value) => {
                      if (value.length) {
                        manageAccess(value[0]);
                      }
                      // manageAccess(_.nth(_.difference(joined, value), 0));
                    }}
                  />
                  {/* <Divider /> */}
                </Space>
              ) : null}

              {requestedList.length ? (
                <Space direction="vertical">
                  <Text>Requested Collaboration Access</Text>
                  <CheckboxGroup
                    options={requestedList}
                    value={[]}
                    onChange={(value) => {
                      manageAccess(_.last(value));
                    }}
                  />
                  {/* <Divider /> */}
                </Space>
              ) : null}

              {accessToStudent.length ? (
                <Space direction="vertical">
                  <Text>Have Collaboration Access</Text>
                  <CheckboxGroup
                    options={accessToStudent}
                    value={accessToStudent}
                    onChange={(value) => {
                      manageAccess(
                        _.nth(_.difference(accessToStudent, value), 0)
                      );
                    }}
                  />
                </Space>
              ) : null}
            </Space>
            <Divider />

            <div className={s["at-dropdownhead"]}>
              <h2>Contribute Multi-Cast</h2>
              {compareArray.length ? (
                <a
                  onClick={toggleComparePopup}
                  className={s["at-btnfullscreentwo"]}
                >
                  <img
                    src="images/icon-fullscreen.png"
                    alt="image description"
                  />
                </a>
              ) : null}
            </div>
            <div className={cx(s["at-dropdowncontentholder"])}>
              <div className={s["at-screensholder"]}>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="nsgaddallcompare"
                    checked={!!compareAll}
                    onChange={addAllToCompare}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="nsgaddallcompare"
                  >
                    Add All to Multi-Cast
                  </label>
                </div>

                {/* <div className={s["at-checkbox"]}>
                                            <input type="checkbox"
                                                onChange={addAllToCompare}
                                                id={`select-all-student`}
                                                name={`select-all-student`}
                                                id={`select-all-student`}
                                                className={s["css-checkbox"]} />
                                            <label htmlFor={`select-all-student`} className={cx(s["css-label"], s['nsg-selectall'])}>Add All to Multi-Cast</label>
                                        </div> */}
                {subscribers.map((sub, i) => (
                  <div
                    key={i}
                    className={cx(s["at-screenrequest"], s["at-dropdownvideo"])}
                  >
                    {/* <div className={s["at-checkbox"]}> */}
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`cast-student-${i}`}
                        checked={compareArray.indexOf(i) > -1}
                        onChange={mannageCompare.bind(this, i)}
                      />
                      <label
                        className={cx(
                          s["nsg-comparelable"],
                          "custom-control-label"
                        )}
                        htmlFor={`cast-student-${i}`}
                      >
                        {" "}
                        Add to Compare
                      </label>
                      <a
                        onClick={toggleUserAudio.bind(this, sub)}
                        className={cx(s["mic-btn"])}
                      >
                        <i
                          style={{
                            ...(unMutedStudent.indexOf(
                              JSON.parse(
                                sub.stream.connection.data.replace("}%/%{", ",")
                              ).clientData.split(" - Student")[0]
                            ) > -1
                              ? { color: "red" }
                              : {}),
                          }}
                          className="fa fa-microphone"
                        ></i>
                      </a>
                    </div>

                    {/* <input type="checkbox"
                                                        onChange={mannageCompare}
                                                        id={`cast-student-${i}`}
                                                        name={`cast-student-${i}`}
                                                        id={`cast-student-${i}`}
                                                        value={i}
                                                        className={s["css-checkbox"]} />

                                                    <label htmlFor={`cast-student-${i}`} className={s["css-label"]}></label> */}

                    <span className={s["nsg-usernameellipsis"]}>
                      {
                        JSON.parse(
                          sub.stream.connection.data.replace("}%/%{", ",")
                        ).clientData
                      }
                    </span>
                    <UserVideoComponent streamManager={sub} />
                    {/* <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        name={`cast-quality-${i}`}
                        id={`quality-144-${i}`}
                        value="144"
                        className="custom-control-input"
                        onChange={changeCastQuality.bind(this, sub, 12)}
                      />
                      <label
                        className={cx(
                          s["nsg-qualitylabel"],
                          "custom-control-label"
                        )}
                        htmlFor={`quality-144-${i}`}
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp; Low
                      </label>
                    </div> */}

                    {/* <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        name={`cast-quality-${i}`}
                        id={`quality-250-${i}`}
                        value="250"
                        className="custom-control-input"
                        onChange={changeCastQuality.bind(this, sub, 18)}
                      />
                      <label
                        className={cx(
                          s["nsg-qualitylabel"],
                          "custom-control-label"
                        )}
                        htmlFor={`quality-250-${i}`}
                      >
                        &nbsp;&nbsp;&nbsp; Med
                      </label>
                    </div> */}
                    {/* <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        name={`cast-quality-${i}`}
                        id={`quality-360-${i}`}
                        value="360"
                        className="custom-control-input"
                        onChange={changeCastQuality.bind(this, sub, 25)}
                      />
                      <label
                        className={cx(
                          s["nsg-qualitylabel"],
                          "custom-control-label"
                        )}
                        htmlFor={`quality-360-${i}`}
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp; Hi
                      </label>
                    </div> */}
                  </div>
                ))}
              </div>
            </div>

            {/* {videoCall && (
              <iframe
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allow="camera; microphone"
                src={`${process.env.REACT_APP_MEET_HOST}/${sessionId}?roomId=${sessionId}&username=${username}&type=lecture`}
              />
            )} */}
          </CustomScrollbars>
        </div>
      </Drawer>
    </Sider>
  );
};

export default CastPopup;
