import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropboxChooser from '../common/DropboxChooser';
import DataPreviewList from './DataPreviewList';
import { categorizeMedia } from '../../utils/helper';

class Dropbox extends Component {
  constructor(props) {
    super(props);
    this.state = { IMAGE: [], LAYOUT: [], SPREADSHEET: [], VIDEO: [], TEXT: [], DATA: [], NOTEBOOK: [], dataFlag: true };
  }

  componentDidMount = () => { }

  dropboxResponse = (media) => {
    console.log(media);
    if (media.length > 0) {
      this.setState({ dataFlag: false });
    }
    const local = { IMAGE: [], LAYOUT: [], SPREADSHEET: [], VIDEO: [], TEXT: [], DATA: [], NOTEBOOK: [] };

    const categorize = categorizeMedia(media);
    for (const keys in categorize) {
      categorize[keys].forEach((i) => {
        local[keys].push(i);
      });

      this.state[keys].forEach((i) => {
        local[keys].push(i);
      });
    }

    let IMAGE = local.IMAGE,
      LAYOUT = local.LAYOUT,
      SPREADSHEET = local.SPREADSHEET,
      VIDEO = local.VIDEO,
      TEXT = local.TEXT,
      DATA = local.DATA,
      NOTEBOOK = local.NOTEBOOK;

    this.setState({ IMAGE, LAYOUT, SPREADSHEET, VIDEO, TEXT, DATA, NOTEBOOK });
  }

  dropboxReject() {
  }

  delClick(item) {
    console.log(item);
    const state = this.state;
    let isDeleted = false;
    for (const key in state) {
      if (!isDeleted) {
        state[key].forEach((i) => {
          const eq = JSON.stringify(item) == JSON.stringify(i);
          if (eq && !isDeleted) {
            isDeleted = true;
            state[key].splice(i, 1);
            console.log(state[key]);
            console.log(key);
            const data = state[key];
            if (key == 'IMAGE') {
              this.setState({ IMAGE: data });
            } else if (key == 'LAYOUT') {
              this.setState({ LAYOUT: data });
            } else if (key == 'SPREADSHEET') {
              this.setState({ SPREADSHEET: data });
            } else if (key == 'VIDEO') {
              this.setState({ VIDEO: data });
            } else if (key == 'TEXT') {
              this.setState({ TEXT: data });
            } else if (key == 'DATA') {
              this.setState({ DATA: data });
            } else if (key == 'NOTEBOOK') {
              this.setState({ NOTEBOOK: data });
            }
          }
        });
      }
    }
  }

  addAllToWorkbook() {
    const state = this.state;
    for (const key in state) {
      if (typeof (state[key]) == 'object') {
        state[key].forEach((item) => {
          this.props.addClick(item, 'thumbnail')
        });
      }

    }
  }

  render() {
    const dropboxLink = (

      <DropboxChooser
        appKey="s9wqk0o36gu5yf6"
        success={this.dropboxResponse}
        cancel={this.dropboxReject}
        multiselect
      >
        <a href="javascript:;" className="opener btn-dropbox">
          <i className="fa fa-dropbox" aria-hidden="true" />
          Dropbox
        </a>

      </DropboxChooser>

    );

    return (
      <li className>
        {this.state.dataFlag ?
          dropboxLink :
          <a href="javascript:;" className="opener btn-dropbox">
            <i className="fa fa-dropbox" aria-hidden="true" />
            Dropbox
          </a>
        }
        <div
          className="slide"
          style={{ display: 'block', position: 'absolute', top: '-9999px', left: '-9999px', width: 297 }}
        >
          <DataPreviewList
            IMAGE={this.state.IMAGE}
            LAYOUT={this.state.LAYOUT}
            SPREADSHEET={this.state.SPREADSHEET}
            TEXT={this.state.TEXT}
            VIDEO={this.state.VIDEO}
            DATA={this.state.DATA}
            NOTEBOOK={this.state.NOTEBOOK}
            dataFlag={this.state.dataFlag}
            mouseUp={this.props.mouseUp}
            addClick={this.props.addClick}
            delClick={this.delClick}
            driveType="dropbox"
          />

          <DropboxChooser
            appKey="s9wqk0o36gu5yf6"
            success={this.dropboxResponse}
            cancel={this.dropboxReject}
            multiselect
          >
            <a href="javascript:;" className="btn-add-more dropbox" style={{ marginBottom: 10 }}>
              <i className="fa fa-dropbox" aria-hidden="true" />
              Add More
            </a>
          </DropboxChooser>

          <a href="javascript:;" onClick={this.addAllToWorkbook} className="btn-add-more oneDrive">
            <i className="fa fa-plus" aria-hidden="true" />
            Add All To Workbook
            </a>

        </div>
      </li>
    );
  }
}

Dropbox.propTypes = {
  mouseUp: PropTypes.func.isRequired,
  addClick: PropTypes.func.isRequired,
};
// export default connect(null, null)(Dropbox);

export default Dropbox;
