import React, { Component } from 'react';
import Google from './Google';
import Dropbox from './Dropbox';
import One from './One';
import Local from './Local';
import PropTypes from 'prop-types';

class DrivePage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Dropbox
          mouseUp={this.props.mouseUp}
          addClick={this.props.addClick}
        />

        <Google
          mouseUp={this.props.mouseUp}
          addClick={this.props.addClick}
        />

        <One
          mouseUp={this.props.mouseUp}
          addClick={this.props.addClick}
        />

        <Local
          mouseUp={this.props.mouseUp}
          addClick={this.props.addClick}
        />
      </div>

    );
  }
}

DrivePage.propTypes = {
  mouseUp: PropTypes.func.isRequired,
  addClick: PropTypes.func.isRequired,
};
export default DrivePage;
