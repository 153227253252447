import React, { Component } from 'react';
import { Row, Typography, Form, Input, Button, Alert, message } from 'antd'
import { connect } from 'react-redux';
import { userResetRequest } from '../../actions/signupActions';

import validateInput from '../../validations/newResetPassword';
import TextFieldGroup from '../common/TextFieldGroup';
import { Link } from 'react-router';
import { addFlashMessage } from '../../actions/flashMessages.js';
import PropTypes from 'prop-types';
import s from './signup.module.css';

import { FormattedMessage } from 'react-intl';
import messages from '../../messages'


const { Paragraph, Title } = Typography;
class ResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      errors: {},
      userData: {},
      isLoading: false,
      invalid: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  componentDidMount() {
    const token = this.props.userData.token;
    const email = this.props.userData.email;
    this.setState({ token, email });
    // let param = this.props.location.query;
    // console.log(param);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.isValid()) {
      this.setState({ errors: {}, isLoading: true });

    }
  }

  onFinish(values) {
    this.setState({ errors: {}, isLoading: true });
    const token = this.state.token;
    const email = this.state.email;

    this.props.userResetRequest({ ...values, token, email }).then(() => {
      message.success('Your password has been reset successfully. ');
      this.context.router.push('/');
    }, (err) => this.setState({ errors: err.response.data.errors, isLoading: false }));
  }

  render() {
    const { errors, isLoading, invalid } = this.state;
    const { intl } = this.context;

    return (
      <Row>
        <Title level={3}>
          <FormattedMessage {...messages.resetMessage} />
        </Title>
        <Paragraph>
          <FormattedMessage {...messages.resetSubMessage} />
        </Paragraph>

        <Form
          name="resetPasswordForm"
          size="large"
          onFinish={this.onFinish}
        >
          {errors.form &&
            <Form.Item>
              <Alert message={errors.form} type="error" />
            </Form.Item>}

          <Form.Item
            name="password"
            {...(errors.password && {
              help: errors.password,
              validateStatus: 'error',
            })}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input.Password placeholder={intl.formatMessage({ ...messages.password })} />
          </Form.Item>

          <Form.Item
            name="passwordConfirmation"
            {...(errors.passwordConfirmation && {
              help: errors.passwordConfirmation,
              validateStatus: 'error',
            })}
            rules={[{ required: true, message: 'This field is required' }, ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject('The two passwords that you entered do not match!');
              },
            }),]}
          >
            <Input.Password placeholder={intl.formatMessage({ ...messages.passwordConfirmation })} />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit" disabled={isLoading || invalid}>
              <FormattedMessage {...messages.reset} />
            </Button>
          </Form.Item>
        </Form>


        <FormattedMessage {...messages.loginYourAccount}
          values={{
            click_here: <Link className={s['btn-holo']} to="/"><FormattedMessage {...messages.clickHere} /></Link>
          }} />
      </Row>
    );
  }
}

ResetForm.propTypes = {
  userResetRequest: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
};

ResetForm.contextTypes = {
  router: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};
export default connect(null, { userResetRequest, addFlashMessage })(ResetForm);

// export default ResetForm;
