import axios from 'axios';

export function getUserProfile() {
    return dispatch => axios.get(`${process.env.REACT_APP_API_HOST}/api/users`);
}

export function updateUserProfile(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/users/profile`, data);
}

export function updateUserPhoto(data) {
    return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/users/profile-photo`, data);
}