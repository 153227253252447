import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import PopupContainer from '../PopupContainer';
import s from './PopupTypeOne.module.css';
import cx from 'classnames';

class PopupTypeOne extends Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		backdrop: PropTypes.string.isRequired,
		isOpen: PropTypes.bool.isRequired,
		size: PropTypes.string,

		onToggle: PropTypes.func.isRequired
	};

	static defaultProps = {
		title: '',
		size: 'sm',
		backdrop: 'static',
		isOpen: false,
		onToggle: () => {}
	};

	render() {
		const { title, tagLine, size, backdrop, isOpen, onToggle } = this.props;
		return (
			<PopupContainer isOpen={isOpen}>
				<div className={cx(s['at-forgotpasswordpopup'], s[size])}>
					<div className={s['at-popuphead']}>
						<h3>{title}</h3>
					</div>
					<div className={s['at-forgotcontent']}>{this.props.children}</div>
				</div>
			</PopupContainer>
		);
	}
}

export default PopupTypeOne;
