import axios from 'axios';

export function userSignupRequest(userData) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/users`, userData);
}

export function isUserExists(identifier) {
  return dispatch => axios.get(`${process.env.REACT_APP_API_HOST}/api/users/${identifier}`);
}

export function userResetRequest(userData) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/users/reset`, userData);
}
