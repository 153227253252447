/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(url, options) {
  let fOptions = {
    ...(options ? options : {}),
    ...{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(options && options.headers ? options.headers : {}),
        ...(localStorage.getItem("jwtToken") && {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        }),
      },
    },
  };

  const fetchResponse = await fetch(`${url}`, fOptions);
  const response = await parseJSON(fetchResponse);
  if (!fetchResponse.ok) {
    throw response;
  }

  return response;
}
