import axios from 'axios';

const actions = {
    create: (data) => {
        return axios.post(`${process.env.REACT_APP_API_HOST}/api/voting-activity/`, data);
    },
    read: (page, search, folder) => {
        return axios.get(`${process.env.REACT_APP_API_HOST}/api/voting-activity/?limit=20&page=${page}&search=${search}&folder=${folder}`);
    },
    readFolder: () => {
        return axios.get(`${process.env.REACT_APP_API_HOST}/api/voting-activity/folders`);
    },
    readById: (id) => {
        return axios.get(`${process.env.REACT_APP_API_HOST}/api/voting-activity/${id}`);
    },
    updateById: (id, data) => {
        return axios.patch(`${process.env.REACT_APP_API_HOST}/api/voting-activity/${id}`, data);
    },
    delete: (id) => {
        return axios.delete(`${process.env.REACT_APP_API_HOST}/api/voting-activity/${id}`);
    }
}

export default actions;