import React, { Component } from "react";
import { Link } from "react-router";
import cx from "classnames";
import PropTypes from "prop-types";
import TextFieldGroup from "../common/TextFieldGroup";
import { connect } from "react-redux";
import { addFlashMessage } from "../../actions/flashMessages.js";
import {
  updateUserProfile,
  getUserProfile,
  updateUserPhoto,
} from "../../actions/users";
import { refreshAuthToken } from "../../actions/authActions";
import s from "./ProfilePage.module.css";
import ProfileForm from "./ProfileForm";
import ProfileHeader from "./ProfileHeader";
import AboutPage from "./AboutPage";
import { FormattedMessage } from "react-intl";
import messages from "../../messages";
import { Modal } from "antd";
import CustomScrollbars from "../../utils/CustomScrollbars";

class ProfilePage extends Component {
  static propTypes = {
    updateUserProfile: PropTypes.func.isRequired,
    updateUserPhoto: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired,
    refreshAuthToken: PropTypes.func.isRequired,
    getUserProfile: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      userProfile: {},
      userForm: {},
      errors: {},
      lectureTools: false,
      license: false,
      licenseError: "",
      editProfile: false,
    };
  }

  onChangeLicense = (e) => {
    this.setState({ [e.target.name]: e.target.value, licenseError: "" });
  };

  onRegisterLicense = (e) => {
    if (!this.state.license) {
      this.setState({ licenseError: "License is required." });
    } else {
      const { updateUserProfile, addFlashMessage } = this.props;
      updateUserProfile({ license: this.state.license })
        .then((res) => {
          addFlashMessage({
            type: "success",
            text: "Your license settings updated successfully.",
          });
          this.getUserProfile();
        })
        .catch((err) => {
          let { data } = err.response;
          addFlashMessage({
            type: "error",
            text: data.message || "Error while updating your license settings.",
          });
        });
    }
  };
  componentDidMount = () => {
    this.getUserProfile();
  };

  getUserProfile = () => {
    const { getUserProfile, refreshAuthToken, addFlashMessage } = this.props;

    getUserProfile()
      .then((res) => {
        const { user } = res.data;
        this.setState({ userProfile: user, lectureTools: user.lectureTools });

        refreshAuthToken();
      })
      .catch((err) => {
        addFlashMessage({
          type: "error",
          text: "Something happen wrong, while fetch data from server.",
        });

        console.log(err);
      });
  };

  onChange = (e) => {
    e.preventDefault();

    const { updateUserPhoto } = this.props;
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("photo", file);

    updateUserPhoto(formData)
      .then((res) => {
        addFlashMessage({
          type: "success",
          text: res.data.message || "User profile photo updated successfully..",
        });
        this.getUserProfile();
      })
      .catch((err) => {
        addFlashMessage({
          type: "error",
          text:
            "Something happen wrong, while updating profile photo data from server.",
        });

        console.log(err);
      });
  };

  toggleChange = (e) => {
    const { updateUserProfile, addFlashMessage } = this.props;

    let { checked, name } = e.target;
    this.setState({ [name]: checked, lectureTools: checked });

    updateUserProfile({ lectureTools: checked })
      .then((res) => {
        addFlashMessage({
          type: "success",
          text: "Your tools settings updated successfully.",
        });

        this.getUserProfile();
      })
      .catch((err) => {
        const { errors } = err.response.data;

        console.error(errors);

        addFlashMessage({
          type: "error",
          text: "Error while updating your tools settings.",
        });
      });
  };

  render() {
    const { userProfile, lectureTools, licenseError } = this.state;

    return (
      <>
        <ProfileHeader
          editProfile={() => this.setState({ editProfile: true })}
          onChangeProfilePic={this.onChange}
          userProfile={userProfile}
          getUserProfile={this.getUserProfile}
          onChangeLicense={this.onChangeLicense}
          onRegisterLicense={this.onRegisterLicense}
          licenseError={licenseError}
        />

        <div className="gx-profile-content">
          <AboutPage userProfile={userProfile} />
        </div>
        <Modal
          footer={null}
          visible={this.state.editProfile}
          onCancel={() => this.setState({ editProfile: false })}
          title="Edit Profile"
          forceRender={true}
          destroyOnClose={true}
        >
          <ProfileForm
            userProfile={userProfile}
            getUserProfile={this.getUserProfile}
          />
        </Modal>
        {/* <div className={s["content"]}>
          <div className={s["profile-holder"]}>
            <div className={s["heading"]}>
              <h2>
                <FormattedMessage {...messages.profilePage} />
              </h2>
              <Link to="/folders" className={cx("pull-right", s["closebtn"])}>
                <i className="fa fa-times fa-lg"></i>
              </Link>
            </div>
            <div className={s["left-side"]}>
              <div className={s["profile-img"]}>
                <form>
                  <input
                    onChange={this.onChange}
                    type="file"
                    id="mediaFile"
                    accept="image/*"
                    name="photo"
                  />
                  <label htmlFor="mediaFile">
                    <img src={userProfile.profile_photo_path} />
                  </label>
                </form>
              </div>
            </div>
            <div className={s["right-side"]}>
              <div className={s["profile-img"]}>
                <img src={userProfile.profile_photo_path} />
              </div>
              <div className={s["profile-table"]}>
                <div className={cx(s["table-responsive"], "table-responsive")}>
                  <table className={cx(s["table"], "table")}>
                    <tbody>
                      <tr>
                        <td>
                          <FormattedMessage {...messages.firstName} />
                        </td>
                        <td>{userProfile.first_name}</td>
                      </tr>
                      <tr>
                        <td>
                          <FormattedMessage {...messages.lastName} />
                        </td>
                        <td>{userProfile.last_name}</td>
                      </tr>
                      <tr>
                        <td>
                          <FormattedMessage {...messages.username} />
                        </td>
                        <td>{userProfile.username}</td>
                      </tr>
                      <tr>
                        <td>
                          <FormattedMessage {...messages.email} />
                        </td>
                        <td>{userProfile.email}</td>
                      </tr>
                      <tr>
                        <td>
                          <FormattedMessage {...messages.password} />
                        </td>
                        <td>*********</td>
                      </tr>
                      <tr>
                        <td>License</td>
                        <td>
                          {userProfile.isLicensed ? (
                            "Your are using a Licensed Account"
                          ) : (
                            <React.Fragment>
                              <input
                                name="license"
                                onChange={this.onChangeLicense}
                              />
                              <button onClick={this.onRegisterLicense}>
                                Product Serial
                              </button>
                              <br />
                              <span>{licenseError}</span>
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className={s["at-checkboxholder"]}>
                <input
                  type="checkbox"
                  onChange={this.toggleChange}
                  checked={lectureTools}
                  name="checkboxG6"
                  id="checkboxG6"
                  className={s["css-checkbox"]}
                />
                <label htmlFor="checkboxG6" className={s["css-label"]}>
                  <FormattedMessage {...messages.wantToolsInSession} />
                </label>
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default connect(null, {
  updateUserProfile,
  getUserProfile,
  updateUserPhoto,
  refreshAuthToken,
  addFlashMessage,
})(ProfilePage);
