import React, { Component } from 'react';
import DataFormater from './common/DataFormater';

class DataPreviewList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
      <ul>
        {this.props.IMAGE && this.props.IMAGE.length > 0 ?
          <li>
            <a className="opener" href="javascript:;"><i className="fa fa-file-image-o" aria-hidden="true" />
              Pictures <sub className="media-count">
                ({this.props.IMAGE.length})</sub></a>
            <div
              className="slide"
              style={{
                display: 'block',
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                width: 297,
              }}
            >
              <DataFormater
                media={this.props.IMAGE}
                mouseUp={this.props.mouseUp}
                delClick={this.props.delClick}
                addClick={this.props.addClick}
              />
            </div>
          </li> : ''}
        {this.props.LAYOUT && this.props.LAYOUT.length > 0 ?

          <li>
            <a className="opener" href="javascript:;"><i className="fa fa-file-pdf-o" aria-hidden="true" />
              PDFs <sub className="media-count">
                ({this.props.LAYOUT.length})</sub></a>
            <div
              className="slide"
              style={{
                display: 'block',
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                width: 297,
              }}
            >
              <DataFormater
                media={this.props.LAYOUT}
                mouseUp={this.props.mouseUp}
                delClick={this.props.delClick}
                addClick={this.props.addClick}
              />
            </div>
          </li> : ''}
        {this.props.SPREADSHEET && this.props.SPREADSHEET.length > 0 ?


          <li>
            <a className="opener" href="javascript:;"><i className="fa fa-file-excel-o" aria-hidden="true" />
              Excels <sub className="media-count">
                ({this.props.SPREADSHEET.length})</sub></a>
            <div
              className="slide"
              style={{
                display: 'block',
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                width: 297,
              }}
            >
              <DataFormater
                media={this.props.SPREADSHEET}
                mouseUp={this.props.mouseUp}
                delClick={this.props.delClick}
                addClick={this.props.addClick}
              />
            </div>
          </li> : ''}
        {this.props.GOOGLE_SPREADSHEET && this.props.GOOGLE_SPREADSHEET.length > 0 ?


          <li>
            <a className="opener" href="javascript:;"><i className="fa fa-file-excel-o" aria-hidden="true" />
              Google Sheets <sub className="media-count">
                ({this.props.GOOGLE_SPREADSHEET.length})</sub></a>
            <div
              className="slide"
              style={{
                display: 'block',
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                width: 297,
              }}
            >
              <DataFormater
                media={this.props.GOOGLE_SPREADSHEET}
                mouseUp={this.props.mouseUp}
                delClick={this.props.delClick}
                addClick={this.props.addClick}
              />
            </div>
          </li> : ''}



        {this.props.TEXT && this.props.TEXT.length > 0 ?

          <li>
            <a className="opener" href="javascript:;"><i className="fa fa-file-word-o" aria-hidden="true" />
              Word Documents <sub className="media-count">
                ({this.props.TEXT.length})</sub></a>
            <div
              className="slide"
              style={{
                display: 'block',
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                width: 297,
              }}
            >
              <DataFormater
                media={this.props.TEXT}
                mouseUp={this.props.mouseUp}
                delClick={this.props.delClick}
                addClick={this.props.addClick}
              />
            </div>
          </li> : ''}
        {this.props.GOOGLE_TEXT && this.props.GOOGLE_TEXT.length > 0 ?

          <li>
            <a className="opener" href="javascript:;"><i className="fa fa-file-word-o" aria-hidden="true" />
              Google Docs <sub className="media-count">
                ({this.props.GOOGLE_TEXT.length})</sub></a>
            <div
              className="slide"
              style={{
                display: 'block',
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                width: 297,
              }}
            >
              <DataFormater
                media={this.props.GOOGLE_TEXT}
                mouseUp={this.props.mouseUp}
                delClick={this.props.delClick}
                addClick={this.props.addClick}
              />
            </div>
          </li> : ''}
        {this.props.VIDEO && this.props.VIDEO.length > 0 ?

          <li>
            <a className="opener" href="javascript:;"><i className="fa fa-file-video-o" aria-hidden="true" />
              Videos <sub className="media-count">
                ({this.props.VIDEO.length})</sub></a>
            <div
              className="slide"
              style={{
                display: 'block',
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                width: 297,
              }}
            >
              <DataFormater
                media={this.props.VIDEO}
                mouseUp={this.props.mouseUp}
                delClick={this.props.delClick}
                addClick={this.props.addClick}
              />
            </div>
          </li> : ''}
        {this.props.DATA && this.props.DATA.length > 0 ?
          <li>
            <a className="opener" href="javascript:;"><i
              className="fa fa-file-powerpoint-o"
              aria-hidden="true"
            />
              Power Point <sub className="media-count">
                ({this.props.DATA.length})</sub></a>
            <div
              className="slide"
              style={{
                display: 'block',
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                width: 297,
              }}
            >
              <DataFormater
                media={this.props.DATA}
                mouseUp={this.props.mouseUp}
                delClick={this.props.delClick}
                addClick={this.props.addClick}
              />
            </div>
          </li> : ''}

        {this.props.NOTEBOOK && this.props.NOTEBOOK.length > 0 ?
          <li>
            <a className="opener" href="javascript:;"><i
              className="fa fa-file-o"
              aria-hidden="true"
            />
              Smart Notebook <sub className="media-count">
                ({this.props.NOTEBOOK.length})</sub></a>
            <div
              className="slide"
              style={{
                display: 'block',
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                width: 297,
              }}
            >
              <DataFormater
                media={this.props.NOTEBOOK}
                mouseUp={this.props.mouseUp}
                delClick={this.props.delClick}
                addClick={this.props.addClick}
              />
            </div>
          </li> : ''}


        {this.props.GOOGLE_DATA && this.props.GOOGLE_DATA.length > 0 ?
          <li>
            <a className="opener" href="javascript:;"><i
              className="fa fa-file-powerpoint-o"
              aria-hidden="true"
            />
              Google Slides <sub className="media-count">
                ({this.props.GOOGLE_DATA.length})</sub></a>
            <div
              className="slide"
              style={{
                display: 'block',
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                width: 297,
              }}
            >
              <DataFormater
                media={this.props.GOOGLE_DATA}
                mouseUp={this.props.mouseUp}
                delClick={this.props.delClick}
                addClick={this.props.addClick}
              />
            </div>
          </li> : ''}
      </ul>

    );
  }
}

export default DataPreviewList;
