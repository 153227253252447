import React, { Component } from 'react';
import ToolHolder from '../holder/ToolHolder'
import PropTypes from 'prop-types'
// import './DiceRoller.css';
import cx from 'classnames'
import DiceRoller from './DiceRoller';

class DiceRollerHolder extends Component {
    static propTypes = {
        closeTool: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props)

        this.state = {}
    }

    rollAllDice = () => {
        this._dice1.rollDice();
        this._dice2.rollDice();
        this._dice3.rollDice();
    }

    componentDidMount() { }


    render() {
        let { closeTool } = this.props

        return (<ToolHolder name={'Dice Roller'} showResizer={false} classname={'at-diceholder'} closeTool={closeTool} tool={'Dice'}>
            <DiceRoller />
            <DiceRoller />
            {/* <DiceRoller /> */}
        </ToolHolder>
        );
    }
}

export default DiceRollerHolder;