// Youtube.js
import React, { Component } from 'react';
import { connect } from 'react-redux';

import TextFieldGroup from '../common/TextFieldGroup';
import classnames from 'classnames';
import { createWorkbook, fetchWorkbooks } from '../../actions/workbookActions';
import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

class Youtube extends Component {
  constructor(props) {
    super(props);
    this.state = {
      youtubeLink: '',
      youtubeThumbnail: '',
      errors: {},
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  // componentWillMount() {
  //     let { email, id } = this.props.auth.user;
  //     this.setState({ userEmail: email, userId: id });
  // }

  isValid() {
    const errors = {};

    if (Validator.isNull(this.state.youtubeLink)) {
      errors.youtubeLink = 'This field is required';
    }
    const results = this.state.youtubeLink.match('[\\?&]v=([^&#]*)');
    if (!results) {
      errors.youtubeLink = 'Enter Valid URL';
    }

    const isValid = isEmpty(errors);

    if (!isValid) {
      this.setState({ errors });
    }

    return isEmpty(errors);
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ errors: {}, isLoading: true });
      this.props.onClick(this.state.youtubeLink);
      this.setState({ youtubeLink: '', youtubeThumbnail: '' });
      $('#embed-youtube').modal('hide');
    }
  }

  onChange(e) {
    const results = e.target.value.match('[\\?&]v=([^&#]*)');
    const video = (results === null) ? e.target.value : results[1];
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ youtubeThumbnail: `http://img.youtube.com/vi/${video}/0.jpg` });
  }

  render() {
    const { youtubeLink, errors, isLoading } = this.state;
    return (
      <div aria-hidden="false" className="modal fade" id="embed-youtube" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" style={{ border: 0 }}>
              <button className="close" data-dismiss="modal" type="button">×</button>
              <h3 className="modal-title">Embed Youtube Video</h3>
            </div>
            <div className="modal-body clearfix">
              <form data-dpmaxz-eid={41} onSubmit={this.onSubmit}>

                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <TextFieldGroup
                    field="youtubeLink"
                    label="Add Youtube Video URL"
                    value={youtubeLink}

                    error={errors.youtubeLink}
                    onChange={this.onChange}
                  />
                </div>
                <div className="col-lg-12 col-sm-12 col-xs-12 img-center">
                  <img src={this.state.youtubeThumbnail} className="img-responsive" />
                </div>

                <div className="col-xs-12 text-center">
                  <div className="form-group btn-modal-holder">
                    <button disabled={isLoading} className="btn btn-default" data-dpmaxz-eid={44}>
                                            Embed
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>);
  }
}

Youtube.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string,
};

Youtube.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { createWorkbook, fetchWorkbooks })(Youtube);
