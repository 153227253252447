import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import map from 'lodash/map'
import math from 'mathjs'
import ToolHolder from '../holder/ToolHolder'
import TextFieldGroup from '../../../common/TextFieldGroup/TextFieldGroup'
import s from './TallyMarks.module.css';

class TallyMarks extends Component {
    static propTypes = {
        closeTool: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props)

        this.state = {
            number: 0
        }
    }

    componentDidMount = () => {
        const { socket, sessionId } = this.props

        socket.on("student:tallymarks", (data) => {
            this.setState(data.tallymarks)
        });
    }

    mannageTally = (type) => {
        const { socket, sessionId } = this.props

        switch (type) {
            case 'add':
                this.setState({ number: ++this.state.number }, () => {
                    socket.emit("teacher:tallymarks", { sessionId: sessionId, tallymarks: this.state });
                });
                break;
            case 'sub':
                this.setState({ number: --this.state.number }, () => {
                    socket.emit("teacher:tallymarks", { sessionId: sessionId, tallymarks: this.state });
                });
                break;
            case 'reset':
                this.setState({ number: 0 }, () => {
                    socket.emit("teacher:tallymarks", { sessionId: sessionId, tallymarks: this.state });
                });
                break;

            default:
                break;
        }

    }

    onChange = (e) => {
        const { socket, sessionId } = this.props

        this.setState({ [e.target.name]: e.target.value }, () => {
            socket.emit("teacher:tallymarks", { sessionId: sessionId, tallymarks: this.state });
        });
    }

    render() {
        let { closeTool } = this.props

        const { number } = this.state
        let tally = [];
        for (let i = 0; i < number; i++) {
            tally.push(<li key={i}></li>)
        }

        return (<ToolHolder name={'Tally Marks'} classname={'at-tallyholder'} closeTool={closeTool} tool={'Tally'}>
            <div className={s["tally-container"]}>
                <TextFieldGroup
                    error={''}
                    label="Add Tally"
                    onChange={this.onChange}
                    value={`${this.state.number}`}
                    field="number"
                    type="number"
                />
                <div className={s["button-container"]}>
                    <button onClick={this.mannageTally.bind(this, 'add')}>Add Tally</button>
                    <button onClick={this.mannageTally.bind(this, 'sub')}>Subtract Tally</button>
                    <button onClick={this.mannageTally.bind(this, 'reset')}>Reset</button>
                </div>
                <ol>{tally}</ol>
            </div>


        </ToolHolder>);
    }
}

export default TallyMarks;