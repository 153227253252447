import React, { Component } from 'react';
import loadScript from 'load-script';
import PropTypes from 'prop-types';

const GOOGLE_SDK_URL = ['https://apis.google.com/js/api.js', 'https://apis.google.com/js/client.js'];
const SCOPES = 'https://www.googleapis.com/auth/drive';


class GoogleDrive extends Component {
    static propTypes = {
        clientId: PropTypes.string.isRequired,
        developerKey: PropTypes.string.isRequired,
        onSuccess: PropTypes.func.isRequired,
        onFailure: PropTypes.func,

        style: PropTypes.object,
    };

    static defaultProps = {
        style: {},
        onFailure: (error) => { console.error(error) }
    };



    constructor(props) {
        super(props);
        this.scriptLoadingStarted = false;
    }

    componentDidMount = () => {
        if (this.isGoogleReady()) {
            this.onApiLoad();
        } else if (!this.scriptLoadingStarted) {
            this.scriptLoadingStarted = true;
            loadScript(GOOGLE_SDK_URL, this.onApiLoad);
        } else {
            console.info('google is loading ...')
        }
    }

    isGoogleReady = () => {
        return !!window.gapi;
    }

    isGoogleAuthReady = () => {
        return !!window.gapi.auth2;
    }

    onApiLoad = () => {
        window.gapi.load('client:auth2', this.initClient);
        // gapi.load('auth', { 'callback': this.initClient });

    }


    initClient = () => {
        const { clientId } = this.props

        window.gapi.client.init({
            clientId: clientId,
            scope: SCOPES,
        });

        window.gapi.load('picker');
        window.gapi.client.load('drive', 'v2', null);

        // windowgapi.client.load('drive');
    }


    handleAuthClick = () => {
        if (!this.isGoogleReady() || !this.isGoogleAuthReady() || !window.gapi.auth2.getToken || !window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
            window.gapi.auth2.getAuthInstance().signIn().then(() => {
                this.openPicker();
            });
        } else {
            this.openPicker();
        }
    }


    openPicker = () => {
        const { clientId, developerKey, onSuccess } = this.props;

        var view = new google.picker.View(google.picker.ViewId.DOCS);
        // view.setMimeTypes("image/png,image/jpeg,image/jpg");
        var picker = new google.picker.PickerBuilder()
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .setAppId('maxif-javed-presentation')
            .setOAuthToken(gapi.auth.getToken().access_token)
            .addView(view)
            .addView(new google.picker.DocsUploadView())
            .setDeveloperKey(developerKey)
            .setCallback(onSuccess)
            .build();
        picker.setVisible(true);
    }

    render() {
        return (
            <div onClick={this.handleAuthClick}>
                {this.props.children ? this.props.children : <button>Open Google Drive</button>}
            </div>
        );
    }



}

export default GoogleDrive;

// import React, { Component } from 'react';
// import loadScript from 'load-script';
// import PropTypes from 'prop-types';

// const GOOGLE_SDK_URL = ['https://apis.google.com/js/api.js'];
// // const SCOPES = 'https://www.googleapis.com/auth/drive';

// var developerKey = 'AIzaSyDYWEMkhEinKD1hSdwRz31M4BYnK21M6oU';
// var clientId = "822121502733-b90091552l3kcd04v9e9v3pr2lvu5bkm.apps.googleusercontent.com"
// var appId = "822121502733";

// var scope = ['https://www.googleapis.com/auth/drive'];

// var pickerApiLoaded = false;
// var oauthToken;


// class GoogleDrive extends Component {
//     static propTypes = {
//         clientId: PropTypes.string.isRequired,
//         onSuccess: PropTypes.func.isRequired,
//         onFailure: PropTypes.func.isRequired,

//         style: PropTypes.object,
//     };

//     static defaultProps = {
//         style: {}
//     };



//     constructor(props) {
//         super(props);
//         this.scriptLoadingStarted = false;
//     }

//     componentDidMount = () => {
//         if (this.isGoogleReady()) {
//             this.onApiLoad();
//         } else if (!this.scriptLoadingStarted) {
//             this.scriptLoadingStarted = true;
//             loadScript(GOOGLE_SDK_URL, this.onApiLoad);
//         } else {
//             console.info('google picker loading ...')
//         }
//     }

//     isGoogleReady = () => {
//         return !!window.gapi;
//     }

//     isGoogleAuthReady = () => {
//         return !!window.gapi.auth2;
//     }

//     onApiLoad = () => {
//         gapi.load('client:auth2', { 'callback': this.onAuthApiLoad });
//         gapi.load('picker', { 'callback': this.onPickerApiLoad });
//     }



//     // loadPicker = () => {
//     //     gapi.load('auth', { 'callback': this.onAuthApiLoad });
//     //     gapi.load('picker', { 'callback': this.onPickerApiLoad });
//     // }

//     onAuthApiLoad = () => {
//         gapi.client.init({
//             'client_id': clientId,
//             'scope': scope,
//         });
//     }

//     handleAuthResult = (authResult) => {
//         if (authResult && !authResult.error) {
//             oauthToken = authResult.access_token;
//             // this.createPicker();
//         }
//     }

//     createPicker = () => {
//         if (!this.isGoogleReady() || !this.isGoogleAuthReady() || !window.gapi.auth2.getToken || !window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
//             window.gapi.auth2.getAuthInstance().signIn().then(() => {
//                 this.openPicker();
//             });
//         } else {
//             this.openPicker();
//         }

//         // if (this.pickerApiLoaded && oauthToken) {
//         //     var view = new google.picker.View(google.picker.ViewId.DOCS);
//         //     view.setMimeTypes("image/png,image/jpeg,image/jpg");
//         //     var picker = new google.picker.PickerBuilder()
//         //         .enableFeature(google.picker.Feature.NAV_HIDDEN)
//         //         .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
//         //         .setAppId(appId)
//         //         .setOAuthToken(oauthToken)
//         //         .addView(view)
//         //         .addView(new google.picker.DocsUploadView())
//         //         .setDeveloperKey(developerKey)
//         //         .setCallback(pickerCallback)
//         //         .build();
//         //     picker.setVisible(true);
//         // }
//     }

//     openPicker = () => {
//         const token = window.gapi.auth2.getToken();
//         const oauthToken = token && token.access_token;

//         var view = new google.picker.View(google.picker.ViewId.DOCS);
//         view.setMimeTypes("image/png,image/jpeg,image/jpg");
//         var picker = new google.picker.PickerBuilder()
//             .enableFeature(google.picker.Feature.NAV_HIDDEN)
//             .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
//             .setAppId(appId)
//             .setOAuthToken(oauthToken)
//             .addView(view)
//             .addView(new google.picker.DocsUploadView())
//             .setDeveloperKey(developerKey)
//             .setCallback(pickerCallback)
//             .build();
//         picker.setVisible(true);
//     }



//     // openPicker = () => {
//     //     // debugger;
//     //     const { clientId, onSuccess } = this.props;

//     //     var view = new google.picker.View(google.picker.ViewId.DOCS);
//     //     view.setMimeTypes("image/png,image/jpeg,image/jpg");
//     //     var picker = new google.picker.PickerBuilder()
//     //         .enableFeature(google.picker.Feature.NAV_HIDDEN)
//     //         .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
//     //         .setAppId('maxif-javed-presentation')
//     //         .setOAuthToken(gapi.auth.getToken().access_token)
//     //         .addView(view)
//     //         .addView(new google.picker.DocsUploadView())
//     //         .setDeveloperKey('AIzaSyDYWEMkhEinKD1hSdwRz31M4BYnK21M6oU')
//     //         .setCallback(onSuccess)
//     //         .build();
//     //     picker.setVisible(true);


//     //     // const { disabled } = this.props;
//     //     // if (!this.isOneDriveReady() || disabled) { return null; }

//     //     // const { clientId, action, multiSelect, advanced, openInNewWindow, success, cancel, error, } = this.props;
//     //     // window.OneDrive.open({ clientId, action, multiSelect, advanced, openInNewWindow, success, cancel, error });
//     // }

//     render() {
//         return (
//             <div onClick={this.createPicker}>
//                 {this.props.children ? this.props.children : <button>Open Google Drive</button>}
//             </div>
//         );
//     }



// }

// export default GoogleDrive;