import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import {Link} from 'react-router';

let ele,
  dropEle,
  onArea,
  stopdraging,
  i;

function eleMouseDown(item, previewProp, mouseUp) {
  ele = document.getElementById('dragableImg');
  dropEle = document.getElementById('dropableImg');
  onArea = false;
  stopdraging = mouseUp;
  i = item;
  i.displayProp = item[previewProp];
  ele.firstElementChild.src = item[previewProp];
  ele
    .classList
    .remove('hide');
  document.addEventListener('mousemove', eleMouseMove, false);
  document.addEventListener('touchmove', eleMouseMove, false);
  document.addEventListener('mouseup', eleMouseUp, false);
  document.addEventListener('touchend', eleMouseUp, false);
}

function eleMouseMove(ev) {
  const dimentions = dropEle.getBoundingClientRect();

  const imgX = dimentions.left;
  const imgY = dimentions.top;
  const imgWidthX = dimentions.width + imgX;
  const imgHeightY = dimentions.height + imgY;
  const pX = (ev.pageX || ev.targetTouches[0].pageX) - 30;
  const pY = (ev.pageY || ev.targetTouches[0].pageY) - 30;

  ele.style.left = `${pX}px`;
  ele.style.top = `${pY}px`;

  if ((imgX < pX * 1 && pY * 1 > imgY) && (imgWidthX > pX * 1 && pY * 1 < imgHeightY)) {
    dropEle
      .classList
      .add('thumbnail');
    onArea = true;
  } else {
    dropEle
      .classList
      .remove('thumbnail');
    onArea = false;
  }
}

function eleMouseUp() {
  if (onArea) {
    dropEle.firstElementChild.src = ele.firstElementChild.src;
  }
  document
    .getElementById('dragableImg')
    .classList
    .add('hide');
  dropEle
    .classList
    .remove('thumbnail');
  document.removeEventListener('mousemove', eleMouseMove, false);
  document.removeEventListener('touchmove', eleMouseMove, false);
  document.removeEventListener('mouseup', eleMouseUp, false);
  document.removeEventListener('touchend', eleMouseUp, false);

  if (onArea) {
    stopdraging(i);
  }
}

export default function FieldItem({ item, mouseUp, addClick, delClick }) {
  return (
    <div className="row">
      <div className="space-holder">
        <div className="col-no-padding col-sm-5 col-xs-4 col-xs-5">
          <img
            className="img-responsive"
            onMouseDown={eleMouseDown.bind(this, item, 'thumbnail', mouseUp)}
            onTouchStart={eleMouseDown.bind(this, item, 'thumbnail', mouseUp)}
            src={item.thumbnail}
          />
        </div>
        <div className="filename-box col-sm-7 col-xs-8">
          <span className="file_name">{item.name}</span>
        </div>
      </div>
      <div className="btn-holder">
        <a href="javascript:;" onClick={addClick.bind(this, item, 'thumbnail')}> <i
          className="fa fa-plus"
          aria-hidden="true"
        /> </a>
        <a href="javascript:;" onClick={delClick.bind(this, item)}> <i
          className="fa fa-trash-o"
          aria-hidden="true"
        /> </a>
      </div>
    </div>
  );
}

FieldItem.propTypes = {
  item: PropTypes.object.isRequired,
  mouseUp: PropTypes.func.isRequired,
  addClick: PropTypes.func.isRequired,
  delClick: PropTypes.func.isRequired,
};
