export default {
  'BankFuturistic': 'BankFuturistic',
  'Agency FB': 'Agency FB',
  'Comic Sans MS': 'Comic Sans MS',
  'Verdana': 'Verdana',
  'Impact': 'Impact',
  'Minion': 'Minion',
  'Monospace': 'Monospace',
  'Courier': 'Courier',
  'Sans-serif': 'Sans-serif',
  'Garamond': 'Garamond',
  'Times': 'Times'
};
