import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { IntlProvider } from 'react-intl';

function getBrowserLocale() {
    const [lang, locale] = (((navigator.userLanguage || navigator.language).replace('-', '_')).toLowerCase()).split('_');
    return lang;
}
export class LanguageProvider extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    render() {
        const { isAuthenticated } = this.props.auth;
        const locale = isAuthenticated ? this.props.auth.user.locale || 'en' : getBrowserLocale();

        return (
            <IntlProvider locale={locale} key={locale} messages={this.props.messages[locale]}>
                {React.Children.only(this.props.children)}
            </IntlProvider>
        );
    }
}

LanguageProvider.propTypes = {
    auth: PropTypes.object.isRequired,
    messages: PropTypes.object,
    children: PropTypes.element.isRequired,
};

function mapStateToProps(state) {
    return { auth: state.auth };
}

export default connect(mapStateToProps)(LanguageProvider);