import axios from 'axios';
import { SET_CURRENT_USER_DETAILS } from './types';

export function setCurrentUserDetails(user) {
  return {
    type: SET_CURRENT_USER_DETAILS,
    user,
  };
}


export function userProfileRequest(userData) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/setting`, userData).then((res) => {
    const data = res.data;
    dispatch(setCurrentUserDetails(data));
  });
}

export function getUserDetails(identifier) {
  return dispatch => axios.get(`${process.env.REACT_APP_API_HOST}/api/setting/${identifier}`);
}

export function getGoogleClassroom(identifier) {
  return dispatch => axios.get(`${process.env.REACT_APP_API_HOST}/api/googleapi/`);
}

export function userResetRequest(userData) {
  return dispatch => axios.post(`${process.env.REACT_APP_API_HOST}/api/setting/reset`, userData).then((res) => {
    const data = res.data;
    dispatch(setCurrentUserDetails(data));
  });
}
