import { getPreview } from "../api";
import {
  IMAGE_TYPE,
  LAYOUT_TYPE,
  SPREADSHEET_TYPE,
  TEXT_TYPE,
  VIDEO_TYPE,
  DATA_TYPE,
  NOTEBOOK_TYPE,
} from "./types";

export function getExtention(name) {
  return name
    .slice((Math.max(0, name.lastIndexOf(".")) || Infinity) + 1)
    .toUpperCase();
}

export function getFileThumbnial(url) {
  let thumbnail = url;
  if (IMAGE_TYPE.indexOf(getExtention(url)) > -1) {
    thumbnail = url;
  } else if (LAYOUT_TYPE.indexOf(getExtention(url)) > -1) {
    thumbnail = "/images/thumbnails/img-07.jpg"; // images/file-type-excel.png
  } else if (SPREADSHEET_TYPE.indexOf(getExtention(url)) > -1) {
    thumbnail = "/images/thumbnails/img-05.jpg";
  } else if (VIDEO_TYPE.indexOf(getExtention(url)) > -1) {
    thumbnail = "/images/thumbnails/img-06.jpg";
  } else if (TEXT_TYPE.indexOf(getExtention(url)) > -1) {
    thumbnail = "/images/thumbnails/img-02.jpg";
  } else if (DATA_TYPE.indexOf(getExtention(url)) > -1) {
    thumbnail = "/images/thumbnails/img-03.jpg";
  } else if (NOTEBOOK_TYPE.indexOf(getExtention(url)) > -1) {
    thumbnail = "/images/thumbnails/img-04.jpg";
  }
  return thumbnail;
}

export function getFileType(name) {
  let fileType;

  if (IMAGE_TYPE.indexOf(getExtention(name)) > -1) {
    fileType = `image`;
  } else if (LAYOUT_TYPE.indexOf(getExtention(name)) > -1) {
    fileType = `pdf`;
  } else if (SPREADSHEET_TYPE.indexOf(getExtention(name)) > -1) {
    fileType = `excel`;
  } else if (VIDEO_TYPE.indexOf(getExtention(name)) > -1) {
    fileType = `video`;
  } else if (TEXT_TYPE.indexOf(getExtention(name)) > -1) {
    fileType = `word`;
  } else if (DATA_TYPE.indexOf(getExtention(name)) > -1) {
    fileType = `ppt`;
  } else if (NOTEBOOK_TYPE.indexOf(getExtention(name)) > -1) {
    fileType = `notebook`;
  }

  return fileType;
}

export const categorizeMedia = async (media) => {
  const category = {
    IMAGE: [],
    LAYOUT: [],
    SPREADSHEET: [],
    VIDEO: [],
    TEXT: [],
    DATA: [],
    NOTEBOOK: [],
  };
  for (const obj of media) {
    const name = obj.name;
    const record = {};
    record.drive = "dropbox";
    record.downloadLinkType = "link";
    record.downloadLink = obj.link;
    record.thumbnail = record.downloadLink; // images/file-type-excel.png
    record.name = obj.name;

    if (IMAGE_TYPE.indexOf(getExtention(name)) > -1) {
      record.downloadFileType = `image`;
      category.IMAGE.push(record);
    } else if (LAYOUT_TYPE.indexOf(getExtention(name)) > -1) {
      let result = await getPreview(
        null,
        "layout",
        JSON.stringify({ drive: "dropbox", link: obj.link, name: obj.name })
      );

      record.thumbnail = result.data.preview; // images/file-type-excel.png
      record.downloadFileType = `pdf`;
      category.LAYOUT.push(record);
    } else if (SPREADSHEET_TYPE.indexOf(getExtention(name)) > -1) {
      let result = await getPreview(
        null,
        "document",
        JSON.stringify({ drive: "dropbox", link: obj.link, name: obj.name })
      );
      record.thumbnail = result.data.preview;
      record.downloadFileType = `excel`;
      category.SPREADSHEET.push(record);
    } else if (VIDEO_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-06.jpg";
      record.downloadFileType = `video`;
      category.VIDEO.push(record);
    } else if (TEXT_TYPE.indexOf(getExtention(name)) > -1) {
      let result = await getPreview(
        null,
        "document",
        JSON.stringify({ drive: "dropbox", link: obj.link, name: obj.name })
      );
      record.thumbnail = result.data.preview;
      record.downloadFileType = `word`;
      category.TEXT.push(record);
    } else if (DATA_TYPE.indexOf(getExtention(name)) > -1) {
      let result = await getPreview(
        null,
        "document",
        JSON.stringify({ drive: "dropbox", link: obj.link, name: obj.name })
      );
      record.thumbnail = result.data.preview;
      record.downloadFileType = `ppt`;
      category.DATA.push(record);
    } else if (NOTEBOOK_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-04.jpg";
      record.downloadFileType = `notebook`;
      category.NOTEBOOK.push(record);
    }
  }
  return category;
};
export function categorizeGoogleMedia(media) {
  const category = {
    IMAGE: [],
    LAYOUT: [],
    SPREADSHEET: [],
    GOOGLE_SPREADSHEET: [],
    VIDEO: [],
    TEXT: [],
    GOOGLE_TEXT: [],
    DATA: [],
    GOOGLE_DATA: [],
    NOTEBOOK: [],
  };
  media.forEach((obj) => {
    const name = obj.name;
    obj.icon = obj.iconUrl;
    const record = {};
    record.drive = "google";
    record.id = obj.id;
    const token = window.gapi.auth.getToken();
    record.token = token.access_token;
    record.downloadLink = `https://drive.google.com/uc?export=download&id=${obj.id}`;
    record.thumbnail = `https://drive.google.com/uc?export=view&id=${obj.id}`;
    record.name = obj.name;
    record.downloadLinkType = "link";
    record.mimeType = obj.mimeType;
    if (IMAGE_TYPE.indexOf(getExtention(name)) > -1) {
      record.downloadFileType = `image`;
      category.IMAGE.push(record);
    } else if (LAYOUT_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-07.jpg";
      record.downloadFileType = `pdf`;
      category.LAYOUT.push(record);
    } else if (SPREADSHEET_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-05.jpg";
      record.downloadFileType = `excel`;
      category.SPREADSHEET.push(record);
    } else if (obj.serviceId == "spread") {
      record.thumbnail = "/images/thumbnails/img-05.jpg";
      record.downloadFileType = `excel`;
      record.name = `${record.name}.xlsx`;
      category.GOOGLE_SPREADSHEET.push(record);
    } else if (VIDEO_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-06.jpg";
      record.downloadFileType = `video`;
      category.VIDEO.push(record);
    } else if (TEXT_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-07.jpg";
      record.downloadFileType = `word`;
      category.TEXT.push(record);
    } else if (TEXT_TYPE.indexOf(obj.serviceId.toUpperCase()) > -1) {
      record.thumbnail = "/images/thumbnails/img-02.jpg";
      record.downloadFileType = `word`;
      record.name = `${record.name}.${obj.serviceId}`;
      category.GOOGLE_TEXT.push(record);
    } else if (DATA_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-03.jpg";
      record.downloadFileType = `ppt`;
      category.DATA.push(record);
    } else if (obj.serviceId == "pres") {
      record.thumbnail = "/images/thumbnails/img-03.jpg";
      record.downloadFileType = `ppt`;
      record.name = `${record.name}.ppt`;
      category.GOOGLE_DATA.push(record);
    } else if (NOTEBOOK_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-04.jpg";
      record.downloadFileType = `notebook`;
      category.NOTEBOOK.push(record);
    }
  });
  return category;
}
export function categorizeOneMedia(media) {
  const category = {
    IMAGE: [],
    LAYOUT: [],
    SPREADSHEET: [],
    VIDEO: [],
    TEXT: [],
    DATA: [],
    NOTEBOOK: [],
  };
  media.forEach((obj) => {
    const name = obj.name;
    const record = {};
    record.drive = "one";
    record.thumbnail = obj.thumbnails
      ? obj.thumbnails[0]
        ? obj.thumbnails[0].large.url
        : ""
      : "";
    record.downloadLink = obj["@microsoft.graph.downloadUrl"];
    record.downloadLinkType = "link";
    record.name = name;
    if (IMAGE_TYPE.indexOf(getExtention(name)) > -1) {
      record.downloadFileType = `image`;
      category.IMAGE.push(record);
    } else if (LAYOUT_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-07.jpg"; // images/file-type-excel.png
      record.downloadFileType = `pdf`;
      category.LAYOUT.push(record);
    } else if (SPREADSHEET_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-05.jpg";
      record.downloadFileType = `excel`;
      category.SPREADSHEET.push(record);
    } else if (VIDEO_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-06.jpg";
      record.downloadFileType = `video`;
      category.VIDEO.push(record);
    } else if (TEXT_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-02.jpg";
      record.downloadFileType = `word`;
      category.TEXT.push(record);
    } else if (DATA_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-03.jpg";
      record.downloadFileType = `ppt`;
      category.DATA.push(record);
    } else if (NOTEBOOK_TYPE.indexOf(getExtention(name)) > -1) {
      record.thumbnail = "/images/thumbnails/img-04.jpg";
      record.downloadFileType = `notebook`;
      category.NOTEBOOK.push(record);
    }
  });
  return category;
}

export async function categorizeLocalMedia(media) {
  let category = {
    IMAGE: [],
    LAYOUT: [],
    SPREADSHEET: [],
    VIDEO: [],
    TEXT: [],
    DATA: [],
    NOTEBOOK: [],
  };

  for (const file of media) {
    try {
      const fileContents = await readUploadedFileAsDataURL(file);
      const name = file.name;
      let record = {};
      record.drive = "local";
      record.thumbnail = file.preview;
      record.downloadLinkType = "base64";
      record.name = name;
      record.downloadLink = fileContents;

      if (IMAGE_TYPE.indexOf(getExtention(name)) > -1) {
        record.downloadFileType = `image`;
        category.IMAGE.push(record);
      } else if (LAYOUT_TYPE.indexOf(getExtention(name)) > -1) {
        let result = await getPreview(file, "layout");
        record.thumbnail = result.data.preview;
        record.downloadFileType = `pdf`;
        category.LAYOUT.push(record);
      } else if (SPREADSHEET_TYPE.indexOf(getExtention(name)) > -1) {
        let result = await getPreview(file, "document");
        record.thumbnail = result.data.preview;
        record.downloadFileType = `excel`;
        category.SPREADSHEET.push(record);
      } else if (VIDEO_TYPE.indexOf(getExtention(name)) > -1) {
        let result = await getPreview(file, "document");
        record.thumbnail = result.data.preview;
        record.downloadFileType = `video`;
        category.VIDEO.push(record);
      } else if (TEXT_TYPE.indexOf(getExtention(name)) > -1) {
        let result = await getPreview(file, `document`);
        record.thumbnail = result.data.preview;
        record.downloadFileType = `word`;
        category.TEXT.push(record);
      } else if (DATA_TYPE.indexOf(getExtention(name)) > -1) {
        let result = await getPreview(file, "document");
        record.thumbnail = result.data.preview;
        record.downloadFileType = `ppt`;
        category.DATA.push(record);
      } else if (NOTEBOOK_TYPE.indexOf(getExtention(name)) > -1) {
        record.thumbnail = "/images/thumbnails/img-04.jpg";
        record.downloadFileType = `notebook`;
        category.NOTEBOOK.push(record);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return category;
}

export function ValidURL(str) {
  var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  return regex.test(str);
}

export function readUploadedFileAsDataURL(inputFile) {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
}
