export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const DELETE_FLASH_MESSAGE = 'DELETE_FLASH_MESSAGE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';


export const SET_USER_WORKBOOK = 'SET_USER_WORKBOOK';


export const SET_CURRENT_USER_DETAILS = 'SET_CURRENT_USER_DETAILS';
export const SET_GALLERY_FILES = 'SET_GALLERY_FILES';
