import React, { Component } from "react";
import PropTypes from "prop-types";
import FlashMessagesList from "./flash/FlashMessagesList";

import { connect } from "react-redux";
import {
  addFlashMessage,
  deleteFlashMessage,
} from "../actions/flashMessages.js";

import s from "./App.module.css";

import { AuthLayout, UnAuthLayout } from "./layout/index";

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  render() {
    const { pathname } = location;
    if (
      pathname &&
      (pathname === "/" ||
        pathname.indexOf("signup") > -1 ||
        pathname.indexOf("reset") > -1)
    ) {
      return (
        <div>
          <UnAuthLayout>{this.props.children}</UnAuthLayout>
        </div>
      );
    } else if (pathname && pathname.indexOf("lecture") > -1) {
      return <div>{this.props.children}</div>;
    } else if (pathname && pathname.indexOf("webinar") > -1) {
      return <>{this.props.children}</>;
    } else if (pathname && pathname.indexOf("get-a-room") > -1) {
      return <>{this.props.children}</>;
    } else {
      return (
        <>
          <FlashMessagesList />
          <AuthLayout>{this.props.children}</AuthLayout>
        </>
      );
    }
  }
}

App.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

App.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, {
  addFlashMessage,
  deleteFlashMessage,
})(App);

// export default App;
